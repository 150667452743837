import { html, nothing, svg } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { ref, createRef } from 'lit/directives/ref.js'
import { styleMap } from 'lit/directives/style-map.js'
import { OneUxTypographyToken } from '../../generated/design-tokens'
import { StyledFactory } from '../../common/mixins/Styled'
import { OneUxElement } from '../../common/OneUxElement'
import { Boundary } from '../common/mixins/Boundary'
import { Focusable } from '../../common/mixins/Focusable'
import { log } from '../../common/utils/log'
import { style } from './style'
import { keyValue, keyLabel, serie, size, yAxisRange } from './types'
import { Lang } from '../common/mixins/Lang'
import { ValueFormatter } from '../common/format'
import { Legends } from './legend/Legends'
import { collectRenderGroups } from './render-groups/getRenderGroups'
import { KeyHoverPopout } from './axis/key/KeyHoverPopout'
import { splitRenderGroupsToLeftAndRight } from './render-groups/utils'
import { ValueAxis } from './axis/value/ValueAxis'
import { RenderGroups, RenderGroupsPointLabels } from './render-groups/RenderGroups'
import { DomainBaseline } from './axis/value/DomainBaseline'
import { KeyHoverBands } from './axis/key/KeyHoverBands'
import { KeyAxis } from './axis/key/KeyAxis'
import { calculateSvgMargin } from './calculateSvgMargin'
import { boundsKeyAxis, boundsLeftAxis, boundsLegends, boundsPlotArea, boundsRightAxis } from './bounds'
import { loadTypography } from '../common/typography'
import { getKeyScale } from './axis/key/getKeyScale'
import { getValueScale } from './axis/value/getValueScale'
import { KeyValueSet } from './axis/key/KeyValueSet'
import { PointLabelCollisionManager } from './point-label/PointLabelCollisionManager'
import { Supportlines } from './axis/value/Supportlines'
import { timing } from './animation'

const Styled = StyledFactory(style)

const BaseClass = Lang(Focusable(Boundary(Styled(OneUxElement))))

/**
 * Plot data on x/y axis in the form of column, line or area.
 */
@customElement('one-ux-plot-chart')
export class OneUxPlotChartElement extends BaseClass {
  constructor() {
    super()
    this.width = 'max'
  }

  #refs = {
    $svg: createRef()
  }

  #typographyTokens: OneUxTypographyToken[] = ['body-100', 'mono-100', 'label-300']

  /**
   * Defines the rendered keys in the x-axis. The type is an array of `string` | `number` | `{ key: string | number; label: string }`.
   * The key of a data point within a series will be plotted against the corresponding key.
   */
  @property({ type: Array, attribute: 'keys' })
  public keys!: (keyValue | keyLabel)[]

  /**
   * **serie**: Describes the series to be plotted on the x/y-axis.
   * * **type**: Defines what type of visual the serie is.
   *   * `column`: Columns are grouped in horizontal order.
   *   * `column-stacked`: Columns are grouped in vertical order.
   *   * `column-overlapped`: Columns are grouped in horizontal order but indented to lie behind the previous column.
   *   * `line`: A continous line from one data point to the next.
   *   * `area`: A continous area from one data point to the next.
   * * **curved**: (optional) `Boolean` whether to render type of `line` | `area` using catmull interpolation.
   * * **strokeDashed**: (optional) `Boolean` whether to render type of `line` | `area` with a dashed stroke.
   * * **strokeWidth**: (optional) `Number` specifying the width of the stroke for type `line` | `area`.
   * * **name**: Name of the series, is displayed in legend and when hovering x-axis.
   * * **color**: The color of the series.
   * * **yTarget**: (optional) Used to target a specific value axis, can be `left` | `right`, is by default set to `left`.
   * * **data**: An array of data points.
   *   * **key**: A unique number or text that says where the data point belong on the x-axis.
   *   * **value**: The value to be plotted based on given key.
   */
  @property({ type: Array, attribute: 'series' })
  public series: serie[] = []

  /**
   * SI notation suffix can be used to increase readability of labels when values are in the thousands and above.
   **/
  @property({ type: Boolean, attribute: 'use-si-suffix' })
  public useSISuffix = false

  /**
   * Supportlines in the value axis will be hidden.
   **/
  @property({ type: Boolean, attribute: 'hide-supportlines' })
  public hideSupportlines = false

  /**
   * Defines the possible min/max values for the left value axis.
   * * min: (optional) The lowest possible number to be displayed within the value axis.
   * * max: (optional) The highest possible number to be displayed within the value axis.
   */
  @property({ type: Object, attribute: 'axis-left-range' })
  public axisLeftRange?: yAxisRange

  /**
   * Defines the possible min/max values for the right value axis.
   * * min: (optional) The lowest possible number to be displayed within the value axis.
   * * max: (optional) The highest possible number to be displayed within the value axis.
   */
  @property({ type: Object, attribute: 'axis-right-range' })
  public axisRightRange?: yAxisRange

  /**
   * Disables collision detection for point labels and will therefore always be visible even when overlapping another point label.
   **/
  @property({ type: Boolean, attribute: 'disable-point-label-collision' })
  public disablePointLabelCollision = false

  /**
   * Disables all animations.
   **/
  @property({ type: Boolean, attribute: 'disable-animation' })
  public disableAnimation = false

  @state()
  private _hasDescription = false

  @state()
  private _svgSize: size = {
    width: 0,
    height: 0
  }

  @state()
  private _hoveredKeyBand: Element | undefined = undefined

  #updateSvgSizeTimout!: ReturnType<typeof setTimeout>

  protected willUpdate() {
    const isSvgSizeDefined = this._svgSize.width > 0 && this._svgSize.height > 0
    if (!isSvgSizeDefined) {
      const clientWidth = this.#refs.$svg.value?.clientWidth ?? 0
      const clientHeight = this.#refs.$svg.value?.clientHeight ?? 0
      if (clientWidth > 0 && clientHeight > 0) {
        this._svgSize = {
          width: clientWidth,
          height: clientHeight
        }
      }
    } else {
      clearTimeout(this.#updateSvgSizeTimout)
      const timeoutMs = this.#updateSvgSizeTimout ? 250 : 0
      this.#updateSvgSizeTimout = setTimeout(() => {
        const clientWidth = this.#refs.$svg.value?.clientWidth ?? 0
        const clientHeight = this.#refs.$svg.value?.clientHeight ?? 0

        if (this._svgSize.width !== clientWidth || this._svgSize.height !== clientHeight) {
          this._svgSize = {
            width: clientWidth,
            height: clientHeight
          }
        }
      }, timeoutMs)
    }
  }

  protected render() {
    if (!this.series) {
      log.error('Missing attribute series, not rendering.')
      return
    }
    if (!this.keys) {
      log.error('Missing attribute keys, not rendering.')
      return
    }

    loadTypography(this, this.#typographyTokens)

    const valueFormatter = new ValueFormatter(this._locale, this.useSISuffix)
    const animate = !this.disableAnimation

    return html`<div class="one-ux-element--root" role="group" tabindex="0">
      <svg ${ref(this.#refs.$svg)} style="width: 100%; flex: 1; overflow: visible;" role="presentation">
        ${this.#SvgContent(valueFormatter, animate)}
      </svg>
      ${this._hoveredKeyBand ? KeyHoverPopout(this._hoveredKeyBand, this.series, valueFormatter) : nothing}
      <div
        class="slots"
        style=${styleMap({
          display: !this._hasDescription ? 'none' : null,
          'animation-duration': this._hasDescription ? `${timing(animate).duration}ms` : null
        })}
      >
        <slot
          class="slot-description one-ux-typography--label-300"
          @slotchange=${this.#handleDescriptionSlotChange}
        ></slot>
      </div>
    </div>`
  }

  #handleDescriptionSlotChange = (event: Event) => {
    const validNodes = this.#getValidSlotNodes(event.target as HTMLSlotElement)
    this._hasDescription = !!validNodes.length
  }

  #getValidSlotNodes = ($slot: HTMLSlotElement) =>
    $slot.assignedNodes().filter((node) => {
      switch (node.nodeName) {
        case 'ONE-UX-ICON':
        case 'ONE-UX-LINK':
        case '#text':
          return true
      }
      return false
    })

  #SvgContent(valueFormatter: ValueFormatter, animate: boolean) {
    const isSizeDefined = this._svgSize.width > 0 && this._svgSize.height > 0
    if (!isSizeDefined) {
      return nothing
    }

    const keyValueSet = new KeyValueSet(this.keys)
    const renderGroups = collectRenderGroups(this.series, keyValueSet)
    const [leftRenderGroups, rightRenderGroups] = splitRenderGroupsToLeftAndRight(renderGroups)

    const svgMargin = calculateSvgMargin(
      this._svgSize,
      valueFormatter,
      leftRenderGroups,
      rightRenderGroups,
      this.series.map((x) => x.name),
      keyValueSet,
      this.axisLeftRange,
      this.axisRightRange
    )

    const keyScale = getKeyScale(this._svgSize, svgMargin, keyValueSet)
    const leftValueScale = getValueScale(boundsLeftAxis(this._svgSize, svgMargin), leftRenderGroups, this.axisLeftRange)
    const rightValueScale = getValueScale(
      boundsRightAxis(this._svgSize, svgMargin),
      rightRenderGroups,
      this.axisRightRange
    )
    const defaultValueScale = (leftValueScale ?? rightValueScale)!
    const pointLabelCollisionManager = new PointLabelCollisionManager(this.disablePointLabelCollision)

    return svg`${Legends(boundsLegends(this._svgSize, svgMargin), this.series, animate)}
      ${
        leftValueScale
          ? ValueAxis(boundsLeftAxis(this._svgSize, svgMargin), leftValueScale, valueFormatter, 'left', animate)
          : nothing
      }
      ${
        rightValueScale
          ? ValueAxis(boundsRightAxis(this._svgSize, svgMargin), rightValueScale, valueFormatter, 'right', animate)
          : nothing
      }
      ${
        !this.hideSupportlines
          ? Supportlines(boundsPlotArea(this._svgSize, svgMargin), defaultValueScale, animate)
          : nothing
      }
      ${DomainBaseline(boundsPlotArea(this._svgSize, svgMargin), defaultValueScale, animate)}
      ${RenderGroups(renderGroups, keyScale, animate, leftValueScale, rightValueScale)}
      ${KeyAxis(boundsKeyAxis(this._svgSize, svgMargin), keyScale, animate)}
      ${RenderGroupsPointLabels(
        renderGroups,
        keyScale,
        valueFormatter,
        pointLabelCollisionManager,
        animate,
        leftValueScale,
        rightValueScale
      )}
      ${KeyHoverBands(
        boundsPlotArea(this._svgSize, svgMargin),
        keyScale,
        ($el) => (this._hoveredKeyBand = $el),
        () => (this._hoveredKeyBand = undefined)
      )}`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-plot-chart': OneUxPlotChartElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-plot-chart': OneUxPlotChartElement
    }
  }
}
