export const callback = (callback) => ({
  value: function (...args) {
    return (callback || noop)(this, ...args)
  }
})
export const asyncCallback = (callback) => ({
  value: async function (...args) {
    return (callback || noop)(this, ...args)
  }
})
export const getterSetter = (fn) => ({
  get: function () {
    return fn(this)
  },
  set: function (value) {
    fn(this, ensureDefined(value))
  }
})
export const getter = (fn) => ({
  get: function () {
    return fn(this)
  }
})
export const reflectBool = (name) => {
  return getterSetter((element, value) => {
    if (isDefined(value)) {
      attr(element, name, value ? '' : null)
    } else {
      return attr(element, name) != null
    }
  })
}
export const reflectString = (name) => {
  return getterSetter((element, value) => {
    if (isDefined(value)) {
      attr(element, name, value)
    } else {
      return attr(element, name)
    }
  })
}

export const ensureDefined = (x) => (isDefined(x) ? x : null)
export const isDefined = (x) => typeof x !== 'undefined'
export const jsonCopy = (x) => JSON.parse(JSON.stringify(x))
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}
export const parseIntStrict = (value) => parseInt(Number(value))
export const toArray = (nodeList) => [].slice.call(nodeList)
export const groupBy = (list, properties) => {
  properties = Array.isArray(properties) ? properties : [properties]
  let groups = list.reduce((acc, item) => {
    let keys = properties.map((p) => (typeof p === 'function' ? p(item) : item[p]))

    acc[keys] = acc[keys] || []
    acc[keys].push(item)

    return acc
  }, {})

  return Object.keys(groups)
    .map((key) => groups[key])
    .filter((n) => n)
}

export const domReady = new Promise((resolve) => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', function handleReady() {
      document.removeEventListener('DOMContentLoaded', handleReady)
      resolve()
    })
  } else {
    resolve()
  }
})

export const query = (element, selector) => element.querySelector(selector)
export const queryAll = (element, selector) => toArray(element.querySelectorAll(selector))
export const tag = (tagName) => document.createElement(tagName)
export const hasAttr = (element, attributeName) => element.hasAttribute(attributeName)
export const attr = (element, attributeName, value) => {
  if (isDefined(value)) {
    if (value !== null) {
      element.setAttribute(attributeName, value)
    } else {
      element.removeAttribute(attributeName)
    }
  } else {
    return element.getAttribute(attributeName)
  }
}

export const on = (element, event, fn, capture) => element.addEventListener(event, fn, capture)
export const off = (element, event, fn, capture) => element.removeEventListener(event, fn, capture)
export const append = (element, elementToAppend, elementToAppendBefore) =>
  element.insertBefore(elementToAppend, elementToAppendBefore)
export const remove = (element) =>
  element && element.parentElement ? element.parentElement.removeChild(element) : element

export const removeTextNodes = (element) => {
  toArray(element.childNodes).forEach((x) => {
    if (x.nodeType === document.TEXT_NODE) {
      element.removeChild(x)
    }
  })
}

export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

export const placePopup = (popup, target, options = {}) => {
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight
  const targetRect = target.getBoundingClientRect()
  const prevLeft = options.prevLeft || 0
  const prevTop = options.prevTop || 0
  let left = targetRect.left
  let top = targetRect.bottom

  if (left + popup.offsetWidth > windowWidth) {
    left -= popup.offsetWidth - targetRect.width
  }
  if (top + popup.offsetHeight > windowHeight) {
    top -= popup.offsetHeight + targetRect.height
  }

  left = Math.floor(left)
  top = Math.floor(top)

  if (left !== prevLeft && Math.abs(left - prevLeft) > 1) {
    popup.style.left = left + 'px'
  }
  if (top !== prevTop && Math.abs(top - prevTop) > 1) {
    popup.style.top = top + 'px'
  }

  return {
    left,
    top
  }
}

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max)
