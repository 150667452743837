import { getTypography } from '@/one-ux/charts/common/typography'
import { keyLabel, keyRotation, keyValue } from '../../types'
import { wrapKeys } from './wrapKeys'
import { getTextSize } from '@/one-ux/charts/common/getTextSize'
import { getRotatedBoxSize } from '@/one-ux/charts/common/getRotatedBoxSize'

export class KeyValueSet {
  #keys: keyValue[]
  #labels: string[]
  #entries: Map<keyValue, string[]> = new Map()
  #isNumeric = true
  #isWrapped = false
  #keyRotation: keyRotation = 0
  #maxWidthKey!: keyValue
  #maxHeightKey!: keyValue

  constructor(keys: (keyValue | keyLabel)[]) {
    const keyLabels = transformToKeyLabel(keys)
    this.#keys = keyLabels.map((x) => x.key)
    this.#labels = keyLabels.map((x) => x.label)

    for (let i = 0; i < this.#keys.length; i++) {
      if (!Number.isFinite(parseFloat(this.#labels[i]))) {
        this.#isNumeric = false
        break
      }
    }

    wrapKeys(this.#labels).forEach((value, i) => {
      if (value.length > 1) {
        this.#isWrapped = true
      }

      this.#entries.set(this.#keys[i], value)
    })

    for (let i = 0; i < this.#keys.length; i++) {
      if (i === 0) {
        this.#maxWidthKey = this.#keys[i]
        this.#maxHeightKey = this.#keys[i]
      } else {
        const newSize = this.getRotatedSize(this.#keys[i], 0)

        const prevMaxWidthSize = this.getRotatedSize(this.#maxWidthKey, 0)
        if (newSize.boxWidth > prevMaxWidthSize.boxWidth) {
          this.#maxWidthKey = this.#keys[i]
        }

        const prevMaxHeightSize = this.getRotatedSize(this.#maxHeightKey, 0)
        if (newSize.boxHeight > prevMaxHeightSize.boxHeight) {
          this.#maxHeightKey = this.#keys[i]
        }
      }
    }
  }

  public get keys() {
    return this.#keys
  }

  public get length() {
    return this.#keys.length
  }

  public get fontToken() {
    return this.#isNumeric ? 'mono-100' : 'body-100'
  }

  public get lineHeight() {
    return this.#isNumeric ? 11 : 13
  }

  public get isNumeric() {
    return this.#isNumeric
  }

  public get isWrapped() {
    return this.#isWrapped
  }

  public hasKey(key: keyValue) {
    return this.#keys.includes(key)
  }

  public indexOf(key: keyValue) {
    return this.#keys.indexOf(key)
  }

  public getWrappedKey(key: keyValue) {
    return this.#entries.get(key)!
  }

  public getRotatedSize(key: keyValue, keyRotation: keyRotation) {
    const wrappedKey = this.#entries.get(key)!
    const { font, letterSpacing } = getTypography(this.fontToken)
    const widestTextSize = wrappedKey
      .map((line) => getTextSize(line, font, letterSpacing))
      .sort((a, b) => b.width - a.width)[0]
    const tickHeight = wrappedKey.length * this.lineHeight

    const boxSize = getRotatedBoxSize(widestTextSize.width, tickHeight, keyRotation)

    return {
      boxWidth: boxSize.width,
      boxHeight: boxSize.height,
      lineFontHeight: widestTextSize.fontHeight,
      lineActualHeight: widestTextSize.actualHeight
    }
  }

  public get maxWidthKeySize() {
    return this.getRotatedSize(this.#maxWidthKey, 0)
  }

  public get maxHeightKeySize() {
    return this.getRotatedSize(this.#maxHeightKey, 0)
  }

  public updateKeyRotation(keySize: number) {
    this.#keyRotation = calculateKeyRotation(keySize, this.maxWidthKeySize.boxWidth)
  }

  public get keyRotation() {
    return this.#keyRotation
  }
}

const calculateKeyRotation = (keySize: number, textWidth: number): keyRotation => {
  const keyToTextRatio = Number((textWidth / keySize).toFixed(1))
  if (keyToTextRatio >= 1.5) {
    return 90
  } else if (keyToTextRatio > 0.8) {
    return 45
  }

  return 0
}

const transformToKeyLabel = (keys: (keyValue | keyLabel)[]) =>
  keys
    .map<keyLabel>((key) => ({
      key: typeof key === 'object' && 'label' in key ? key.key : key,
      label: typeof key === 'object' && 'label' in key ? key.label ?? key.label : key?.toString() ?? ''
    }))
    .filter((x) => !(x.key === null || typeof x.key === 'undefined'))
