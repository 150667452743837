import { OneUxIconToken, OneUxPaletteToken } from '@/one-ux/generated/design-tokens'

export type InputSegment = {
  name: string
  value: number
  color: OneUxPaletteToken
  icon?: OneUxIconToken['status']
}

export const hiddenVisuals = ['inline-stats', 'text-label', 'text-total'] as const
export type HiddenVisual = 'all' | (typeof hiddenVisuals)[number]

export type InternalData = {
  gaugePercent: number
  totalValue: number
  segments: InternalSegment[]
  inlineStatsSpecs: InternalInlineSpecs
  snapSizeConfig: InternalSnapSizeConfig
}

export type InternalSegment = {
  index: number
  name: string
  value: number
  color: OneUxPaletteToken
  icon?: OneUxIconToken['status']
  startAngle: number
  endAngle: number
  highlighted: boolean
}

export type InternalInlineSpecs = {
  rowCount: number
  details: InternalInlineSpecsDetail[]
}

export type InternalInlineSpecsDetail = {
  rowIndex: number
  columnIndex: number
  statWidth: number
  accStatWidth: number
  offsetX: number
  offsetY: number
}

export type InternalSnapSizeConfig = {
  arcsMargin: number
  segmentDefaultSize: number
  segmentHighlightSize: number
  segmentPadAngleDegrees: number
  segmentCornerRadius: number
  pointerSize: number
  pointerOffset: number
  textLabelOffset: number
  inlineStatsOffsetTop: number
  inlineStatsIconSize: number
  inlineStatsHorizontalGap: number
  inlineStatsVerticalGap: number
  inlineStatsTextGap: number
}
