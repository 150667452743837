import { svg } from 'lit'
import { curvedSegment } from '../../common/paths/curvedSegment'
import { getLineSegments } from '../../common/getLineSegments'
import { FULL_CIRCLE_IN_RADIANS } from '../../common/constants'
import { svgTransition, ease, interpolate } from '../../common/directives/svgTransition'
import { indicationData } from '../types'
import { weight } from '@/one-ux/common/mixins/Weight'

const weightStyles: Record<
  weight,
  {
    gap: number
    radius: number
    height: number
    selectedHeight: number
  }
> = {
  high: {
    gap: 3,
    radius: 2,
    height: 6.5,
    selectedHeight: 13
  },
  normal: {
    gap: 2.5,
    radius: 1,
    height: 4,
    selectedHeight: 8
  },
  low: {
    gap: 2,
    radius: 1,
    height: 3,
    selectedHeight: 4
  }
}

export function ArcSegments({
  indication,
  size,
  x,
  y,
  startAngle,
  availableRadians,
  weight
}: {
  indication: indicationData[]
  size: number
  x: number
  y: number
  startAngle: number
  availableRadians: number
  weight: weight
}) {
  const weightStyle = weightStyles[weight]
  const perimiterWidth = size * Math.PI * (availableRadians / FULL_CIRCLE_IN_RADIANS)
  const borderRadius = weightStyle.radius
  const pixelToRadians = availableRadians / perimiterWidth

  const segments = getLineSegments({
    segments: indication.map((x) => ({
      value: 1,
      item: x
    })),
    width: perimiterWidth,
    minSegmentWidth: 2 * borderRadius,
    gap: weightStyle.gap
  })

  const anyHighlighted = segments.some((segment) => segment.item.highlighted)

  return svg`
    <g transform=${`translate(${x},${y})`} fill="none">
      <g transform=${`translate(${size / 2},${size / 2})`}>
        ${segments.map((segment) => {
          const height = segment.item.highlighted ? weightStyle.selectedHeight : weightStyle.height
          const opacity = !anyHighlighted || segment.item.highlighted ? 1 : 0.2
          return svg`
            <path
              class="segment"
              fill=${segment.item.color}
              ${svgTransition({
                timing: {
                  easing: ease.outCubic,
                  duration: 500
                },
                attributes: {
                  opacity: interpolate.standard(0, opacity, 0),
                  d: interpolate.standard(
                    [segment.width, segment.x, 0],
                    [segment.width, segment.x, height],
                    [segment.width, segment.x, 0],
                    ([segmentWidth, segmentX, segmentHeight]) =>
                      curvedSegment(
                        size / 2,
                        startAngle - segmentX * pixelToRadians,
                        segmentWidth * pixelToRadians,
                        segmentHeight,
                        borderRadius
                      )
                  )
                }
              })}
            />
          `
        })}
      </g>
    </g>
    `
}
