const color = {
  white: '#FFFFFF',
  darkgray: '#636363',
  gray: '#999',
  lightgray: '#8A9FB0',
  lightblack: '#2A2A2A',
  neutral600: '#154061',
  gray2: '#F2F2F2',
  gray300: '#D9D9D9',
  neutral300: '#BAC5CD'
}

const fontFamily = {
  roboto: 'Roboto, Arial, sans-serif'
}

const fontWeight = {
  x300: 300,
  x400: 400,
  x500: 500,
  x600: 600,
  x700: 700
}

const dominantBaseline = {
  central: 'central',
  hanging: 'hanging'
}

const size = {
  x0: 0,
  x1: 1,
  x2: 2,
  x3: 3,
  x4: 4,
  x5: 5,
  x6: 6,
  x7: 7,
  x8: 8,
  x9: 9,

  x10: 10,
  x11: 11,
  x12: 12,
  x13: 13,
  x14: 14,
  x15: 15,
  x16: 16,
  x17: 17,
  x18: 18,
  x19: 19,

  x20: 20,
  x21: 21,
  x22: 22,
  x23: 23,
  x24: 24,
  x25: 25,
  x26: 26,
  x27: 27,
  x28: 28,
  x29: 29,

  x30: 30,
  x40: 40
}

const opacity = {
  x0: 0,
  x02: 0.2,
  x03: 0.3,
  x08: 0.8,
  x1: 1
}

export default {
  color,
  fontFamily,
  fontWeight,
  size,
  opacity,
  dominantBaseline
}
