import { svg } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { KeyScale } from '../axis/key/KeyScale'
import { ValueScale } from '../axis/value/ValueScale'
import { ValueFormatter } from '../../common/format'
import { AreaRenderGroup, areaRenderGroupData } from './area/types'
import { LineRenderGroup, lineRenderGroupData } from './line/types'
import { PointLabelCollisionManager } from '../point-label/PointLabelCollisionManager'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { timing } from '../animation'

export function LineAreaPointLabel(
  keyScale: KeyScale,
  valueScale: ValueScale,
  group: LineRenderGroup | AreaRenderGroup,
  valueFormatter: ValueFormatter,
  pointLabelCollisionManager: PointLabelCollisionManager,
  animate: boolean
) {
  const data = group.line.filter((dp) =>
    canRenderPointLabel(dp, keyScale, valueScale, valueFormatter, pointLabelCollisionManager)
  )

  return repeat(
    data,
    (dp) => `line:${dp.key}:${dp.value}`,
    (dp) => {
      const x = keyScale.center(dp.key)
      const y = valueScale.clampPosition(dp.value)
      let dy = dp.value >= 0 ? -6 : 6
      const dominantBaseline = dp.value >= 0 ? 'auto' : 'hanging'
      const value = valueFormatter.formatValue(dp.value)

      const isKeyScaleIntersection = pointLabelCollisionManager.isIntersectingKeyScale(
        keyScale,
        value,
        x,
        y,
        0,
        dy,
        'middle',
        dominantBaseline
      )

      if (isKeyScaleIntersection) {
        dy -= 12
      }

      return svg`<text
        text-anchor="middle"
        dominant-baseline=${dominantBaseline}
        fill="var(--one-ux-palette--brand-neutral-600)"
        class="one-ux-typography--mono-100"
        style="margin: 0;"
        ${svgTransition({
          timing: timing(animate),
          attributes: {
            x: interpolate.constant(x, (x) => x.toString()),
            y: interpolate.constant(y, (y) => y.toString()),
            dy: interpolate.constant(dy, (dy) => dy.toString()),
            opacity: interpolate.standard(0, 1, 0)
          }
        })}>
          ${value}
      </text>`
    }
  )
}

const canRenderPointLabel = (
  dp: areaRenderGroupData | lineRenderGroupData,
  keyScale: KeyScale,
  valueScale: ValueScale,
  valueFormatter: ValueFormatter,
  pointLabelCollisionManager: PointLabelCollisionManager
) => {
  if (valueScale.getLimitPassed(dp.value) !== 'none') {
    return false
  }

  const hidePointLabel = !dp.showPointLabel
  const isValueUndefined = typeof dp.value === 'undefined'
  const isValueOutOfBounds = valueScale.getLimitPassed(dp.value) !== 'none'
  if (hidePointLabel || isValueUndefined || isValueOutOfBounds) {
    return false
  }

  const x = keyScale.center(dp.key)
  const y = valueScale.clampPosition(dp.value)
  let dy = dp.value >= 0 ? -6 : 6
  const dominantBaseline = dp.value >= 0 ? 'auto' : 'hanging'
  const value = valueFormatter.formatValue(dp.value)

  const isKeyScaleIntersection = pointLabelCollisionManager.isIntersectingKeyScale(
    keyScale,
    value,
    x,
    y,
    0,
    dy,
    'middle',
    dominantBaseline
  )

  if (isKeyScaleIntersection) {
    dy -= 12
  }

  const isCollision = pointLabelCollisionManager.isCollision(value, x, y, 0, dy, 'middle', dominantBaseline)
  if (isCollision) {
    return false
  }

  return true
}
