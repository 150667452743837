import { html, nothing } from 'lit'
import { classMap } from 'lit/directives/class-map.js'
import { ease, interpolate, svgTransition } from '../../../common/directives/svgTransition'
import { weight } from '../../../../common/mixins/Weight'
import { styleMap } from 'lit/directives/style-map.js'

const weightStyles: Record<
  weight,
  {
    svgHeight: number
  }
> = {
  high: { svgHeight: 16 },
  normal: { svgHeight: 12 },
  low: { svgHeight: 8 }
}

export function ShapeBar({
  compact,
  label,
  shadowRoot,
  total,
  value,
  valueText,
  altValueText,
  weight,
  slots
}: {
  compact: boolean
  label: string
  shadowRoot: ShadowRoot
  total: number
  value: number
  valueText: string
  altValueText: string
  weight: weight
  slots: {
    hasDescription: boolean
    descriptionChanged: (event: Event) => void
  }
}) {
  let width = 160
  const $svg = shadowRoot.querySelector('svg')
  if ($svg) {
    width = $svg.clientWidth
  }

  const { svgHeight } = weightStyles[weight]
  const barValue = value > 0 ? Math.max(Math.ceil((value / total) * width), svgHeight) : 0
  const completed = value >= total

  const transitionTiming = {
    easing: ease.outCubic,
    duration: 500
  }

  return html`<div
    class=${classMap({
      barShape: true,
      completed: completed
    })}
  >
    ${compact
      ? nothing
      : html`
          <div class="topRow one-ux-typography--label-400" aria-hidden="true">
            ${weight === 'high'
              ? html`<div class="valueText one-ux-typography--heading-500">${valueText}</div> `
              : nothing}
            <div class="label">${label}</div>
          </div>
        `}

    <div class="middleRow one-ux-typography--label-300" aria-hidden="true">
      <svg
        height="${svgHeight}"
        role="presentation"
        style=${`border-radius: ${svgHeight / 2}px`}
        one-ux-tooltip=${label}
      >
        <g>
          <rect class="bar--plate" height="${svgHeight}" width="100%"></rect>
          <rect
            class="bar--value"
            height="${svgHeight}"
            ${svgTransition({
              timing: transitionTiming,
              attributes: {
                width: interpolate.standard(0, barValue, 0)
              }
            })}
          ></rect>
        </g>
      </svg>
      ${!compact
        ? nothing
        : html`
            ${completed ? html`<one-ux-icon set="status" icon="done" size="100"></one-ux-icon>` : nothing}
            <div class="valueText">${valueText}</div>
          `}
    </div>

    <div
      class="bottomRow one-ux-typography--label-300"
      style=${styleMap({
        display: compact && !slots.hasDescription ? 'none' : null
      })}
    >
      <div class="slotArea">
        <slot @slotchange=${slots.descriptionChanged}></slot>
      </div>
      ${compact
        ? nothing
        : html`
            <div class="valueText" aria-hidden="true">${weight == 'high' ? altValueText : valueText}</div>
            ${completed ? html`<one-ux-icon set="status" icon="done" size="100"></one-ux-icon>` : nothing}
          `}
    </div>
  </div>`
}
