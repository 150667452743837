import { LanguageSets, langCode } from '../../common/utils/getLangCode'

export const getLanguageSet = (locale: langCode) => languages[locale]

export type LanguageSet = {
  name: string
  percent: string
  gaugeChart: string
  showDetails: string
}

const languages: LanguageSets<LanguageSet> = {
  en: {
    name: 'Name',
    percent: 'Percent',
    gaugeChart: 'Gauge chart',
    showDetails: 'Show table'
  },
  sv: {
    name: 'Namn',
    percent: 'Procent',
    gaugeChart: 'Mätardiagram',
    showDetails: 'Visa tabell'
  },
  nb: {
    name: 'Navn',
    percent: 'Prosent',
    gaugeChart: 'Målediagram',
    showDetails: 'Vis bord'
  },
  fi: {
    name: 'Nimi',
    percent: 'Prosenttia',
    gaugeChart: 'Mittarikaavio',
    showDetails: 'Näytä taulukko'
  },
  da: {
    name: 'Navn',
    percent: 'Procent',
    gaugeChart: 'Målediagram',
    showDetails: 'Vis bord'
  }
}
