import { css } from 'lit'

export const style = css`
  :host {
    justify-content: center !important;
    align-items: center !important;
    vertical-align: -0.125em;
  }

  svg {
    flex: 1 1 auto;
    min-width: 0;
    line-height: 0;
    height: 1em;
    width: 1em;
    fill: currentColor;
  }

  :host([size='100']) svg {
    font-size: var(--one-ux-size--icon-100);
  }
  :host([size='200']) svg {
    font-size: var(--one-ux-size--icon-200);
  }
  :host([size='300']) svg {
    font-size: var(--one-ux-size--icon-300);
  }
  :host([size='400']) svg {
    font-size: var(--one-ux-size--icon-400);
  }
  :host([size='500']) svg {
    font-size: var(--one-ux-size--icon-500);
  }
  :host([size='600']) svg {
    font-size: var(--one-ux-size--icon-600);
  }
  :host([size='700']) svg {
    font-size: var(--one-ux-size--icon-700);
  }
`
