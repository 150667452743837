import { OneUxElement } from '../common/OneUxElement'
import { html, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { style } from './style'
import { Weight } from '../common/mixins/Weight'
import { Purpose } from '../common/mixins/Purpose'
import { Implicit } from '../common/mixins/Implicit'
import { StyledFactory } from '../common/mixins/Styled'
import { parseLinks } from '../common/utils/link-parser'

const Styled = StyledFactory(style)

const BaseClass = Implicit(Purpose(Weight(Styled(OneUxElement))))

/**
 * A `<one-ux-message>` is used for creating an area that is of extra importance.
 */
@customElement('one-ux-message')
export class OneUxMessageElement extends BaseClass {
  /**
   * The heading of the message. Is not guaranteed to always be visible.
   */
  @property({ type: String })
  public heading = ''

  /**
   * The main message of the component. Will always be visible.
   */
  @property({ type: String })
  public content = ''

  /**
   * Enables parsing of the `content` property.
   * Will only parse Markdown-style links `(a link)[https://example.com]`
   * by default. Can be expanded to automatically pick up email and links with the options
   * `parse-links` and `parse-emails`.
   */
  @property({ type: Boolean })
  public parse = false

  /**
   * Parse the `content` string and make all urls into `<one-ux-link>` tags.
   * Will match `http://`, `https://`, `mailto:` and `tel:` tags.
   */
  @property({ attribute: 'parse-links', type: Boolean })
  public parseLinks = false

  /**
   * Parse the `content` string and make all emails into `<one-ux-link>` tags.
   * Will match any string with an `@` sign.
   */
  @property({ attribute: 'parse-emails', type: Boolean })
  public parseEmails = false

  constructor() {
    super()
    this.width = 'max'
  }

  protected render() {
    const hideHeading = !this.heading || this.weight === 'low'

    const { verticalIndent, horizontalIndent, columnGutters } = this.#getIndentAndGutters()
    const rowGutters = this.weight === 'high' ? 'normal' : 'small'
    const headingWeight = this.weight === 'high' ? '300' : '200'
    const iconVerticalAlignment = this.weight === 'high' ? 'center' : undefined

    const title = hideHeading ? this.heading : undefined

    const icon = this.#getIcon()
    return html`<one-ux-container
      aria-live=${this.#getAriaLive()}
      class="one-ux-element--root message"
      layout="columns"
      indent=${verticalIndent}
      indent-left=${horizontalIndent}
      indent-right=${horizontalIndent}
      gutters=${columnGutters}
      one-ux-tooltip=${ifDefined(title)}
      aria-label=${ifDefined(title)}
    >
      <one-ux-container width="min" align-vertical=${ifDefined(iconVerticalAlignment)}>
        <one-ux-icon aria-hidden="true" set=${icon.set} icon=${icon.icon}></one-ux-icon>
      </one-ux-container>
      <one-ux-container layout="rows" gutters=${rowGutters} align-vertical="center">
        ${hideHeading
          ? nothing
          : html`<strong class="heading one-ux-typography--heading-${headingWeight}">${this.heading}</strong>`}
        <span
          pdr-test-hook="one-ux-message-content"
          class="content one-ux-typography--body-${this.weight === 'low' ? '200' : '300'}"
          >${parseLinks(this.content.trim(), {
            parse: this.parse,
            parseEmails: this.parseEmails,
            parseLinks: this.parseLinks
          })}</span
        >
      </one-ux-container>
    </one-ux-container>`
  }

  #getIndentAndGutters() {
    switch (this.weight) {
      case 'high':
        return {
          verticalIndent: 'large',
          horizontalIndent: 'larger',
          columnGutters: 'larger'
        }
      case 'low':
        return {
          verticalIndent: 'normal',
          horizontalIndent: 'normal',
          columnGutters: 'normal'
        }
      case 'normal':
      default:
        return {
          verticalIndent: '400',
          horizontalIndent: 'large',
          columnGutters: 'large'
        }
    }
  }

  #getIcon() {
    switch (this.purpose) {
      case 'caution':
      case 'notice':
        return { set: 'default', icon: 'warning' }
      case 'main':
        return { set: 'status', icon: 'done' }
      case 'default':
      default:
        return { set: 'default', icon: 'info' }
    }
  }

  #getAriaLive() {
    return this.weight.startsWith('high') ? 'assertive' : 'polite'
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-message': OneUxMessageElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-message': OneUxMessageElement
    }
  }
}
