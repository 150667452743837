import { html, TemplateResult } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { styleMap } from 'lit/directives/style-map.js'
import { GroupData } from '../../types'
import { MenuItem, MenuItemOptions } from './MenuItem'
import { Icon } from './Icon'
import { findIndexBasedOnType } from '../../utils'

export function Group({
  item,
  currentItem,
  callbacks,
  rootDisabled,
  itemTypeIndex
}: MenuItemOptions<GroupData>): TemplateResult {
  const { children, expanded, text } = item
  const showSubMenu = !!children?.length && expanded && !rootDisabled

  return html`
    <div
      role="menuitem"
      aria-haspopup="menu"
      aria-expanded=${showSubMenu}
      aria-disabled=${rootDisabled}
      id=${ifDefined(item === currentItem ? 'active-menu-item' : undefined)}
      class="group"
      pdr-test-hook=${`one-ux-menu-group-${itemTypeIndex}`}
    >
      <div
        class="menu-item-content"
        @mousemove=${() => {
          callbacks.onChangeCurrentItem(item, true)
        }}
      >
        <div id=${`group-content-${item.instanceId}`} class="group-content">${Icon(item.icon)} ${text}</div>
        <one-ux-icon aria-hidden="true" icon="toggle-right"></one-ux-icon>
      </div>
      ${showSubMenu
        ? html`
            <one-ux-popout direction="horizontal" .offsetAlignment=${-8} indent="none">
              <one-ux-scroll implicit style="max-height: 35vh">
                <div
                  role="menu"
                  aria-labelledby=${`group-content-${item.instanceId}`}
                  style=${styleMap({
                    padding: 'var(--one-ux-spacing--normal)'
                  })}
                >
                  ${(children || []).map((child) =>
                    MenuItem({
                      item: child,
                      currentItem,
                      callbacks,
                      rootDisabled,
                      itemTypeIndex: findIndexBasedOnType(children, child)
                    })
                  )}
                </div>
              </one-ux-scroll>
            </one-ux-popout>
          `
        : null}
    </div>
  `
}
