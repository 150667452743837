const resolves = {}

const observer = new IntersectionObserver((entries) => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      resolve(entry.target)
    }
  }
})

export async function detectIsInViewport(element) {
  const promise = new Promise((resolve) => (resolves[getId(element)] = resolve))

  observer.observe(element)

  return promise
}

export function cleanupIsInViewport(element) {
  delete resolves[getId(element)]
  observer.unobserve(element)
}

const resolve = (element) => {
  const id = getId(element)
  if (id && resolves[id]) {
    resolves[id]()
    cleanupIsInViewport(element)
  }
}

const getId = (element) => element?.__internal__?.id
