import { AnimatedCustomElement } from '../AnimatedCustomElement'

export class LayoutScrollElement extends AnimatedCustomElement {
  #dynamicXBarElement = document.createElement('dynamic-layout-scroll-x-bar')
  #dynamicXBarHandleElement = document.createElement('dynamic-layout-scroll-x-bar-handle')
  #dynamicYBarElement = document.createElement('dynamic-layout-scroll-y-bar')
  #dynamicYBarHandleElement = document.createElement('dynamic-layout-scroll-y-bar-handle')

  #prevLeft
  #prevWidth
  #prevShowX
  #prevTop
  #prevHeight
  #prevShowY

  onInitialized() {
    this.#renderDynamicElements()
    this.#attachEvents()
  }

  animate() {
    const $content = this.content
    let showX = false
    let showY = false

    if ($content) {
      const left = (100 * $content.scrollLeft) / $content.scrollWidth
      const width = (100 * $content.clientWidth) / $content.scrollWidth
      const top = (100 * $content.scrollTop) / $content.scrollHeight
      const height = (100 * $content.clientHeight) / $content.scrollHeight
      showX = $content.scrollWidth - $content.clientWidth > 0
      showY = $content.scrollHeight - $content.clientHeight > 0

      if (left !== this.#prevLeft) {
        this.#dynamicXBarHandleElement.style.left = left + '%'
        this.#prevLeft = left
      }
      if (width !== this.#prevWidth) {
        this.#dynamicXBarHandleElement.style.width = width + '%'
        this.#prevWidth = width
      }
      if (top !== this.#prevTop) {
        this.#dynamicYBarHandleElement.style.top = top + '%'
        this.#prevTop = top
      }
      if (height !== this.#prevHeight) {
        this.#dynamicYBarHandleElement.style.height = height + '%'
        this.#prevHeight = height
      }
    }

    if (showX !== this.#prevShowX) {
      this.setAttribute('state-show-x', showX)
      this.#prevShowX = showX
    }
    if (showY !== this.#prevShowY) {
      this.setAttribute('state-show-y', showY)
      this.#prevShowY = showY
    }
  }

  get content() {
    for (let i = 0; i < this.children.length; ++i) {
      if (this.children[i].tagName === 'LAYOUT-SCROLL-CONTENT') {
        return this.children[i]
      }
    }
    return null
  }

  #renderDynamicElements() {
    this.#dynamicXBarElement.append(this.#dynamicXBarHandleElement)
    this.#dynamicYBarElement.append(this.#dynamicYBarHandleElement)
    this.append(this.#dynamicXBarElement, this.#dynamicYBarElement)
  }

  #attachEvents() {
    this.#dynamicXBarElement.addEventListener('mousedown', this.#createMouseDownBarHandler('x'))
    this.#dynamicYBarElement.addEventListener('mousedown', this.#createMouseDownBarHandler('y'))
    this.#dynamicYBarElement.addEventListener('wheel', this.#handleYBarWheel, { passive: true })
  }

  #handleYBarWheel = (event) => {
    const content = this.content
    if (content) {
      content.scrollTop += event.deltaY * 40
    }
  }

  #createMouseDownBarHandler(type) {
    const isX = type === 'x'
    const $bar = this.querySelector(isX ? 'dynamic-layout-scroll-x-bar' : 'dynamic-layout-scroll-y-bar')
    const $handle = this.querySelector(
      isX ? 'dynamic-layout-scroll-x-bar-handle' : 'dynamic-layout-scroll-y-bar-handle'
    )
    const scrollValue = isX ? 'scrollLeft' : 'scrollTop'
    const scrollSize = isX ? 'scrollWidth' : 'scrollHeight'
    const clientValue = isX ? 'clientX' : 'clientY'
    const clientSize = isX ? 'clientWidth' : 'clientHeight'
    const pageValue = isX ? 'pageX' : 'pageY'
    const rectValue = isX ? 'left' : 'top'

    return (event) => {
      event.preventDefault()
      requestAnimationFrame(() => {
        const content = this.content

        if (!content) {
          return
        }

        const factor = content[scrollSize] / $bar[clientSize]
        const startMouseValue = event[clientValue]
        let startScrollValue = content[scrollValue]

        if (event.target === $bar) {
          startScrollValue = content[scrollValue] =
            (event[pageValue] -
              document.documentElement[scrollValue] -
              $bar.getBoundingClientRect()[rectValue] -
              $handle[clientSize] / 2) *
            factor
        }

        function mouseMove(e) {
          requestAnimationFrame(() => {
            content[scrollValue] = startScrollValue + (e[clientValue] - startMouseValue) * factor
          })
        }

        function mouseUp() {
          requestAnimationFrame(() => {
            document.removeEventListener('mousemove', mouseMove)
            document.removeEventListener('mouseup', mouseUp)
            $bar.removeAttribute('active')
          })
        }

        $bar.setAttribute('active', '')
        document.addEventListener('mousemove', mouseMove)
        document.addEventListener('mouseup', mouseUp)
      })
    }
  }
}
