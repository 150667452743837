import { KeyScale } from '../axis/key/KeyScale'
import { bounds } from '../types'
import { calculatePointLabelRect } from './calculatePointLabelRect'

export class PointLabelCollisionManager {
  #rects: DOMRect[] = []
  #disabled = false

  constructor(disabled = false) {
    this.#disabled = disabled
  }

  public isCollision(
    text: string,
    x: number,
    y: number,
    dx: number,
    dy: number,
    textAnchor: 'middle' | 'start' | 'end',
    dominantBaseline: 'auto' | 'middle' | 'hanging'
  ) {
    if (this.#disabled) {
      return false
    }

    const currentRect = calculatePointLabelRect(text, x, y, dx, dy, textAnchor, dominantBaseline)

    let isCollision = false
    for (const rect of this.#rects) {
      if (this.#isIntersection(currentRect, rect)) {
        isCollision = true
        break
      }
    }

    this.#rects.push(currentRect)

    return isCollision
  }

  public isIntersectingKeyScale(
    keyScale: KeyScale,
    text: string,
    x: number,
    y: number,
    dx: number,
    dy: number,
    textAnchor: 'middle' | 'start' | 'end',
    dominantBaseline: 'auto' | 'middle' | 'hanging'
  ) {
    const bounds = keyScale.bounds
    const currentRect = calculatePointLabelRect(text, x, y, dx, dy, textAnchor, dominantBaseline)

    return this.#isIntersection(bounds, currentRect)
  }

  #isIntersection(a: DOMRect | bounds, b: DOMRect | bounds) {
    return !(b.left > a.right || b.right < a.left || b.top > a.bottom || b.bottom < a.top)
  }
}
