const sv = {
    confirm: {
        header: 'Bekräfta',
        message: 'Är du säker?',
        ok: 'OK',
        cancel: 'Avbryt'
    },
    alert: {
        header: 'Meddelande',
        ok: 'OK'
    }
};
const en = {
    confirm: {
        header: 'Confirm',
        message: 'Are you sure?',
        ok: 'OK',
        cancel: 'Cancel'
    },
    alert: {
        header: 'Message',
        ok: 'OK'
    }
};
const locales = {
    sv: sv,
    'sv-se': sv,
    en: en,
    'en-gb': en
};
export const getLanguage = (locale) => ({
    lang: (locale || '').toLowerCase(),
    translations: locales[(locale || '').toLowerCase()] || en
});
