import { OneUxElement } from '../common/OneUxElement'
import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { styleMap } from 'lit/directives/style-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { style } from './style'
import { Layout } from '../common/mixins/Layout'
import { Explicit } from '../common/mixins/Explicit'
import { StyledFactory } from '../common/mixins/Styled'

const backgroundsWithDarkTheme = ['gray-600', 'gray-700', 'brand-neutral-600', 'brand-neutral-700']

const Styled = StyledFactory(style)

const BaseClass = Explicit(Layout(Styled(OneUxElement)))

type alignment = 'start' | 'center' | 'end'

/**
 * A component that allows you to set a theme-sensitive background and align
 * its content area. Will set the theme of child components so that good
 * contrast is maintained depending on the background specified.
 */
@customElement('one-ux-container')
export class OneUxContainerElement extends BaseClass {
  /**
   * The background of the container.
   */
  @property({ type: String })
  public background?: string

  /**
   * Align content area horizontally and vertically at the same time.
   * See `.alignHorizontal` and `.alignVertical` for more information.
   */
  @property({ type: String })
  public align?: alignment

  /**
   * Align content area horizontally. If undefined, the area will stretch
   * and cover the entire inner width. If defined, the content area will
   * wrap the width around the actual content and place the area horizontally
   * according to the alignment.
   */
  @property({ attribute: 'align-horizontal', type: String })
  public alignHorizontal?: alignment

  /**
   * Align content area vertically. If undefined, the area will stretch
   * and cover the entire inner height. If defined, the content area will
   * wrap the height around the actual content and place the area vertically
   * according to the alignment.
   */
  @property({ attribute: 'align-vertical', type: String })
  public alignVertical?: alignment

  constructor() {
    super()
    this.width = 'auto'
    this.height = 'auto'
  }

  protected render() {
    return html`<div
      class="container one-ux-element--root"
      theme=${ifDefined(this.#getTheme())}
      style=${styleMap({
        '--one-ux-container-element--background': this.background ? `var(--one-ux-palette--${this.background})` : null
      })}
      state-align-horizontal=${ifDefined(this.alignHorizontal || this.align)}
      state-align-vertical=${ifDefined(this.alignVertical || this.align)}
    >
      <div class="one-ux-element--content">
        <slot></slot>
      </div>
    </div>`
  }

  #getTheme = () => {
    if (!this.background && !this.explicit) {
      return
    }
    if (backgroundsWithDarkTheme.includes(this.background || '')) {
      return 'dark'
    }
    return 'light'
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-container': OneUxContainerElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-container': OneUxContainerElement
    }
  }
}
