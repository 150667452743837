import { svg } from 'lit'
import { distributionMetadata, distributionValues } from '../../common/types'
import { curvedSegment } from '../../common/paths/curvedSegment'
import { getLineSegments } from '../../common/getLineSegments'
import { FULL_CIRCLE_IN_RADIANS } from '../../common/constants'
import { svgTransition, ease, interpolate } from '../../common/directives/svgTransition'
import { weight } from '../../../common/mixins/Weight'

const weightStyles: Record<
  weight,
  {
    gap: number
    radius: number
    height: number
  }
> = {
  high: {
    gap: 3,
    radius: 2,
    height: 6.5
  },
  normal: {
    gap: 2.5,
    radius: 1,
    height: 4
  },
  low: {
    gap: 2,
    radius: 1,
    height: 3
  }
}

export function CircleSegments({
  distribution,
  metadata,
  size,
  x,
  y,
  weight
}: {
  distribution: distributionValues
  metadata: distributionMetadata[]
  size: number
  x: number
  y: number
  weight: weight
}) {
  const weightStyle = weightStyles[weight]
  const perimiterWidth = size * Math.PI
  const borderRadius = weightStyle.radius
  const pixelToRadians = FULL_CIRCLE_IN_RADIANS / perimiterWidth
  const startAngle = 0.25 * FULL_CIRCLE_IN_RADIANS

  const segments = getLineSegments({
    segments: metadata.map((x) => ({
      item: x,
      value: distribution[x.key] || 0
    })),
    width: perimiterWidth,
    minSegmentWidth: 2 * borderRadius,
    gap: weightStyle.gap,
    loop: true
  })

  return svg`
    <g transform=${`translate(${x},${y})`} fill="none">
      <g transform=${`translate(${size / 2},${size / 2})`}>
        ${segments.map(
          (segment) => svg`
            <path
              fill=${segment.item.color}
              ${svgTransition({
                timing: {
                  easing: ease.outCubic,
                  duration: 500
                },
                attributes: {
                  opacity: interpolate.standard(0, 1, 0),
                  d: interpolate.standard(
                    [0, segment.x, 0],
                    [segment.width, segment.x, weightStyle.height],
                    [0, segment.x + segment.width / 2, 0],
                    ([segmentWidth, segmentX, segmentHeight]) =>
                      curvedSegment(
                        size / 2,
                        startAngle - segmentX * pixelToRadians,
                        segmentWidth * pixelToRadians,
                        segmentHeight,
                        borderRadius
                      )
                  )
                }
              })}
            />
          `
        )}
      </g>
    </g>
    `
}
