import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Focusable } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { Compact } from '../common/mixins/Compact'
import { Purpose } from '../common/mixins/Purpose'
import { OneUxElement } from '../common/OneUxElement'
import { IValue, ValueFactory } from '../common/mixins/Value'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../common/mixins/Validated'
import { InternalValueChangedEvent } from '../events/internal/InternalValueChangedEvent'
import { FormAssociated } from '../common/mixins/FormAssociated'
import { Required, type IRequired } from '../common/mixins/Required'
import { Label } from '../common/mixins/Label'
import { FieldSetProps } from '../one-ux-field-set/FieldSetProps'
import { Errors } from '../common/mixins/Errors'

type CheckboxOption = {
  value: unknown
  text: string
}

type valueType = unknown[]
const Value = ValueFactory<valueType>({
  type: Array,
  reflect: true
})

const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldYouHaveToMakeChoice } = getFormValidationLanguage(this)
    const hasValue = !!this.value?.length

    return {
      valid: hasValue,
      flags: {
        valueMissing: !hasValue
      },
      errors: [fieldYouHaveToMakeChoice]
    }
  }
})

const BaseClass = Errors(
  Label(Required(FieldSetProps(FormAssociated(Validated(Value(Focusable(Compact(Implicit(Purpose(OneUxElement))))))))))
)

/**
 * A group of check boxes, where several can be checked.
 */
@customElement('one-ux-checkbox-group')
export class OneUxCheckboxGroupElement extends BaseClass {
  constructor() {
    super()

    this.value = []
  }
  /*
   * The list of options for the checkbox group.
   * * text: Displayed text for option
   * * value: When listening on the option event it will be passed as argument
   */
  @property({ type: Array })
  public options: CheckboxOption[] = []

  /*
   * Placement of the checkboxes against their texts.
   */
  @property({ attribute: 'checkbox-position', type: String })
  public checkboxPosition = 'before-text' as 'before-text' | 'after-text'

  render() {
    return html`
      <one-ux-field-set
        class="one-ux-element--root"
        .delegateAria=${{
          role: 'listbox',
          'aria-orientation': 'vertical',
          'aria-multiselectable': true
        }}
        .implicit=${this.implicit}
        .compact=${this.compact}
        .label=${this.label}
        .columns=${this.columns}
        .errors=${this.errors}
        .required=${this.required}
      >
        ${this.options.map(
          ({ value, text }, index) => html`
            <one-ux-checkbox
              tabindex=${index == 0 ? (this.hasKeyboardFocus ? -1 : 0) : -1}
              .checkboxPosition=${this.checkboxPosition}
              .checked=${Array.isArray(this.value) ? this.value.includes(value) : false}
              .delegateAria=${{ role: 'option' }}
              .implicit=${this.implicit}
              .purpose=${this.purpose}
              .text=${text}
              pdr-test-hook="one-ux-checkbox-group-item-${String(value)}"
              @change=${() => this.#handleSelect(value)}
            >
            </one-ux-checkbox>
          `
        )}
      </one-ux-field-set>
    `
  }

  #select(value: unknown) {
    if (this.value?.includes(value)) {
      this._applyUserValue(this.value.filter((v: unknown) => v !== value))
    } else {
      this._applyUserValue([value, ...(this.value || [])])
    }
  }

  #handleSelect(value: unknown) {
    this.#select(value)
    this.dispatchEvent(new Event('input'))
    this.dispatchEvent(new Event('change'))
    this.dispatchEvent(new InternalValueChangedEvent())
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-checkbox-group': OneUxCheckboxGroupElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-checkbox-group': OneUxCheckboxGroupElement
    }
  }
}
