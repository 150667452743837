import { PropertyValues, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { Focusable } from '../../common/mixins/Focusable'
import { Compact } from '../../common/mixins/Compact'
import { Boundary } from '../common/mixins/Boundary'
import { StyledFactory } from '../../common/mixins/Styled'
import { style } from './style'
import { DistributionPopout } from '../common/components/DistributionPopout'
import { Legend } from '../common/components/Legend'
import { AccessibleDistributionTable } from '../common/components/AccessibleDistributionTable'
import { Axis } from './components/Axis'
import { Bar } from './components/Bar'
import { Guides } from './components/Guides'
import { keyCodes } from '../../common/utils'
import { getLanguage } from './lang'
import { OneUxElement } from '../../common/OneUxElement'
import { styleMap } from 'lit/directives/style-map.js'
import { classMap } from 'lit/directives/class-map.js'
import { legendItem, barItem, barChartType } from './types'
import { palette } from '../../generated/design-tokens.json'
import { OneUxPaletteToken } from '../../generated/design-tokens'
import { Lang } from '../common/mixins/Lang'
import { Implicit } from '../../common/mixins/Implicit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { ID_ACTIVE_BAR } from './constants'
import { AxisScale } from '../common/AxisScale'
import { Label } from '../../common/mixins/Label'
import { createAxisScale } from './createAxisScale'

const Styled = StyledFactory(style)
const BaseClass = Label(Lang(Boundary(Implicit(Compact(Focusable(Styled(OneUxElement)))))))

/**
 * A chart presenting data as various types of bars.
 */
@customElement('one-ux-bar-chart')
export class OneUxBarChartElement extends BaseClass {
  /**
   * **barChartType**: Defines what type of visual presentation to use for each bar.
   * * `distribution`: Stretches the entire width and displays the ratio of positive non-zero values.
   * * `grouped`: Separate bar for each value and grouped vertically.
   * * `stacked`: Stacks all positive non-zero values into a single bar that represents the total amount.
   */
  @property({ type: String })
  public type!: barChartType

  /**
   * **legendItem**: Defines a legend item and how bar segments should be represented.
   * * **key**: Key representing the legend item.
   * * **name**: A readable name.
   * * **color**: Color to use. `OneUxPaletteToken` | `CSS color string`
   * * **icon**: Icon to use.
   *   * **name**: Icon name.
   *   * **set**: Icon set.
   */
  @property({ type: Array })
  public legend: legendItem[] = []

  /**
   * **barItem**: Bar to be rendered.
   * * **name**: A readable name.
   * * **data**: An object with key value pairs of data points.
   *   * **key**: Which legend item this value refers to.
   *   * **value**: The value to be used.
   */
  @property({ type: Array })
  public bars: barItem[] = []

  /**
   * The unit to use as suffix for displayed values in popout.
   */
  @property({ type: String })
  public unit?: string

  /**
   * Min value for axis.
   */
  @property({ type: Number })
  public min?: number

  /**
   * Max value for axis.
   */
  @property({ type: Number })
  public max?: number

  @state()
  private _mouseHover = false

  @state()
  private _keyboardOpen = false

  @state()
  public _activeBarForPopout?: barItem

  constructor() {
    super()
    this.addEventListener('focus', () => {
      if (!this._activeBarForPopout) {
        this._activeBarForPopout = this.bars[0]
      }
    })
    this.addEventListener('blur', () => {
      this._activeBarForPopout = undefined
      this._keyboardOpen = false
    })
    this.addEventListener('keydown', this.#handleKeydown)
  }

  protected willUpdate(changed: PropertyValues): void {
    if (changed.has('data')) {
      this._activeBarForPopout = undefined
    }

    if (changed.has('legend')) {
      this.legend = this.legend.map((x) => ({
        ...x,
        color: palette[x.color as OneUxPaletteToken] ? `var(--one-ux-palette--${x.color})` : x.color
      }))
    }
  }

  guardedRender() {
    const { locale, translations } = getLanguage(this._locale)
    const tickSize = 1
    const tickSpacing = 40
    const axisWidth = this.shadowRoot?.querySelector('.js-axis')?.getBoundingClientRect().width || 0
    const axisScale = createAxisScale({
      type: this.type,
      legend: this.legend,
      bars: this.bars,
      axisWidth,
      tickSpacing,
      minHardLimit: this.min,
      maxHardLimit: this.max
    })

    const hasHardMin = this.min != null
    const hasHardMax = this.max != null

    return html`<div
      class="one-ux-element--root"
      role="group"
      aria-roledescription="${translations.barChart}"
      aria-label=${this.label}
      tabindex="0"
      aria-activedescendant=${ifDefined(this._activeBarForPopout ? ID_ACTIVE_BAR : undefined)}
    >
      <div
        class="gridLayout"
        style=${styleMap({
          '--one-ux-bar-chart-element--bar-count': this.bars.length
        })}
      >
        <div class="guidesArea">
          ${this.type === 'distribution'
            ? nothing
            : Guides({
                axisScale,
                shadowRoot: this.shadowRoot!,
                width: axisWidth,
                tickSize,
                hasHardMin,
                hasHardMax
              })}
        </div>

        ${this.implicit
          ? nothing
          : html`<div aria-hidden="true" class="legendArea">${Legend({ metadata: this.legend })}</div>`}
        ${this.#renderBars(axisScale, axisWidth)}

        <div aria-hidden="true" class="axisArea">
          <div class="js-axis">
            ${Axis({
              type: this.type,
              locale,
              axisScale,
              width: axisWidth,
              tickSize,
              hasHardMin,
              hasHardMax
            })}
          </div>
        </div>
      </div>
    </div>`
  }

  #renderBars(axisScale: AxisScale, width: number) {
    return this.bars.map((bar, barIndex) => {
      const active = this._activeBarForPopout === bar
      const barIndexStyle = styleMap({ '--one-ux-bar-chart-element--bar-index': barIndex })
      const legendForBar = this.legend.filter((legendItem) => {
        const value = bar.data[legendItem.key]
        return value != null && (value >= 0 || this.type === 'grouped')
      })
      return html`
        ${this.compact
          ? nothing
          : html`<div
              aria-hidden="true"
              class=${classMap({
                labelArea: true,
                'one-ux-typography--label-300': true,
                typeDistribution: this.type === 'distribution'
              })}
              style=${barIndexStyle}
            >
              ${this.#getBarLabel(bar)}
            </div>`}

        <div
          aria-hidden="true"
          class=${classMap({
            'js-bar': true,
            barArea: true,
            active: active,
            inactive: !!this._activeBarForPopout && !active,
            'one-ux-typography--label-300': true
          })}
          style=${barIndexStyle}
          @mousemove=${() => {
            this._activeBarForPopout = bar
            this._mouseHover = true
          }}
          @mouseleave=${() => {
            this._activeBarForPopout = undefined
            this._mouseHover = false
          }}
        >
          ${this.compact ? this.#getBarLabel(bar) : nothing}
          ${Bar({
            type: this.type,
            legend: this.legend,
            bar,
            axisScale,
            width,
            implicit: this.implicit
          })}
        </div>

        ${AccessibleDistributionTable({
          metadata: legendForBar,
          unit: this.unit,
          data: {
            name: bar.name,
            values: bar.data
          },
          locale: this._locale,
          id: active ? ID_ACTIVE_BAR : undefined
        })}
        ${active && (this._keyboardOpen || this._mouseHover)
          ? DistributionPopout({
              name: bar.name,
              unit: this.unit,
              metadata: legendForBar,
              values: bar.data,
              referenceElement: this.shadowRoot!.querySelectorAll('.js-bar')[barIndex],
              direction: 'horizontal',
              alignment: 'center',
              hidePercentage: this.type === 'grouped'
            })
          : nothing}
      `
    })
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    switch (event.code) {
      case keyCodes.UP:
      case keyCodes.LEFT:
        if (!this._activeBarForPopout) {
          this._activeBarForPopout = this.bars[this.bars.length - 1]
        } else {
          const previousIndex = Math.max(this.bars.indexOf(this._activeBarForPopout) - 1, 0)
          this._activeBarForPopout = this.bars[previousIndex]
        }
        handled()
        break

      case keyCodes.DOWN:
      case keyCodes.RIGHT:
        if (!this._activeBarForPopout) {
          this._activeBarForPopout = this.bars[0]
        } else {
          const nextIndex = Math.min(this.bars.indexOf(this._activeBarForPopout) + 1, this.bars.length - 1)
          this._activeBarForPopout = this.bars[nextIndex]
        }
        handled()
        break

      case keyCodes.HOME:
      case keyCodes.PAGEUP:
        this._activeBarForPopout = this.bars[0]
        handled()
        break

      case keyCodes.END:
      case keyCodes.PAGEDOWN:
        this._activeBarForPopout = this.bars[this.bars.length - 1]
        handled()
        break

      case keyCodes.RETURN:
      case keyCodes.SPACE:
        this._keyboardOpen = !this._keyboardOpen
        handled()
        break

      case keyCodes.ESCAPE:
        this._keyboardOpen = false
        handled()
        break
    }
  }

  #getBarLabel(bar: barItem) {
    if (this.type === 'distribution' || this.type === 'grouped') {
      const total = Object.keys(bar.data).reduce((acc, key) => acc + (bar.data[key] ?? 0), 0)
      return `${bar.name} (${total})`
    }

    return bar.name
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-bar-chart': OneUxBarChartElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-bar-chart': OneUxBarChartElement
    }
  }
}
