import { nothing, svg } from 'lit'
import { ease, interpolate, svgTransition } from '../../common/directives/svgTransition'
import { weight } from '@/one-ux/common/mixins/Weight'

const weightStyles: Record<
  weight,
  {
    xOffset: number
  }
> = {
  high: {
    xOffset: 8.5
  },
  normal: {
    xOffset: 7
  },
  low: {
    xOffset: 6
  }
}

export function BarPointer({
  value,
  width,
  x,
  y,
  weight
}: {
  value: number
  width: number
  x: number
  y: number
  weight: weight
}) {
  const weightStyle = weightStyles[weight]
  return svg`
    <g transform=${`translate(${x}, ${y})`}>
      <g
        fill="var(--one-ux-palette--brand-neutral-600)"
        ${svgTransition({
          timing: {
            easing: ease.outCubic,
            duration: 500
          },
          attributes: {
            opacity: interpolate.standard(0, 1, 1),
            transform: interpolate.constant(
              value,
              (translateValue) =>
                `translate(${x + weightStyle.xOffset + (width - 2 * weightStyle.xOffset) * translateValue}, 0)`
            )
          }
        })}
      >
        ${
          weight === 'high'
            ? svg`
              <path 
                transform="translate(${-weightStyles.high.xOffset}, 10.5)"
                d="M8.06699 0.749999C8.25944 0.416666 8.74056 0.416667 8.93301 0.75L15.4282 12C15.6207 12.3333 15.3801 12.75 14.9952 12.75L2.00481 12.75C1.61991 12.75 1.37935 12.3333 1.5718 12L8.06699 0.749999Z"
              />
            `
            : nothing
        }
        ${
          weight === 'normal'
            ? svg`
              <path 
                transform="translate(${-weightStyles.normal.xOffset}, 8)"
                d="M6.56699 0.75C6.75944 0.416666 7.24056 0.416667 7.43301 0.75L12.6292 9.75C12.8216 10.0833 12.5811 10.5 12.1962 10.5L1.80385 10.5C1.41895 10.5 1.17838 10.0833 1.37084 9.75L6.56699 0.75Z"
              />
            `
            : nothing
        }
        ${
          weight === 'low'
            ? svg`
              <path 
                transform="translate(${-weightStyles.normal.xOffset}, 5)"
                d="M5.56699 0.75C5.75944 0.416667 6.24056 0.416667 6.43301 0.75L10.7631 8.25C10.9556 8.58333 10.715 9 10.3301 9L1.66987 9C1.28497 9 1.04441 8.58333 1.23686 8.25L5.56699 0.75Z"
              />
            `
            : nothing
        }
      </g>
    </g>
  `
}
