import { OneUxElement } from '@/one-ux/common/OneUxElement'
import { Constructor } from '@/one-ux/common/utils'
import { property } from 'lit/decorators.js'
import { ContextProvider } from '@lit/context'
import { IListContext, listContext } from './IListContext'
import { listOption, listOptions } from './contextual-one-ux-list/types'
import { IValue, ValueFactory } from '../../common/mixins/Value'
import { InternalValidityVisibleEvent } from '../../events/internal/InternalValidityVisibleEvent'
import { Deprecation } from '@/one-ux/common/utils/Deprecation'
import { InternalValueChangedEvent } from '@/one-ux/events/internal/InternalValueChangedEvent'

export interface IListProps extends IValue<unknown> {
  options: listOptions
  multiple: boolean
}

export interface IListContextMixin extends IListProps {
  _listContextProvider: ContextProvider<{ __context__: IListContext }>
}

export const ListContextMixin = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  const Value = ValueFactory<unknown, ListContextMixinClass>({
    getter: function (): unknown {
      return this.multiple ? [...this._listContextProvider.value!.value] : this._listContextProvider.value!.value[0]
    },
    setter: function (value) {
      this._rawValue = value
      const newValue: unknown[] = []

      if (Array.isArray(value) && this.multiple) {
        newValue.push(...value)
      } else if (value != null && value !== '') {
        newValue.push(value)
      }

      this._updateContext('value', newValue)
    },
    converter: function (value) {
      try {
        // Map is required to verify that parsed value is an array
        return JSON.parse(value!).map((x: unknown) => x)
      } catch {
        return value
      }
    }
  })

  const BaseClass = Value(SuperClass)
  /**
   * @property {unknown} value
   * The value, or values if `multiple` is set, of the current selection.
   * Note that the type of the value is significant. This can lead to a gotcha where
   * an option is loosely (`==`) equal to a value `5 == "5"` but not strictly equal (`===`).
   *
   * This effect can be exaggerated when using the attribute API, i.e. writing HTML or JS using `setAttribute`,
   * since attributes are strings.
   *
   * In HTML `<one-ux-list-dropdown options='"[｛ "value": 5, text: "not selected" ｝]"' value="5">` will not
   * set the value of the list dropdown as you might initially expect since the type of the option is `number` but
   * the type of the value is `string`.
   *
   * These type of gotchas do not affect VDom libraries that prioritize properties over attributes, like Vue.
   */
  class ListContextMixinClass extends BaseClass {
    #iconDeprecation = new Deprecation(
      this,
      'The property "icon" is deprecated.',
      'The property "icons", which accepts an array of icon objects, should be used instead.'
    )
    #iconTextDeprecation = new Deprecation(
      this,
      'The property "text" on and icon is deprecated.',
      'The property will not longer be rendered in the next major version.'
    )
    #logDeprecation = (entry: listOption) => {
      if (entry.icon) {
        this.#iconDeprecation.notify()
      }
      if (entry.icons?.some((icon) => icon.text)) {
        this.#iconTextDeprecation.notify()
      }
    }
    /**
     * The options to show. A list of options, can also optionally contain grouped options.
     */
    @property({ type: Array })
    public get options() {
      return this._listContextProvider.value?.options
    }
    public set options(value: listOptions) {
      const options = value.map((entry) => {
        if ('options' in entry) {
          return {
            ...entry,
            options: entry.options.map((option) => {
              this.#logDeprecation(option)
              return { ...option, value: option.value }
            })
          }
        } else {
          this.#logDeprecation(entry)
          return {
            ...entry,
            value: entry.value
          }
        }
      })
      this._updateContext('options', options)
    }

    /**
     * Enables multiselect.
     */
    @property({ type: Boolean })
    public get multiple() {
      return this._listContextProvider.value?.multiple
    }
    public set multiple(value: boolean) {
      const hasChanged = value !== this._listContextProvider.value?.multiple
      this._updateContext('multiple', value)

      if (hasChanged) {
        this.value = this._rawValue
      }
    }

    _rawValue: unknown

    _listContextProvider = new ContextProvider(this, {
      context: listContext,
      initialValue: {
        options: [],
        value: [],
        multiple: false,
        setValue: (value: unknown[]) => {
          this._updateContext('value', value)
          this.dispatchEvent(new InternalValueChangedEvent())
          this.dispatchEvent(new InternalValidityVisibleEvent())
        }
      }
    })

    _updateContext<TKey extends keyof IListContext>(name: TKey, value: IListContext[TKey]) {
      const context = this._listContextProvider.value!
      const oldValue = context[name]
      if (oldValue !== value) {
        this._listContextProvider.setValue({
          ...this._listContextProvider.value,
          [name]: value
        })
        this.requestUpdate(name, oldValue)
      }
    }
  }
  return ListContextMixinClass as Constructor<IListContextMixin> & TSuperClass
}
