import { html, nothing } from 'lit'
import { weight } from '@/one-ux/common/mixins/Weight'
import { Legend } from '../../common/components/Legend'
import { indicationData } from '../types'
import { distributionMetadata, distributionValues } from '../../common/types'
import { BarSegments } from './BarSegments'
import { BarPointer } from './BarPointer'
import { styleMap } from 'lit/directives/style-map.js'

const weightStyles: Record<
  weight,
  {
    svgHeight: number
  }
> = {
  high: { svgHeight: 24 },
  normal: { svgHeight: 19 },
  low: { svgHeight: 14 }
}

export function ShapeBar({
  indication,
  metadata,
  distribution,
  value,
  weight,
  compact,
  implicit,
  slots,
  width
}: {
  indication: indicationData[]
  metadata: distributionMetadata[]
  distribution: distributionValues
  value: number
  weight: weight
  compact: boolean
  implicit: boolean
  slots: {
    hasSummary: boolean
    hasDescription: boolean
    summaryChanged: (event: Event) => void
    descriptionChanged: (event: Event) => void
  }
  width: number
}) {
  const weightStyle = weightStyles[weight]
  const showLegend = weight === 'high' && !compact && !implicit

  return html`
    <div class="barShape">
      <div
        class="slotsAndLegend"
        style=${styleMap({
          display: slots.hasSummary || slots.hasDescription || showLegend ? null : 'none'
        })}
      >
        <div
          class="slots"
          style=${styleMap({
            display: slots.hasSummary || slots.hasDescription ? null : 'none'
          })}
        >
          ${weight === 'high'
            ? html`
                <slot
                  name="summary"
                  @slotchange=${slots.summaryChanged}
                  class="summary one-ux-typography--heading-500"
                  style=${styleMap({ display: slots.hasSummary ? null : 'none' })}
                ></slot>
              `
            : nothing}

          <slot
            name="description"
            @slotchange=${slots.descriptionChanged}
            class="description one-ux-typography--label-300"
            style=${styleMap({ display: slots.hasDescription ? null : 'none' })}
          ></slot>

          ${weight !== 'high'
            ? html`
                <span
                  class="summary one-ux-typography--label-300"
                  style=${styleMap({ display: slots.hasSummary ? null : 'none' })}
                >
                  (<slot name="summary" @slotchange=${slots.summaryChanged}></slot>)
                </span>
              `
            : nothing}
        </div>

        ${showLegend ? Legend({ metadata, values: distribution }) : nothing}
      </div>

      <svg width="${width}" height="${weightStyle.svgHeight}" role="presentation">
        <g>
          ${BarSegments({
            indication,
            width: width,
            x: 0,
            y: 0,
            weight
          })}
          ${BarPointer({
            value,
            width: width,
            x: 0,
            y: 0,
            weight
          })}
        </g>
      </svg>
    </div>
  `
}
