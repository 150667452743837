import './input-color.css'
import { InputColorElement } from './InputColorElement'
import { InputColorGroupElement } from './InputColorGroupElement'
import { InputColorOptionsElement } from './InputColorOptionsElement'
import { InputColorOptionElement } from './InputColorOptionElement'

customElements.define('input-color', InputColorElement)
customElements.define('input-color-group', InputColorGroupElement)
customElements.define('input-color-options', InputColorOptionsElement)
customElements.define('input-color-option', InputColorOptionElement)
