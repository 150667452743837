import { DEG_TO_RAD } from '../common/constants'
import { OneUxGaugeChartElement } from './OneUxGaugeChartElement'
import { HiddenVisual, InternalData, InternalSegment, hiddenVisuals } from './types'

const REFERENCE_SIZE = 128

export const getSvgHeight = (host: OneUxGaugeChartElement, data: InternalData) => {
  const availableHeight = getAvailableHeight(host, data)
  const inlineStatsHeight = getInlineStatsHeight(data)

  return Math.max(
    host._boundary!.width,
    host._boundary!.width + inlineStatsHeight - availableHeight + data.snapSizeConfig.arcsMargin
  )
}

export const getAvailableHeight = (host: OneUxGaugeChartElement, data: InternalData) =>
  Math.max(0, host._boundary!.width - getArcsBottom(host, data))

export const getInlineStatsHeight = (data: InternalData) =>
  Math.max(...data.inlineStatsSpecs.details.map((x) => x.offsetY)) + data.snapSizeConfig.inlineStatsIconSize

export const getScaleFactor = (host: OneUxGaugeChartElement) => host._boundary!.width / REFERENCE_SIZE

export const getArcsCenter = (host: OneUxGaugeChartElement) => {
  const x = host._boundary!.width / 2
  const y = host._boundary!.width / 2

  return [x, y]
}

export const getArcsBottom = (host: OneUxGaugeChartElement, data: InternalData) =>
  getArcsCenter(host)[1] + getArcsOuterRadius(host, data) * Math.abs(Math.sin(ARCS_ORIGIN_START_ANGLE_RAD))

export const getArcsOuterRadius = (host: OneUxGaugeChartElement, data: InternalData) =>
  host._boundary!.width / 2 - data.snapSizeConfig.arcsMargin

export const getArcsInnerRadius = (host: OneUxGaugeChartElement, data: InternalData, segment?: InternalSegment) =>
  getArcsOuterRadius(host, data) - getArcsThickness(data, segment)

export const getArcsThickness = (data: InternalData, segment?: InternalSegment): number => {
  if (segment?.highlighted) {
    return data.snapSizeConfig.segmentHighlightSize
  }

  return data.snapSizeConfig.segmentDefaultSize
}

export const isHiddenVisual = (host: OneUxGaugeChartElement, ...validatedVisuals: HiddenVisual[]) => {
  if (validatedVisuals.includes('all')) {
    return host.hideVisuals.includes('all') || hiddenVisuals.length === host.hideVisuals.length
  }
  return host.hideVisuals.some((hv) => hv === 'all' || validatedVisuals.includes(hv))
}

const ARCS_ORIGIN_START_ANGLE_DEG = 225
const ARCS_TOTAL_SPAN_ANGLE_DEG = 270
const ARCS_ORIGIN_END_ANGLE_DEG = ARCS_ORIGIN_START_ANGLE_DEG + ARCS_TOTAL_SPAN_ANGLE_DEG

export const ARCS_ORIGIN_START_ANGLE_RAD = ARCS_ORIGIN_START_ANGLE_DEG * DEG_TO_RAD
export const ARCS_TOTAL_SPAN_ANGLE_RAD = ARCS_TOTAL_SPAN_ANGLE_DEG * DEG_TO_RAD
export const ARCS_ORIGIN_END_ANGLE_RAD = ARCS_ORIGIN_END_ANGLE_DEG * DEG_TO_RAD
