import { svg } from 'lit'
import { bounds } from '../../types'
import { path } from '../../../common/path'
import { ValueScale } from './ValueScale'
import { interpolate, svgTransition } from '@/one-ux/charts/common/directives/svgTransition'
import { timing } from '../../animation'

export function DomainBaseline(bounds: bounds, valueScale: ValueScale, animate: boolean) {
  const x = Math.max(bounds.left, 4)
  const y = Math.floor(valueScale.domainPosition) + 0.5
  const width = bounds.right - x - 0.5 - 4

  return svg`
    <g transform="translate(0, 0)" fill="none" class="domain-baseline">
      <path
        stroke="var(--one-ux-palette--brand-neutral-300)"
        stroke-linecap="square"
        stroke-width="1"
        ${svgTransition({
          timing: timing(animate),
          attributes: {
            d: interpolate.constant([y, width], ([y, width]) => path().move(x, y).horizontalLine(width).toString()),
            opacity: interpolate.standard(0, 1, 0)
          }
        })}
      />
    </g>
  `
}
