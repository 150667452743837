import { AnimatedCustomElement } from '../AnimatedCustomElement'

const storageKey = `AccessibilityDebugElement__active`

export class AccessibilityDebugElement extends AnimatedCustomElement {
  onConnected() {
    this.#hideDuplicateInstances()
    this.active = localStorage.getItem(storageKey) === 'true'

    document.addEventListener('click', this.#handleClick, true)
  }

  onDisconnected() {
    document.removeEventListener('click', this.#handleClick, true)
  }

  animate() {
    this.#renderTableOfContents()
  }

  get active() {
    return this.hasAttribute('state-active')
  }

  set active(value) {
    if (value) {
      this.setAttribute('state-active', '')
    } else {
      this.removeAttribute('state-active')
    }
    localStorage.setItem(storageKey, !!value)
  }

  #hideDuplicateInstances = () => {
    if (document.querySelector('accessibility-debug') !== this) {
      PDR.log.warning('Multiple instance of <ACCESSIBILITY-DEBUG/>, ignoring...')
      this.hidden = true
      return
    }
    this.hidden = false
  }

  #handleClick = (e) => {
    if (e.altKey) {
      const rect = this.getBoundingClientRect()
      const mouseViewPortX = e.pageX - window.scrollX
      const mouseViewPortY = e.pageY - window.scrollY

      if (
        mouseViewPortX >= rect.left &&
        mouseViewPortX < rect.right &&
        mouseViewPortY >= rect.top &&
        mouseViewPortY < rect.bottom
      ) {
        e.preventDefault()
        e.stopPropagation()
        this.active = !this.active
      }
    }
  }

  #renderTableOfContents = () => {
    const title = document.title || 'ERROR: Title is missing'
    const toc = [title, '='.repeat(title.length)]
    document.querySelectorAll('h1,h2,h3,h4,h5,h6,[role="heading"]').forEach(($el) => {
      const isVisibleForScreenReader =
        $el.offsetWidth > 0 && $el.offsetHeight > 0 && !$el.closest('[aria-hidden="true"]')
      if (isVisibleForScreenReader) {
        let level
        if ($el.hasAttribute('aria-level')) {
          level = +$el.getAttribute('aria-level')
        } else if ($el.getAttribute('role') === 'heading') {
          level = 2 // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/heading_role
        } else {
          level = +$el.tagName[1]
        }

        const text = $el.textContent.replace(/\n/g, ' ').trim()
        toc.push(!isNaN(level) ? `${'  '.repeat(level)}H${level}: ${text}` : `ERROR: ${text}`)
      }
    })

    this.textContent = toc.join('\n')
  }
}
