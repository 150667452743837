import { svg } from 'lit'
import { bounds } from '../../types'
import { KeyScale } from './KeyScale'

export function KeyHoverBands(
  bounds: bounds,
  keyScale: KeyScale,
  onMouseEnter: ($el: Element) => void,
  onMouseLeave: () => void
) {
  const height = Math.abs(bounds.bottom - bounds.top)

  return svg`<g transform="translate(0, 0)" fill="none" class="key-hover-bands">
    ${keyScale.keys.map((key, i) => {
      return svg`<foreignObject width=${keyScale.size} height=${height} x=${keyScale.size * i + bounds.left} y=${
        bounds.top
      }>
        <div
            xmlns="http://www.w3.org/1999/xhtml"
            style="display: inline-block; width: 100%; height: 100%;"
            class="key-hover--band"
            @mouseenter=${(e: Event) => onMouseEnter(e.target as Element)}
            @mouseleave=${() => onMouseLeave()}
            data-key=${key}
          ></div>
      </foreignObject> `
    })}
    </g>
  `
}
