import { html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { style } from './style'
import { Boundary } from '../common/mixins/Boundary'
import { Compact } from '../../common/mixins/Compact'
import { Focusable } from '../../common/mixins/Focusable'
import { StyledFactory } from '../../common/mixins/Styled'
import { DistributionPopout } from '../common/components/DistributionPopout'
import { log } from '../../common/utils/log'
import { keyCodes } from '../../common/utils'
import { AccessibleDistributionTable } from '../common/components/AccessibleDistributionTable'
import { getLanguage, language } from './lang'
import { distributionData } from './types'
import { OneUxElement } from '../../common/OneUxElement'
import { ShapeArc } from './components/ShapeArc'
import { ShapeBar } from './components/ShapeBar'
import { ShapeCircle } from './components/ShapeCircle'
import { Weight } from '../../common/mixins/Weight'
import { Implicit } from '../../common/mixins/Implicit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { palette } from '../../generated/design-tokens.json'
import { OneUxPaletteToken } from '../../generated/design-tokens'

const Styled = StyledFactory(style)

const BaseClass = Implicit(Weight(Focusable(Boundary(Styled(Compact(OneUxElement))))))

/**
 * A single distribution rendered in various shapes
 */
@customElement('one-ux-distribution')
export class OneUxDistributionElement extends BaseClass {
  /**
   * The shape of the distribution.
   * This is a required property and will not render if it is omitted.
   */
  @property({ type: String })
  public shape!: 'arc' | 'bar' | 'circle'

  /**
   * The label associated with the distribution.
   * This is a required property and will not render if it is omitted.
   */
  @property({ type: String })
  public label!: string

  /**
   * The distribution to be rendered.
   */
  @property({ type: Array })
  public data: distributionData[] = []

  /**
   * The unit to use for displayed values
   */
  @property({ type: String })
  public unit!: string

  @state()
  private _mouseHover = false

  @state()
  private _keyboardOpen = false

  @state()
  private _hasSummary = false

  @state()
  private _hasDescription = false

  constructor() {
    super()
    this.addEventListener('mouseenter', () => {
      this._mouseHover = true
    })
    this.addEventListener('mouseleave', () => {
      this._mouseHover = false
    })
    this.addEventListener('keydown', this.#handleKeydown)
    this.addEventListener('blur', () => {
      this._keyboardOpen = false
    })
  }

  render() {
    if (!this.label) {
      log.error('<one-ux-distribution> Missing label, not rendering.')
      return
    }

    if (!this.shape) {
      log.error('<one-ux-distribution> Missing shape, not rendering.')
      return
    }

    const language = getLanguage(this)
    const { metadata, distribution } = this.#extractMetadataAndDistribution()
    const slots = {
      hasSummary: this._hasSummary,
      hasDescription: this._hasDescription,
      summaryChanged: this.#handleSummarySlotChange,
      descriptionChanged: this.#handleDescriptionSlotChange
    }
    const showPopout = !this.implicit && (this._keyboardOpen || this._mouseHover)

    return html`<div
      class="one-ux-element--root"
      role="group"
      aria-roledescription=${this.#getRoleDescription(language)}
      aria-label="${this.label}"
      tabindex=${ifDefined(this.implicit ? undefined : 0)}
    >
      ${this.shape === 'arc'
        ? ShapeArc({
            weight: this.weight,
            compact: this.compact,
            implicit: this.implicit,
            metadata,
            distribution,
            slots,
            shadowRoot: this.shadowRoot!
          })
        : nothing}
      ${this.shape === 'bar'
        ? ShapeBar({
            weight: this.weight,
            compact: this.compact,
            implicit: this.implicit,
            width: this.compact ? this.clientWidth : this.clientWidth - 2 * 8,
            metadata,
            distribution,
            slots
          })
        : nothing}
      ${this.shape === 'circle'
        ? ShapeCircle({
            weight: this.weight,
            compact: this.compact,
            implicit: this.implicit,
            metadata,
            distribution,
            slots,
            shadowRoot: this.shadowRoot!
          })
        : nothing}
      ${showPopout
        ? DistributionPopout({
            name: this.label,
            unit: this.unit,
            metadata: metadata,
            values: distribution,
            referenceElement: this.shadowRoot!.querySelector('svg')!
          })
        : nothing}
      ${AccessibleDistributionTable({
        metadata: metadata,
        data: {
          name: this.label,
          values: distribution
        },
        unit: this.unit,
        locale: language.locale
      })}
    </div>`
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    switch (event.code) {
      case keyCodes.RETURN:
      case keyCodes.SPACE:
        this._keyboardOpen = !this._keyboardOpen
        handled()
        break

      case keyCodes.ESCAPE:
        this._keyboardOpen = false
        handled()
        break
    }
  }

  #getRoleDescription = (language: language) => {
    switch (this.shape) {
      case 'arc':
        return language.translations.distributionArc
      case 'bar':
        return language.translations.distributionBar
      case 'circle':
        return language.translations.distributionCircle
    }
    throw new Error('Missing role description')
  }

  #extractMetadataAndDistribution = () => {
    const metadata = this.data.map((x, i) => ({
      key: String(i),
      name: x.name,
      color: palette[x.color as OneUxPaletteToken] ? `var(--one-ux-palette--${x.color})` : x.color,
      icon: x.icon
    }))
    const distribution = this.data.reduce((result: any, item, i) => {
      result[String(i)] = item.value
      return result
    }, {})

    return {
      metadata,
      distribution
    }
  }

  #handleSummarySlotChange = (event: Event) => {
    const validNodes = this.#getValidSlotNodes(event.target as HTMLSlotElement)
    this._hasSummary = !!validNodes.length
  }

  #handleDescriptionSlotChange = (event: Event) => {
    const validNodes = this.#getValidSlotNodes(event.target as HTMLSlotElement)
    this._hasDescription = !!validNodes.length
  }

  #getValidSlotNodes = ($slot: HTMLSlotElement) =>
    $slot.assignedNodes().filter((node) => {
      switch (node.nodeName) {
        case 'ONE-UX-TEXT':
        case 'ONE-UX-ICON':
        case 'ONE-UX-LINK':
          return true
      }
      return false
    })
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-distribution': OneUxDistributionElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-distribution': OneUxDistributionElement
    }
  }
}
