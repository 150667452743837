import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    display: flex;
    flex-direction: column;
  }

  ::slotted(:is(one-ux-input, one-ux-textarea)) {
    flex: 1 !important;
    height: 100% !important;
  }

  .label {
    font: var(--one-ux-font--label-400);
    letter-spacing: var(--one-ux-letter-spacing--label-400);
    color: var(--contextual-one-ux-field-element--label-color);
  }

  .asterisk {
    color: var(--one-ux-palette--red-500);
  }

  .field {
    --contextual-one-ux-field-element--border-width: var(--one-ux-border-width--500);

    flex: 1 1 auto;
    background: var(--one-ux-palette--gray-100);
    color: var(--one-ux-palette--brand-neutral-600);
    border: var(--contextual-one-ux-field-element--border-width) solid var(--one-ux-palette--brand-neutral-300);
    border-radius: var(--one-ux-radius--300);
    min-height: 32px; /* Fetch from somewhere? */
    cursor: default;

    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  :host([weight='high']) .field {
    min-height: 46px; /* Fetch from somewhere? */
  }

  :host([weight='low']) .field {
    min-height: 24px; /* Fetch from somewhere? */

    font: var(--one-ux-font--body-200);
    letter-spacing: var(--one-ux-letter-spacing--body-200);
  }

  :host(:not(:hover, :active)) .field.empty {
    color: var(--one-ux-palette--brand-neutral-400);
  }

  :host .field.disabled {
    background: var(--one-ux-palette--gray-150);
    color: var(--one-ux-palette--gray-400);
    border-color: var(--one-ux-palette--gray-300);
  }

  :host .field:not(.disabled):hover {
    border-color: var(--one-ux-palette--brand-neutral-400);
  }

  .field:active {
    background: var(--one-ux-palette--brand-neutral-100);
  }

  :host(:not(:active):focus-within) .field:not(.disabled) {
    border-color: var(--one-ux-palette--focus-400);
  }

  :host([state-keyboard-focus]:not(:active):focus-within) .field:not(.disabled) {
    background: var(--one-ux-palette--keyboard-focus-200);
    color: var(--one-ux-palette--brand-neutral-600);
    border-color: var(--one-ux-palette--keyboard-focus-400);
  }
  :host([state-keyboard-focus]:active) .field:not(.disabled) {
    background: var(--one-ux-palette--keyboard-focus-300);
    color: var(--one-ux-palette--brand-neutral-600);
    border-color: var(--one-ux-palette--keyboard-focus-400);
  }

  :host([implicit]) .field {
    color: var(--one-ux-palette--brand-neutral-600);
    border-color: transparent;
    background: none;
    background-color: transparent;
  }

  :host([implicit]:not(:hover, :active)) .field.empty {
    color: var(--one-ux-palette--brand-neutral-400);
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.03);
  }

  :host([implicit]) .field.disabled {
    background: var(--one-ux-palette--gray-150);
    color: var(--one-ux-palette--gray-400);
    border-color: transparent;
  }

  :host([implicit]) .field:hover:not(.disabled) {
    border-color: transparent;
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.05);
  }

  :host([implicit]:not(:active):focus-within) .field:not(.disabled) {
    border-color: var(--one-ux-palette--focus-400);
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.05);
  }

  :host([implicit]:active) .field:not(.disabled) {
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.05);
  }

  :host([implicit][state-keyboard-focus]:not(:active):focus-within) .field:not(.disabled) {
    background: var(--one-ux-palette--keyboard-focus-200);
    color: var(--one-ux-palette--brand-neutral-600);
    border-color: var(--one-ux-palette--keyboard-focus-400);
  }
  :host([implicit][state-keyboard-focus]:active) .field:not(.disabled) {
    background: var(--one-ux-palette--keyboard-focus-300);
    color: var(--one-ux-palette--brand-neutral-600);
    border-color: var(--one-ux-palette--keyboard-focus-400);
  }

  .bottom-indicator {
    --one-ux-field--indicator-gap: var(--one-ux-spacing--smaller);
    --one-ux-field--indicator-background: var(--one-ux-palette--brand-neutral-300);

    position: absolute;
    left: var(--one-ux-spacing--small);
    right: var(--one-ux-spacing--small);
    bottom: calc(0px - var(--contextual-one-ux-field-element--border-width));
    height: var(--contextual-one-ux-field-element--border-width);
    pointer-events: none;

    padding: 0 var(--one-ux-field--indicator-gap);
    background: inherit; /* Clip underlying border with parent background */

    opacity: 0;
    transition-duration: var(--one-ux-duration--400);
    transition-property: opacity, background;
  }

  :host([implicit]) .bottom-indicator {
    background: none; /* No underlying border to clip */
  }

  .bottom-indicator:before {
    content: '';
    display: block;
    transition-duration: inherit;
    background: var(--one-ux-field--indicator-background);
    height: 100%;
    border-radius: var(--one-ux-radius--100);
  }

  .field:not(.multi-line).error .bottom-indicator {
    opacity: 1;
    --one-ux-field--indicator-background: var(--one-ux-palette--red-400);
  }

  :host([implicit]) .field:not(.disabled, .error.multi-line).empty .bottom-indicator {
    opacity: 1;
  }

  :host([implicit]:is(:hover, :active)) .field:not(.disabled, .error) .bottom-indicator {
    opacity: 1;
    --one-ux-field--indicator-background: var(--one-ux-palette--brand-neutral-400);
  }

  :host([implicit]:not(:active):focus-within) .field:not(.error) .bottom-indicator,
  :host([implicit][state-keyboard-focus]:active) .field:not(.error) .bottom-indicator {
    opacity: 0 !important;
  }

  .left-indicator {
    --one-ux-field--indicator-gap: var(--one-ux-spacing--smaller);
    --one-ux-field--indicator-background: var(--one-ux-palette--brand-neutral-300);

    position: absolute;
    top: var(--one-ux-spacing--small);
    bottom: var(--one-ux-spacing--small);
    left: calc(0px - var(--contextual-one-ux-field-element--border-width));
    width: var(--contextual-one-ux-field-element--border-width);
    pointer-events: none;

    padding: var(--one-ux-field--indicator-gap) 0;
    background: inherit; /* Clip underlying border with parent background */

    opacity: 0;
    transition-duration: var(--one-ux-duration--400);
    transition-property: opacity, background;
  }

  :host([implicit]) .left-indicator {
    background: none; /* No underlying border to clip */
  }

  .left-indicator:before {
    content: '';
    display: block;
    transition-duration: inherit;
    background: var(--one-ux-field--indicator-background);
    height: 100%;
    border-radius: var(--one-ux-radius--100);
  }

  .field.multi-line.error .left-indicator {
    opacity: 1;
    --one-ux-field--indicator-background: var(--one-ux-palette--red-400);
  }

  :host(:not([disabled]):not(:is(:hover, :focus-within))) .errors-popout {
    visibility: hidden;
    pointer-events: none;
  }
`
