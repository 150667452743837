import { css } from 'lit'

export const style = css`
  .message {
    --one-ux-message--icon-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-message--background-color: transparent;
    --one-ux-message--font-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-message--border-color: var(--one-ux-palette--brand-neutral-600);

    background: var(--one-ux-message--background-color);
    color: var(--one-ux-message--font-color);
    border-color: var(--one-ux-message--border-color);

    border-style: solid;
    border-radius: var(--one-ux-radius--300);
    border-width: var(--one-ux-border-width--100);
  }

  .content,
  .heading {
    /* TODO: Define text max-width as variable in Figma */
    max-width: 807px;
    margin: 0;
  }

  .content {
    white-space: pre-line;
  }

  .heading {
    display: flex;
    align-items: center;
  }

  :host(:not([weight='low'], [weight='high'])) .heading {
    height: 23px;
  }

  one-ux-icon {
    --one-ux-message--icon-size: var(--one-ux-size--icon-400);

    color: var(--one-ux-message--icon-color);
    font-size: var(--one-ux-message--icon-size);

    margin-top: var(--one-ux-spacing--smallest);
  }

  :host([weight='low']) one-ux-icon {
    --one-ux-message--icon-size: var(--one-ux-size--icon-200);
  }

  :host([weight='high']) one-ux-icon {
    --one-ux-message--icon-size: var(--one-ux-size--icon-700);
  }

  :host([purpose='default']) .message {
    --one-ux-message--font-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-message--icon-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-message--background-color: var(--one-ux-palette--brand-neutral-100);
    --one-ux-message--icon-color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([purpose='caution']) .message {
    --one-ux-message--font-color: var(--one-ux-palette--red-700);
    --one-ux-message--icon-color: var(--one-ux-palette--red-700);
    --one-ux-message--border-color: var(--one-ux-palette--red-700);
    --one-ux-message--background-color: var(--one-ux-palette--red-100);
  }

  :host([purpose='notice']) .message {
    --one-ux-message--icon-color: var(--one-ux-palette--orange-700);
    --one-ux-message--border-color: var(--one-ux-palette--orange-700);
    --one-ux-message--font-color: var(--one-ux-palette--orange-700);
    --one-ux-message--background-color: var(--one-ux-palette--yellow-100);
  }

  :host([purpose='main']) .message {
    --one-ux-message--icon-color: var(--one-ux-palette--mint-700);
    --one-ux-message--border-color: var(--one-ux-palette--mint-700);
    --one-ux-message--font-color: var(--one-ux-palette--mint-700);
    --one-ux-message--background-color: var(--one-ux-palette--mint-100);
  }

  :host([implicit]) .message {
    border-color: transparent;
  }
`
