import {
  KEY_UP,
  KEY_DOWN,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_ENTER,
  KEY_SPACE,
  KEY_HOME,
  KEY_END,
  KEY_TAB,
  KEY_ESCAPE,
  KEY_PAGEUP,
  KEY_PAGEDOWN,
  KEY_BACKSPACE,
  KEY_DELETE
} from '../../helpers/keyCodes'
import { SearchEvent } from './events/SearchEvent'

export class KeyboardEventHandler {
  #inputSelectElement
  #typeAheadClearTimer = null
  #typeAheadText = ''

  constructor($inputSelect) {
    this.#inputSelectElement = $inputSelect
  }

  handleKeyDown = (event) => {
    const $inputSelect = this.#inputSelectElement
    const keyCode = event.which
    const options = $inputSelect.options
    let keyboardLetterKey = event.key.length === 1 && /[\S]/.test(event.key[0]) ? event.key[0] : null

    if ($inputSelect.expanded && $inputSelect.searchable && !$inputSelect.multiple && keyCode === KEY_SPACE) {
      keyboardLetterKey = ' '
    }

    if (keyCode !== KEY_TAB) {
      event.preventDefault()
    }

    if (!$inputSelect.expanded) {
      if (keyboardLetterKey) {
        $inputSelect.expanded = true
        const $matchingOption = options.find(
          (option) => option.text.toLowerCase()[0] === keyboardLetterKey.toLowerCase() && !option.disabled
        )
        if ($matchingOption) {
          $matchingOption.active = true
        }
      } else if (this.#isOpenKeyCode(keyCode)) {
        $inputSelect.expanded = true
      }
      return
    }

    if ($inputSelect.hasAttribute('searchable')) {
      if (keyboardLetterKey) {
        $inputSelect.searchTerm =
          $inputSelect.searchTerm.slice(0, $inputSelect.searchCursor) +
          keyboardLetterKey +
          $inputSelect.searchTerm.slice($inputSelect.searchCursor)
        $inputSelect.searchCursor++
        $inputSelect.dispatchEvent(new SearchEvent($inputSelect.searchTerm))
        return
      }

      if (keyCode === KEY_DELETE) {
        $inputSelect.searchTerm =
          $inputSelect.searchTerm.slice(0, $inputSelect.searchCursor) +
          $inputSelect.searchTerm.slice($inputSelect.searchCursor + 1)
        $inputSelect.dispatchEvent(new SearchEvent($inputSelect.searchTerm))
        return
      }

      if (keyCode === KEY_BACKSPACE) {
        const newSearchCursor = Math.max($inputSelect.searchCursor - 1, 0)
        $inputSelect.searchTerm =
          $inputSelect.searchTerm.slice(0, newSearchCursor) + $inputSelect.searchTerm.slice($inputSelect.searchCursor)
        $inputSelect.searchCursor = newSearchCursor
        $inputSelect.dispatchEvent(new SearchEvent($inputSelect.searchTerm))
        return
      }

      if (keyCode === KEY_LEFT) {
        $inputSelect.searchCursor = Math.max($inputSelect.searchCursor - 1, 0)
      }

      if (keyCode === KEY_RIGHT) {
        $inputSelect.searchCursor = Math.min($inputSelect.searchCursor + 1, $inputSelect.searchTerm.length)
        return
      }
    }

    if (keyboardLetterKey) {
      clearTimeout(this.#typeAheadClearTimer)
      this.#typeAheadText += keyboardLetterKey.toLowerCase()
      this.#typeAheadClearTimer = setTimeout(() => {
        this.#typeAheadText = ''
      }, 1000)

      const $matchingOption = options.find(
        (option) => option.text.toLowerCase().startsWith(this.#typeAheadText) && !this.#isOptionDisabled(option)
      )
      if ($matchingOption) {
        $matchingOption.active = true
      }
      return
    }

    if (this.#isStepKeyCode(keyCode)) {
      this.#stepActiveOption(keyCode)
      return
    }

    if (this.#isTriggerKeyCode(keyCode)) {
      const $activeOption = $inputSelect.options.find(($option) => $option.active)
      if ($activeOption) {
        $activeOption.trigger(this.#getTriggerReason(keyCode))
      }
      return
    }

    if (keyCode === KEY_ESCAPE) {
      $inputSelect.expanded = false
    }
  }

  #isStepKeyCode(keyCode) {
    return [KEY_UP, KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_HOME, KEY_END, KEY_PAGEUP, KEY_PAGEDOWN].includes(keyCode)
  }

  #stepActiveOption(keyCode) {
    const $inputSelect = this.#inputSelectElement
    const steps = {
      [KEY_UP]: -1,
      [KEY_LEFT]: -1,
      [KEY_DOWN]: 1,
      [KEY_RIGHT]: 1,
      [KEY_PAGEUP]: -10,
      [KEY_PAGEDOWN]: 10
    }
    const step = steps[keyCode] || 0
    const currentActiveOption = $inputSelect.options.find(($option) => $option.active)
    const options = $inputSelect.options.filter((x) => !this.#isOptionDisabled(x) || x === currentActiveOption)
    let $newActiveOption

    if (keyCode === KEY_HOME || (!currentActiveOption && step > 0)) {
      $newActiveOption = options[0]
    } else if (keyCode === KEY_END || (!currentActiveOption && step < 0)) {
      $newActiveOption = options[options.length - 1]
    } else {
      $newActiveOption = options[Math.min(Math.max(options.indexOf(currentActiveOption) + step, 0), options.length - 1)]
    }

    if ($newActiveOption) {
      $newActiveOption.active = true
    }
  }

  #isOpenKeyCode(keyCode) {
    return [
      KEY_UP,
      KEY_LEFT,
      KEY_DOWN,
      KEY_RIGHT,
      KEY_HOME,
      KEY_END,
      KEY_PAGEUP,
      KEY_PAGEDOWN,
      KEY_SPACE,
      KEY_ENTER
    ].includes(keyCode)
  }

  #isTriggerKeyCode(keyCode) {
    return [KEY_ENTER, KEY_TAB, KEY_SPACE].includes(keyCode)
  }

  #triggersReasons = {
    [KEY_ENTER]: 'keyboard-enter',
    [KEY_TAB]: 'keyboard-tab',
    [KEY_SPACE]: 'keyboard-space'
  }
  #getTriggerReason(keyCode) {
    return this.#triggersReasons[keyCode]
  }

  #isOptionDisabled(option) {
    if (option.disabled || option.filtered) {
      return true
    }
    const optgroup = option.closest('input-select-option-group')
    return optgroup ? optgroup.hasAttribute('disabled') : false
  }
}
