import { OneUxElement } from '../common/OneUxElement'
import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { style } from './style'
import { StyledFactory } from '../common/mixins/Styled'

import { Implicit } from '../common/mixins/Implicit'
import { OneUxIconToken, OneUxPaletteToken } from '../generated/design-tokens'
import { Focusable } from '../common/mixins/Focusable'
import { Disabled } from '../common/mixins/Disabled'

const Styled = StyledFactory(style)

const BaseClass = Disabled(Focusable(Implicit(Styled(OneUxElement))))

// TODO: Make type "dynamic" based on current set
type icons = OneUxIconToken[keyof OneUxIconToken]

/**
 * A smaller button alternative that can be used as a widget in other components where a regular button is to large.
 * Intended to be used where the interaction can be considered optional. A `<one-ux-widget-button>` is not accessible
 * by default and should only be used to augment a UI and not constitute core functionality.
 */
@customElement('one-ux-widget-button')
export class OneUxWidgetButtonElement extends BaseClass {
  /**
   * Passed to a <one-ux-icon>. See that component for details.
   */
  @property({ type: String })
  public set = 'default' as keyof OneUxIconToken

  /**
   * Passed to a <one-ux-icon>. See that component for details.
   */
  @property({ type: String })
  public icon!: icons

  /**
   * Passed to a <one-ux-icon>. See that component for details.
   */
  @property({ type: String })
  public label!: string

  /**
   * Makes the widget accessible by using a button as an intermediate layer.
   * Generally not required as widgets should only enhance a UI and not be required to interact with.
   */
  @property({ type: Boolean })
  public accessible = false

  /**
   * Allows you change the icon color from one of the available colors in the OneUx palette.
   */
  @property({ type: String })
  public color?: OneUxPaletteToken

  render() {
    const scrubbedColor = this.color?.replaceAll(/[^a-z0-9-]/gi, '').trim()
    const style = scrubbedColor ? `color: var(--one-ux-palette--${scrubbedColor});` : ''
    return html`<one-ux-container
      align="center"
      class="one-ux-element--root widget"
      style=${style}
      ?state-disabled=${this.disabled}
    >
      ${this.accessible && !this.disabled
        ? html`<button one-ux-tooltip=${this.label} aria-label=${this.label} ?disabled=${this.disabled}>
            <one-ux-icon part="icon" set=${this.set} icon=${this.icon}></one-ux-icon>
          </button>`
        : html`<one-ux-icon part="icon" set=${this.set} icon=${this.icon}></one-ux-icon>`}
    </one-ux-container>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-widget-button': OneUxWidgetButtonElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-widget-button': OneUxWidgetButtonElement
    }
  }
}
