import { log } from '../log';
import { Action } from './Action';
export class Prefetch extends Action {
    constructor(tag, url) {
        super();
        this.$link = this.createLinkElement(tag, url);
    }
    execute() {
        if (this.completed) {
            return;
        }
        if (!this.started) {
            if (document.getElementById(this.$link.id)) {
                throw new Error(`A link tag with the same id ${this.$link.id} already exists, this should not be possible.`);
            }
            this.$link.rel = 'prefetch';
            document.head.appendChild(this.$link);
        }
    }
    abort() {
        if (this.started && !this.completed) {
            log.debug({
                title: `Attempting to abort prefetch ${this.$link.id}`,
                message: `An attempt to abort the prefetch of the url ${this.$link.href} with the id ${this.$link.id} has been made. This will not always abandon a started request but makes sure that no request will be done after the time of abortion.`,
                details: {
                    link: this.$link
                }
            });
            this.$link.dataset['aborted'] = JSON.stringify(true);
            this.$link.dataset['href'] = this.$link.href;
            this.$link.removeAttribute('href');
        }
    }
    createLinkElement(tag, url) {
        const $link = document.createElement('link');
        $link.id = `mfe-prefetch-${tag}`;
        $link.href = this.applyCacheQuery(url);
        $link.as = 'script';
        $link.onload = () => {
            this.completed = true;
        };
        return $link;
    }
    get started() {
        return this.$link.parentNode !== null;
    }
}
