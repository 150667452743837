export function getLineSegments<T>({
  segments,
  width,
  minSegmentWidth,
  gap,
  loop
}: {
  segments: {
    value: number
    item: T
  }[]
  width: number
  minSegmentWidth: number
  gap: number
  loop?: boolean
}): {
  x: number
  width: number
  value: number
  item: T
}[] {
  const segmentsWithValue = segments.filter((segment) => segment.value > 0)
  const totalCount = segmentsWithValue.reduce((sum, x) => sum + x.value, 0)
  const remainingWidthAvailable =
    width - (segmentsWithValue.length * minSegmentWidth + (segmentsWithValue.length - 1) * gap) - (loop ? gap : 0)

  const lineSegments = segmentsWithValue.map((segment, i) => {
    return {
      x: (minSegmentWidth + gap) * i + (loop ? gap / 2 : 0),
      width: minSegmentWidth,
      value: segment.value,
      item: segment.item
    }
  })

  for (let i = 0; i < lineSegments.length; ++i) {
    const bar = lineSegments[i]
    const fraction = bar.value / totalCount
    const additionalWidth = remainingWidthAvailable * fraction

    bar.width += additionalWidth
    for (let j = i + 1; j < lineSegments.length; ++j) {
      lineSegments[j].x += additionalWidth
    }
  }

  return lineSegments
}
