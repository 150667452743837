import { langCode, LanguageSets } from '@/one-ux/common/utils/getLangCode'

type language = {
  locale: string
  translations: translationSet
}

type translationSet = {
  name: string
  total: string
}

const languages: LanguageSets<translationSet> = {
  en: {
    name: 'Name',
    total: 'Total'
  },
  sv: {
    name: 'Namn',
    total: 'Total'
  },
  nb: {
    name: 'Navn',
    total: 'Total'
  },
  fi: {
    name: 'Nimi',
    total: 'Kaikki yhteensä'
  },
  da: {
    name: 'Navn',
    total: 'Total'
  }
}

export const getLanguage = (lang: langCode): language => {
  const locale = lang in languages ? lang : 'en'
  const translations = languages[locale]
  return {
    locale,
    translations
  }
}
