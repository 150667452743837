import { css } from 'lit'

export const style = css`
  :host {
    position: absolute;
    pointer-events: none !important;
    /* Max z-index allowed. */
    z-index: 2147483647;
  }

  :host(:not([role='tooltip'])) {
    display: none !important;
  }

  one-ux-popout {
    background-color: white;
    border-radius: var(--one-ux-radius--200);
  }

  .tooltip {
    display: flex;
    background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.9);
    border-radius: var(--one-ux-radius--200);
    color: var(--one-ux-palette--gray-100);
    padding: var(--one-ux-spacing--small) var(--one-ux-spacing--normal);
    gap: var(--one-ux-spacing--small);
    font: var(--one-ux-font--body-200);
    letter-spacing: var(--one-ux-letter-spacing--body-200);
    line-height: 16px;
    min-height: 24px;
    box-sizing: border-box;
    max-width: 35vw;
  }

  ::slotted(*:not(one-ux-icon)) {
    display: none !important;
  }

  ::slotted(one-ux-icon) {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    overflow: hidden !important;
    flex-shrink: 0 !important;
  }
`
