import { OneUxIconToken } from '../generated/design-tokens'

const sourceSetCache = {} as Record<keyof OneUxIconToken, Record<string, string>>
class SvgSourceService {
  constructor() {
    this.#preload()
  }

  async loadSource(icon: string, set: keyof OneUxIconToken) {
    await this.#cacheSourceSet(set)
    return this.cachedSource(icon, set)
  }

  #preload() {
    try {
      this.#cacheSourceSet('default')
      this.#cacheSourceSet('internal')
      this.#cacheSourceSet('status')
    } catch (err: any) {
      // Do nothing with errors in preload
    }
  }

  async #cacheSourceSet(set: keyof OneUxIconToken) {
    if (!sourceSetCache[set]) {
      const iconSetModule = await this.#fetchSourceSet(set)
      // Additional check is needed since iconSets[set] could have been set by another call that finished before this.
      if (!sourceSetCache[set] && iconSetModule) {
        const iconSet = iconSetModule.default
        sourceSetCache[set] = iconSet
      }
    }
  }

  cachedSource(icon: string, set: keyof OneUxIconToken) {
    const iconSet = sourceSetCache[set]
    if (!iconSet) {
      throw new Error(`The set "${set}" was not preloaded.`)
    }
    const svgSourceOrElement = iconSet[icon]
    if (!svgSourceOrElement) {
      throw new Error(`The icon "${icon}" was not found in the set "${set}".`)
    }

    return sourceSetCache[set][icon]
  }

  #fetchSourceSet(set: keyof OneUxIconToken) {
    switch (set) {
      case 'ai':
        return import('../generated/icons/ai.json')
      case 'default':
        return import('../generated/icons/default.json')
      case 'drawer':
        return import('../generated/icons/drawer.json')
      case 'file':
        return import('../generated/icons/file.json')
      case 'forms':
        return import('../generated/icons/forms.json')
      case 'grc':
        return import('../generated/icons/grc.json')
      case 'internal':
        return import('../generated/icons/internal.json')
      case 'notification':
        return import('../generated/icons/notification.json')
      case 'platform-center':
        return import('../generated/icons/platform-center.json')
      case 'platform-feature':
        return import('../generated/icons/platform-feature.json')
      case 'resource-planning':
        return import('../generated/icons/resource-planning.json')
      case 'scorecard':
        return import('../generated/icons/scorecard.json')
      case 'status':
        return import('../generated/icons/status.json')
      case 'user-permission':
        return import('../generated/icons/user-permission.json')
    }

    throw new Error(`The provided icon set is not supported.`)
  }
}

export const svgSourceService = new SvgSourceService()
