import { AnimatedCustomElement } from '../AnimatedCustomElement'
import { KEY_ENTER } from '@/helpers/keyCodes'

export class LayoutModalElement extends AnimatedCustomElement {
  #currentLeft = 0
  #currentTop = 0
  #dynamicCloseElement = document.createElement('dynamic-layout-modal-close')

  onInitialized() {
    this.#dynamicCloseElement.setAttribute('tabindex', 0)
    this.#dynamicCloseElement.setAttribute('role', 'button')
    this.#dynamicCloseElement.setAttribute('aria-label', this.#getCloseLabel())

    this.#dynamicCloseElement.addEventListener('click', this.#handleClose)
    this.#dynamicCloseElement.addEventListener('keydown', this.#handleKeydown)
    this.addEventListener('mousewheel', this.#preventAncestorScrollHandler)
    this.addEventListener('DOMMouseScroll', this.#preventAncestorScrollHandler)
    this.#setAnimationState()
  }

  onConnected() {
    this.insertBefore(this.#dynamicCloseElement, this.firstElementChild)
  }

  static get observedAttributes() {
    return ['hidden']
  }

  onAttributeChanged({ name }) {
    if (name === 'hidden') {
      this.#setAnimationState()
    }
  }

  animate() {
    if (this.firstElementChild !== this.#dynamicCloseElement) {
      this.insertBefore(this.#dynamicCloseElement, this.firstElementChild)
    }

    const left = Math.floor((window.innerWidth - this.clientWidth) / 2)
    const top = Math.floor((window.innerHeight - this.clientHeight) / 2)

    if (left !== this.#currentLeft && Math.abs(left - this.#currentLeft) > 1) {
      this.style.setProperty('--layout-modal--left', left + 'px')
      this.#currentLeft = left
    }
    if (top !== this.#currentTop && Math.abs(top - this.#currentTop) > 1) {
      this.style.setProperty('--layout-modal--top', top + 'px')
      this.#currentTop = top
    }
  }

  get closable() {
    return (this.getAttribute('closable') || '').toLowerCase() !== 'false'
  }
  set closable(value) {
    this.setAttribute('closable', !!value)
  }

  get header() {
    return this.#getOrCreateTag('layout-modal-header')
  }
  get content() {
    return this.#getOrCreateTag('layout-modal-content')
  }
  get footer() {
    return this.#getOrCreateTag('layout-modal-footer')
  }

  #getCloseLabel = () => {
    const locale = this.closest('[lang]')?.getAttribute('lang')?.substr(0, 2).toLowerCase()
    switch (locale) {
      case 'sv':
        return 'Stäng'
      default:
        return 'Close'
    }
  }

  #setAnimationState = () => {
    this.shouldAnimate = !this.hasAttribute('hidden')
  }

  #handleClose = () => {
    if (this.closable && this.dispatchEvent(new CustomEvent('close', { cancelable: true }))) {
      this.hidden = true
    }
  }

  #handleKeydown = (event) => {
    if (event.which === KEY_ENTER) {
      event.preventDefault()
      this.#dynamicCloseElement.click()
    }
  }

  #preventAncestorScrollHandler = (event) => {
    for (const $node of event.composedPath()) {
      if ($node === this) {
        break
      }

      if (
        $node.clientHeight < $node.scrollHeight &&
        ['auto', 'scroll'].indexOf(window.getComputedStyle($node).overflowY.toLowerCase()) > -1
      ) {
        const delta = event.deltaY || -event.wheelDelta || event.detail
        if (
          (delta < 0 && $node.scrollTop > 0) ||
          (delta > 0 && $node.scrollTop < $node.scrollHeight - $node.clientHeight - 1)
        ) {
          return
        }
      }
    }
    event.preventDefault()
  }

  #getOrCreateTag(tagName) {
    return this.querySelector(tagName) || this.appendChild(document.createElement(tagName))
  }
}
