import { CustomElementBase } from '../CustomElementBase'
export class InputColorOptionsElement extends CustomElementBase {
  onInitialized() {
    this.tabIndex = -1
  }

  onConnected() {
    Array.from(this.childNodes).forEach(($child) => {
      if ($child.nodeType === document.TEXT_NODE) {
        $child.remove()
      }
    })
  }

  get label() {
    return this.getAttribute('label')
  }

  set label(value) {
    this.setAttribute('label', value)
  }
}
