import { css } from 'lit'

export const style = css`
  :host {
    width: 300px;
    vertical-align: text-bottom;
  }

  .one-ux-element--root {
    display: flex;
    flex-direction: column;
    gap: var(--one-ux-spacing--100);
    outline: none;
  }

  .gridLayout {
    --one-ux-bar-chart-element--bars-x: 2;
    --one-ux-bar-chart-element--bars-y: 2;

    display: grid;
    gap: 0 var(--one-ux-spacing--200);
    grid-template-columns: fit-content(30%) 1fr;
  }

  :host([compact]) .gridLayout {
    --one-ux-bar-chart-element--bars-x: 1;

    grid-template-columns: 1fr;
  }

  :host([implicit]) .gridLayout {
    --one-ux-bar-chart-element--bars-y: 1;
  }

  .guidesArea {
    grid-column: var(--one-ux-bar-chart-element--bars-x) / span 1;
    grid-row: var(--one-ux-bar-chart-element--bars-y) / span var(--one-ux-bar-chart-element--bar-count);

    display: flex;
    flex-direction: column;
    padding: 0 var(--one-ux-spacing--200);
  }

  .guides {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  .legendArea {
    grid-column: var(--one-ux-bar-chart-element--bars-x) / span 1;
    grid-row: 1 / span 1;

    margin-bottom: var(--one-ux-spacing--200);
    padding: 0 var(--one-ux-spacing--200);
  }

  .labelArea {
    grid-column: 1 / span 1;
    grid-row: calc(var(--one-ux-bar-chart-element--bars-y) + var(--one-ux-bar-chart-element--bar-index)) / span 1;

    align-self: center;
    text-align: right;
    color: var(--one-ux-palette--brand-neutral-600);
    min-width: 0;
    overflow-wrap: break-word;
  }

  :host(:not([implicit])) .labelArea.typeDistribution {
    align-self: flex-start;
    padding-top: var(--one-ux-spacing--100);
  }

  .barArea {
    grid-column: var(--one-ux-bar-chart-element--bars-x) / span 1;
    grid-row: calc(var(--one-ux-bar-chart-element--bars-y) + var(--one-ux-bar-chart-element--bar-index)) / span 1;

    border-radius: var(--one-ux-radius--300);
    padding: var(--one-ux-spacing--200);
    margin: 0;
    color: var(--one-ux-palette--brand-neutral-600);
    will-change: opacity; /* Need to always promote to new composition layer, otherwise leads to misaligned hover hit box and flickering popouts due to opacity change */
  }

  :host([compact]) .barArea {
    padding-top: var(--one-ux-spacing--100);
  }

  .barArea.inactive {
    opacity: 0.8;
  }

  .barArea.active {
    background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
  }

  :host([state-keyboard-focus]) .barArea.active {
    outline: var(--one-ux-border-width--500) solid var(--one-ux-palette--keyboard-focus-400);
    background: var(--one-ux-palette--keyboard-focus-100);
  }

  .axisArea {
    grid-column: var(--one-ux-bar-chart-element--bars-x) / span 1;
    grid-row: calc(var(--one-ux-bar-chart-element--bars-y) + var(--one-ux-bar-chart-element--bar-count)) / span 1;

    margin-top: var(--one-ux-spacing--200);
    padding: 0 var(--one-ux-spacing--200);
  }
`
