var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _LogSettings_config;
export class LogSettings {
    constructor(config) {
        _LogSettings_config.set(this, void 0);
        __classPrivateFieldSet(this, _LogSettings_config, config, "f");
    }
    static build(config) {
        return new LogSettings(config.log || {});
    }
    get message() {
        return typeof __classPrivateFieldGet(this, _LogSettings_config, "f").message !== 'undefined' ? !!__classPrivateFieldGet(this, _LogSettings_config, "f").message : undefined;
    }
    get info() {
        return typeof __classPrivateFieldGet(this, _LogSettings_config, "f").info !== 'undefined' ? !!__classPrivateFieldGet(this, _LogSettings_config, "f").info : undefined;
    }
    get warning() {
        return typeof __classPrivateFieldGet(this, _LogSettings_config, "f").warning !== 'undefined' ? !!__classPrivateFieldGet(this, _LogSettings_config, "f").warning : undefined;
    }
    get error() {
        return typeof __classPrivateFieldGet(this, _LogSettings_config, "f").error !== 'undefined' ? !!__classPrivateFieldGet(this, _LogSettings_config, "f").error : undefined;
    }
    get debug() {
        return typeof __classPrivateFieldGet(this, _LogSettings_config, "f").debug !== 'undefined' ? !!__classPrivateFieldGet(this, _LogSettings_config, "f").debug : undefined;
    }
    get timer() {
        return typeof __classPrivateFieldGet(this, _LogSettings_config, "f").timer !== 'undefined' ? !!__classPrivateFieldGet(this, _LogSettings_config, "f").timer : undefined;
    }
    get tracker() {
        return typeof __classPrivateFieldGet(this, _LogSettings_config, "f").tracker !== 'undefined' ? !!__classPrivateFieldGet(this, _LogSettings_config, "f").tracker : undefined;
    }
}
_LogSettings_config = new WeakMap();
