import { html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { createRef, ref } from 'lit/directives/ref.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { styleMap } from 'lit/directives/style-map.js'

import { getLanguage, LanguageSet } from './language'
import { keyCodes } from '../common/utils'

import { ListContextMixin } from '../contexts/list/ListContextMixin'
import { StyledFactory } from '../common/mixins/Styled'
import { style } from './style'
import { Disabled } from '../common/mixins/Disabled'
import { Focusable } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { Weight } from '../common/mixins/Weight'
import { Placeholder } from '../common/mixins/Placeholder'
import { OneUxElement } from '../common/OneUxElement'
import { delegateAria } from '../common/mixins/DelegateAria'
import { OptionContent } from '../contexts/list/contextual-one-ux-list/components/OptionContent'
import { ContextualOneUxListElement } from '../contexts/list/contextual-one-ux-list/ContextualOneUxListElement'
import { OneUxPopoutElement } from '../one-ux-popout/OneUxPopoutElement'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../common/mixins/Validated'
import { Required, IRequired } from '../common/mixins/Required'
import { FormAssociated } from '../common/mixins/FormAssociated'
import type { IValue } from '../common/mixins/Value'
import { Label } from '../common/mixins/Label'
import { Errors } from '../common/mixins/Errors'
import { Compact } from '../common/mixins/Compact'
import type { OneUxFieldElement } from '../one-ux-field/OneUxFieldElement'
import { log } from '../common/utils/log'

const Styled = StyledFactory(style)

const Validated = ValidatedFactory<IValue<unknown> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldYouHaveToMakeChoice } = getFormValidationLanguage(this)
    const valid = hasValue(this.value)
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldYouHaveToMakeChoice]
    }
  }
})

function hasValue(value: unknown) {
  return !!(Array.isArray(value) ? value.length : value)
}

const BaseClass = Validated(
  Errors(Label(Compact(Required(Placeholder(Styled(Disabled(Focusable(Implicit(Weight(OneUxElement))))))))))
)

/**
 * A dropdown component that allows for single and multi selection in from a list.
 */
@customElement('one-ux-list-dropdown')
export class OneUxListDropdownElement extends FormAssociated(ListContextMixin(BaseClass)) {
  /**
   * A template sting that will have all occurrences of `$0` replaced with the count of selected items.
   * Only applied when `multiple` is set.
   */
  @property({ attribute: 'multiple-selected-template', type: String })
  public multipleSelectedTemplate!: string

  @state()
  private _open = false

  #valueOnOpen: unknown | unknown[]

  constructor() {
    super()
    this.addEventListener('blur', () => {
      this._open = false
    })
    this.addEventListener('keydown', this.#handleKeydown)
  }

  #fieldElement = createRef<OneUxFieldElement>()
  #popoutElement = createRef<OneUxPopoutElement>()
  #contextualListElement = createRef<ContextualOneUxListElement>()
  protected render() {
    const values = this._listContextProvider.value!.value
    const listId = 'list'

    const { translations, lang } = getLanguage(this)

    const $reference = this.#fieldElement.value?.shadowRoot?.querySelector('.js-field')

    return html`<one-ux-field
      ${ref(this.#fieldElement)}
      class="one-ux-element--root"
      .label=${this.label}
      .compact=${this.compact}
      .required=${this.required}
      .disabled=${this.disabled}
      .implicit=${this.implicit}
      .weight=${this.weight}
      .empty=${!hasValue(values)}
      .errors=${this.errors}
      .hideErrors=${this._open}
      @click=${this.#toggleOpen}
    >
      <div
        class="field-inner"
        role="combobox"
        lang=${ifDefined(lang)}
        aria-required=${!!this.required}
        aria-label=${this.label}
        aria-expanded=${this._open}
        aria-controls=${ifDefined(this._open ? listId : undefined)}
        tabindex=${this.disabled || this._open ? -1 : 0}
      >
        <div class="field-inner-content">${this.#renderText(values, translations)}</div>
        <one-ux-icon class="field-icon" icon="toggle-down" aria-hidden="true" size="200"></one-ux-icon>
      </div>

      ${!this._open
        ? nothing
        : html`
            <one-ux-popout
              ${ref(this.#popoutElement)}
              @click=${(e: Event) => e.stopPropagation()}
              .reference=${$reference}
              indent="none"
              indent-top="normal"
              indent-bottom="normal"
              style=${styleMap({
                'min-width': $reference ? `${$reference.getBoundingClientRect().width}px` : null
              })}
            >
              <contextual-one-ux-list
                ${ref(this.#contextualListElement)}
                id=${listId}
                .delegateAria=${{
                  'aria-label': this.label || null
                } as delegateAria}
                implicit
                width="max"
                @input="${this.#handleInput}"
                @blur="${this.#handleBlur}"
              ></contextual-one-ux-list>
            </one-ux-popout>
          `}
    </one-ux-field>`
  }

  protected async getUpdateComplete() {
    const result = await super.getUpdateComplete()
    await this.#fieldElement.value?.updateComplete
    await this.#popoutElement.value?.updateComplete
    await this.#contextualListElement.value?.updateComplete
    return result
  }

  #renderText = (values: unknown[], translations: LanguageSet) => {
    const options = this._listContextProvider.value!.options?.flatMap((entry) =>
      'options' in entry ? entry.options : entry
    )

    if (!values.length || !options?.length) {
      return this.placeholder
    }

    if (values.length > 1) {
      return (this.multipleSelectedTemplate || translations.selected).replace(/\$0/g, values.length.toString())
    }

    const selected = options.find((entry) => 'value' in entry && entry.value === values[0])
    return selected ? OptionContent(selected, { truncate: true }) : this.placeholder
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    if (!this._open) {
      switch (event.code) {
        case keyCodes.SPACE:
        case keyCodes.UP:
        case keyCodes.DOWN:
        case keyCodes.RETURN:
          this.#toggleOpen()
          return handled()
      }
      return
    }

    switch (event.code) {
      case keyCodes.ESCAPE:
        if (this._open) {
          this.#toggleOpen()
          return handled()
        }
        break
    }
  }

  #handleInput = () => {
    if (!this.multiple) {
      this.#toggleOpen()
    }
    this.dispatchEvent(new Event('input'))
  }

  #handleBlur = () => {
    if (this.#changed) {
      this.dispatchEvent(new Event('change'))
    }
  }

  #toggleOpen = async () => {
    if (this._open) {
      // Fix for bug in chromium where mouse leave events are not triggered if you remove a DOM element below the mouse and the mouse afterwards is outside the ShadowDOM
      this.shadowRoot!.querySelector<HTMLElement>('.field-inner')!.focus()
      this.shadowRoot!.querySelector<HTMLElement>('one-ux-popout')!.hidden = true
      requestAnimationFrame(() => {
        this._open = false
      })
    } else {
      this.#valueOnOpen = this.value
      this._open = true
      await this.updateComplete
      requestAnimationFrame(() => {
        this.shadowRoot!.querySelector('contextual-one-ux-list')!.focus()
      })
    }
  }

  get #changed() {
    if (!this.multiple) {
      return this.value != this.#valueOnOpen
    }
    const sameSize = (this.value as unknown[]).length === (this.#valueOnOpen as unknown[]).length
    if (!sameSize) {
      return true
    }
    const areEqual = (this.#valueOnOpen as unknown[]).every((element) => {
      return (this.value as unknown[]).includes(element)
    })
    return !areEqual
  }

  /**
   * **DEPRECATED** Use `compact` instead.
   */
  @property({ attribute: 'label-hidden', type: Boolean })
  public get labelHidden() {
    return this.compact
  }
  public set labelHidden(value: boolean) {
    log.deprecation({
      title: '"label-hidden" is deprecated in favour of the property "compact".',
      details: this
    })
    this.compact = value
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-list-dropdown': OneUxListDropdownElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-list-dropdown': OneUxListDropdownElement
    }
  }
}
