import { invLerp, lerp } from '../../../common/math'
import { bounds } from '../../types'
import { getValueTicks } from './getValueTicks'
import { Scale } from '../types'

export class ValueScale implements Scale<number> {
  #bounds: bounds
  #minLimitValue?: number
  #maxLimitValue?: number
  ticks: number[]

  constructor(bounds: bounds, minValue: number, maxValue: number, minLimitValue?: number, maxLimitValue?: number) {
    this.#bounds = bounds
    this.#minLimitValue = minLimitValue
    this.#maxLimitValue = maxLimitValue
    this.ticks = getValueTicks(Math.abs(bounds.bottom - bounds.top), minValue, maxValue, minLimitValue, maxLimitValue)
  }

  public get bounds(): bounds {
    return { ...this.#bounds }
  }

  public get domainPosition(): number {
    return this.clampPosition(0)
  }

  public position(value: number): number {
    const min = this.ticks[0]
    const max = this.ticks[this.ticks.length - 1]
    const t = invLerp(min, max, value)
    return lerp(this.#bounds.bottom, this.#bounds.top, t)
  }

  public clampPosition(value: number): number {
    switch (this.getLimitPassed(value)) {
      case 'max':
        return this.position(Number(this.#maxLimitValue))
      case 'min':
        return this.position(Number(this.#minLimitValue))
      default:
        return this.position(value)
    }
  }

  public getLimitPassed(value: number) {
    if (Number.isFinite(this.#maxLimitValue) && value > Number(this.#maxLimitValue)) {
      return 'max'
    } else if (Number.isFinite(this.#minLimitValue) && value < Number(this.#minLimitValue)) {
      return 'min'
    }
    return 'none'
  }
}
