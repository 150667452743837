import BezierEasing from 'bezier-easing'
const { document, requestAnimationFrame, cancelAnimationFrame, getComputedStyle } = window

document.addEventListener('animationstart', handleAnimationStart, true)

function handleAnimationStart(e) {
  if (
    e.animationName === 'pdr-idiom-busy-start' ||
    e.animationName === 'pdr-idiom-input-busy-start' ||
    e.animationName === 'pdr-idiom-input-color-busy-start' ||
    e.animationName === 'pdr-idiom-textarea-busy-start' ||
    e.animationName === 'pdr-idiom-select-busy-start'
  ) {
    const field = e.target
    const easing = BezierEasing(0.4, 0, 0.2, 1)
    const animationDuration = 2 * 1000
    let startTime
    field.setAttribute('busy-in-progress', '')

    cancelAnimationFrame(field.__busyProgressUpdateRequest)
    cancelAnimationFrame(field.__busyDoneUpdateRequest)

    field.__busyProgressUpdateRequest = requestAnimationFrame(function update(currentTime) {
      startTime = startTime || currentTime
      let normalizedAnimationTime = ((currentTime - startTime) % animationDuration) / animationDuration
      let value = easing(normalizedAnimationTime)

      field.style.borderBottomColor = ''
      field.style.backgroundImage = ''
      field.style.backgroundSize = ''
      field.style.backgroundPosition = ''
      field.style.backgroundRepeat = ''
      const currentStyle = getComputedStyle(field)

      let currentBackgroundImage = currentStyle.backgroundImage ? `${currentStyle.backgroundImage},` : ''
      let currentBackgroundSize = currentStyle.backgroundSize ? `${currentStyle.backgroundSize},` : ''
      let currentBackgroundPosition = currentStyle.backgroundPosition ? `${currentStyle.backgroundPosition},` : ''
      let currentBackgroundRepeat = currentStyle.backgroundRepeat ? `${currentStyle.backgroundRepeat},` : ''

      if (e.target.tagName === 'SELECT' || e.target.tagName === 'INPUT-SELECT') {
        currentBackgroundPosition = 'center right 11px,'
      }

      let borderBottomColor = currentStyle.borderBottomColor
      let borderBottomWidth = currentStyle.borderBottomWidth
      let width
      let offset

      if (value < 0.5) {
        width = 75 * (value / 0.5)
        offset = 25 * (value / 0.5)
      } else {
        width = 75 - 75 * ((value - 0.5) / 0.5)
        offset = 25 + 75 * ((value - 0.5) / 0.5)
      }

      field.style.borderBottomColor = 'transparent'
      field.style.backgroundImage = `${currentBackgroundImage} linear-gradient(#7DBBEA, #7DBBEA), linear-gradient(${borderBottomColor}, ${borderBottomColor})`
      field.style.backgroundSize = `${currentBackgroundSize} ${Math.round(width)}% 3px, 100% ${borderBottomWidth}`
      field.style.backgroundPosition = `${currentBackgroundPosition} ${Math.floor(
        1 + (offset * field.clientWidth) / 100
      )}px calc(100% + ${borderBottomWidth}), 0% calc(100% + ${borderBottomWidth})`
      field.style.backgroundRepeat = `${currentBackgroundRepeat} no-repeat, no-repeat`

      if (currentStyle.animationName !== 'none' && document.contains(field)) {
        field.__busyProgressUpdateRequest = requestAnimationFrame(update)
      } else {
        field.style.borderBottomColor = ''
        field.style.backgroundImage = ''
        field.style.backgroundSize = ''
        field.style.backgroundPosition = ''
        field.style.backgroundRepeat = ''
        field.removeAttribute('busy-in-progress')
      }
    })
  }

  if (
    e.animationName === 'pdr-idiom-busy-stop' ||
    e.animationName === 'pdr-idiom-input-busy-stop' ||
    e.animationName === 'pdr-idiom-input-color-busy-stop' ||
    e.animationName === 'pdr-idiom-textarea-busy-stop' ||
    e.animationName === 'pdr-idiom-select-busy-stop'
  ) {
    const field = e.target
    const easing = BezierEasing(0.4, 0, 0.2, 1)
    const animationDuration = 1 * 1000
    let startTime

    cancelAnimationFrame(field.__busyProgressUpdateRequest)
    cancelAnimationFrame(field.__busyDoneUpdateRequest)

    field.__busyDoneUpdateRequest = requestAnimationFrame(function update(currentTime) {
      startTime = startTime || currentTime
      let normalizedAnimationTime = (currentTime - startTime) / animationDuration
      let value = easing(normalizedAnimationTime)

      field.style.borderBottomColor = ''
      field.style.backgroundImage = ''
      field.style.backgroundSize = ''
      field.style.backgroundPosition = ''
      field.style.backgroundRepeat = ''
      const currentStyle = getComputedStyle(field)

      let currentBackgroundImage = currentStyle.backgroundImage ? `${currentStyle.backgroundImage},` : ''
      let currentBackgroundSize = currentStyle.backgroundSize ? `${currentStyle.backgroundSize},` : ''
      let currentBackgroundPosition = currentStyle.backgroundPosition ? `${currentStyle.backgroundPosition},` : ''
      let currentBackgroundRepeat = currentStyle.backgroundRepeat ? `${currentStyle.backgroundRepeat},` : ''

      if (e.target.tagName === 'SELECT' || e.target.tagName === 'INPUT-SELECT') {
        currentBackgroundPosition = 'center right 11px,'
      }

      let borderBottomColor = currentStyle.borderBottomColor
      let borderBottomWidth = currentStyle.borderBottomWidth
      let width
      let color

      // blue HSL: 206, 72%, 70%
      // green HSL: 179, 56%, 48%

      if (value < 0.5) {
        let t = value / 0.5
        width = 100 * t
        color = `hsla(${lerp(206, 179, t)}, ${lerp(72, 56, t)}%, ${lerp(70, 48, t)}%, 1)`
      } else if (value < 0.75) {
        width = 100
        color = `hsla(179, 56%, 48%, 1)`
      } else {
        width = 100
        color = `hsla(179, 56%, 48%, ${1 - (value - 0.5) / 0.5})`
      }

      field.style.borderBottomColor = 'transparent'
      field.style.backgroundImage = `${currentBackgroundImage} linear-gradient(${color}, ${color}), linear-gradient(${borderBottomColor}, ${borderBottomColor})`
      field.style.backgroundSize = `${currentBackgroundSize} ${Math.round(width)}% 3px, 100% ${borderBottomWidth}`
      field.style.backgroundPosition = `${currentBackgroundPosition} 50% calc(100% + ${borderBottomWidth}), 0% calc(100% + ${borderBottomWidth})`
      field.style.backgroundRepeat = `${currentBackgroundRepeat} no-repeat, no-repeat`

      if (normalizedAnimationTime < 1 && document.contains(field)) {
        field.__busyDoneUpdateRequest = requestAnimationFrame(update)
      } else {
        field.style.borderBottomColor = ''
        field.style.backgroundImage = ''
        field.style.backgroundSize = ''
        field.style.backgroundPosition = ''
        field.style.backgroundRepeat = ''
        field.removeAttribute('busy-in-progress')
      }
    })
  }
}

function lerp(a, b, t) {
  return a * (1 - t) + b * t
}
