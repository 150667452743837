import { OneUxElement } from '../common/OneUxElement'
import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { createRef, ref } from 'lit/directives/ref.js'
import { PlacementController } from './PlacementController'
import { style } from './style'
import { Layout } from '../common/mixins/Layout'
import { StyledFactory } from '../common/mixins/Styled'
import { ifDefined } from 'lit/directives/if-defined.js'
import { OneUxContainerElement } from '../one-ux-container/OneUxContainerElement'
import { Implicit } from '../common/mixins/Implicit'

const Styled = StyledFactory(style)

const BaseClass = Implicit(Layout(Styled(OneUxElement)))

/**
 * A component that allows you to place popout elements that float above other content.
 */
@customElement('one-ux-popout')
export class OneUxPopoutElement extends BaseClass {
  /**
   * Controls where on the reference element the popout should be placed.
   */
  @property({ type: String })
  public alignment: 'start' | 'center' | 'end' = 'start'

  /**
   * Controls the initial direction in which the popout opens
   */
  @property({ type: String })
  public direction: 'horizontal' | 'vertical' = 'vertical'

  /**
   * Controls if the `<one-ux-popout>` is placed before or after the reference element.
   * Works in relation with `direction`.
   */
  @property({ type: String })
  public placement: 'before' | 'after' = 'after'

  /**
   * Allows you to defined the reference element. Can be any of the following:
   * - `"parent"` A parent element, can define how far way to look with `reference-depth`.
   * - `"previous"` The previous element.
   * - `Element` An instance of a DOM element. Can be placed anywhere in the DOM hierarchy. Useful when working with shadowDom.
   */
  @property({ type: String })
  public reference: 'parent' | 'previous' | Element | null | undefined = 'parent'

  /**
   * When `reference` is set to `"parent"` you can control how many levels above the `<one-ux-popout>` to select the reference element.
   */
  @property({ type: Number, attribute: 'reference-depth' })
  public referenceDepth = 1

  /**
   * Bump the `<one-ux-popout>` in relation to the reference element by the provided amount of pixels.
   * Depending on what is set in `direction` and `placement` this will move the popout in different directions.
   */
  @property({ type: Number, attribute: 'offset-reference' })
  public offsetReference = 4

  /**
   * Bump the `<one-ux-popout>` in relation the edge it is aligned to by the provided amount of pixels.
   * Depending on what is set in `alignment` the popout will move in different directions.
   */
  @property({ type: Number, attribute: 'offset-alignment' })
  public offsetAlignment = 0

  /**
   * Allow `<one-ux-popout>` to cover the reference element when normal positioning is not possible due to
   * Viewport constraints, by placing `<one-ux-popout>` centered on top of the reference element.
   */
  @property({ type: Boolean, attribute: 'prevent-overlap' })
  public preventOverlap = false

  constructor() {
    super()
    this.indent = 'normal'
    new PlacementController(this)
  }

  protected render() {
    return html`
      <one-ux-container
        ${ref(this.#refs.$container)}
        class="one-ux-element--root"
        background=${ifDefined(this.implicit ? undefined : 'gray-100')}
        layout=${this.layout}
        indent=${this.indent}
        indent-top=${ifDefined(this.indentTop)}
        indent-right=${ifDefined(this.indentRight)}
        indent-bottom=${ifDefined(this.indentBottom)}
        indent-left=${ifDefined(this.indentLeft)}
        gutters=${this.gutters}
      >
        <slot></slot>
      </one-ux-container>
    `
  }

  protected async getUpdateComplete() {
    const result = await super.getUpdateComplete()
    await this.#refs.$container.value?.updateComplete
    return result
  }

  #refs = {
    $container: createRef<OneUxContainerElement>()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-popout': OneUxPopoutElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-popout': OneUxPopoutElement
    }
  }
}
