import { OneUxElement } from '../../common/OneUxElement'
import { Constructor } from '../../common/utils'
import { property } from 'lit/decorators.js'
import {
  DividerData,
  GroupData,
  InputDivider,
  InputGroup,
  InputOption,
  OptionData
} from './contextual-one-ux-menu/types'
import { cloneItem } from './contextual-one-ux-menu/utils'
import { ContextProvider } from '@lit/context'
import { IMenuContext, menuContext } from './IMenuContext'

export interface IMenuProps {
  items: (InputDivider | InputOption | InputGroup)[]
}

export interface IMenuContextMixin extends IMenuProps {
  _menuContextProvider: ContextProvider<{ __context__: IMenuContext }>
}

export const MenuContextMixin = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class MenuContextMixinClass extends SuperClass {
    /**
     * InputOption:
     * The option to be clicked that will execute an action either defined on the item itself or listened on through the "option" event.
     * * text: Displayed text for option.
     * * value: When listening on the option event it will be passed as argument.
     * * icon: Icon to the left of displayed text.
     * * disabled: Non-interactive and greyed out option .
     * * action: Function to be executed when clicked.
     * * tooltip: A tooltip that will be displayed on hover and when an option is active.
     *
     * InputDivider:
     * Defines a divided section between options/groups.
     *
     * InputGroup:
     * Defines expandable grouping of options.
     * * text: Displayed text for option.
     * * expanded: Sets whether the grouping is expanded.
     * * icon: Icon to the left of displayed text.
     * * disabled: Non-interactive and greyed out grouping.
     */
    @property({ type: Array })
    public get items() {
      return this.#items
    }
    public set items(value: (InputDivider | InputOption | InputGroup)[]) {
      const oldValue = this.#items
      this.#items = value
      this.#calculateInternalItems()
      this.requestUpdate('items', oldValue)
    }
    #items: (InputDivider | InputOption | InputGroup)[] = []

    #calculateInternalItems() {
      this.#updateContext(
        'items',
        this.#items.map((item) => cloneItem(item, null))
      )
    }

    _menuContextProvider = new ContextProvider(this, menuContext, {
      items: [] as (GroupData | OptionData | DividerData)[]
    })

    #updateContext<TKey extends keyof IMenuContext>(name: TKey, value: IMenuContext[TKey]) {
      const context = this._menuContextProvider.value!
      const oldValue = context[name]
      if (oldValue !== value) {
        this._menuContextProvider.setValue({
          ...this._menuContextProvider.value,
          [name]: value
        })
      }
    }
  }
  return MenuContextMixinClass as Constructor<IMenuContextMixin> & TSuperClass
}
