import { keyValue } from '../../types'

export const wrapKeys = (keys: keyValue[]): string[][] => {
  if (!isBreakingLines(keys)) {
    return keys.map((x) => [x.toString()])
  }

  const BREAKLINE_AT_LENGTH = 30
  const MINIMUM_LINE_COUNT = 2

  const maxTextLength = Math.max(...keys.map((key) => key.toString().length))
  const maxLineCount = Math.max(Math.ceil(maxTextLength / BREAKLINE_AT_LENGTH), MINIMUM_LINE_COUNT)
  const lineTextCountLimit = Math.ceil(maxTextLength / maxLineCount)

  const result = keys.map((key) => {
    const parts = key.toString().split(/(\s+)/)
    const lines = parts.reduce((acc, current) => {
      if (!acc.length) {
        acc[0] = current
      } else if (acc[acc.length - 1].length < lineTextCountLimit) {
        acc[acc.length - 1] += current
      } else {
        acc[acc.length] = current.trim()
      }
      return acc
    }, [] as string[])

    return lines
  })

  return result
}

const isBreakingLines = (keys: keyValue[]) => {
  const MIN_WORD_COUNT = 2
  const LENGTH_OF_LINE_LIMIT = 15

  return keys.some(
    (key) => key.toString().split(' ').length > MIN_WORD_COUNT && key.toString().length > LENGTH_OF_LINE_LIMIT
  )
}
