var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _ApiModule_instances, _ApiModule_responseCallbacks, _ApiModule_httpClient, _ApiModule_local, _ApiModule_headers, _ApiModule_scopes, _ApiModule_request, _ApiModule_consolidateHeaders, _ApiModule_executeResponseCallbacks;
import { PDRModule } from '../PDRModule';
import { CancellationToken } from './CancellationToken';
import { HttpClient } from './HttpClient';
import { ApiErrors } from './ApiErrors';
import { HttpResult } from './HttpResult';
import { ApiSettings } from './ApiSettings';
import { log } from '../log';
export class ApiModule extends PDRModule {
    constructor({ scopes = [], headers = {}, responseCallbacks = [], local = false, started = null, configured = null, initialized = null } = {}) {
        super(ApiSettings.build);
        _ApiModule_instances.add(this);
        _ApiModule_responseCallbacks.set(this, []);
        _ApiModule_httpClient.set(this, new HttpClient());
        _ApiModule_local.set(this, false);
        _ApiModule_headers.set(this, {});
        _ApiModule_scopes.set(this, []);
        __classPrivateFieldSet(this, _ApiModule_local, local, "f");
        Object.assign(__classPrivateFieldGet(this, _ApiModule_headers, "f"), headers);
        __classPrivateFieldGet(this, _ApiModule_scopes, "f").push(...scopes);
        __classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f").push(...responseCallbacks.filter((c) => typeof c === 'function'));
        if (local) {
            this._started = started;
            this._configured = configured;
            this._initialized = initialized;
        }
    }
    get(url, options = {}) {
        return this.request('GET', url, options);
    }
    delete(url, options = {}) {
        return this.request('DELETE', url, options);
    }
    post(url, data, options = {}) {
        return this.request('POST', url, Object.assign({}, options, {
            data
        }));
    }
    put(url, data, options = {}) {
        return this.request('PUT', url, Object.assign({}, options, {
            data
        }));
    }
    patch(url, data, options = {}) {
        return this.request('PATCH', url, Object.assign({}, options, {
            data
        }));
    }
    async download(url, { method = 'GET', fileName = '', headers = {}, cancellationToken = null } = {}) {
        const httpResult = await __classPrivateFieldGet(this, _ApiModule_instances, "m", _ApiModule_request).call(this, method, url, {
            options: {
                responseType: 'blob'
            },
            headers,
            cancellationToken
        });
        fileName = fileName.length ? fileName : getFileNameFromContentDispositionHeader(httpResult);
        const { utils } = await this._started;
        utils.download(httpResult.response, fileName);
    }
    async request(method, url, { data = null, headers = {}, options = {}, cancellationToken = null } = {}) {
        const httpResult = await __classPrivateFieldGet(this, _ApiModule_instances, "m", _ApiModule_request).call(this, method, url, { data, headers, options, cancellationToken });
        return httpResult.response;
    }
    createCancellationToken() {
        return new CancellationToken();
    }
    build({ scopes = [], headers = {}, responseCallbacks = [] } = {}) {
        const api = new ApiModule({
            scopes,
            headers,
            responseCallbacks,
            local: true,
            started: this._started,
            configured: this._configured,
            initialized: this._initialized
        });
        return api;
    }
    withScopes(...scopes) {
        const headers = __classPrivateFieldGet(this, _ApiModule_headers, "f");
        const responseCallbacks = __classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f");
        return this.build({ scopes, headers, responseCallbacks });
    }
    withHeaders(headers) {
        const scopes = __classPrivateFieldGet(this, _ApiModule_scopes, "f");
        const responseCallbacks = __classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f");
        return this.build({ scopes, headers, responseCallbacks });
    }
    addResponseCallback(callback) {
        if (typeof callback !== 'function') {
            throw ApiErrors.error(`Invalid request callback with type ${typeof callback}`);
        }
        if (!__classPrivateFieldGet(this, _ApiModule_local, "f")) {
            throw ApiErrors.error('Operation is only available on local PDR.api instances.');
        }
        __classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f").push(callback);
        return this;
    }
    removeResponseCallback(callback) {
        const index = __classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f").indexOf(callback);
        if (index >= 0) {
            __classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f").splice(index, 1);
        }
        else {
            // eslint-disable-next-line no-console
            log.warning('Could not find and remove response callback.');
        }
        return this;
    }
    clearResponseCallbacks() {
        if (!__classPrivateFieldGet(this, _ApiModule_local, "f")) {
            return;
        }
        __classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f").length = 0;
        return this;
    }
}
_ApiModule_responseCallbacks = new WeakMap(), _ApiModule_httpClient = new WeakMap(), _ApiModule_local = new WeakMap(), _ApiModule_headers = new WeakMap(), _ApiModule_scopes = new WeakMap(), _ApiModule_instances = new WeakSet(), _ApiModule_request = async function _ApiModule_request(method, url, { data = null, headers = {}, options = {}, cancellationToken = null } = {}) {
    const [{ session }, config] = await this._initialized;
    const user = await session.getUser(false, __classPrivateFieldGet(this, _ApiModule_scopes, "f"));
    if (!user) {
        throw ApiErrors.error('No user found in session, request failed');
    }
    const consolidatedHeaders = __classPrivateFieldGet(this, _ApiModule_instances, "m", _ApiModule_consolidateHeaders).call(this, user, config, headers);
    let httpResult;
    try {
        try {
            httpResult = await __classPrivateFieldGet(this, _ApiModule_httpClient, "f").send(method, url, {
                data,
                headers: consolidatedHeaders,
                options,
                cancellationToken
            });
        }
        catch (e) {
            const error = e;
            if (config.loginAndRetryCodes.includes(error.status)) {
                const user = await session.getUser(true, __classPrivateFieldGet(this, _ApiModule_scopes, "f"));
                httpResult = await __classPrivateFieldGet(this, _ApiModule_httpClient, "f").send(method, url, {
                    data,
                    headers: Object.assign({}, consolidatedHeaders, {
                        Authorization: `Bearer ${user.access_token}`
                    }),
                    options,
                    cancellationToken
                });
            }
            else {
                throw error;
            }
        }
    }
    catch (e) {
        const error = e;
        if (error.result instanceof HttpResult) {
            httpResult = error.result;
        }
        throw error;
    }
    finally {
        __classPrivateFieldGet(this, _ApiModule_instances, "m", _ApiModule_executeResponseCallbacks).call(this, httpResult);
    }
    return httpResult;
}, _ApiModule_consolidateHeaders = function _ApiModule_consolidateHeaders(user, config, headers) {
    return Object.assign({
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        Authorization: `Bearer ${user.access_token}`
    }, config.extraHeaders, __classPrivateFieldGet(this, _ApiModule_headers, "f"), headers);
}, _ApiModule_executeResponseCallbacks = function _ApiModule_executeResponseCallbacks(httpResult) {
    const callbacks = [...__classPrivateFieldGet(this, _ApiModule_responseCallbacks, "f")];
    if (httpResult) {
        callbacks.forEach((callback) => {
            try {
                callback(httpResult);
            }
            catch (err) {
                // eslint-disable-next-line no-console
                log.warning('Could not execute request callback.');
            }
        });
    }
    callbacks.length = 0;
};
function getFileNameFromContentDispositionHeader(httpResult) {
    if (!Object.prototype.hasOwnProperty.call(httpResult.headers, 'content-disposition')) {
        throw new Error('Couldn\'t access header "Content-Disposition". Make sure "Content-Disposition" is provided by the server and is exposed to CORS-requests by adding "Content-Disposition" to the header "Access-Control-Expose-Headers".');
    }
    const disposition = httpResult.headers['content-disposition'] || '';
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    let fileNameFromDisposition = '';
    if (utf8FilenameRegex.test(disposition)) {
        fileNameFromDisposition = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    }
    else {
        const matches = asciiFilenameRegex.exec(disposition);
        if (matches != null && matches[2]) {
            fileNameFromDisposition = matches[2];
        }
    }
    if (!fileNameFromDisposition.length) {
        throw new Error('The "Content-Disposition" header does not include a file name.');
    }
    return fileNameFromDisposition;
}
