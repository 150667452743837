import { CustomElementBase } from './CustomElementBase'

export class AnimatedCustomElement extends CustomElementBase {
  #animationFrame
  #shouldAnimate = true

  /* Template Methods */
  animate() {}

  startEffects() {
    if (this.#shouldAnimate) {
      this.#animationFrame = requestAnimationFrame(this.#loop)
    }
  }

  stopEffects() {
    cancelAnimationFrame(this.#animationFrame)
  }

  set shouldAnimate(val) {
    this.#shouldAnimate = val
    if (val) {
      this.#animationFrame = requestAnimationFrame(this.#loop)
    } else {
      cancelAnimationFrame(this.#animationFrame)
    }
  }

  #loop = () => {
    this.animate()
    this.#animationFrame = requestAnimationFrame(this.#loop)
  }
}
