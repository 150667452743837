import { html, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';
import { Toast as ToastComponent } from './Toast';
export const ToastList = (toasts) => {
    return toasts.length
        ? html `<one-ux-container
        width="auto"
        layout="rows"
        separate="none"
        gutters="large"
        style=${styleMap({
            maxWidth: '360px',
            position: 'fixed',
            top: 'var(--one-ux-spacing--500)',
            right: 'var(--one-ux-spacing--700)',
            zIndex: Number.MAX_SAFE_INTEGER.toString()
        })}
      >
        ${repeat(toasts, (toast) => toast.$ref, (toast) => ToastComponent(toast))}
      </one-ux-container>`
        : nothing;
};
