import { LanguageSets, langCode } from '../../common/utils/getLangCode'

export type language = {
  locale: langCode
  translations: translationSet
}

export type translationSet = {
  barChart: string
}

export const languages: LanguageSets<translationSet> = {
  en: {
    barChart: 'Bar chart'
  },
  sv: {
    barChart: 'Stapeldiagram'
  },
  nb: {
    barChart: 'Stolpediagram'
  },
  fi: {
    barChart: 'Pylväsdiagrammi'
  },
  da: {
    barChart: 'Søjlediagram'
  }
}

export const getLanguage = (locale: langCode): language => {
  const translations = languages[locale]
  return {
    locale,
    translations
  }
}
