import './input-select.less'
import { InputSelectOptionElement } from './InputSelectOptionElement'
import { InputSelectOptionGroupElement } from './InputSelectOptionGroupElement'
import { InputSelectPopupElement } from './InputSelectPopupElement'
import { InputSelectPreviewElement } from './InputSelectPreviewElement'
import { DynamicInputSelectSearchElement } from './DynamicInputSelectSearchElement'
import { InputSelectElement } from './InputSelectElement'

customElements.define('input-select-option', InputSelectOptionElement)
customElements.define('input-select-option-group', InputSelectOptionGroupElement)
customElements.define('input-select-popup', InputSelectPopupElement)
customElements.define('input-select-preview', InputSelectPreviewElement)
customElements.define('dynamic-input-select-search', DynamicInputSelectSearchElement)
customElements.define('input-select', InputSelectElement)
