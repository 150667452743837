import { html, nothing } from 'lit';
import { ref } from 'lit/directives/ref.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { getLanguage } from '../lang';
export const Toast = (toast) => {
    return html `
    <one-ux-container
      ${ref(toast.$ref)}
      layout="rows"
      gutters="normal"
      indent="large"
      background="azure-100"
      role="alert"
      aria-live="assertive"
      class="one-ux-element--root"
      style=${styleMap({
        boxShadow: 'var(--one-ux-shadow--drop-500)',
        borderRadius: 'var(--one-ux-radius--500)',
        overflow: 'hidden',
        zIndex: Number.MAX_SAFE_INTEGER.toString(),
        cursor: 'pointer'
    })}
      @click=${() => (toast.copyable ? toast.copy() : toast.close())}
    >
      <one-ux-container gutters="normal" layout="columns" height="min">
        <one-ux-container width="min" align-vertical="center"> ${getToastIcon(toast)} </one-ux-container>
        <one-ux-container align-vertical="center">
          <span class="one-ux-typography--heading-200" width="auto" style="margin: 0;">${toast.title}</span>
        </one-ux-container>
      </one-ux-container>
      <one-ux-container>
        <span class="one-ux-typography--body-300" style="margin: 0;">${toast.description}</span>
      </one-ux-container>
      <one-ux-container layout="columns" gutters="smallest" height="min">
        <one-ux-container align-vertical="center">${ifDefined(getProgress(toast))}</one-ux-container>
        ${getButtonCopy(toast)}
        <one-ux-container width="min" align-vertical="center"> ${getButtonOK(toast)} </one-ux-container>
      </one-ux-container>
    </one-ux-container>
  `;
};
const getProgress = (toast) => {
    if (!Number.isInteger(toast.duration)) {
        return nothing;
    }
    return html `<progress
    value="0"
    min="0"
    max="100"
    class="sem is-sem is-prio--low is-mode--both set-color--focus-600"
    style="width: 100%"
  ></progress>`;
};
const getButtonOK = (toast) => {
    const { lang, translations: language } = getLanguage();
    return html `<one-ux-button
    implicit
    lang=${lang}
    weight="low"
    @click=${(e) => {
        e.stopImmediatePropagation();
        e.preventDefault();
        toast.close();
    }}
    text=${language.ok}
  ></one-ux-button>`;
};
const getButtonCopy = (toast) => {
    if (!toast.copyable) {
        return nothing;
    }
    const { lang, translations: language } = getLanguage();
    const text = !toast.isCopied ? language.copy : `${language.copied}!`;
    const icon = !toast.isCopied ? 'copy' : nothing;
    return html `
    <one-ux-container width="min" align-vertical="center">
      <one-ux-button
        implicit
        lang=${lang}
        weight="low"
        @click=${(e) => {
        e.stopImmediatePropagation();
        e.preventDefault();
        toast.copy();
    }}
        text=${text}
        icon=${ifDefined(icon)}
      ></one-ux-button>
    </one-ux-container>
  `;
};
const getToastIcon = (toast) => {
    let set = 'default';
    let color = '';
    let icon = '';
    const size = 600;
    switch (toast.type) {
        case 'confirmation':
            set = 'status';
            icon = 'done';
            color = '--one-ux-palette--mint-400';
            break;
        case 'info':
            icon = 'info';
            color = '--one-ux-palette--brand-neutral-600';
            break;
        case 'warning':
            icon = 'warning';
            color = '--one-ux-palette--red-500';
            break;
    }
    return html `<one-ux-icon
    style=${styleMap({
        color: `var(${color}) !important`
    })}
    set=${set}
    icon=${icon}
    size=${size}
  ></one-ux-icon>`;
};
