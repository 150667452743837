import { CustomElementBase } from '../CustomElementBase'

export class InputSelectPreviewElement extends CustomElementBase {
  #value = []
  get value() {
    return this.#value
  }

  set value(value) {
    this.#value = value

    if (this.#automatic) {
      // eslint-disable-next-line no-unsanitized/property
      this.innerHTML = value.length ? value.map(($option) => $option.innerHTML).join(', ') : this.placeholder
    }
  }

  #placeholder = null
  get placeholder() {
    return this.#placeholder
  }

  set placeholder(placeholder) {
    this.#placeholder = placeholder

    if (this.#automatic && !this.value.length) {
      this.innerText = placeholder
    }
  }

  get #automatic() {
    return this.hasAttribute('state-automatic')
  }
}
