import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: middle;
  }

  .field-inner {
    flex: 1 1 auto;
    min-width: 0;
    display: flex;
    flex-direction: row;
    padding-left: var(--one-ux-spacing--normal);
    padding-right: var(--one-ux-spacing--normal);
    gap: var(--one-ux-spacing--small);
    outline: none;
  }

  .field-inner-content {
    flex: 1 1 auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .field-icon {
    color: var(--one-ux-palette--brand-neutral-600);
  }

  contextual-one-ux-tree {
    max-height: 35vh;
  }
`
