// Change cacheBust if the structure has to change and become incompatible
const cacheBust = '6535e20a-0708-4aab-ac1c-8b5e4eb6db11'
const cachePrefix = 'pdr-icon-cached-svg-sprite'
const cacheKey = `${cachePrefix}-${cacheBust}`
const cacheLimitInKb = 1024 * 256 // Slightly less than 125 icons

function clearOldCacheEntries() {
  Array.from({ length: localStorage.length }, (x, i) => localStorage.key(i))
    .filter((x) => x.startsWith(cachePrefix) && !x.endsWith(cacheBust))
    .forEach((x) => localStorage.removeItem(x))
}
clearOldCacheEntries()

export class SpriteSheetManager {
  #parser = new DOMParser()
  #serializer = new XMLSerializer()
  #sprite

  constructor() {
    this.#sprite = this.#loadSpriteSheetFromCache()
    window.addEventListener('unload', () => this.#saveSpriteSheet(this.#sprite))
  }

  getIcon(iconId) {
    // Needs to be querySelector, not getElementById (for Safari bug)
    const $icon = this.#sprite.querySelector('#' + iconId)
    return $icon ? $icon.cloneNode(true) : null
  }

  #loadSpriteSheetFromCache() {
    const cachedSvgText = localStorage.getItem(cacheKey)
    if (cachedSvgText) {
      const $svgDocument = this.#parser.parseFromString(cachedSvgText, 'image/svg+xml')
      const $svg = $svgDocument.documentElement
      if ($svg.tagName === 'svg') {
        Array.from($svg.children).forEach((child) => !child.id && child.remove())
        return $svg
      }
    }
    return document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  }

  #saveSpriteSheet($sprite) {
    const $spriteClone = $sprite.cloneNode(true)
    let cacheValue = this.#serializeSpriteSheet($spriteClone)
    // x2 due to unicode
    while (cacheValue.length * 2 > cacheLimitInKb) {
      $spriteClone.removeChild($spriteClone.firstChild)
      cacheValue = this.#serializeSpriteSheet($spriteClone)
    }
    localStorage.setItem(cacheKey, cacheValue)
  }

  #serializeSpriteSheet($sprite) {
    return this.#serializer.serializeToString($sprite)
  }

  updateSpriteSheet($icon, iconId) {
    // Apply the id to a clone so that multiple icon names can use the same svg with different ids
    const $iconClone = $icon.cloneNode(true)
    $iconClone.id = iconId

    const $cachedIcon = this.#sprite.getElementById(iconId)
    if ($cachedIcon) {
      $cachedIcon.replaceWith($iconClone)
    } else {
      this.#sprite.appendChild($iconClone)
    }
  }
}
