var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _JSXModule_instances, _JSXModule_isFragment, _JSXModule_handleAttributes, _JSXModule_appendChild;
import { PDRModule } from '../PDRModule';
export class JSXModule extends PDRModule {
    constructor() {
        super(...arguments);
        _JSXModule_instances.add(this);
    }
    createElement(tagName, attributes = null, ...children) {
        const isFragment = __classPrivateFieldGet(this, _JSXModule_instances, "m", _JSXModule_isFragment).call(this, tagName);
        const element = isFragment ? document.createDocumentFragment() : document.createElement(tagName);
        if (!isFragment) {
            __classPrivateFieldGet(this, _JSXModule_instances, "m", _JSXModule_handleAttributes).call(this, element, attributes);
        }
        children.forEach((child) => {
            __classPrivateFieldGet(this, _JSXModule_instances, "m", _JSXModule_appendChild).call(this, element, child);
        });
        return element;
    }
    get Fragment() {
        return 'fragment';
    }
    ref() {
        let refValue;
        return function (value) {
            if (value) {
                refValue = value;
            }
            else {
                return refValue;
            }
        };
    }
}
_JSXModule_instances = new WeakSet(), _JSXModule_isFragment = function _JSXModule_isFragment(tagName) {
    return tagName === this.Fragment;
}, _JSXModule_handleAttributes = function _JSXModule_handleAttributes(element, attributes) {
    if (!attributes) {
        return;
    }
    Object.keys(attributes).forEach((key) => {
        const attributeValue = attributes[key];
        if (key === 'className') {
            element.setAttribute('class', attributeValue);
        }
        else if (key.startsWith('on') && typeof attributes[key] === 'function') {
            element.addEventListener(key.substring(2), attributeValue);
        }
        else if (key === 'ref') {
            const ref = attributeValue;
            ref(element);
        }
        else if (typeof attributeValue === 'boolean' && attributeValue) {
            element.setAttribute(key, '');
        }
        else {
            element.setAttribute(key, attributeValue);
        }
    });
}, _JSXModule_appendChild = function _JSXModule_appendChild(parent, child) {
    if (!child && child !== '') {
        return;
    }
    if (child instanceof Node) {
        parent.appendChild(child);
    }
    else if (Array.isArray(child)) {
        for (const value of child) {
            __classPrivateFieldGet(this, _JSXModule_instances, "m", _JSXModule_appendChild).call(this, parent, value);
        }
    }
    else {
        parent.appendChild(document.createTextNode(child));
    }
};
