import { svg } from 'lit'
import { path } from '../../common/path'

export function Axis({
  axisWidth,
  numberOfTicks,
  showTicks,
  x,
  y
}: {
  axisWidth: number
  numberOfTicks: number
  showTicks: boolean
  x: number
  y: number
}) {
  const tickStepX = axisWidth / (numberOfTicks - 1)
  const labels = showTicks ? Array.from({ length: numberOfTicks }, (_, i) => (100 * i) / (numberOfTicks - 1)) : []

  return svg`
    <g transform=${`translate(${x}, ${y})`} fill="none">
      <path
        stroke="var(--one-ux-palette--brand-neutral-300)"
        stroke-linecap="square"
        d=${path()
          .move(0.5, 0.5)
          .verticalLine(2)
          .horizontalLine(axisWidth - 1)
          .verticalLine(-2)
          .toString()}
      ></path>

      <text
        x=${axisWidth + 2}
        y="3"
        fill="var(--one-ux-palette--brand-neutral-300)"
        class="one-ux-typography--label-variant-100"
      >%</text>

      ${labels.map(
        (tick, i) => svg`
          <text
            x=${Math.round(i * tickStepX) + (i === labels.length - 1 ? 9 : 0)}
            y=16
            text-anchor=${i === labels.length - 1 ? 'end' : i === 0 ? 'start' : 'middle'}
            fill="var(--one-ux-palette--brand-neutral-400)"
            class="one-ux-typography--label-400 axis--tick"
          >
            ${tick}
          </text>
        `
      )}
    </g>
  `
}
