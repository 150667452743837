import { KEY_ENTER } from '../../helpers/keyCodes'

export class AutosizeTextarea {
  #element
  #changeDirty = false

  constructor($element) {
    this.#element = $element

    this.#defineValueProperty()
    this.#attachEvents()
    this.#updateHeight()

    $element.dataset['autosize_textarea'] = true
    $element._updateHeight = this.#updateHeight

    document.fonts.load(getComputedStyle(this.#element).font).then(() => {
      this.#updateHeight(true)
    })
  }

  #defineValueProperty() {
    const $element = this.#element
    const nativeValue = Object.getOwnPropertyDescriptor(Object.getPrototypeOf($element), 'value')
    Object.defineProperty($element, 'value', {
      get: () => nativeValue.get.call($element),
      set: (value) => {
        if (typeof value !== 'undefined') {
          nativeValue.set.call($element, value)
          this.#updateHeight()
        }
      }
    })
  }

  #attachEvents() {
    this.#element.addEventListener('input', this.#handleInput)
    this.#element.addEventListener('change', this.#handleChange)
    this.#element.addEventListener('blur', this.#handleBlur)
    this.#element.addEventListener('keydown', this.#handleKeydown)
  }

  #handleInput = () => {
    // Firefox needs to wait in order for native scroll to cursor functionality to work when typing and max-height is set
    requestAnimationFrame(() => {
      this.#updateHeight()
    })
  }

  #handleChange = () => {
    this.#changeDirty = false
  }

  #handleBlur = () => {
    requestAnimationFrame(() => {
      if (this.#changeDirty) {
        this.#element.dispatchEvent(new CustomEvent('change'))
      }
    })
  }

  #handleKeydown = (event) => {
    if (event.which === KEY_ENTER) {
      event.preventDefault()

      const $element = this.#element
      const allowNewline = $element.dispatchEvent(new CustomEvent('newline', { cancelable: true }))
      if (allowNewline) {
        const text = $element.value
        const cursorStart = $element.selectionStart
        const cursorEnd = $element.selectionEnd
        const startText = text.slice(0, cursorStart)
        const endText = text.slice(cursorEnd, text.length)
        const prevScrollHeight = $element.scrollHeight
        $element.value = startText + '\n' + endText

        const newCursorStart = cursorStart + 1
        $element.setSelectionRange(newCursorStart, newCursorStart)
        $element.scrollTop += $element.scrollHeight - prevScrollHeight

        $element.dispatchEvent(new CustomEvent('input'))
        this.#changeDirty = true
      }
    }
  }

  #updateHeight = (force) => {
    const $element = this.#element
    const currentScrollTop = $element.scrollTop // Preserve scrollTop
    const style = $element.style
    style.width = $element.offsetWidth + 'px' // Lock due to width changes when altering overflow
    style.setProperty('overflow', 'hidden', 'important') // All calculations assume no scrollbars should be visible

    const currentOffsetHeight = $element.offsetHeight
    const currentClientHeight = $element.clientHeight
    const currentScrollHeight = $element.scrollHeight

    // Fix for Firefox to report correct scrollHeight
    const { paddingTop: currentPaddingTop, paddingBottom: currentPaddingBottom } = window.getComputedStyle($element)

    let newHeight
    if (currentScrollHeight > currentClientHeight) {
      newHeight = currentOffsetHeight + (currentScrollHeight - currentClientHeight)
    } else {
      // Lock height in order not cause layout flicker in the surrounding DOM (worst case causes loss of scroll position in other elements or ping pong effects)
      style.height = currentOffsetHeight + 'px'

      // Fix for Firefox to report correct scrollHeight
      style.paddingTop = currentPaddingTop
      style.paddingBottom = currentPaddingBottom

      const A = $element.scrollHeight

      style.paddingTop = currentClientHeight + 'px'
      const B = $element.scrollHeight

      style.paddingBottom = currentClientHeight + 'px'
      const C = $element.scrollHeight

      style.paddingTop = currentPaddingTop // Fix for Firefox to report correct scrollHeight, '' will not work here
      const D = $element.scrollHeight

      // We want to find how much the extra stretch filling is so that we can subtract it from the current height

      // A = top + text + stretch + bottom
      // B = client + text + bottom
      // C = client + text + client
      // D = top + text + client

      const text = C - 2 * currentClientHeight
      const top = D - text - currentClientHeight
      const bottom = B - text - currentClientHeight
      const stretch = A - top - text - bottom

      style.padding = ''
      newHeight = currentOffsetHeight - stretch
    }

    if (Math.abs(newHeight - currentOffsetHeight) > 1 || force) {
      // Always add one extra pixel to remove scrollbars due to browser rounding errors
      style.height = `${newHeight + 1}px`
    }

    style.setProperty('overflow', 'auto', 'important')
    style.width = ''
    $element.scrollTop = currentScrollTop
  }
}
