import { groupBy } from '../../common/groupBy'
import { typeSet } from '../render-groups/types'
import { ColumnOverlappedRenderGroup, columnOverlappedRenderGroupData } from './types'

export const getColumnOverlappedRenderGroups = (set: typeSet): ColumnOverlappedRenderGroup[] => {
  const keyGroups = groupBy(
    set,
    (x) => x.datapoint.key,
    (x) => typeof x.datapoint.value !== 'undefined'
  )

  const groups = Object.values(
    groupBy(
      set,
      (x) => `${x.serie.yTarget ?? 'left'}:${x.datapoint.key}`,
      (x) => typeof x.datapoint.value !== 'undefined'
    )
  )

  const collection: ColumnOverlappedRenderGroup[] = []
  for (const group of groups) {
    const data: columnOverlappedRenderGroupData[] = []

    let minValue = 0
    let maxValue = 0
    for (const entry of group) {
      if (typeof entry.datapoint.value === 'undefined') {
        continue
      }

      minValue = Math.min(minValue, entry.datapoint.value)
      maxValue = Math.max(maxValue, entry.datapoint.value)
      const keyIndex = keyGroups[entry.datapoint.key].indexOf(entry)
      data.push({
        value: entry.datapoint.value,
        color: entry.serie.color,
        name: entry.serie.name,
        showPointLabel: entry.serie.showPointLabels !== false,
        keyIndex
      })
    }

    const entry = group[0]
    collection.push({
      type: 'column-overlapped',
      yTarget: group[0].serie.yTarget,
      minValue,
      maxValue,
      key: group[0].datapoint.key,
      data,
      keyCount: keyGroups[entry.datapoint.key].length
    })
  }

  return collection
}
