var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _PdrIconElement_instances, _PdrIconElement_fetchIcon, _PdrIconElement_checkLegacyIconAttribute;
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { until } from 'lit/directives/until.js';
import { SpriteSheetManager } from './SpriteSheetManager.js';
import { SvgService } from './SvgService.js';
import { style } from './style';
import { observeInline } from '../../sem-2.0/helpers/is-observers.js';
import fa5ToFa6Map from './fa5Tofa6-corrected.json';
const sourceNames = [
    'type',
    'stratsys',
    'stratsys-byos',
    'stratsys-configuration',
    'stratsys-flags',
    'stratsys-misc',
    'stratsys-products',
    'stratsys-all',
    'fontawesome-regular',
    'fontawesome-light',
    'fontawesome-solid',
    'fontawesome-brands'
];
const spriteSheetManager = new SpriteSheetManager();
const svgService = new SvgService();
let PdrIconElement = class PdrIconElement extends LitElement {
    constructor() {
        super(...arguments);
        _PdrIconElement_instances.add(this);
        this.set = 'type';
        this._observer = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                __classPrivateFieldGet(this, _PdrIconElement_instances, "m", _PdrIconElement_checkLegacyIconAttribute).call(this, mutation.attributeName);
            }
        });
    }
    connectedCallback() {
        super.connectedCallback();
        for (const source of sourceNames) {
            __classPrivateFieldGet(this, _PdrIconElement_instances, "m", _PdrIconElement_checkLegacyIconAttribute).call(this, source);
        }
        this._observer.observe(this, { attributeFilter: sourceNames });
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        this._observer.disconnect();
    }
    firstUpdated() {
        observeInline(this);
        this.setAttribute('role', 'img');
    }
    willUpdate(changedProperties) {
        if (changedProperties.has('icon') && this.icon && this.set?.startsWith('fontawesome-')) {
            const fa6Name = fa5ToFa6Map[this.icon];
            if (fa6Name) {
                PDR.log.deprecation({
                    title: `"${this.icon}" has changed to "${fa6Name}" in FontAwesome6. Replacing automatically now. Update to remove this warning.`,
                    details: this
                });
                this.icon = fa6Name;
            }
        }
    }
    render() {
        const iconId = `pdr-${this.set}-${this.icon}`;
        return html `${until(__classPrivateFieldGet(this, _PdrIconElement_instances, "m", _PdrIconElement_fetchIcon).call(this, iconId), spriteSheetManager.getIcon(iconId))}`;
    }
};
_PdrIconElement_instances = new WeakSet();
_PdrIconElement_fetchIcon = async function _PdrIconElement_fetchIcon(iconId) {
    try {
        const $svg = await svgService.loadSvg(this.set, this.icon, iconId);
        spriteSheetManager.updateSpriteSheet($svg, iconId);
        return spriteSheetManager.getIcon(iconId);
    }
    catch (err) {
        PDR.log.warning({
            title: `Icon "${this.icon}" from source "${this.set}" not available. ${err.message}`,
            details: this
        });
        return null;
    }
};
_PdrIconElement_checkLegacyIconAttribute = function _PdrIconElement_checkLegacyIconAttribute(source) {
    const icon = this.getAttribute(source);
    if (icon != null) {
        this.set = source;
        this.icon = icon;
    }
};
PdrIconElement.styles = style;
__decorate([
    property()
], PdrIconElement.prototype, "icon", void 0);
__decorate([
    property()
], PdrIconElement.prototype, "set", void 0);
PdrIconElement = __decorate([
    customElement('pdr-icon')
], PdrIconElement);
export { PdrIconElement };
