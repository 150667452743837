import { groupBy } from '../../common/groupBy'
import { typeSet } from '../render-groups/types'
import { ColumnStackedRenderGroup, columnStackedRenderGroupData } from './types'

export const getColumnStackedRenderGroups = (set: typeSet): ColumnStackedRenderGroup[] => {
  const keyGroups = groupBy(
    set,
    (x) => x.datapoint.key,
    (x) => typeof x.datapoint.value !== 'undefined'
  )

  const groups = Object.values(
    groupBy(
      set,
      (x) => `${x.serie.yTarget}:${x.datapoint.key}`,
      (x) => typeof x.datapoint.value !== 'undefined'
    )
  )

  const collection: ColumnStackedRenderGroup[] = []
  for (const group of groups) {
    const data: columnStackedRenderGroupData[] = []

    let minValue = 0
    let maxValue = 0

    let previousValue = 0
    for (const entry of group.filter((x) => x.datapoint.value >= 0)) {
      const newValue = previousValue + (entry.datapoint.value ?? 0)
      minValue = Math.min(minValue, newValue)
      maxValue = Math.max(maxValue, newValue)

      const keyIndex = keyGroups[entry.datapoint.key].indexOf(entry)

      data.push({
        value: newValue,
        previousValue: previousValue,
        color: entry.serie.color,
        name: entry.serie.name,
        keyIndex
      })

      previousValue = newValue
    }

    previousValue = 0
    for (const entry of group.filter((x) => x.datapoint.value < 0)) {
      const newValue = previousValue + entry.datapoint.value
      minValue = Math.min(minValue, newValue)
      maxValue = Math.max(maxValue, newValue)
      const keyIndex = keyGroups[entry.datapoint.key].indexOf(entry)

      data.push({
        value: newValue,
        previousValue: previousValue,
        color: entry.serie.color,
        name: entry.serie.name,
        keyIndex
      })

      previousValue = newValue
    }

    collection.push({
      type: 'column-stacked',
      yTarget: group[0].serie.yTarget,
      key: group[0].datapoint.key,
      data,
      minValue,
      maxValue
    })
  }

  return collection
}
