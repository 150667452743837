import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement'
import { Constructor } from '../utils'
import { InternalValidityConfigChangedEvent } from '../../events/internal/InternalValidityConfigChangedEvent'

export declare class IRequired {
  required: boolean
}

export const Required = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class RequiredClass extends SuperClass {
    /**
     * If set, the component will be marked as required.
     */
    @property({ type: Boolean })
    public get required() {
      return this.#required
    }
    public set required(value: boolean) {
      const oldValue = this.#required
      this.#required = value
      this.dispatchEvent(new InternalValidityConfigChangedEvent(!this.#firstChange))
      this.requestUpdate('required', oldValue)
      if (this.#firstChange) {
        this.#firstChange = false
      }
    }
    #required: boolean
    #firstChange = true

    constructor(...args: any[]) {
      super(...args)
      this.#required = false
    }
  }

  return RequiredClass as Constructor<IRequired> & TSuperClass
}
