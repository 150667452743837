export default async function (el) {
  const isVisible = () => el.offsetParent !== null
  if (isVisible()) {
    return Promise.resolve()
  } else {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (isVisible()) {
          clearInterval(interval)
          resolve()
        }
      }, 10)
    })
  }
}
