import { html, TemplateResult } from 'lit'
import { pieces } from '../../common/format'
import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalData, InternalSegment } from '../types'
import { getLanguageSet } from '../getLanguageSet'

export const Table = (host: OneUxGaugeChartElement, data: InternalData): TemplateResult => {
  const languageSet = getLanguageSet(host._locale)

  return html`
    <table class="summary--table">
      <caption class="summary--label">
        ${host.label}: ${data.totalValue} ${pieces(host._locale)}
      </caption>
      <thead>
        <tr>
          <th scope="col" class="one-ux-accessibility--screen-reader">${languageSet.name}</th>
          <th scope="col" class="one-ux-accessibility--screen-reader">${pieces(host._locale)}</th>
          <th scope="col" class="one-ux-accessibility--screen-reader">${languageSet.percent}</th>
        </tr>
      </thead>
      ${data.segments.map((segment) => {
        return html` <tr>
          <td class="summary--table-name">${Icon(segment)} ${segment.name}</td>
          <td class="summary--table-pcs">${segment.value} ${pieces(host._locale)}</td>
          <td class="summary--table-percent">(${Math.round((segment.value / Math.max(1, data.totalValue)) * 100)}%)</td>
        </tr>`
      })}
    </table>
  `
}

const Icon = (segment: InternalSegment) => {
  const name = segment.icon ?? 'complete'

  return html`<one-ux-icon
    set="status"
    icon=${name}
    size="100"
    style="color: var(--one-ux-palette--${segment.color});"
  ></one-ux-icon>`
}
