import { html, nothing, TemplateResult } from 'lit'
import { classMap } from 'lit/directives/class-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { LanguageSet } from '../language'
import { setOverflow } from '../utils/setOverflow'
import { InternalTabBase, InternalTab, ScrollDirection } from '../types'
import { OneUxSpacingToken } from '@/one-ux/generated/design-tokens'
import { styleMap } from 'lit/directives/style-map.js'

export type TabsComponentOptions = {
  tabs: InternalTabBase[]
  indent: OneUxSpacingToken
  focusedTab: InternalTabBase
  label: string
  iconSize: string
  languageSet: LanguageSet
  onTabClicked: (tab: InternalTabBase, activate: boolean) => void
  onTabScroll: (e: Event, direction: ScrollDirection) => void
}

export function TabsComponent(options: TabsComponentOptions): TemplateResult {
  const indentation = !options.indent || options.indent === 'none' ? null : `var(--one-ux-spacing--${options.indent})`
  return html`<div
    class=${classMap({
      tabs: true,
      'is-subtabs': options.tabs[0].type === 'sub',
      'has-subtabs': options.tabs.some((tab) => hasSubtabs(tab))
    })}
  >
    <div class="tabs--navigation">
      <one-ux-widget-button
        icon="toggle-left"
        implicit
        class="tabs--navigation-button left"
        @click=${(e: Event) => options.onTabScroll(e, 'left')}
      ></one-ux-widget-button>
      <div
        class="tabs--list"
        role="tablist"
        style=${styleMap({
          marginLeft: indentation,
          marginRight: indentation
        })}
        aria-roledescription=${ifDefined(getRoleDescriptionForTablist(options.tabs, options.languageSet))}
        aria-label=${options.label}
        @scroll=${(e: Event) => setOverflow((e.target as Element).closest('.tabs') as HTMLElement)}
      >
        ${options.tabs.map((tab) => {
          const label = tab.pill?.label
            ? tab.pill?.value != null
              ? `${tab.label} (${tab.pill.label}: ${tab.pill.value})`
              : `${tab.label} (${tab.pill.label})`
            : tab.label

          return html`<div
            id=${ifDefined(tab === options.focusedTab ? 'active-tab-item' : undefined)}
            aria-selected=${tab.isActive}
            aria-disabled=${ifDefined(tab.disabled || undefined)}
            pdr-test-hook="one-ux-tabs-tab-${tab.id}"
            @click=${(e: Event) => {
              options.onTabClicked(tab, true)
              options.onTabScroll(e, 'current')
            }}
            class=${classMap({
              'tabs--button': true,
              'is-only-icon': tab.isIconOnly
            })}
            role="tab"
            aria-roledescription=${ifDefined(getRoleDescriptionForTab(tab, options.languageSet))}
            one-ux-tooltip=${label}
            aria-label=${label}
            aria-controls="tabs--tabpanel"
          >
            <span class="tabs--button-text">
              ${tab.icon
                ? html`<one-ux-icon
                    set=${ifDefined(tab.icon.set)}
                    icon=${tab.icon.name}
                    size=${options.iconSize}
                    aria-hidden="true"
                  ></one-ux-icon>`
                : nothing}
              ${tab.text}
              ${tab.pill
                ? html`<span
                    class=${classMap({
                      'tabs--button-pill': true,
                      'is-numbered': tab.pill.value != null,
                      'is-single-digit': tab.pill.value != null && tab.pill.value < 10
                    })}
                  >
                    ${tab.pill.value}
                  </span>`
                : nothing}
            </span>
          </div>`
        })}
      </div>
      <one-ux-widget-button
        icon="toggle-right"
        implicit
        class="tabs--navigation-button right"
        @click=${(e: Event) => options.onTabScroll(e, 'right')}
      ></one-ux-widget-button>
    </div>
  </div>`
}

const hasSubtabs = (tab: InternalTabBase) => tab.type === 'parent' && !!(tab as InternalTab).subtabs.length

const getRoleDescriptionForTablist = (tabs: InternalTabBase[], languageSet: LanguageSet) => {
  if (tabs.some(hasSubtabs)) {
    return languageSet.tabsLevelOne
  } else if (tabs[0].type === 'sub') {
    return languageSet.tabsLevelTwo
  }

  return undefined
}

const getRoleDescriptionForTab = (tab: InternalTabBase, languageSet: LanguageSet) => {
  if (hasSubtabs(tab)) {
    return languageSet.tabIncludingSubtabs
  } else if (tab.type === 'sub') {
    return languageSet.subtab
  }

  return undefined
}
