var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Log_instances, _Log_normalizeMessage, _Log_output, _Log_getFormattedPrefix, _Log_getLogoStyle, _Log_getPrefixStyle, _Log_getPrefixColors, _Log_getTitleStyle, _Log_notify;
const settings = {
    message: false,
    info: false,
    warning: false,
    error: false,
    debug: false,
    timer: false,
    tracker: false
};
updateSettings({}); // Set initial default values
export function updateSettings(newSettings) {
    settings.message = typeof newSettings.message !== 'undefined' ? !!newSettings.message : true;
    settings.info = typeof newSettings.info !== 'undefined' ? !!newSettings.info : true;
    settings.warning = typeof newSettings.warning !== 'undefined' ? !!newSettings.warning : true;
    settings.error = typeof newSettings.error !== 'undefined' ? !!newSettings.error : true;
    settings.debug = typeof newSettings.debug !== 'undefined' ? !!newSettings.debug : false;
    settings.timer = typeof newSettings.timer !== 'undefined' ? !!newSettings.timer : false;
    settings.tracker = typeof newSettings.tracker !== 'undefined' ? !!newSettings.tracker : false;
}
const LOCAL_STORAGE_KEY = '__pdr_log_enable_trace__';
const A_DAY = 24 * 60 * 60 * 1000;
let stackTracesEnabled = false;
const stackTraceTimeStamp = Number(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '0');
if (Date.now() - stackTraceTimeStamp < A_DAY) {
    stackTracesEnabled = true;
    setTimeout(() => {
        PDR?.log?.info('Stack traces are enabled.');
    }, 50);
}
else {
    localStorage.setItem(LOCAL_STORAGE_KEY, String(0));
}
export function enableStackTraces() {
    if (confirm(`Enabling stack traces for PDR.log incurs a performance penalty and should only be done for development and bug hunting.
      
They are automatically disabled after 24 hours and can be manually disabled prior to that by calling PDR.log.disableStackTraces().`)) {
        localStorage.setItem(LOCAL_STORAGE_KEY, String(Date.now()));
        stackTracesEnabled = true;
        PDR?.log?.info('Stack traces are enabled.');
    }
}
export function disableStackTraces() {
    localStorage.setItem(LOCAL_STORAGE_KEY, String(0));
    stackTracesEnabled = false;
    PDR?.log?.info('Stack traces are disabled.');
}
export class Log {
    constructor(prefix = 'PDR') {
        _Log_instances.add(this);
        this.prefix = prefix;
    }
    message(message) {
        if (!settings.message) {
            return;
        }
        message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message);
        __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'message', message);
    }
    info(message) {
        if (!settings.info) {
            return;
        }
        message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message);
        __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'info', message);
    }
    warning(message) {
        if (!settings.warning) {
            return;
        }
        message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message);
        __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'warning', message);
    }
    deprecation(message) {
        message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message);
        __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'deprecation', message);
    }
    error(message) {
        if (!settings.error) {
            return;
        }
        message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message);
        __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'error', message);
    }
    debug(message) {
        if (!settings.debug) {
            return;
        }
        message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message);
        __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'debug', message);
    }
    timer(message) {
        const startTime = performance.now();
        return {
            stop: () => {
                if (!settings.timer) {
                    return;
                }
                const time = performance.now() - startTime;
                message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message || '(Ellapsed time)');
                message.title = `[${Math.round(time)}ms] ${message.title}`;
                __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'timer', message, time);
            }
        };
    }
    tracker(trackName) {
        const startTime = performance.now();
        const trackerId = (startTime * 200 + Math.random() * 10000).toFixed(0);
        return {
            track: (message) => {
                message = __classPrivateFieldGet(this, _Log_instances, "m", _Log_normalizeMessage).call(this, message);
                message.id = trackerId;
                const elapsedTime = performance.now() - startTime;
                message.title = trackName ? `${trackName} - ${message.title}` : message.title;
                message.details = Object.assign(message.details || {}, {
                    __elapsedTime: elapsedTime
                });
                __classPrivateFieldGet(this, _Log_instances, "m", _Log_output).call(this, 'tracker', message);
            }
        };
    }
}
_Log_instances = new WeakSet(), _Log_normalizeMessage = function _Log_normalizeMessage(message) {
    if (typeof message !== 'object') {
        return {
            title: message + ''
        };
    }
    return message;
}, _Log_output = function _Log_output(type, message, time) {
    const logo = '  '; // Intentional blank spaces reserved for CSS styling
    const prefix = __classPrivateFieldGet(this, _Log_instances, "m", _Log_getFormattedPrefix).call(this, type);
    const title = message.title;
    console.groupCollapsed(`%c${logo}%c${prefix}%c${title}`, __classPrivateFieldGet(this, _Log_instances, "m", _Log_getLogoStyle).call(this), __classPrivateFieldGet(this, _Log_instances, "m", _Log_getPrefixStyle).call(this, type), __classPrivateFieldGet(this, _Log_instances, "m", _Log_getTitleStyle).call(this));
    if (message.message) {
        console.log(message.message);
    }
    if (message.details) {
        console.groupCollapsed('🗄️ Details');
        console.dir(message.details);
        console.groupEnd();
    }
    if (message.id) {
        console.groupCollapsed('🆔 Id');
        console.log(message.id);
        console.groupEnd();
    }
    console.groupCollapsed('🔍 Stacktrace');
    if (stackTracesEnabled) {
        console.trace('Log called from:');
    }
    else {
        console.log('Stack traces are disabled by default for performance reassons. To enable run PDR.log.enableStackTraces()');
    }
    console.groupEnd();
    console.groupEnd();
    __classPrivateFieldGet(this, _Log_instances, "m", _Log_notify).call(this, {
        type,
        message,
        time
    });
}, _Log_getFormattedPrefix = function _Log_getFormattedPrefix(type) {
    switch (type) {
        case 'info':
            return `💡 ${this.prefix}`;
        case 'warning':
            return `⚠️ ${this.prefix}`;
        case 'deprecation':
            return `💣 ${this.prefix}`;
        case 'error':
            return `🚩 ${this.prefix}`;
        case 'debug':
            return `🐞 ${this.prefix}`;
        case 'timer':
            return `⏱️ ${this.prefix}`;
        case 'tracker':
            return `👀 ${this.prefix}`;
        default:
            return this.prefix;
    }
}, _Log_getLogoStyle = function _Log_getLogoStyle() {
    return `
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="14 14 28 28" fill="rgb(1,156,162)"><g fill-rule="evenodd"><path d="M23.66 40.956l1.933-3.037a10.948 10.948 0 0 1-2.088-1.743 11.095 11.095 0 0 1-2.96-7.887c.104-4.014 2.253-7.439 5.419-9.278L24.03 16c-4.098 2.482-6.893 6.99-7.022 12.2a14.708 14.708 0 0 0 6.651 12.756z"></path><path d="M33.09 18.826h-.285c-5.366 0-9.809 4.093-10.266 9.366h3.546c.448-3.23 3.321-5.757 6.72-5.757h.19c.76.017 1.493.167 2.183.414l1.958-3.081a10.13 10.13 0 0 0-4.046-.942z"></path><path d="M38.896 31.15c.406-3.108-1.57-5.96-4.477-6.585a5.545 5.545 0 0 0-4.374.88l1.742 2.747c.5-.361 1.122-.555 1.77-.467.068.01.137.018.198.036 1.285.281 2.148 1.575 1.967 2.966a2.744 2.744 0 0 1-1.122 1.884l1.743 2.746a5.992 5.992 0 0 0 2.553-4.207z"></path></g></svg>') center no-repeat;
      background-size: 16px;
    `;
}, _Log_getPrefixStyle = function _Log_getPrefixStyle(type) {
    const colors = __classPrivateFieldGet(this, _Log_instances, "m", _Log_getPrefixColors).call(this, type);
    return `
      border-width: 0 2px;
      border-style: solid;
      border-color: ${colors[0]};
      background: ${colors[1]};
      padding: 2px 6px;
      margin: 0 5px;
      font-weight: 700;
    `;
}, _Log_getPrefixColors = function _Log_getPrefixColors(type) {
    switch (type) {
        case 'info':
            return ['#7dbbea', '#d6edfa'];
        case 'warning':
            return ['#ffdd00', '#fff9c3'];
        case 'deprecation':
            return ['#fba112', '#ffd57a'];
        case 'error':
            return ['#f5432f', '#fddedd'];
        default:
            return ['#bdc3c7', '#ecf0f1'];
    }
}, _Log_getTitleStyle = function _Log_getTitleStyle() {
    return `
      font-weight: 400;
    `;
}, _Log_notify = function _Log_notify(details) {
    document.dispatchEvent(new CustomEvent('pdr-log', {
        detail: {
            prefix: this.prefix,
            ...details
        }
    }));
};
