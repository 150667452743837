import { langCode } from '../../common/utils/getLangCode'

const fractionDigits = 1

export class ValueFormatter {
  #locale: langCode
  #useSISuffix: boolean

  constructor(locale: langCode, useSISuffix = false) {
    this.#locale = locale
    this.#useSISuffix = useSISuffix
  }

  public formatValue(value: string | number, useSISuffix?: boolean) {
    return formatValue(this.#locale, value, useSISuffix === false ? false : this.#useSISuffix)
  }
}

export const formatValue = (locale: langCode, value: string | number, useSISuffix = false) => {
  if (typeof value === 'undefined' || value === null) {
    return ''
  }

  if (!Number.isFinite(value)) {
    return String(value)
  }

  const numericValue = Number(value)
  if (useSISuffix && Math.abs(numericValue) >= 1000) {
    return toSISuffixFormat(locale, numericValue)
  }

  return toDefaultFormat(locale, numericValue)
}

export const pieces = (locale: langCode) => formatLocales[locale].pcs

const toSISuffixFormat = (locale: langCode, value: number) => {
  const siPower = Math.floor(Math.log10(value) / 3) * 3
  const siFactor = Math.pow(10, siPower)
  const siValue = value / siFactor
  const siDecimalValue = getDecimalValue(siValue)
  const decimalCount = siDecimalValue > 0 ? fractionDigits : 0
  const { decimalSeparator } = formatLocales[locale]

  return `${siValue.toFixed(decimalCount)}${siPowerTable[siPower]}`.replace('.', decimalSeparator)
}

const toDefaultFormat = (locale: langCode, value: number) => {
  const decimalValue = getDecimalValue(value)
  const { decimalSeparator, thousandsSeparator } = formatLocales[locale]
  const thousands = Math.floor(value)
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator)

  if (decimalValue > 0) {
    return `${thousands}${decimalSeparator}${decimalValue.toFixed(fractionDigits).split('.')[1]}`
  }

  return thousands
}

const getDecimalValue = (value: number) => Math.abs(value - Math.floor(value))

type FormatLocale = {
  decimalSeparator: string
  thousandsSeparator: string
  pcs: string
}

const siPowerTable: Record<number, string> = {
  [-24]: 'y',
  [-21]: 'z',
  [-18]: 'a',
  [-15]: 'f',
  [-12]: 'p',
  [-9]: 'n',
  [-6]: 'µ',
  [-3]: 'm',
  [0]: '',
  [3]: 'k',
  [6]: 'M',
  [9]: 'G',
  [12]: 'T',
  [15]: 'P',
  [18]: 'E',
  [21]: 'Z',
  [24]: 'Y'
}

const formatLocales: Record<langCode, FormatLocale> = {
  sv: {
    decimalSeparator: ',',
    thousandsSeparator: '\u00a0',
    pcs: 'st'
  },
  en: {
    decimalSeparator: '.',
    thousandsSeparator: ',',
    pcs: 'pcs'
  },
  fi: {
    decimalSeparator: ',',
    thousandsSeparator: '\u00a0',
    pcs: 'kpl'
  },
  nb: {
    decimalSeparator: ',',
    thousandsSeparator: '\u00a0',
    pcs: 'stk'
  },
  da: {
    decimalSeparator: ',',
    thousandsSeparator: '\u00a0',
    pcs: 'stk'
  }
}
