export type internalValidityChangeDetails = { valid: boolean; errors: string[]; flags: ValidityStateFlags }

export class InternalValidityChangeEvent extends CustomEvent<internalValidityChangeDetails> {
  static get eventName() {
    return 'internal-one-ux-validity-change'
  }

  constructor(detail: internalValidityChangeDetails) {
    super(InternalValidityChangeEvent.eventName, {
      bubbles: true,
      cancelable: false,
      composed: true,
      detail
    })
  }
}
