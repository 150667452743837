import { html, nothing } from 'lit'
import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalData } from '../types'
import { Table } from './Table'

export const Popout = (host: OneUxGaugeChartElement, data: InternalData, ariaHidden: boolean) => {
  if (!data) {
    return nothing
  }

  const size = host._boundary!.width
  return html`
    <one-ux-popout
      id="summary"
      aria-hidden=${ariaHidden}
      reference="previous"
      direction="horizontal"
      alignment="end"
      .offsetAlignment=${size / 1.25}
      .offsetReference=${-size / 8}
      class="summary"
    >
      ${Table(host, data)}
    </one-ux-popout>
  `
}
