export class Action {
    constructor() {
        this.completed = false;
    }
    applyCacheQuery(url) {
        if (window.__pdr_mfe_cache_query__) {
            return `${url}?${window.__pdr_mfe_cache_query__}`;
        }
        return url;
    }
}
