import { LanguageSets, getLangCode } from '../common/utils/getLangCode'

export type LanguageSet = {
  close: string
}

const languages: LanguageSets<LanguageSet> = {
  en: {
    close: 'Close'
  },
  sv: {
    close: 'Stäng'
  },
  nb: {
    close: 'Lukk'
  },
  fi: {
    close: 'Sulje'
  },
  da: {
    close: 'Luk'
  }
}

export const getLanguage = (element: Element): { translations: LanguageSet; lang?: string } => {
  const closestLang = getLangCode(element)
  const translations = languages[closestLang]

  return { translations, lang: closestLang }
}
