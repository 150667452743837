import { html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { Boundary } from '../common/mixins/Boundary'
import { Compact } from '../../common/mixins/Compact'
import { StyledFactory } from '../../common/mixins/Styled'
import { Weight } from '../../common/mixins/Weight'
import { OneUxElement } from '../../common/OneUxElement'
import { log } from '../../common/utils/log'
import { ShapeBar } from './components/shape/Bar'
import { ShapeCircle } from './components/shape/Circle'
import { percentage } from './utils'
import { style } from './style'
import { getLanguage, language } from './lang'

const Styled = StyledFactory(style)

const BaseClass = Boundary(Compact(Weight(Styled(OneUxElement))))

@customElement('one-ux-progress')
export class OneUxProgressElement extends BaseClass {
  /**
   * The shape of the progress.
   * This is a required property and will not render if it is omitted.
   */
  @property({ type: String })
  public shape!: 'bar' | 'circle'

  /**
   * The label associated with the progress.
   * This is a required property and will not render if it is omitted.
   */
  @property({ type: String })
  public label!: string

  /**
   * The current state value of the progress.
   * This is a required property and will not render if it is omitted.
   */
  @property({ type: Number })
  public value!: number

  /**
   * The completion value of the progress.
   * This is a required property and will not render if it is omitted.
   */
  @property({ type: Number })
  public total!: number

  /**
   * Display completion ratio instead of completion percentage.
   */
  @property({ type: Boolean })
  public ratio = false

  @state()
  private _hasDescription = false

  render() {
    if (!this.label) {
      log.error({ title: '<one-ux-progress> Missing label, not rendering.', details: this })
      return
    }

    if (!this.shape) {
      log.error({ title: '<one-ux-progress> Missing shape, not rendering.', details: this })
      return
    }

    if (!this.total) {
      log.error({ title: '<one-ux-progress> Missing total, not rendering.', details: this })
      return
    }

    if (!Number.isFinite(this.value) || this.value < 0) {
      log.error({ title: '<one-ux-progress> Missing value, not rendering.', details: this })
      return
    }

    const language = getLanguage(this)

    const slots = {
      hasDescription: this._hasDescription,
      descriptionChanged: this.#handleDescriptionSlotChange
    }

    const valueText = this.ratio
      ? this.weight === 'high'
        ? `${this.value} / ${this.total}`
        : `${this.value}/${this.total}`
      : `${percentage(this.value, this.total)}%`
    const altValueText = !this.ratio ? `${this.value}/${this.total}` : `${percentage(this.value, this.total)}%`

    return html`<div
      class="one-ux-element--root"
      role="group"
      aria-roledescription=${this.#getRoleDescription(language)}
      aria-label=${this.label}
    >
      <div
        class="one-ux-accessibility--screen-reader"
        role="progressbar"
        aria-valuenow=${this.value}
        aria-valuemin="0"
        aria-valuemax=${this.total}
        aria-valuetext=${valueText}
      ></div>

      ${this.shape === 'bar'
        ? ShapeBar({
            compact: this.compact,
            label: this.label,
            shadowRoot: this.shadowRoot!,
            total: this.total,
            value: this.value,
            weight: this.weight,
            slots,
            valueText,
            altValueText
          })
        : nothing}
      ${this.shape === 'circle'
        ? ShapeCircle({
            compact: this.compact,
            label: this.label,
            shadowRoot: this.shadowRoot!,
            total: this.total,
            value: this.value,
            weight: this.weight,
            valueText,
            slots
          })
        : nothing}
    </div>`
  }

  #handleDescriptionSlotChange = (event: Event) => {
    const validNodes = this.#getValidSlotNodes(event.target as HTMLSlotElement)
    this._hasDescription = !!validNodes.length
  }

  #getValidSlotNodes = ($slot: HTMLSlotElement) =>
    $slot.assignedNodes().filter((node) => {
      switch (node.nodeName) {
        case '#text':
          return !!node.textContent?.trim()
        case 'ONE-UX-ICON':
        case 'ONE-UX-LINK':
          return true
      }
      return false
    })

  #getRoleDescription = (language: language) => {
    switch (this.shape) {
      case 'bar':
        return language.translations.progressBar
      case 'circle':
        return language.translations.progressCircle
    }
    throw new Error('Missing role description')
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-progress': OneUxProgressElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-progress': OneUxProgressElement
    }
  }
}
