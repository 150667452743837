import { PDRModule } from '../PDRModule';
const libraries = {
    'oidc-client': {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        version: require('oidc-client/package.json').version,
        package: import('oidc-client')
    },
    lit: {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        version: require('../../../node_modules/lit/package.json').version,
        package: import('lit')
    }
};
export class LibrariesModule extends PDRModule {
    get(name, version = '') {
        const library = libraries[name];
        if (!library || (version && library.version !== version)) {
            return null;
        }
        return library.package;
    }
    version(name) {
        const library = libraries[name];
        if (!library) {
            return null;
        }
        return library.version;
    }
    list() {
        return Object.keys(libraries).reduce((result, library) => Object.assign(result, {
            [library]: libraries[library].version
        }), {});
    }
}
