import { OneUxElement } from '../common/OneUxElement'
import { html, nothing, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { style } from './style'
import { Weight } from '../common/mixins/Weight'
import { Purpose } from '../common/mixins/Purpose'
import { Implicit } from '../common/mixins/Implicit'
import { StyledFactory } from '../common/mixins/Styled'
import { DelegateAria } from '../common/mixins/DelegateAria'
import { Focusable } from '../common/mixins/Focusable'
import { Disabled } from '../common/mixins/Disabled'
import { OneUxIconToken } from '../generated/design-tokens'
import { Compact } from '../common/mixins/Compact'
import { log } from '../common/utils/log'
import { Label } from '../common/mixins/Label'
import { OneUxIconElement } from '../one-ux-icon/OneUxIconElement'
import { Deprecation } from '../common/utils/Deprecation'
import { HidableTooltip } from '../common/mixins/HidableTooltip'

const Styled = StyledFactory(style)

const BaseClass = HidableTooltip(
  Label(Compact(Disabled(Focusable(DelegateAria(Implicit(Purpose(Weight(Styled(OneUxElement)))))))))
)

// TODO: Make type "dynamic" based on current set
type icons = OneUxIconToken[keyof OneUxIconToken]

/**
 * A button component that can be used in many different contexts.
 * Can be used as a form associated control to submit and reset forms.
 */
@customElement('one-ux-button')
export class OneUxButtonElement extends BaseClass {
  static formAssociated = true

  #elementInternals!: ElementInternals
  #textDeprecation: Deprecation
  #iconsDeprecation: Deprecation
  #text!: string
  #icon!: icons
  #iconSet!: keyof OneUxIconToken
  #iconPosition = 'before-text' as 'before-text' | 'after-text' | 'before' | 'after'

  constructor() {
    super()
    this.#elementInternals = this.attachInternals()
    this.#textDeprecation = new Deprecation(
      this,
      'The property text is deprecated.',
      `The text property on buttons is deprecated in favour of a declarative syntax. See https://stratsys.github.io/PDR.Package/?path=/docs/components-one-ux-button--docs#deprecations for migration details.`
    )
    this.#iconsDeprecation = new Deprecation(
      this,
      'The properties icon, icons-set and icon-position are deprecated.',
      `The properties for icons on buttons are deprecated in favour of a declarative syntax. See https://stratsys.github.io/PDR.Package/?path=/docs/components-one-ux-button--docs#deprecations for migration details.`
    )
  }

  /**
   * Controls form interactions.
   */
  @property({ attribute: 'form-action', type: String })
  public formAction?: 'submit' | 'reset'

  protected willUpdate(changed: PropertyValues): void {
    if (changed.has('iconPosition')) {
      // TODO: Remove deprecation and before/after options
      const controlIconPosition = (alternative: 'before' | 'after') => {
        if (this.iconPosition === alternative) {
          log.deprecation(
            `The value "${alternative}" for the property "icon-position" has been deprecated in favor for "${alternative}-text"`
          )
          this.iconPosition = `${alternative}-text`
        }
      }

      controlIconPosition('before')
      controlIconPosition('after')
    }
  }

  // TODO: Remove when non-declarative way is removed. Slot should work as expected then.
  @state()
  private _hasSlotContent = false

  protected render() {
    if (!this.text && !this.label) {
      log.error({
        title: 'Missing text or label, not rendering.',
        details: {
          element: this
        }
      })
      return
    }

    const title = this.compact ? this.label || this.text : undefined
    const icon = this.icon
      ? html`<one-ux-icon set=${ifDefined(this.iconSet)} icon=${ifDefined(this.icon)} aria-hidden="true"></one-ux-icon>`
      : this.compact
      ? html`<one-ux-icon set="internal" icon="icon-not-found" aria-hidden="true"></one-ux-icon>`
      : nothing
    const text = html`<span class="button-text">${this.text}</span>`
    const content = this.compact ? icon : this.iconPosition === 'before-text' ? [icon, text] : [text, icon]

    const tooltip = this.hideTooltip ? undefined : title

    return html`<button
      id="button"
      @click=${this.#handleClick}
      ${this._ariaTarget()}
      class="one-ux-element--root"
      ?disabled=${this.disabled}
      one-ux-tooltip=${ifDefined(tooltip)}
      ?one-ux-tooltip-custom-aria=${!!tooltip}
      aria-label=${ifDefined(title)}
      type="button"
    >
      <slot @slotchange=${this.#handleSlotChange}></slot>
      ${this._hasSlotContent ? nothing : content}
    </button>`
  }

  updated(changed: PropertyValues) {
    if (changed.has('compact') && !this.text) {
      const $slot = this.shadowRoot?.querySelector('slot')
      if ($slot) {
        this.#validateSlot($slot)
      }
    }
  }

  #handleClick = (originalEvent: MouseEvent) => {
    originalEvent.stopPropagation()
    const event = new MouseEvent('click', originalEvent)
    if (this.dispatchEvent(event)) {
      switch (this.formAction) {
        case 'submit': {
          this.#elementInternals.form?.requestSubmit()
          break
        }
        case 'reset': {
          this.#elementInternals.form?.reset()
          break
        }
      }
    }
  }

  #handleSlotChange(event: Event) {
    const $slot = event.target as HTMLSlotElement
    this.#validateSlot($slot)
  }

  #validationTimeout!: ReturnType<typeof setTimeout>
  #validateSlot($slot: HTMLSlotElement) {
    this._hasSlotContent = $slot.assignedNodes().some((node) => node.nodeName !== '#text' || node.textContent?.trim())

    clearTimeout(this.#validationTimeout)
    this.#validationTimeout = setTimeout(() => {
      const assignedNodes = $slot.assignedNodes()

      const hasText = assignedNodes.some((node) => node.nodeName === '#text' && node.textContent?.trim())
      if (!hasText && !this.compact) {
        log.error({
          title: 'A button should have text content if not compact.',
          details: {
            element: this
          }
        })
      } else if (hasText && this.compact) {
        log.error({
          title: 'A compact button should not have text content.',
          details: {
            element: this
          }
        })
      }
      const assignedIcons = assignedNodes.filter((node) => node.nodeName === 'ONE-UX-ICON') as OneUxIconElement[]
      const hasMoreThanOneIcon = assignedIcons.length > 1
      if (hasMoreThanOneIcon) {
        log.warning({
          title: 'A button should only have one icon.',
          details: {
            element: this
          }
        })
      }
      const hasOtherNodes = assignedNodes.some((node) => node.nodeName !== '#text' && node.nodeName !== 'ONE-UX-ICON')
      if (hasOtherNodes) {
        log.warning({
          title: 'A button should only have text or icons.',
          details: {
            element: this
          }
        })
      }
    })
  }

  /**
   * **DEPRECATED**: See [deprecations](./?path=/docs/components-one-ux-button--docs#deprecations) for details.
   *
   * The text associated with the button.
   * This is a required property and the button will not render if it is omitted.
   */
  @property({ type: String })
  public get text() {
    return this.#text
  }
  public set text(value: string) {
    const oldValue = this.#text
    this.#text = value
    this.requestUpdate('text', oldValue)
    this.#textDeprecation.notify()
  }

  /**
   * **DEPRECATED**: See [deprecations](./?path=/docs/components-one-ux-button--docs#deprecations) for details.
   *
   * Allows you to set an icon for the button. By default only the default icon set of `<one-ux-icon>` is used.
   * You can change the icon set in use by providing the `icon-set` property.
   */
  @property({ type: String })
  public get icon() {
    return this.#icon
  }
  public set icon(value: icons) {
    const oldValue = this.#icon
    this.#icon = value
    this.requestUpdate('icon', oldValue)
    this.#iconsDeprecation.notify()
  }

  /**
   * **DEPRECATED**: See [deprecations](./?path=/docs/components-one-ux-button--docs#deprecations) for details.
   *
   * Controls what icon set the icon defined via the `icon` property should be read from.
   * Can be any of the sets that `<one-ux-icon>` can use. See the documentation of that component for all details
   */
  @property({ attribute: 'icon-set', type: String })
  public get iconSet() {
    return this.#iconSet
  }
  public set iconSet(value: keyof OneUxIconToken) {
    const oldValue = this.#iconSet
    this.#iconSet = value
    this.requestUpdate('iconSet', oldValue)
    this.#iconsDeprecation.notify()
  }

  /**
   * **DEPRECATED**: See [deprecations](./?path=/docs/components-one-ux-button--docs#deprecations) for details.
   *
   * Positions the icon either to before or after of the text. Optional and defaults to before.
   */
  @property({ attribute: 'icon-position', type: String })
  public get iconPosition() {
    return this.#iconPosition
  }
  public set iconPosition(value: 'before-text' | 'after-text' | 'before' | 'after') {
    const oldValue = this.#iconPosition
    this.#iconPosition = value
    this.requestUpdate('iconPosition', oldValue)
    this.#iconsDeprecation.notify()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-button': OneUxButtonElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-button': OneUxButtonElement
    }
  }
}
