let mouseMoveTimeout: ReturnType<typeof setTimeout>
let mouseMoving = false
document.addEventListener(
  'mousemove',
  () => {
    clearTimeout(mouseMoveTimeout)
    mouseMoveTimeout = setTimeout(() => {
      mouseMoving = false
    }, 100)
    mouseMoving = true
  },
  {
    capture: true
  }
)

export const hasMouseMovement = () => {
  return mouseMoving
}

let mouseActive = false
let pendingMouseInactive: ReturnType<typeof setTimeout>
const updateMouseActivity = () => {
  mouseActive = true
  clearTimeout(pendingMouseInactive)
  pendingMouseInactive = setTimeout(() => {
    mouseActive = false
  }, 500)
}
document.addEventListener('mousedown', updateMouseActivity, { capture: true })
document.addEventListener('mouseup', updateMouseActivity, { capture: true })

export const hasMouseActivity = () => {
  return mouseActive
}
