import { iconManager } from '@/one-ux/one-ux-icon/IconManager'
import { select, create } from 'd3-selection'
import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalData, InternalSegment } from '../types'
import { getArcsBottom } from '../utils'

export const renderInlineStats = (host: OneUxGaugeChartElement, data: InternalData) => {
  const { inlineStatsIconSize, inlineStatsTextGap } = data.snapSizeConfig
  const details = data.inlineStatsSpecs.details
  const segments = details.length ? data.segments : []
  const iconPadding = 4

  select(host.$svg)
    .selectAll<SVGGElement, InternalSegment>('.gauge--inline-stats-symbol')
    .data(segments, (d) => d.name)
    .join(
      (enter) => {
        const selection = enter
          .append('g')
          .attr('class', 'gauge--inline-stats-symbol')
          .attr('transform', (d) => getGroupTransform(host, data, d))

        selection
          .append((d) => Icon(d))
          .select('svg')
          .attr('fill', (d) => getIconColor(d))
          .attr('width', inlineStatsIconSize + iconPadding)
          .attr('height', inlineStatsIconSize + iconPadding)
          .attr('y', -iconPadding / 2)

        selection
          .append('text')
          .attr('x', inlineStatsIconSize + iconPadding / 2 + inlineStatsTextGap)
          .attr('y', inlineStatsIconSize - 1.5)
          .text((d) => d.value)

        return selection
      },

      (update) => {
        update
          .attr('transform', (d) => getGroupTransform(host, data, d))
          .select('svg')
          .attr('fill', (d) => getIconColor(d))

        update.select('text').text((d) => d.value)

        return update
      },
      (exit) => exit.remove()
    )
}

const Icon = (segment: InternalSegment) => {
  const $svgIcon = (iconManager.get(segment.icon || 'complete', 'status') || iconManager.get('complete', 'status'))!
  const groupSelection = create('svg:g').attr('class', 'icon')
  groupSelection.append<SVGElement>(() => $svgIcon)

  return groupSelection.node()
}

const getIconColor = (segment: InternalSegment) => `var(--one-ux-palette--${segment.color})`

const getGroupTransform = (host: OneUxGaugeChartElement, data: InternalData, segment: InternalSegment) => {
  const segmentIndex = data.segments.indexOf(segment)
  const { offsetX, offsetY } = data.inlineStatsSpecs.details[segmentIndex]

  return `translate(${offsetX}, ${getArcsBottom(host, data) + offsetY})`
}
