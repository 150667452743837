const animationTime = 400;
export async function show(toast) {
    const $toastElement = toast.$ref.value;
    if (!$toastElement) {
        return;
    }
    $toastElement.animate([
        // keyframes
        { transform: 'translateX(600px)', opacity: 0 },
        { transform: 'translateX(0px)', opacity: 1 }
    ], {
        // timing options
        duration: animationTime,
        easing: 'ease-in-out',
        fill: 'forwards'
    });
    return new Promise((resolve) => setTimeout(resolve, animationTime));
}
export async function hide(toast) {
    const $toastElement = toast.$ref.value;
    if (!$toastElement) {
        return;
    }
    if (toast.isCopied) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    $toastElement.animate([
        // keyframes
        { transform: 'translateX(0px)', opacity: 1 },
        { transform: 'translateX(600px)', opacity: 0 }
    ], {
        // timing options
        duration: animationTime,
        easing: 'ease-in-out',
        fill: 'forwards'
    });
    return new Promise((resolve) => setTimeout(resolve, animationTime));
}
