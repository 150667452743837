import { OneUxPaletteToken } from '@/one-ux/generated/design-tokens'
import { html } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { ID_ACTIVE_OPTION } from '../constants'
import { componentProps, listOption } from '../types'
import { OptionContent } from './OptionContent'

export type optionProps = componentProps & {
  option: listOption
  index: number
}

export const Option = ({ option, activeIndex, index, values, onChange, onActivate, multiple }: optionProps) => {
  const selected = values.includes(option.value)
  const active = index === activeIndex
  const disabled = !!option.disabled
  let iconColorOverride!: OneUxPaletteToken
  if (disabled) {
    iconColorOverride = active ? 'gray-500' : 'gray-400'
  }

  return html`<div
    id=${ifDefined(active ? ID_ACTIVE_OPTION : undefined)}
    class="option"
    role="option"
    ?disabled=${disabled}
    aria-disabled=${disabled}
    aria-label=${option.text}
    aria-selected=${ifDefined(!multiple ? selected : undefined)}
    aria-checked=${ifDefined(multiple ? selected : undefined)}
    ?state-multiple=${multiple}
    ?state-selected=${selected}
    ?state-active=${active}
    pdr-test-hook="one-ux-list-option-${String(option.value)}"
    one-ux-tooltip=${ifDefined(option.tooltip || undefined)}
    @click=${() => onChange(option)}
    @mouseover=${() => !disabled && onActivate(index)}
  >
    ${OptionContent(option, { colorOverride: iconColorOverride })}
  </div>`
}
