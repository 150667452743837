import { CustomElementBase } from '../CustomElementBase'
let instanceIdGenerator = 0
export class InputSelectOptionElement extends CustomElementBase {
  #instanceId = instanceIdGenerator++
  #selectedDirty = false

  onInitialized() {
    this.setAttribute('role', 'option')
    this.id = this.id || `input-select-option-${this.#instanceId}`
    if (!this.#selectedDirty) {
      this.selected = this.hasAttribute('selected')
    }

    this.addEventListener('click', this.#handleClick)
    this.addEventListener('mouseover', this.#handleMouseOver)
  }

  static get observedAttributes() {
    return ['disabled']
  }

  onAttributeRendered({ name }) {
    switch (name) {
      case 'disabled':
        this.#disabledChanged()
        break
    }
  }

  get value() {
    const value = this.getAttribute('value')
    return value != null ? value : this.text
  }

  set value(value) {
    this.setAttribute('value', value)
  }

  get text() {
    return this.textContent.trim().replace(/\s\s+/g, ' ')
  }

  get selected() {
    return this.getAttribute('aria-selected') === 'true'
  }

  set selected(selected) {
    this.setAttribute('aria-selected', !!selected)
    this.#selectedDirty = true
    this.dispatchEvent(new CustomEvent('#option-changed'))
  }

  get disabled() {
    return this.hasAttribute('disabled')
  }

  set disabled(disabled) {
    this.toggleAttribute('disabled', disabled)
  }

  get filtered() {
    return this.hasAttribute('state-filtered')
  }

  set filtered(filtered) {
    this.toggleAttribute('state-filtered', filtered)
    this.dispatchEvent(new CustomEvent('#option-filtered'))
  }

  get active() {
    return this.hasAttribute('state-active')
  }

  // TODO: Hantera disabled och filtered?
  set active(active) {
    if (this.active === !!active) {
      return
    }

    this.toggleAttribute('state-active', active)
    if (active) {
      this.dispatchEvent(new CustomEvent('#option-activated'))
    }
  }

  trigger(triggerReason) {
    if (this.disabled || this.filtered) {
      return
    }

    this.active = true
    this.selected = !this.selected
    this.dispatchEvent(new CustomEvent('#option-triggered', { detail: triggerReason }))
  }

  #handleClick = (event) => {
    if (this.disabled || this.filtered) {
      return
    }

    event.stopPropagation()
    this.trigger('mouse-click')
  }

  #handleMouseOver = () => {
    if (this.disabled || this.filtered) {
      return
    }

    this.active = true
  }

  #disabledChanged() {
    this.setAttribute('aria-disabled', this.disabled)
  }
}
