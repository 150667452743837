import { css } from 'lit'

export const style = css`
  .search {
    --one-ux-search-element--icon-color: var(--one-ux-palette--brand-neutral-400);
    --one-ux-search-element--text-color: var(--one-ux-palette--brand-neutral-400);
    --one-ux-search-element--placeholder-color: var(--one-ux-palette--brand-neutral-400);
    --one-ux-search-element--border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-search-element--background-color: var(--one-ux-palette--gray-100);

    border-color: var(--one-ux-search-element--border-color);
    background-color: var(--one-ux-search-element--background-color);

    border-style: solid;
    border-width: var(--one-ux-border-width--500);
    border-radius: var(--one-ux-radius--300);
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  :host(:not([compact])) .search {
    min-width: 74px;
  }

  :host([compact]:not([disabled], [state-expanded])) .search:hover {
    cursor: pointer;
  }

  input {
    all: unset;
    line-height: 18px;
    height: 20px;
    cursor: text;
    color: var(--one-ux-search-element--text-color);
    caret-color: var(--one-ux-palette--brand-neutral-400);
    box-sizing: border-box;
    width: 100%;
    vertical-align: top;
  }

  input:disabled {
    cursor: default;
  }

  input::placeholder {
    color: var(--one-ux-search-element--placeholder-color);
  }

  .clear-container,
  .search-container {
    min-width: 20px;
    min-height: 20px;
  }

  one-ux-widget-button {
    vertical-align: top;
  }

  one-ux-widget-button::part(icon) {
    color: var(--one-ux-search-element--icon-color);
  }

  :host([compact]) .search {
    --one-ux-search-element--icon-color: var(--one-ux-palette--brand-neutral-600);
  }

  :host(:hover) .search {
    --one-ux-search-element--border-color: var(--one-ux-palette--brand-neutral-400);
  }

  :host(:focus) .search {
    --one-ux-search-element--border-color: var(--one-ux-palette--focus-400);
  }

  :host(:is(:hover, :focus, :not([state-empty]))) .search {
    --one-ux-search-element--text-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-search-element--icon-color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([state-keyboard-focus]) .search {
    --one-ux-search-element--border-color: var(--one-ux-palette--keyboard-focus-400);
    --one-ux-search-element--background-color: var(--one-ux-palette--keyboard-focus-200);
  }

  :host([implicit]:not([state-keyboard-focus])) .search {
    --one-ux-search-element--background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.03);
  }

  :host([implicit]:not([state-keyboard-focus])[compact]) .search {
    --one-ux-search-element--background-color: transparent;
  }

  :host([implicit]:not(:focus)) .search {
    --one-ux-search-element--border-color: transparent;
  }

  :host([implicit]:not(:focus, [compact], [disabled])) {
    position: relative;
  }

  :host([implicit]:not(:focus, [compact], [disabled]))::after {
    content: '';
    position: absolute;
    left: var(--one-ux-spacing--300);
    right: var(--one-ux-spacing--300);
    height: var(--one-ux-border-width--500);
    bottom: 0;
    border-radius: var(--one-ux-radius--100);
    background-color: var(--one-ux-palette--brand-neutral-300);
  }

  :host([implicit]:hover:not(:focus, [compact], [disabled]))::after {
    background-color: var(--one-ux-palette--brand-neutral-400);
  }

  :host([implicit]:not([state-keyboard-focus], [disabled]):hover) .search {
    --one-ux-search-element--background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.05);
  }

  :host([disabled]) .search {
    --one-ux-search-element--border-color: var(--one-ux-palette--gray-300);
    --one-ux-search-element--background-color: var(--one-ux-palette--gray-150);
    --one-ux-search-element--text-color: var(--one-ux-palette--gray-400);
    --one-ux-search-element--icon-color: var(--one-ux-palette--gray-400);
    --one-ux-search-element--placeholder-color: var(--one-ux-palette--gray-400);
  }

  :host([implicit][disabled]) .search {
    --one-ux-search-element--border-color: transparent;
  }
`
