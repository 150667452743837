import { LanguageSets, getLangCode } from '../common/utils/getLangCode'

export type LanguageSet = {
  placeholder: string
  title: string
}

const languages: LanguageSets<LanguageSet> = {
  en: {
    placeholder: 'Search...',
    title: 'Search'
  },
  sv: {
    placeholder: 'Sök...',
    title: 'Sök'
  },
  nb: {
    placeholder: 'Søk...',
    title: 'Søk'
  },
  fi: {
    placeholder: 'Hae...',
    title: 'Hae'
  },
  da: {
    placeholder: 'Søg...',
    title: 'Søg'
  }
}

export const getLanguage = (element: Element): { translations: LanguageSet; lang?: string } => {
  const closestLang = getLangCode(element)
  const translations = languages[closestLang]

  return { translations, lang: closestLang }
}
