import { PropertyValues, html, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { FocusableFactory } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { Weight } from '../common/mixins/Weight'
import { OneUxElement } from '../common/OneUxElement'
import { classMap } from 'lit/directives/class-map.js'
import { Label } from '../common/mixins/Label'
import { StyledFactory } from '../common/mixins/Styled'
import { style } from './style'
import { Errors } from '../common/mixins/Errors'
import { Compact } from '../common/mixins/Compact'
import { Context, ContextProvider } from '@lit/context'
import { OneUxFieldContext, oneUxFieldContext } from './OneUxFieldContext'
import { Disabled } from '../common/mixins/Disabled'
import { Required } from '../common/mixins/Required'
import { Deprecation } from '../common/utils/Deprecation'
import { ErrorsPopout } from '../common/components/ErrorsPopout'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const BaseClass = Errors(Label(Compact(Required(Disabled(Implicit(Weight(Styled(Focusable(OneUxElement)))))))))
/**
 * A base component that is used create fields. Can be used in conjunction with different inputs to create bordered, labeled fields.
 */
@customElement('one-ux-field')
export class OneUxFieldElement extends BaseClass {
  /**
   * Enables the visuals of the empty state. Currently only affects the `implicit` state.
   *
   * This is only provided for custom implementations. When used together with other OneUx components it is handled automatically and should **not** be set.
   */
  @property({ type: Boolean })
  private empty = true

  /**
   * Defines if the field should have the visuals to manage multiple lines of text.
   *
   * This is only provided for custom implementations. When used together with other OneUx components it is handled automatically and should **not** be set.
   */
  @property({ attribute: 'multi-line', type: Boolean })
  private multiLine = false

  willUpdate(dirty: PropertyValues) {
    if (dirty.has('label')) {
      this.#context.setValue({
        ...this.#context.value,
        label: this.label
      })
    }
    if (dirty.has('required') || dirty.has('disabled')) {
      this.#context.setValue(this.#context.value, true)
    }
  }

  #context: ContextProvider<Context<string, OneUxFieldContext>>

  constructor() {
    super()
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.#context = new ContextProvider(this, {
      context: oneUxFieldContext,
      initialValue: {
        label: that.label,
        setEmpty(value: boolean) {
          that.empty = value
        },
        setRequired(value: boolean) {
          that.required = value
        },
        setDisabled(value: boolean) {
          that.disabled = value
        },
        setMultiLine(value: boolean) {
          that.multiLine = value
        }
      }
    })
  }

  protected guardedRender() {
    return html`
      <div class="one-ux-element--root">
        ${this.compact
          ? nothing
          : html`
              <span class="label">
                ${this.label} ${this.required ? html`<span class="asterisk">*</span>` : nothing}
              </span>
            `}

        <div
          class=${classMap({
            field: true,
            'js-field': true,
            empty: this.empty,
            error: this.errors?.length,
            disabled: this.disabled,
            'multi-line': this.multiLine
          })}
        >
          <div class="left-indicator"></div>
          <slot></slot>
          <div class="bottom-indicator"></div>
        </div>

        ${ErrorsPopout({
          reference: 'previous',
          errors: this.errors,
          hidden: this.hideErrors
        })}
      </div>
    `
  }

  #deprecation = new Deprecation(
    this,
    'The label-hidden property is deprecated.',
    `The label-hidden property is deprecated in favour of the compact property. See https://stratsys.github.io/PDR.Package/?path=/docs/components-one-ux-field--docs#deprecations for migration details.`
  )
  /**
   * **DEPRECATED** See [deprecations](./?path=/docs/components-one-ux-field--docs#deprecations) for details
   * Use `compact` instead.
   */
  @property({ attribute: 'label-hidden', type: Boolean })
  public get labelHidden() {
    return this.compact
  }
  public set labelHidden(value: boolean) {
    this.#deprecation?.notify()
    this.compact = value
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-field': OneUxFieldElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-field': OneUxFieldElement
    }
  }
}
