import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement'
import { Constructor } from '../utils'

export declare class ICompact {
  compact: boolean
}

export const Compact = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class CompactClass extends SuperClass {
    /**
     * Render the component in a compact mode.
     * This could make other properties on the component required.
     */
    @property({ type: Boolean, reflect: true })
    public compact = false
  }
  return CompactClass as Constructor<ICompact> & TSuperClass
}
