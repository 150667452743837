import { svg } from 'lit'
import { KeyScale } from '../axis/key/KeyScale'
import { ValueScale } from '../axis/value/ValueScale'
import { columnOverlapped } from '../column-overlapped/ColumnOverlapped'
import { ColumnOverlappedRenderGroup } from '../column-overlapped/types'
import { ColumnStacked } from '../column-stacked/ColumnStacked'
import { ColumnStackedRenderGroup } from '../column-stacked/types'
import { Column } from '../column/Column'
import { ColumnPointLabel } from '../column/ColumnPointLabel'
import { ColumnRenderGroup } from '../column/types'
import { LineRenderGroup } from '../line-area/line/types'
import { Line } from '../line-area/line/Line'
import { AreaRenderGroup } from '../line-area/area/types'
import { Area } from '../line-area/area/Area'
import { RenderGroup } from './types'
import { ValueFormatter } from '../../common/format'
import { LineAreaPointLabel } from '../line-area/LineAreaPointLabel'
import { PointLabelCollisionManager } from '../point-label/PointLabelCollisionManager'
import { ColumnOverlappedPointLabel } from '../column-overlapped/ColumnOverlappedPointLabel'

export const RenderGroups = (
  groups: RenderGroup[],
  keyScale: KeyScale,
  animate: boolean,
  leftValueScale?: ValueScale,
  rightValueScale?: ValueScale
) => {
  return groups.map((group) => {
    const valueScale = group.yTarget !== 'right' ? leftValueScale! : rightValueScale!

    switch (group.type) {
      case 'column':
        return Column(keyScale, valueScale, group as ColumnRenderGroup, animate)
      case 'column-stacked':
        return ColumnStacked(keyScale, valueScale, group as ColumnStackedRenderGroup, animate)
      case 'column-overlapped':
        return columnOverlapped(keyScale, valueScale, group as ColumnOverlappedRenderGroup, animate)
      case 'line':
        return Line(keyScale, valueScale, group as LineRenderGroup, animate)
      case 'area':
        return Area(keyScale, valueScale, group as AreaRenderGroup, animate)
      default:
        throw new Error('invalid type for serie, provide a valid type for serie')
    }
  })
}

export const RenderGroupsPointLabels = (
  groups: RenderGroup[],
  keyScale: KeyScale,
  valueFormatter: ValueFormatter,
  pointLabelCollisionManager: PointLabelCollisionManager,
  animate: boolean,
  leftValueScale?: ValueScale,
  rightValueScale?: ValueScale
) => {
  return svg`
    <g transform="translate(0, 0)" fill="none" class="series-point-labels">
      ${groups.map((group) => {
        const valueScale = group.yTarget !== 'right' ? leftValueScale! : rightValueScale!

        switch (group.type) {
          case 'column':
            return ColumnPointLabel(
              keyScale,
              valueScale,
              group as ColumnRenderGroup,
              valueFormatter,
              pointLabelCollisionManager,
              animate
            )
          case 'column-overlapped':
            return ColumnOverlappedPointLabel(
              keyScale,
              valueScale,
              group as ColumnOverlappedRenderGroup,
              valueFormatter,
              pointLabelCollisionManager,
              animate
            )
          case 'line':
            return LineAreaPointLabel(
              keyScale,
              valueScale,
              group as LineRenderGroup,
              valueFormatter,
              pointLabelCollisionManager,
              animate
            )
          case 'area':
            return LineAreaPointLabel(
              keyScale,
              valueScale,
              group as AreaRenderGroup,
              valueFormatter,
              pointLabelCollisionManager,
              animate
            )
        }
      })}
    </g>
  `
}
