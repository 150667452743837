import { OneUxElement } from '../common/OneUxElement'
import { html, nothing, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { style } from './style'
import { Implicit } from '../common/mixins/Implicit'
import { StyledFactory } from '../common/mixins/Styled'
import { Disabled } from '../common/mixins/Disabled'
import { Focusable } from '../common/mixins/Focusable'
import { Placeholder } from '../common/mixins/Placeholder'
import { getLanguage } from './language'
import { ifDefined } from 'lit/directives/if-defined.js'

const Styled = StyledFactory(style)

const BaseClass = Placeholder(Disabled(Focusable(Implicit(Styled(OneUxElement)))))

/**
 * An input that is intended as a search component on a page.
 * Does not perform any searching, you have to listen to either
 * the `change` or `input` events and perform any search manually.
 * Emits the event `change` with a debounce and the event `input` immediately on text entry.
 */
@customElement('one-ux-search')
export class OneUxSearchElement extends BaseClass {
  /**
   * Renders the search component in a minimal state.
   * Requires the user to interact with the user before the input field is visible.
   */
  @property({ type: Boolean, reflect: true })
  public compact = false

  /**
   * Allows you to set and read the value of the search component.
   */
  @property({ type: String })
  public value = ''

  @state()
  private _expanded = !this.compact

  protected willUpdate(changedProperties: PropertyValues): void {
    if (changedProperties.has('compact')) {
      this._expanded = this.compact ? !!this.value : true
    }

    if (changedProperties.has('value')) {
      this.toggleAttribute('state-empty', !this.value)
    }

    if (changedProperties.has('_expanded')) {
      this.toggleAttribute('state-expanded', this._expanded)
    }
  }

  protected render() {
    const { translations, lang } = getLanguage(this)
    return html`
      <one-ux-container
        class="one-ux-element--root search"
        layout="columns"
        gutters="small"
        indent="small"
        lang=${ifDefined(lang)}
        @click=${this.#handleExpand}
      >
        <one-ux-container class="search-container" width="min" align="center">
          <one-ux-widget-button
            implicit
            ?accessible=${this.compact && !this._expanded}
            ?disabled=${this.disabled}
            label=${translations.title}
            icon="search"
          ></one-ux-widget-button>
        </one-ux-container>
        ${!this._expanded && !this.value
          ? nothing
          : html`
            </one-ux-container>
              <one-ux-container align-vertical="center">
                <input
                  ?disabled=${this.disabled}
                  placeholder=${this.placeholder || translations.placeholder}
                  .value=${this.value}
                  @input=${this.#handleInput}
                  @change=${this.#handleChange}
                  @blur=${this.#handleBlur}
                />
              </one-ux-container>
              <one-ux-container class="clear-container" width="min" align-vertical="center">
                ${
                  !this.value
                    ? nothing
                    : html`<one-ux-widget-button
                        icon="remove"
                        ?disabled=${this.disabled}
                        @click=${this.#handleClear}
                      ></one-ux-widget-button>`
                }
              </one-ux-container>
            </one-ux-container>
      `}
      </one-ux-container>
    `
  }

  #handleInput = (event: InputEvent) => {
    event.preventDefault()
    event.stopPropagation()
    this.value = (event.target as HTMLInputElement).value
    this.#dispatch()
  }

  #handleChange = (event: InputEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  #handleExpand = async () => {
    if (this.disabled || this._expanded) {
      return
    }
    this._expanded = true
    await this.updateComplete
    this.focus()
  }

  #handleClear = () => {
    if (this.disabled) {
      return
    }
    this.value = ''
    this.#dispatch()
  }

  #debounce!: ReturnType<typeof setTimeout>
  #dispatch = () => {
    this.dispatchEvent(new Event('input'))
    if (this.#debounce) {
      clearTimeout(this.#debounce)
    }
    this.#debounce = setTimeout(() => {
      this.dispatchEvent(new Event('change'))
    }, 300)
  }

  #handleBlur = () => {
    if (this.compact && !this.value) {
      this._expanded = false
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-search': OneUxSearchElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-search': OneUxSearchElement
    }
  }
}
