import * as getters from '../getters'
import * as helpers from '../helpers'

export default (value) => ({
  transitionDuration: getters.getTransitionDuration(value),
  renderWhenInViewport: getters.getRenderWhenInViewport(value),
  title: getters.getTitle(value),
  series: helpers.flattenSeries(value, (serie, datapoint) => ({
    type: getters.getChartType(value, serie),
    series: getters.getSeriesNumber(value, serie),
    key: getters.getKey(datapoint),
    value: getters.getValue(datapoint),
    color: getters.getColor(serie, datapoint),
    title: getters.getTitleFromDataPoint(datapoint),
    progressTotal: getters.getProgressTotal(serie)
  }))
})
