import { placePopup } from '../../helpers/helpers'
import { AnimatedCustomElement } from '../AnimatedCustomElement'
import { ColorCalculator } from './ColorCalculator'
import { PickerInputHandler } from './PickerInputHandler'

export class InputColorElement extends AnimatedCustomElement {
  #value = '#000000'
  #dirtyValue = false
  #colorCalculator = new ColorCalculator()
  #hsv = { h: 0, s: 0, v: 0 }
  #pickerInputHandler = new PickerInputHandler()
  #popoutElement
  #closeTimer

  #dynamicShadeElement = document.createElement('dynamic-input-color-shade')
  #dynamicShadeHandleElement = document.createElement('dynamic-input-color-shade-handle')
  #dynamicHueElement = document.createElement('dynamic-input-color-hue')
  #dynamicHueHandleElement = document.createElement('dynamic-input-color-hue-handle')
  #dynamicInputContainerElement = document.createElement('dynamic-input-color-input-container')
  #dynamicTextInputElement = document.createElement('input')
  #dynamicPreviewElement = document.createElement('dynamic-input-color-preview')
  #dynamicHiddenInputElement = document.createElement('input')

  onInitialized() {
    this.tabIndex = 0
    this.expanded = false
    this.shouldAnimate = false
    this.#attachEvents()
    this.#initDynamicElements()
    this.#updateColorPresentation()

    if (!this.#dirtyValue) {
      this.value = this.getAttribute('value')
    }
  }

  onRendered() {
    this.#popoutElement =
      this.querySelector('input-color-options') || this.appendChild(document.createElement('input-color-options'))
    this.#renderDynamicElements()
  }

  #prevLeft
  #prevTop
  animate() {
    const { left, top } = placePopup(this.#popoutElement, this, { prevLeft: this.#prevLeft, prevTop: this.#prevTop })
    this.#prevLeft = left
    this.#prevTop = top
  }

  get value() {
    return this.#value.toLowerCase()
  }

  set value(value) {
    if (value != null && value.length === 7) {
      this.#value = value
      this.#dirtyValue = true
      this.#hsv = this.#colorCalculator.HEXtoHSV(this.#value)

      this.#updateColorPresentation()
    }
  }

  get optionsOnly() {
    return this.getAttribute('options-only') === 'true'
  }

  set optionsOnly(value) {
    this.setAttribute('options-only', !!value)
  }

  get expanded() {
    return this.getAttribute('aria-expanded') === 'true'
  }

  set expanded(value) {
    this.shouldAnimate = value
    this.setAttribute('aria-expanded', !!value)

    if (value) {
      if (this.optionsOnly) {
        this.#popoutElement.focus()
      } else {
        this.#dynamicTextInputElement.focus()
      }
    }
  }

  #initDynamicElements() {
    this.#dynamicShadeElement.appendChild(this.#dynamicShadeHandleElement)
    this.#dynamicHueElement.appendChild(this.#dynamicHueHandleElement)

    this.#dynamicTextInputElement.spellcheck = false
    this.#dynamicInputContainerElement.appendChild(this.#dynamicTextInputElement)

    this.#dynamicHiddenInputElement.type = 'hidden'
    this.#dynamicHiddenInputElement.name = this.getAttribute('name')
    this.#dynamicPreviewElement.appendChild(this.#dynamicHiddenInputElement)
  }

  #renderDynamicElements() {
    this.#popoutElement.insertBefore(this.#dynamicShadeElement, this.#popoutElement.children[0])
    this.#popoutElement.insertBefore(this.#dynamicHueElement, this.#popoutElement.children[1])
    this.#popoutElement.insertBefore(this.#dynamicInputContainerElement, this.#popoutElement.children[2])
    this.insertBefore(this.#dynamicPreviewElement, this.#popoutElement)
  }

  #attachEvents() {
    this.addEventListener('focus', this.#handleFocus, true)
    this.addEventListener('blur', this.#handleBlur, true)
    this.addEventListener('keydown', this.#handleKeydown, true)
    this.addEventListener('click', this.#handleClick, true)
    this.addEventListener('mousedown', this.#handleMouseDown, true)
    this.addEventListener('touchstart', this.#handleTouchStart)
  }

  #handleFocus = () => {
    clearTimeout(this.#closeTimer)
  }

  #handleBlur = (e) => {
    this.#closeTimer = setTimeout(() => {
      this.expanded = false
    })

    const $textInput = this.#dynamicTextInputElement
    if (e.target === $textInput) {
      $textInput.value = this.value.substring(1)
    }
  }

  #handleKeydown = (e) => {
    const $textInput = this.#dynamicTextInputElement
    if (e.target === $textInput) {
      requestAnimationFrame(() => {
        if ($textInput.value.length === 6) {
          if ($textInput.value !== this.value.substring(1)) {
            this.value = '#' + $textInput.value
            this.dispatchEvent(new CustomEvent('change'))
          }
        } else if ($textInput.value.length > 6) {
          $textInput.value = $textInput.value.substring(0, 6)
        }
      })
    }
  }

  #handleClick = (e) => {
    if (e.target.tagName === 'INPUT-COLOR-OPTION') {
      this.value = e.target.value
      this.dispatchEvent(new CustomEvent('change'))

      if (this.optionsOnly) {
        this.expanded = false
      }
    }
  }

  #handleMouseDown = (e) => {
    if (!this.#popoutElement.contains(e.target)) {
      if (!this.expanded) {
        e.preventDefault()
      }
      this.expanded = !this.expanded
    } else {
      e.preventDefault()
    }

    this.#pickerInputHandler.handleMouse(e, this.#dynamicShadeElement, this.#userShadeInput.bind(this))
    this.#pickerInputHandler.handleMouse(e, this.#dynamicHueElement, this.#userHueInput.bind(this))
  }

  #handleTouchStart = (e) => {
    this.#pickerInputHandler.handleTouch(e, this.#dynamicShadeElement, this.#userShadeInput.bind(this))
    this.#pickerInputHandler.handleTouch(e, this.#dynamicHueElement, this.#userHueInput.bind(this))
  }

  #userShadeInput(x, y) {
    this.#hsv.s = x
    this.#hsv.v = y
    this.value = this.#colorCalculator.HSVtoHEX(this.#hsv)
    this.dispatchEvent(new CustomEvent('change'))
  }

  #userHueInput(x) {
    this.#hsv.h = x
    this.value = this.#colorCalculator.HSVtoHEX(this.#hsv)
    this.dispatchEvent(new CustomEvent('change'))
  }

  #updateColorPresentation() {
    this.#dynamicHiddenInputElement.value = this.value

    this.#dynamicPreviewElement.style.background = this.value
    this.#dynamicHueHandleElement.style.left = `${this.#hsv.h * 100}%`
    this.#dynamicShadeHandleElement.style.left = `${this.#hsv.s * 100}%`
    this.#dynamicShadeHandleElement.style.top = `${(1.0 - this.#hsv.v) * 100}%`
    this.#dynamicShadeElement.style.background = `hsl(${this.#hsv.h * 360}, 100%, 50%)`

    this.#dynamicTextInputElement.value = this.value.substring(1)
  }
}
