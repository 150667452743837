import { html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { StyledFactory } from '../common/mixins/Styled'
import { OneUxElement } from '../common/OneUxElement'
import { style } from './style'

import './TooltipAttributeHandler'
import { ShowTooltipEvent } from './events/ShowTooltipEvent'
import { HideTooltipEvent } from './events/HideTooltipEvent'

const Styled = StyledFactory(style)

const BaseClass = Styled(OneUxElement)
/**
 * A tooltip component that requires manual management.
 * Not very useful stand alone, should be used in conjunction with the custom attribute `one-ux-tooltip`.
 */
@customElement('one-ux-tooltip')
export class OneUxTooltipElement extends BaseClass {
  static get events() {
    return {
      ShowTooltipEvent,
      HideTooltipEvent
    }
  }

  /**
   * Controls the visibility of the tooltip.
   */
  @property({ type: Boolean })
  public visible = false

  /**
   * Defines the reference element. Required in order to render a tooltip.
   */
  @property({ type: Object, attribute: false })
  public reference?: Element

  /**
   * Controls the preferred placement of the tooltip in relation to the reference element.
   */
  @property({ type: String })
  public placement?: 'above' | 'below' | 'before' | 'after'

  @state()
  private _hasContent = false

  get #valid() {
    return this.visible && this.reference
  }
  render() {
    if (!this.#valid) {
      return
    }
    const { direction, placement } = this.#tooltipPosition()
    return html`<one-ux-popout
      .reference=${this.reference}
      direction=${direction}
      placement=${placement}
      implicit
      indent="none"
      alignment="center"
      prevent-overlap
    >
      <div class="tooltip">
        <slot @slotchange=${this.#handleSlotChange}></slot>
      </div>
    </one-ux-popout>`
  }

  protected updated(): void {
    // Have to set attribute as FF does not reflect .role
    if (this.#valid && this._hasContent) {
      this.setAttribute('role', 'tooltip')
    } else {
      this.removeAttribute('role')
    }
  }

  #tooltipPosition() {
    switch (this.placement) {
      case 'above':
        return { direction: 'vertical', placement: 'before' } as const
      case 'below':
        return { direction: 'vertical', placement: 'after' } as const
      case 'before':
        return { direction: 'horizontal', placement: 'before' } as const
      case 'after':
      default:
        return { direction: 'horizontal', placement: 'after' } as const
    }
  }

  #handleSlotChange(event: Event) {
    const $slot = event.target as HTMLSlotElement
    const validNodes = $slot.assignedNodes().filter((node) => {
      switch (node.nodeName) {
        case '#text':
          return !!node.textContent?.trim()
        case 'ONE-UX-ICON':
          return true
        default:
          return false
      }
    })
    this._hasContent = !!validNodes.length
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tooltip': OneUxTooltipElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tooltip': OneUxTooltipElement
    }
  }
}
