const context = document.createElement('canvas').getContext('2d')

export const getTextSize = (text: string, font: string, letterSpacing: string) => {
  if (!context) {
    return { width: NaN, fontHeight: NaN, actualHeight: NaN }
  }

  context.font = font
  ;(context as any).letterSpacing = letterSpacing

  const metrics = context.measureText(text)
  const width = metrics.width
  const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent
  const actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent

  return { width, fontHeight, actualHeight }
}

export const getTextsSize = (texts: string[], font: string, letterSpacing: string) => {
  const sizes: { width: number; fontHeight: number; actualHeight: number }[] = []
  texts.forEach((text) => {
    const size = getTextSize(text, font, letterSpacing)
    sizes.push(size)
  })

  return sizes
}

export const getWrappedTextSize = (lines: string[], font: string, letterSpacing: string) => {
  let width = 0
  let height = 0

  lines.forEach((text) => {
    const size = getTextSize(text, font, letterSpacing)
    width = Math.max(width, size.width)
    height += size.actualHeight
  })

  return { width, height }
}
