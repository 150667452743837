import { html, nothing, TemplateResult } from 'lit'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { NodeData, NodeIcon, RootCallbacks } from '../types'

import { RootRefs } from '../ContextualOneUxTreeElement'

type TreeNodeOptions = {
  refs: RootRefs
  callbacks: RootCallbacks
  node: NodeData
  depth: number
  multiple: boolean
  treeDisabled: boolean
  activeNode: NodeData | null
  contentOverride: ((node: NodeData) => unknown) | null
}

export function TreeNode({
  refs,
  callbacks,
  node,
  depth,
  multiple,
  treeDisabled,
  activeNode,
  contentOverride
}: TreeNodeOptions): TemplateResult | string {
  const grouping = node.value == null || node.value === ''
  const expandable = node.children?.length
  const expanded = expandable && node.expanded

  const rowClasses = {
    'tree-node--row': true,
    'tree-node--grouping': grouping,
    'tree-node--selected': node.selected,
    'tree-node--has-child-selected': node.anyChildSelected,
    'tree-node--disabled': !grouping && (treeDisabled || node.disabled),
    'tree-node--expandable': expandable,
    'tree-node--expanded': expanded,
    'tree-node--active': node === activeNode
  }
  const styles = {
    '--contextual-one-ux-tree-element--depth': depth.toString()
  }

  const onToggleExpand = (event: Event) => {
    if (!treeDisabled) {
      event.stopPropagation()
      callbacks.onNodeToggleExpand(node)
    }
  }

  const onRowClick = () => {
    if (!treeDisabled) {
      if (grouping) {
        callbacks.onNodeToggleExpand(node)
      } else {
        callbacks.onNodeToggleSelect(node)
      }
    }
  }

  const onMouseMove = () => {
    if (!treeDisabled) {
      callbacks.onChangeActiveNode(node)
    }
  }

  const Icon = (icon: NodeIcon) => html`
    <one-ux-icon
      class="tree-node--icon"
      .set=${icon.set}
      .icon=${icon.name}
      .label=${icon.text}
      style=${styleMap({ color: icon.color ? `var(--one-ux-palette--${icon.color});` : null })}
    ></one-ux-icon>
  `

  const content = contentOverride
    ? contentOverride(node)
    : html`
        <div class="tree-node--default-content">
          ${node.icons?.filter((icon) => icon.position === 'before').map(Icon)}
          <div class="tree-node--text">${node.text}</div>
          ${node.icons?.filter((icon) => icon.position === 'after').map(Icon)}
        </div>
      `

  return html`
    <div
      class="tree-node--container"
      style=${styleMap(styles)}
      role="treeitem"
      id=${ifDefined(node === activeNode ? 'active-tree-node' : undefined)}
      aria-expanded=${ifDefined(expandable ? node.expanded : undefined)}
      aria-selected=${ifDefined(!grouping && !multiple ? node.selected : undefined)}
      aria-checked=${ifDefined(!grouping && multiple ? node.selected : undefined)}
      aria-disabled=${ifDefined(!grouping ? node.disabled : undefined)}
      pdr-test-hook=${`one-ux-tree-node-${node.path.join('_')}`}
    >
      <div class=${classMap(rowClasses)} @click=${onRowClick} @mousemove=${onMouseMove}>
        <div
          class="tree-node--expander"
          pdr-test-hook=${`one-ux-tree-node-expander-${node.path.join('_')}`}
          @click=${onToggleExpand}
        ></div>
        ${grouping
          ? nothing
          : html`
              <div
                class=${classMap({
                  'tree-node--checkbox': multiple,
                  'tree-node--radio': !multiple
                })}
              ></div>
            `}

        <div class="tree-node--content">${content}</div>
      </div>

      ${expanded
        ? html`
            <div class="tree-node--children" role="group">
              ${node?.children.map((child) =>
                TreeNode({
                  refs,
                  callbacks,
                  node: child,
                  depth: depth + 1,
                  multiple,
                  treeDisabled,
                  activeNode,
                  contentOverride
                })
              )}
            </div>
          `
        : null}
    </div>
  `
}
