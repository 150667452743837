//const noop = () => {}
export class CustomElementBase extends HTMLElement {
  #initialized = false
  #rendered = false
  #renderedRequest

  /* Template Methods */
  onInitialized() {}
  onConnected() {}
  onRendered() {}
  startEffects() {}
  onDisconnected() {}
  stopEffects() {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAttributeChanged({ name, oldValue, newValue }) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAttributeRendered({ name, oldValue, newValue }) {}

  connectedCallback() {
    if (!this.#initialized) {
      this.#initialized = true
      this.onInitialized()
    }
    this.onConnected()
    this.#renderedRequest = requestAnimationFrame(() => {
      this.#rendered = true
      this.onRendered()
      this.startEffects()

      // Callback for ALL observed attributes, including those not currently present on element to ensure proper initial state management
      // Example: Should set [tabindex] depending if [disable] is present or not initially
      const attributes = this.constructor.observedAttributes || []
      attributes.forEach((attribute) =>
        this.onAttributeRendered({
          name: attribute,
          newValue: this.getAttribute(attribute),
          oldValue: null
        })
      )
    })
  }

  disconnectedCallback() {
    this.#rendered = false
    this.onDisconnected()
    this.stopEffects()
    cancelAnimationFrame(this.#renderedRequest)
  }

  attributeChangedCallback(name, oldValue, newValue) {
    this.onAttributeChanged({ name, oldValue, newValue })

    if (this.#rendered) {
      this.onAttributeRendered({ name, oldValue, newValue })
    }
  }
}
