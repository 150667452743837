import { getTextSize } from '../../common/getTextSize'
import { getTypography } from '../../common/typography'
import { legendIconHeight, legendIconWidth, legendNameMarginLeft, typographyName } from './Legend'

const legendsPaddingTop = 2
const legendsHorizontalGap = 4
const legendsVerticalGap = 4

export const getLegendsOffset = (names: string[], fitWidth: number) => {
  const positions: { top: number; left: number; bottom: number }[] = []

  let top = legendsPaddingTop
  let left = 0

  for (let i = 0; i < names.length; i++) {
    const name = names[i]
    const { width, height } = getLegendSize(name)

    const right = left + width
    if (i === 0 || right < fitWidth) {
      positions.push({ top, left, bottom: top + height })
      left += width + legendsHorizontalGap
    } else {
      left = 0
      top += height + legendsVerticalGap
      positions.push({ top, left, bottom: top + height })
      left += width + legendsHorizontalGap
    }
  }

  return positions
}

export const getLegendSize = (name: string) => {
  const { font, letterSpacing } = getTypography(typographyName)
  const textWidth = getTextSize(name, font, letterSpacing).width

  return {
    width: legendIconWidth + legendNameMarginLeft + textWidth,
    height: legendIconHeight
  }
}
