import { nothing, svg } from 'lit'
import { ease, interpolate, svgTransition } from '../../common/directives/svgTransition'
import { RAD_TO_DEG } from '../../common/constants'
import { weight } from '@/one-ux/common/mixins/Weight'

const pointerOffsets: Record<weight, number> = {
  high: 26,
  normal: 15,
  low: 10
}

export function ArcPointer({
  value,
  size,
  x,
  y,
  startAngle,
  availableRadians,
  weight
}: {
  value: number
  size: number
  x: number
  y: number
  startAngle: number
  availableRadians: number
  weight: weight
}) {
  const radius = size / 2
  return svg`
    <g
      ${svgTransition({
        timing: {
          easing: ease.outCubic,
          duration: 500
        },
        attributes: {
          opacity: interpolate.standard(0, 1, 1),
          transform: interpolate.constant(
            value,
            (rotateValue) =>
              `translate(${x + radius}, ${y + radius}) rotate(${
                (availableRadians * rotateValue - startAngle) * RAD_TO_DEG
              })`
          )
        }
      })}
    >
      <g
        transform=${`translate(${radius - pointerOffsets[weight]}, 0) rotate(90)`}
        fill="var(--one-ux-palette--brand-neutral-600)"
      >
        ${
          weight === 'high'
            ? svg`
                <path 
                  transform="translate(-8, -8)"
                  d="M7.13398 1.5C7.51888 0.833333 8.48113 0.833333 8.86603 1.5L14.0622 10.5C14.4471 11.1667 13.966 12 13.1962 12L2.80385 12C2.03405 12 1.55292 11.1667 1.93782 10.5L7.13398 1.5Z"
                />
              `
            : nothing
        }
        ${
          weight === 'normal'
            ? svg`
              <path 
                transform="translate(-4, -4)"
                d="M3.56699 0.75C3.75944 0.416666 4.24056 0.416667 4.43301 0.75L7.03109 5.25C7.22354 5.58333 6.98298 6 6.59808 6L1.40192 6C1.01702 6 0.776461 5.58333 0.968911 5.25L3.56699 0.75Z"
              />
            `
            : nothing
        }
        ${
          weight === 'low'
            ? svg`
              <path 
                transform="translate(-3, -3)"
                d="M2.67524 0.5625C2.81958 0.3125 3.18042 0.3125 3.32476 0.5625L5.27332 3.9375C5.41765 4.1875 5.23723 4.5 4.94856 4.5L1.05144 4.5C0.762768 4.5 0.582346 4.1875 0.726683 3.9375L2.67524 0.5625Z"
              />
            `
            : nothing
        }
      </g>
    </g>
  `
}
