import { clamp } from '../../helpers/helpers'

export class PickerInputHandler {
  handleMouse(e, $pickerElement, updateCallback) {
    this.#handle(
      e,
      $pickerElement,
      (evt) => evt.clientX,
      (evt) => evt.clientY,
      'mousemove',
      'mouseup',
      updateCallback
    )
  }

  handleTouch(e, $pickerElement, updateCallback) {
    this.#handle(
      e,
      $pickerElement,
      (evt) => evt.targetTouches[0].clientX,
      (evt) => evt.targetTouches[0].clientY,
      'touchmove',
      'touchend',
      updateCallback
    )
  }

  #handle(e, pickerElement, xFunc, yFunc, moveEvent, upEvent, updateCallback) {
    if (!pickerElement.contains(e.target)) {
      return
    }

    e.preventDefault()
    const rect = pickerElement.getBoundingClientRect()

    const pickerUpdate = (e) => {
      updateCallback(
        clamp((xFunc(e) - rect.left) / rect.width, 0, 1),
        clamp(1 - (yFunc(e) - rect.top) / rect.height, 0, 1)
      )
    }
    const pickerStop = () => {
      document.removeEventListener(moveEvent, pickerUpdate, true)
      document.removeEventListener(upEvent, pickerStop, true)
    }

    document.addEventListener(moveEvent, pickerUpdate, true)
    document.addEventListener(upEvent, pickerStop, true)
    pickerUpdate(e)
  }
}
