var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _PromptModule_instances, _PromptModule_prompts, _PromptModule_getLang, _PromptModule_emptyMessageError, _PromptModule_normalizeOptions, _PromptModule_getContent, _PromptModule_requestModal;
import { PDRModule } from '../PDRModule';
import { getLanguage } from './lang';
import { render } from 'lit';
import { PromptModal } from './components/PromptModal';
import { CallbackQueue } from '../utils/CallbackQueue';
export class PromptModule extends PDRModule {
    constructor() {
        super(...arguments);
        _PromptModule_instances.add(this);
        _PromptModule_prompts.set(this, new CallbackQueue());
        _PromptModule_emptyMessageError.set(this, 'Attempted to show an alert without a message.');
    }
    async alert(messageOrOptions) {
        const { translations: lang, lang: langCode } = await __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_getLang).call(this);
        const options = __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_normalizeOptions).call(this, messageOrOptions);
        return __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_requestModal).call(this, {
            type: 'alert',
            lang: langCode,
            open: true,
            header: options.header || lang.alert.header,
            content: __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_getContent).call(this, options),
            ok: options.ok || lang.alert.ok,
            okIcon: options.okIcon || ''
        });
    }
    async confirm(messageOrOptions) {
        const { translations: lang, lang: langCode } = await __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_getLang).call(this);
        const options = __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_normalizeOptions).call(this, messageOrOptions, true);
        return __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_requestModal).call(this, {
            type: 'confirm',
            lang: langCode,
            open: true,
            header: options?.header || lang.confirm.header,
            content: __classPrivateFieldGet(this, _PromptModule_instances, "m", _PromptModule_getContent).call(this, options, lang.confirm.message),
            ok: options?.ok || lang.confirm.ok,
            okIcon: options?.okIcon || '',
            cancel: options?.cancel || lang.confirm.cancel,
            cancelIcon: options?.cancelIcon || '',
            danger: options?.danger || false
        });
    }
}
_PromptModule_prompts = new WeakMap(), _PromptModule_emptyMessageError = new WeakMap(), _PromptModule_instances = new WeakSet(), _PromptModule_getLang = async function _PromptModule_getLang() {
    const { utils } = await this._started;
    return getLanguage(await utils.lang());
}, _PromptModule_normalizeOptions = function _PromptModule_normalizeOptions(options, emptyOptionsAllowed = false) {
    if (!options) {
        if (emptyOptionsAllowed) {
            return {};
        }
        throw new Error(__classPrivateFieldGet(this, _PromptModule_emptyMessageError, "f"));
    }
    if (typeof options === 'string') {
        options = {
            message: options
        };
    }
    return options;
}, _PromptModule_getContent = function _PromptModule_getContent(options, fallback) {
    const fallbackOrFail = () => {
        if (fallback) {
            return fallback;
        }
        throw new Error(__classPrivateFieldGet(this, _PromptModule_emptyMessageError, "f"));
    };
    if (!options) {
        return fallbackOrFail();
    }
    if (typeof options === 'string') {
        return options;
    }
    if (!options.message) {
        return fallbackOrFail();
    }
    return options.message;
}, _PromptModule_requestModal = async function _PromptModule_requestModal(modalData) {
    function renderModal(modalData, resolve) {
        const rootId = '__PDR_prompt_root__';
        let $root = document.getElementById(rootId);
        if (!$root) {
            $root = document.createElement('div');
            $root.id = rootId;
            document.body.appendChild($root);
        }
        render(PromptModal({
            ...modalData,
            onClose: (result) => {
                renderModal({ ...modalData, open: false }, () => { });
                modalData.type === 'alert' ? resolve() : resolve(result);
            }
        }), $root);
    }
    return __classPrivateFieldGet(this, _PromptModule_prompts, "f").enqueue(() => {
        return new Promise((resolve) => {
            renderModal(modalData, resolve);
        });
    });
};
