import { vector } from '../../common/types'
import { lerpX, lerpY } from '../../common/vector'
import { bounds } from '../types'

export const clampedYInterpolation = (x0: number, y0: number, x1: number, y1: number, boundary: bounds): vector => {
  const hasPassedTop = y0 < boundary.top
  const hasPassedBottom = y0 > boundary.bottom

  if (hasPassedTop || hasPassedBottom) {
    const y = hasPassedTop ? boundary.top : boundary.bottom
    const x = lerpX(x0, y0, x1, y1, y)

    return [x, y]
  } else {
    return [x0, y0]
  }
}

export const boundaryInterpolation = (
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  boundary: bounds
): [number, number] => {
  const edge = getClosestEdge(x0, y0, boundary)
  const edgeValue = boundary[edge]
  if (edge === 'top' || edge === 'bottom') {
    return [lerpX(x0, y0, x1, y1, edgeValue), edgeValue]
  } else {
    return [edgeValue, lerpY(x0, y0, x1, y1, edgeValue)]
  }
}

const getClosestEdge = (x: number, y: number, boundary: bounds) => {
  const { top, bottom, left, right } = boundary
  let edge = 'top'
  let edgeDist = Math.abs(top - y)
  if (Math.abs(bottom - y) < edgeDist) {
    edge = 'bottom'
    edgeDist = Math.abs(bottom - y)
  }
  if (Math.abs(left - x) < edgeDist) {
    edge = 'left'
    edgeDist = Math.abs(left - x)
  }
  if (Math.abs(right - x) < edgeDist) {
    edge = 'right'
    edgeDist = Math.abs(right - x)
  }

  return edge as keyof bounds
}
