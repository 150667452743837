import { log } from './log';
import { RogueMicrofrontend } from './types/RogueMicrofrontend';
import { DefaultMicrofrontend } from './types/DefaultMicrofrontend';
import { Microfrontend } from './types/Microfrontend';
export class MicrofrontendsContainer {
    constructor(settings) {
        this.microfrontends = new Map();
        this.init(settings);
    }
    init(settings) {
        this.mode = settings.mode;
        if (settings.dependencies && settings.dependencies.length) {
            log.debug({
                title: 'Defining static dependencies',
                message: 'The following dependencies are statically defined.',
                details: settings.dependencies
            });
        }
        settings.dependencies.forEach((config) => {
            const microfrontend = new Microfrontend(config);
            if (this.microfrontends.has(microfrontend.tag)) {
                log.warning({
                    title: `Duplicate static dependency ${microfrontend.tag}.`,
                    message: `Multiple entries for a static dependency detected, review the configuration and keep a single entry for ${microfrontend.tag}. Only the first dependency defined will be used.`,
                    details: {
                        previous: this.microfrontends.get(microfrontend.tag).asObject(),
                        current: microfrontend.asObject()
                    }
                });
            }
            else {
                this.microfrontends.set(microfrontend.tag, microfrontend);
                microfrontend.prefetchOrAutoLoad();
            }
        });
    }
    register(microfrontend) {
        if (!this.microfrontends.has(microfrontend.tag)) {
            if (this.mode === 'static') {
                log.error({
                    title: `Unexpected registration of ${microfrontend.tag} in static mode.`,
                    message: `The microfrontend does not appear in the static dependencies defined via PDR.init(). The host expects all microfrontends to be defined before being registered. The current configuration might lead to unintended behavior. To make this error go away add the following configuration to the dependencies provided via PDR.init().\n${microfrontend.toString()}`,
                    details: {
                        registered: microfrontend.asObject()
                    }
                });
            }
            this.microfrontends.set(microfrontend.tag, microfrontend);
        }
        const resolvedMicrofrontend = this.microfrontends.get(microfrontend.tag);
        if (!resolvedMicrofrontend.compatible(microfrontend)) {
            log.warning({
                title: `Incompatible subsequent registration of ${microfrontend.tag}.`,
                message: `The microfrontend was previously configured with a version that is not semantically compatible with this attempt. The previous version will be used, review the following configuration and make sure they are functionally compatible.`,
                details: {
                    requested: microfrontend.asObject(),
                    previous: resolvedMicrofrontend.asObject()
                }
            });
        }
        resolvedMicrofrontend.patchConfig(microfrontend);
        resolvedMicrofrontend.prefetchOrAutoLoad();
    }
    async load(microfrontend) {
        if (!this.microfrontends.has(microfrontend.tag)) {
            if (this.mode === 'static') {
                log.error({
                    title: `Unexpected loading of ${microfrontend.tag} in static mode.`,
                    message: `The microfrontend does not appear in the dependencies defined via PDR.init(). The host expects all microfrontends to be defined before being loaded.The current configuration might lead to unintended behavior. To make this error go away add the following configuration to the dependencies provided via PDR.init().\n${microfrontend.toString()}`,
                    details: {
                        loaded: microfrontend.asObject()
                    }
                });
            }
            if (this.mode === 'restricted') {
                log.error({
                    title: `Unexpected loading of ${microfrontend.tag} in restricted mode.`,
                    message: `The microfrontend has not been registered via PDR.mfe.register() or as a dependency with PDR.mfe.install(). The host expects all microfrontends to be registered before being loaded. The current configuration might lead to unintended behavior. To make this error go away add the following configuration either to a PDR.mfe.register() call or as a dependency in a PDR.mfe.install() call.\n${microfrontend.toString()}`,
                    details: {
                        loaded: microfrontend.asObject()
                    }
                });
            }
            this.microfrontends.set(microfrontend.tag, microfrontend);
        }
        const resolvedMicrofrontend = this.microfrontends.get(microfrontend.tag);
        if (!resolvedMicrofrontend.compatible(microfrontend)) {
            const tag = microfrontend.tag;
            log.warning({
                title: `A potentially incompatible version of ${tag} will be loaded.`,
                message: `The microfrontend was previously configured with a version that is not semantically compatible with the version provided to PDR.mfe.load(). The previous version will be used, review the following configuration and make sure they are functionally compatible.`,
                details: {
                    previous: resolvedMicrofrontend.asObject(),
                    loaded: microfrontend.asObject()
                }
            });
        }
        return resolvedMicrofrontend.load();
    }
    install(implementation) {
        let resolvedMicrofrontend = this.microfrontends.get(implementation.tag);
        if (!resolvedMicrofrontend) {
            log.warning({
                title: `${implementation.tag} is a rogue microfrontend.`,
                message: 'A microfrontend is considered rogue when PDR.mfe.install() is called for a microfrontend without any prior knowledge of said microfrontend. This occurs when a script that calls PDR.mfe.install() is loaded manually. The following implementation configuration was provided.',
                details: implementation.config
            });
            resolvedMicrofrontend = new RogueMicrofrontend(implementation.tag, implementation.version.toString());
            this.microfrontends.set(implementation.tag, resolvedMicrofrontend);
        }
        else if (resolvedMicrofrontend.installed) {
            log.warning({
                title: `${implementation.tag} is already installed.`,
                message: `Attempt to install ${implementation.version} of ${implementation.tag} will be ignored as it is already installed with version ${resolvedMicrofrontend.implementation.version}. The nature of custom elements only allows for a single definition of each to exist at a time. Inspect the following configurations to see if they are compatible.`,
                details: {
                    requested: implementation,
                    installed: resolvedMicrofrontend.implementation
                }
            });
        }
        else {
            if (!resolvedMicrofrontend.loading) {
                log.warning({
                    title: `${resolvedMicrofrontend.tag} was not loaded with PDR.mfe.load()`,
                    message: 'This can occur if the microfrontend is registered and then manually loaded. This is probably a mistake and should be fixed. Manually loading a microfrontend sidesteps parts of the lifecycle is considered bad practice and should be avoided.'
                });
            }
            if (resolvedMicrofrontend.loading &&
                !resolvedMicrofrontend.url &&
                resolvedMicrofrontend.version.toString() !== 'latest' &&
                !implementation.version.fulfills(resolvedMicrofrontend.version)) {
                log.error({
                    title: `Implementation ${resolvedMicrofrontend.tag} possibly wrong.`,
                    message: `About to install an implementation of ${resolvedMicrofrontend.tag} with version ${implementation.version.toString()} but version ${resolvedMicrofrontend.version.toString()} was requested by the load call. This could be a mistake during your microfrontend's build or publication steps.`
                });
            }
        }
        return resolvedMicrofrontend.install(implementation);
    }
    contains(tag) {
        return this.microfrontends.has(tag);
    }
    resolve(tag) {
        if (!this.microfrontends.has(tag)) {
            const microfrontend = new DefaultMicrofrontend(tag);
            log.warning({
                title: `Using default configuration for ${tag}.`,
                message: `The microfrontend ${tag} could not be resolved to a known configuration and the following default configuration will be used.`,
                details: microfrontend.asObject()
            });
            return microfrontend;
        }
        return this.microfrontends.get(tag);
    }
    list() {
        return Array.from(this.microfrontends.values()).map((microfrontend) => {
            const managed = !(microfrontend instanceof RogueMicrofrontend);
            return {
                tag: microfrontend.tag,
                version: microfrontend.version ? microfrontend.version.toString() : 'unknown',
                installedVersion: microfrontend.installed ? microfrontend.implementation.version.toString() : undefined,
                status: {
                    managed,
                    loaded: microfrontend.loaded,
                    prefetched: microfrontend.prefetched,
                    installed: microfrontend.installed
                },
                resolvedUrl: microfrontend.getUrl(),
                config: microfrontend.asObject()
            };
        });
    }
}
