var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Toast_resolveOnClose;
import { createRef } from 'lit/directives/ref.js';
import * as ToastListRenderer from './toastListRenderer';
class Toast {
    constructor(type, title, description, duration, copyable) {
        this.isCopied = false;
        this.$ref = createRef();
        _Toast_resolveOnClose.set(this, () => { });
        this.type = type;
        this.title = title;
        this.description = description;
        this.duration = duration === false ? false : Number.isInteger(duration) ? duration : 6000;
        this.copyable = copyable;
    }
    async show() {
        return new Promise((resolve) => {
            __classPrivateFieldSet(this, _Toast_resolveOnClose, resolve, "f");
            ToastListRenderer.add(this);
        });
    }
    async close() {
        await ToastListRenderer.remove(this);
        __classPrivateFieldGet(this, _Toast_resolveOnClose, "f").call(this, undefined);
    }
    async copy() {
        const shouldCopy = this.copyable && !this.isCopied && navigator.clipboard;
        if (shouldCopy) {
            navigator.clipboard.writeText(this.description);
            this.isCopied = true;
            ToastListRenderer.update();
        }
        this.close();
    }
}
_Toast_resolveOnClose = new WeakMap();
export default Toast;
