import { PropertyValues, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { OneUxElement } from '../common/OneUxElement'
import { FocusableFactory } from '../common/mixins/Focusable'
import { Explicit } from '../common/mixins/Explicit'
import { UpdateOnResizedController } from './controllers/UpdateOnResizedController'
import { Weight } from '../common/mixins/Weight'
import { StyledFactory } from '../common/mixins/Styled'
import { keyCodes } from '../common/utils'
import { InputTab, InternalSubtab, InternalTab, InternalTabBase, ScrollDirection } from './types'
import { mapState } from './utils/mapState'
import { setOverflow } from './utils/setOverflow'
import { scrollToLeft, scrollToCurrent, scrollToRight } from './utils/scrollTo'
import { style } from './style'
import { TabsComponent } from './components/TabsComponent'
import { getLanguage } from './language'
import { OneUxSpacingToken } from '../generated/design-tokens'
import { styleMap } from 'lit/directives/style-map.js'
import { log } from '../common/utils/log'
import {
  getActiveTab,
  getImplicitActiveTab,
  getFirstTab,
  getNextTab,
  getPreviousTab,
  getTabSiblings,
  getLastTab
} from './utils/tabGetters'
import { setActiveTabState } from './utils/setActiveTabState'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const BaseClass = Weight(Explicit(Focusable(Styled(OneUxElement))))

/**
 * Tabbable section with support for another layer of subtabs for each tab.
 * A tab can have slotted content with either a custom slot name [slot="customName"] or set to the expected generated slot name based on tab nr and subtab nr (tab-1, tab-1-3 etc.).
 */
@customElement('one-ux-tabs')
export class OneUxTabsElement extends BaseClass {
  /**
   * Accessability label for the tablist.
   */
  @property({ type: String })
  public label = ''

  /**
   * Sets the placement of tabs.
   */
  @property({ type: String, reflect: true })
  public placement: 'start' | 'center' | 'end' | 'flex' = 'start'

  /**
   * InputTab:
   * The tab to be clicked. Optinal custom event "tab" can be listened on for detecting a clicked tab.
   * * slot: Must be set to the same name as the custom [slot="customName"] name for slotted tab content. Will default to generated slot name based on tab nr and subtab nr (tab-1, tab-1-3 etc.).
   * * icon: Optional icon for the or only icon.
   * * disabled: Non interactable greyed out option.
   * * tabs: Subtabs for current tab.
   */
  @property({ type: Array })
  public tabs!: InputTab[]

  /**
   * **DEPRECATED** Use `indent-content="none"` instead.
   */
  @property({ attribute: 'disable-indent', type: Boolean })
  public get disableIndent() {
    return this.indentContent === 'none'
  }
  public set disableIndent(value: boolean) {
    log.deprecation(
      'disable-indent property is deprecated, use indent-content="none" or indent-content="normal" instead'
    )
    if (value) {
      this.indentContent = 'none'
    } else {
      this.indentContent = 'normal'
    }
  }

  /**
   * Allows control over the indentation of the content.
   */
  @property({ attribute: 'indent-content', type: String })
  public indentContent: OneUxSpacingToken = 'normal'

  /**
   * Allows control over the indentation of the tabs.
   */
  @property({ attribute: 'indent-tabs', type: String })
  public indentTabs: OneUxSpacingToken = 'none'

  /**
   * Disable auto activate tab on focus.
   */
  @property({ type: Boolean, reflect: true })
  public manual = false

  /**
   * Sets active tab
   */
  @property({ type: String, attribute: 'active-tab' })
  public activeTab = ''

  @state()
  private _tabs!: InternalTab[]

  @state()
  private _focusedTab!: InternalTabBase

  @state()
  private _hasSlotContent = false

  #isFocusedByUser = false

  constructor() {
    super()

    this.width = 'max'

    new UpdateOnResizedController(this)
  }

  protected willUpdate(changedProperties: PropertyValues): void {
    if (changedProperties.has('tabs')) {
      this._tabs = mapState(this.tabs, this.activeTab)
      const activeTab = getActiveTab(this._tabs, this.activeTab)
      this._focusedTab = activeTab
    } else if (changedProperties.has('activeTab') && this.activeTab) {
      const activeTab = getActiveTab(this._tabs, this.activeTab)
      setActiveTabState(this._tabs, activeTab)
      this._focusedTab = activeTab
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    for (const $tabs of this.shadowRoot!.querySelectorAll('.tabs')) {
      setOverflow($tabs as HTMLElement)
    }

    if (changedProperties.has('_focusedTab') && this.#isFocusedByUser) {
      const $focusedTab = this.shadowRoot!.querySelector('#active-tab-item') as HTMLElement
      if ($focusedTab) {
        scrollToCurrent($focusedTab)
      }
    }
    this.#isFocusedByUser = false
  }

  protected render() {
    if (!this.#validate()) {
      return
    }

    const { languageKey, languageSet } = getLanguage(this)
    const activeTab = getImplicitActiveTab(this._tabs, this.activeTab)
    const subtabs =
      activeTab.type === 'parent' ? (activeTab as InternalTab).subtabs : getTabSiblings(this._tabs, activeTab)

    const tabPanelStyle = styleMap({
      marginTop: !this.indentContent || this.indentContent === 'none' ? null : `var(--one-ux-spacing--normal)`,
      padding:
        !this.indentContent || this.indentContent === 'none' ? null : `var(--one-ux-spacing--${this.indentContent})`
    })
    return html`<div
      class="one-ux-element--root"
      tabindex="0"
      aria-activedescendant="active-tab-item"
      lang=${languageKey}
      @keydown=${(e: KeyboardEvent) => this.#handleKeydown(e)}
      @focus=${() => this.#handlefocus()}
    >
      ${TabsComponent({
        tabs: this._tabs,
        indent: this.indentTabs,
        focusedTab: this._focusedTab,
        label: this.label,
        iconSize: this.#getIconSize(true),
        languageSet,
        onTabClicked: (tab, activate) => this.#handleTabClicked(tab, activate),
        onTabScroll: (e, direction) => this.#handleTabScroll(e, direction)
      })}
      <div class=${classMap({ 'tabs--content': true, 'has-content': this._hasSlotContent })}>
        ${subtabs.length
          ? TabsComponent({
              tabs: subtabs,
              indent: this.indentTabs,
              focusedTab: this._focusedTab,
              label: this.label,
              iconSize: this.#getIconSize(false),
              languageSet,
              onTabClicked: (tab, activate) => this.#handleTabClicked(tab, activate),
              onTabScroll: (e, direction) => this.#handleTabScroll(e, direction)
            })
          : nothing}
        <div id="tabs--tabpanel" tabindex="0" role="tabpanel" aria-label=${activeTab.label} style=${tabPanelStyle}>
          <slot @slotchange=${this.#onSlotchange} name=${activeTab.id}></slot>
        </div>
      </div>
    </div>`
  }

  #validate() {
    if (!this.label?.length) {
      log.error('Missing label, not rendering.')
      return false
    }
    if (!this._tabs?.length) {
      log.error('Missing tabs, not rendering.')
      return false
    }

    const isEveryTabDisabled = (tabs: InternalTabBase[]) => tabs.length && tabs?.every((x) => x.disabled)
    if (isEveryTabDisabled(this._tabs) || this._tabs.every((tab) => isEveryTabDisabled(tab.subtabs))) {
      log.error("All tabs can't be disabled (including subtabs), not rendering.")
      return false
    }

    const isAnyTabMissingLabel = (tabs: InternalTabBase[]) => tabs.length && tabs?.some((x) => !x.label)
    if (isAnyTabMissingLabel(this._tabs) || this._tabs?.some((tab) => isAnyTabMissingLabel(tab.subtabs))) {
      log.error(
        'All tabs must set text property or the text property on the icon to ensure that aria-label is set (including subtabs), not rendering.'
      )
      return false
    }

    const isAnyPillMissingLabel = (tabs: InternalTabBase[]) => tabs.length && tabs?.some((x) => x.pill && !x.pill.label)
    if (isAnyPillMissingLabel(this._tabs) || this._tabs?.some((tab) => isAnyPillMissingLabel(tab.subtabs))) {
      log.error('All pills must have a label for accessibility (includings subtabs), not rendering.')
      return false
    }

    return true
  }

  #onSlotchange(e: any) {
    const $slotElements = e.target?.assignedElements() as HTMLSlotElement[]
    this._hasSlotContent = !!$slotElements[0]
  }

  #getIconSize(isParent: boolean) {
    if (this.weight === 'low') {
      return '100'
    }
    if (this.weight === 'normal') {
      return isParent ? '200' : '100'
    }
    return isParent ? '300' : '200'
  }

  #setFocusedTab(tab: InternalTabBase) {
    if (tab.disabled) {
      return
    }
    this._focusedTab = tab
  }

  #setActiveTab(tab: InternalTabBase) {
    if (tab.disabled) {
      return
    }

    if (this.activeTab === tab.id) {
      this.#setFocusedTab(tab) // ensures active tab is actually focused
    } else {
      this.activeTab = tab.id
      this.dispatchEvent(new CustomEvent('tab', { detail: this.activeTab, composed: true }))
    }
  }

  #handleTabScroll(e: Event, direction: ScrollDirection) {
    switch (direction) {
      case 'left':
        scrollToLeft(e.target as HTMLElement)
        break
      case 'current':
        scrollToCurrent(e.target as HTMLElement)
        break
      case 'right':
        scrollToRight(e.target as HTMLElement)
        break
    }
  }

  #handleTabClicked(tab: InternalTabBase, activate: boolean) {
    this.#isFocusedByUser = true
    if (activate) {
      this.#setActiveTab(tab)
    } else {
      this.#setFocusedTab(tab)
    }
  }

  #handlefocus() {
    if (!this._focusedTab || this._focusedTab.type === 'sub') {
      return
    }

    const subtabs = (this._focusedTab as InternalTab).subtabs
    const subtab = subtabs.find((tab) => tab.isActive && !tab.disabled)
    if (subtab) {
      this.#setFocusedTab(subtab)
    }
  }

  #handleKeydown(e: KeyboardEvent) {
    if (e.target !== this.shadowRoot?.querySelector('[aria-activedescendant]')) {
      return
    }

    const handled = () => {
      e.preventDefault()
      e.stopPropagation()
    }

    this.#isFocusedByUser = true

    const tabs = getTabSiblings(this._tabs, this._focusedTab)

    switch (e.code) {
      case keyCodes.LEFT:
        {
          handled()
          const previousTab = getPreviousTab(tabs, this._focusedTab)
          if (!this.manual) {
            this.#setActiveTab(previousTab)
          } else {
            this.#setFocusedTab(previousTab)
          }
        }
        break
      case keyCodes.RIGHT:
        {
          handled()
          const nextTab = getNextTab(tabs, this._focusedTab)
          if (!this.manual) {
            this.#setActiveTab(nextTab)
          } else {
            this.#setFocusedTab(nextTab)
          }
        }
        break
      case keyCodes.DOWN:
        {
          if (this._focusedTab.type == 'parent') {
            const parentTab = this._focusedTab as InternalTab
            const childTab =
              parentTab.subtabs.find((x) => x.isActive && !x.disabled) || parentTab.subtabs.find((x) => !x.disabled)

            if (childTab) {
              handled()
              if (!this.manual) {
                this.#setActiveTab(childTab)
              } else {
                this.#setFocusedTab(childTab)
              }
            }
          }
        }
        break
      case keyCodes.UP:
        {
          const subtab = this._focusedTab as InternalSubtab
          if (subtab.parent) {
            handled()
            this.#setFocusedTab(subtab.parent)
          }
        }
        break
      case keyCodes.HOME:
        {
          handled()
          const firstTab = getFirstTab(tabs)
          if (!this.manual) {
            this.#setActiveTab(firstTab)
          } else {
            this.#setFocusedTab(firstTab)
          }
        }
        break
      case keyCodes.END:
        {
          handled()
          const lastTab = getLastTab(tabs)
          if (!this.manual) {
            this.#setActiveTab(lastTab)
          } else {
            this.#setFocusedTab(lastTab)
          }
        }
        break

      case keyCodes.RETURN:
      case keyCodes.NUMPADRETURN:
      case keyCodes.SPACE:
        {
          handled()
          this.#setActiveTab(this._focusedTab)
        }
        break

      case keyCodes.ESCAPE:
        {
          if (this._focusedTab.type === 'sub') {
            handled()
            const parentTab = (this._focusedTab as InternalSubtab).parent
            this.#setFocusedTab(parentTab)
          }
        }
        break
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tabs': OneUxTabsElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tabs': OneUxTabsElement
    }
  }
}
