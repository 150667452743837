import './autosize-textarea.css'
import { AutosizeTextarea } from './AutosizeTextarea'

requestAnimationFrame(function checkForNewElements() {
  const $textareas = document.querySelectorAll('textarea[is="autosize-textarea"]:not([data-autosize_textarea])')
  $textareas.forEach(($textarea) => {
    new AutosizeTextarea($textarea)
  })
  requestAnimationFrame(checkForNewElements)
})

window.addEventListener('resize', () => {
  const $autosizeTextareas = document.querySelectorAll('textarea[is="autosize-textarea"][data-autosize_textarea]')
  $autosizeTextareas.forEach((x) => {
    x._updateHeight(true)
  })
})
