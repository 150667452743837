import { WildcardVersion } from './WildcardVersion';
import { MajorVersion } from './MajorVersion';
const wildcard = '^';
export class MinorVersion extends WildcardVersion {
    fulfills(version) {
        if (version instanceof MajorVersion) {
            if (this.major === version.major) {
                if (this.minor === version.minor) {
                    return this.patch >= version.patch;
                }
                return this.minor > version.minor;
            }
            return this.major >= version.major;
        }
        else if (version instanceof MinorVersion) {
            if (this.major !== version.major) {
                return false;
            }
            if (this.minor === version.minor) {
                return this.patch >= version.patch;
            }
            return this.minor > version.minor;
        }
        return false;
    }
    includes(version) {
        if (version.type !== 'numbered') {
            return false;
        }
        const numberedVersion = version;
        if (this.major !== numberedVersion.major) {
            return false;
        }
        if (this.minor === numberedVersion.minor) {
            return this.patch <= numberedVersion.patch;
        }
        return this.minor < numberedVersion.minor;
    }
    static matches(versionString) {
        return versionString[0] === wildcard;
    }
}
