import { css } from 'lit'

export const POPOUT_Z_INDEX = 1000

export const style = css`
  :host {
    box-sizing: border-box;
    position: fixed;
    backface-visibility: hidden;
    z-index: ${POPOUT_Z_INDEX};
  }

  :host([state-initial]) {
    display: none !important;
    pointer-events: none !important;
  }

  :host(:not([implicit])) one-ux-container {
    box-shadow: var(--one-ux-shadow--drop-400);
    border-radius: var(--one-ux-radius--400);
  }

  one-ux-container {
    background-clip: padding-box;
    overflow: auto;
  }
`
