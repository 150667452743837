import { OneUxElement } from '../common/OneUxElement'
import { html, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { createRef, ref } from 'lit/directives/ref.js'
import { Implicit } from '../common/mixins/Implicit'
import { Focusable } from '../common/mixins/Focusable'
import { Disabled } from '../common/mixins/Disabled'
import { MenuContextMixin } from '../contexts/menu/MenuContextMixin'
import { keyCodes } from '../common/utils'
import { Weight } from '../common/mixins/Weight'
import { defer } from '../common/utils/defer'
import { log } from '../common/utils/log'

const BaseClass = Disabled(Focusable(Weight(Implicit(OneUxElement))))

/**
 * A button that opens a menu when activated.
 * Primarily intended as a context menu button.
 */
@customElement('one-ux-menu-button')
export class OneUxMenuButtonElement extends MenuContextMixin(BaseClass) {
  /**
   * A text that will be used to describe the button.
   */
  @property({ type: String })
  public text!: string

  @state()
  private _open = false

  constructor() {
    super()

    this.addEventListener('blur', () => {
      this._open = false
      this.#refs.$button.value!.tabIndex = 0
    })

    this.addEventListener('click', (event) => {
      if (event.composedPath()[0] == this) {
        this.#refs.$button.value?.click()
      }
    })

    this.addEventListener('keydown', this.#handleKeyDown)
  }

  protected render() {
    if (!this.text) {
      log.error('Text is not provided. Aborting.')
      return
    }

    return html`
      <one-ux-container class="one-ux-element--root" layout="columns">
        <one-ux-button
          ${ref(this.#refs.$button)}
          class="one-ux-element--root"
          ?implicit=${this.implicit}
          .disabled=${this.disabled || !this.items.length}
          weight=${this.weight}
          .delegateAria=${{
            'aria-haspopup': 'menu',
            'aria-expanded': this._open || null
          }}
          label=${this.text}
          compact
          @click=${this.#toggleOpen}
        >
          <one-ux-icon set="internal" icon="context-menu"></one-ux-icon>
        </one-ux-button>
        ${this._open
          ? html`<one-ux-popout indent="none">
              <one-ux-scroll implicit style="max-height: 35vh">
                <contextual-one-ux-menu @option=${this.#toggleOpen}></contextual-one-ux-menu>
              </one-ux-scroll>
            </one-ux-popout>`
          : null}
      </one-ux-container>
    `
  }

  protected updated(dirty: PropertyValues): void {
    if (dirty.has('_open')) {
      this.dispatchEvent(new CustomEvent(this._open ? 'open' : 'close'))
    }
  }

  public get open() {
    return this._open
  }

  #toggleOpen = async () => {
    if (!this.items.length) {
      return
    }
    this._open = !this._open
    if (this._open) {
      defer(() => {
        this.shadowRoot?.querySelector('contextual-one-ux-menu')?.focus()
        this.#refs.$button.value!.tabIndex = -1
      })
    } else {
      this.#refs.$button.value!.tabIndex = 0
      this.focus()
    }
  }

  #refs = {
    $button: createRef<OneUxMenuButtonElement>()
  }

  #handleKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case keyCodes.ESCAPE:
        if (this._open) {
          event.preventDefault()
          event.stopPropagation()
          this.#toggleOpen()
        }
        return
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-menu-button': OneUxMenuButtonElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-menu-button': OneUxMenuButtonElement
    }
  }
}
