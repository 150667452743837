export function defer(operation: (...args: unknown[]) => void, delay?: number) {
  if (typeof delay === 'number') {
    setTimeout(operation, delay)
  } else {
    const channel = new MessageChannel()
    channel.port1.onmessage = function () {
      try {
        operation()
      } finally {
        channel.port1.onmessage = null
      }
    }
    channel.port2.postMessage('')
  }
}
