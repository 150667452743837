const validVersionRegex = /^[~^*]?[0-9]+\.[0-9]+\.[0-9]+(?:-.+)?$/;
export class Version {
    constructor(versionString, type) {
        this.versionString = versionString;
        this.type = type;
    }
    static valid(versionString) {
        if (versionString === 'latest' || versionString === 'stable') {
            return true;
        }
        return validVersionRegex.test(versionString);
    }
    toString() {
        return this.versionString;
    }
}
