import { css } from 'lit'

export const BASE_Z_INDEX = 99999999

export const style = css`
  :host {
    position: fixed !important;
    backface-visibility: hidden !important;
    z-index: ${BASE_Z_INDEX};
    max-width: 90%;
    max-height: 90%;

    --one-ux-dialog-element--top: 0;
    --one-ux-dialog-element--left: 0;

    top: var(--one-ux-dialog-element--top);
    left: var(--one-ux-dialog-element--left);
  }

  :host(:not([visible])) {
    pointer-events: none !important;
  }

  .dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: var(--one-ux-radius--500);
    background-clip: padding-box;
    background: var(--one-ux-palette--gray-100);

    color: var(--one-ux-palette--brand-neutral-600);

    width: 100%;
    box-sizing: border-box;

    padding-right: var(--one-ux-spacing--large);
    padding-left: var(--one-ux-spacing--larger);

    box-shadow: var(--one-ux-shadow--drop-500);
    transform-origin: center;
  }

  .dialog:not(.visible) {
    display: none;
  }

  .backdrop {
    position: fixed;
    backface-visibility: hidden;
    background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    transition-duration: var(--one-ux-duration--200);
    transition-property: opacity, visibility;
    transition-timing-function: var(--one-ux-easing--default);
  }

  .backdrop:not(.visible) {
    visibility: hidden;
    opacity: 0;
  }

  .header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    overflow: hidden;
    outline: none;

    font: var(--one-ux-font--heading-300);
    letter-spacing: var(--one-ux-letter-spacing--heading-300);

    padding-top: var(--one-ux-spacing--large);
    padding-bottom: var(--one-ux-spacing--large);
  }

  #headerText {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
    box-sizing: border-box;
    padding-top: var(--one-ux-spacing--400);
    padding-bottom: var(--one-ux-spacing--600);
    font: var(--one-ux-font--body-400);
    letter-spacing: var(--one-ux-letter-spacing--body-400);
  }

  .footer {
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    justify-content: end;
    gap: var(--one-ux-spacing--normal);
  }

  .dialog.has-footer .footer {
    padding-top: var(--one-ux-spacing--large);
    padding-bottom: var(--one-ux-spacing--large);
  }
`
