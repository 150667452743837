import { html, nothing, svg } from 'lit'
import { styleMap } from 'lit/directives/style-map.js'
import { ValueFormatter } from '../../common/format'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { repeat } from 'lit/directives/repeat.js'
import { transitionTiming } from '../animation'
import { AxisScale } from '../../common/AxisScale'
import { langCode } from '../../../common/utils/getLangCode'
import { barChartType } from '../types'

export function Axis({
  axisScale,
  locale,
  type,
  width,
  tickSize,
  hasHardMin,
  hasHardMax
}: {
  axisScale: AxisScale
  locale: langCode
  type: barChartType
  width: number
  tickSize: number
  hasHardMin: boolean
  hasHardMax: boolean
}) {
  const valueFormatter = new ValueFormatter(locale, true)
  const height = 17
  const textBaseline = 13
  const startTick = axisScale.ticks[0]
  const middleTicks = axisScale.ticks.slice(1, -1)
  const endTick = axisScale.ticks[axisScale.ticks.length - 1]

  const usePercentage = type === 'distribution' && !hasHardMin && !hasHardMax

  if (!width) {
    return nothing
  }

  return html`
    <svg
      style=${styleMap({
        height: `${height}px`,
        width: '100%',
        display: 'block'
      })}
    >
      ${usePercentage
        ? svg`<rect width=${width} height="1" fill="var(--one-ux-palette--brand-neutral-300)"></rect>`
        : nothing}
      ${usePercentage || hasHardMin
        ? svg`
          <text
            x="0"
            y=${textBaseline}
            text-anchor="start"
            fill="var(--one-ux-palette--brand-neutral-400)"
            class="one-ux-typography--mono-400 axis--tick"
          >
            ${startTick}
          </text>
        `
        : nothing}
      ${usePercentage
        ? nothing
        : repeat(
            middleTicks,
            (tick) => tick,
            (tick) => svg`
              <text
                y=${textBaseline}
                text-anchor="middle"
                fill="var(--one-ux-palette--brand-neutral-400)"
                class="one-ux-typography--mono-400 axis--tick"
                ${svgTransition({
                  timing: transitionTiming,
                  attributes: {
                    x: interpolate.constant(Math.floor(axisScale.position(tick) + tickSize / 2)),
                    opacity: interpolate.standard(0, 1, 0)
                  }
                })}
              >
                ${valueFormatter.formatValue(tick)}
              </text>
            `
          )}
      ${usePercentage || hasHardMax
        ? svg`
          <text
            x=${width}
            y=${textBaseline}
            text-anchor="end"
            fill="var(--one-ux-palette--brand-neutral-400)"
            class="one-ux-typography--mono-400 axis--tick"
          >
            ${endTick}${usePercentage ? '%' : ''}
          </text>
        `
        : nothing}
    </svg>
  `
}
