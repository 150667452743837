import { ReactiveController } from 'lit'
import { state } from 'lit/decorators.js'
import { Constructor } from '../../../common/utils'
import { OneUxElement } from '../../../common/OneUxElement'

export declare class IBoundary {
  _boundary?: DOMRect
}

export const Boundary = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class ClientRectClass extends SuperClass {
    static get __one_ux_mixin_boundary__() {
      return true as const
    }

    @state()
    _boundary?: DOMRect

    constructor(...args: any[]) {
      super(...args)

      new BoundaryController(this)
    }
  }
  return ClientRectClass as Constructor<IBoundary> & TSuperClass
}

type BoundaryMixinElement = OneUxElement & IBoundary

const intersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
  for (const entry of entries) {
    if (entry.intersectionRatio > 0) {
      const host = entry.target as BoundaryMixinElement
      const isInitialRender = !host._boundary
      if (isInitialRender) {
        updateClientRect(host, host.getBoundingClientRect())
        resizeObserver.observe(host)
      } else {
        updateClientRect(host, DOMRect.fromRect(host._boundary))
      }
    }
  }
})

const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
  for (const entry of entries) {
    const host = entry.target as BoundaryMixinElement
    if (
      !host._boundary ||
      host._boundary.width !== entry.contentRect.width ||
      host._boundary.height !== entry.contentRect.height
    ) {
      updateClientRect(host, entry.contentRect)
    }
  }
})

class BoundaryController implements ReactiveController {
  constructor(private host: BoundaryMixinElement) {
    this.host.addController(this)
  }

  hostConnected(): void {
    intersectionObserver.observe(this.host)
  }

  hostDisconnected(): void {
    intersectionObserver.unobserve(this.host)
    resizeObserver.unobserve(this.host)
    this.host._boundary = undefined
  }
}

const updateClientRect = (host: BoundaryMixinElement, newValue: DOMRect) => {
  if (!newValue.width || !newValue.height) {
    return
  }

  const oldValue = cloneClientRect(host)
  if (
    oldValue &&
    Math.round(oldValue.width) === Math.round(newValue.width) &&
    Math.round(oldValue.height) === Math.round(newValue.height)
  ) {
    return
  }

  host._boundary = newValue
}

const cloneClientRect = (host: BoundaryMixinElement) => (host._boundary ? DOMRect.fromRect(host._boundary) : undefined)
