import { nothing, svg } from 'lit'
import { KeyScale } from '../../axis/key/KeyScale'
import { ValueScale } from '../../axis/value/ValueScale'
import { AreaRenderGroup } from './types'
import { split } from '../split'
import { bounds } from '../../types'
import {
  getCurvedAreaPathData,
  getCurvedLinePathData,
  getStraightAreaPathData,
  getStraightLinePathData
} from '../paths'
import { vector } from '@/one-ux/charts/common/types'
import { interpolate, svgTransition } from '@/one-ux/charts/common/directives/svgTransition'
import { timing } from '../../animation'

export function Area(keyScale: KeyScale, valueScale: ValueScale, group: AreaRenderGroup, animate: boolean) {
  const lines = split(keyScale, valueScale, group.line)
  const boundary = getBoundary(keyScale, valueScale)
  const domainPosition = valueScale.domainPosition
  const isStraight = !group.curved || (lines.length === 1 && lines[0].length === 2)

  return svg`
    <g transform="translate(0, 0)" fill="none" class="series-area">
      ${lines.map((line, lineIndex) => {
        const { previousPoint, nextPoint } = getPreviousAndNextPoint(line, lines)

        const svgAreaPath = svg`<path fill=${group.color} opacity="0.3"
        ${svgTransition({
          timing: timing(animate),
          attributes: {
            d: interpolate.constant(
              [
                boundary.top,
                boundary.right,
                boundary.bottom,
                boundary.left,
                previousPoint[0],
                previousPoint[1],
                nextPoint[0],
                nextPoint[1],
                domainPosition,
                ...line.flat()
              ],
              ([
                top,
                right,
                bottom,
                left,
                previousPointX,
                previousPointY,
                nextPointX,
                nextPointY,
                domainPosition,
                ...flatLine
              ]) => {
                const interpolatedBoundary = {
                  top,
                  right,
                  bottom,
                  left
                }
                const interpolatedLine = line.map(
                  (_, index) => [flatLine[2 * index], flatLine[2 * index + 1]] as vector
                )

                const interpolatedPreviousPoint =
                  previousPointX > 0 && previousPointY > 0 && lineIndex !== 0
                    ? ([previousPointX, previousPointY] as vector)
                    : null

                const interpolatedNextPoint =
                  nextPointX > 0 && nextPointY > 0 && lineIndex !== 0 && lineIndex !== lines.length - 1
                    ? ([nextPointX, nextPointY] as vector)
                    : null

                return (
                  isStraight
                    ? getStraightAreaPathData(interpolatedLine, interpolatedBoundary, domainPosition)
                    : getCurvedAreaPathData(
                        interpolatedLine,
                        interpolatedPreviousPoint,
                        interpolatedNextPoint,
                        interpolatedBoundary,
                        domainPosition
                      )
                ).toString()
              }
            )
          }
        })} />`

        const svgLinePath = svg`<path
          stroke=${group.color}
          stroke-linecap="round"
          stroke-width=${strokeWidth(group)}
          stroke-dasharray=${dashArray(group)}

          ${svgTransition({
            timing: timing(animate),
            attributes: {
              d: interpolate.constant(
                [
                  boundary.top,
                  boundary.right,
                  boundary.bottom,
                  boundary.left,
                  previousPoint[0],
                  previousPoint[1],
                  nextPoint[0],
                  nextPoint[1],
                  ...line.flat()
                ],
                ([top, right, bottom, left, previousPointX, previousPointY, nextPointX, nextPointY, ...flatLine]) => {
                  const interpolatedBoundary = {
                    top,
                    right,
                    bottom,
                    left
                  }
                  const interpolatedLine = line.map(
                    (_, index) => [flatLine[2 * index], flatLine[2 * index + 1]] as vector
                  )

                  const interpolatedPreviousPoint =
                    previousPointX > 0 && previousPointY > 0 && lineIndex !== 0
                      ? ([previousPointX, previousPointY] as vector)
                      : null

                  const interpolatedNextPoint =
                    nextPointX > 0 && nextPointY > 0 && lineIndex !== 0 && lineIndex !== lines.length - 1
                      ? ([nextPointX, nextPointY] as vector)
                      : null

                  return (
                    isStraight
                      ? getStraightLinePathData(interpolatedLine, interpolatedBoundary)
                      : getCurvedLinePathData(
                          interpolatedLine,
                          interpolatedPreviousPoint,
                          interpolatedNextPoint,
                          interpolatedBoundary
                        )
                  ).toString()
                }
              )
            }
          })}
        />`

        const svgCircles = line.map(([cx, cy]) => {
          if (cy < boundary.top || cy > boundary.bottom) {
            return nothing
          }

          return svg`<circle
            r="3"
            fill=${group.color}
            ${svgTransition({
              timing: timing(animate),
              attributes: {
                cx: interpolate.constant(cx, (cx) => cx.toString()),
                cy: interpolate.constant(cy, (cy) => cy.toString()),
                opacity: interpolate.standard(0, 1, 0)
              }
            })}
          />`
        })

        return [svgAreaPath, svgLinePath, ...svgCircles]
      })}
    </g>
  `
}

const getBoundary = (keyScale: KeyScale, valueScale: ValueScale): bounds => {
  const { left, right } = keyScale.bounds
  const { top, bottom } = valueScale.bounds
  return { left, right, top, bottom }
}

const strokeWidth = (group: AreaRenderGroup) => {
  const defaultSize = 2
  return Math.max(1, group.strokeWidth ? Number(group.strokeWidth) : defaultSize)
}

const dashArray = (group: AreaRenderGroup) => {
  if (!group.strokeDashed) {
    return nothing
  }

  const dashLength = 6
  const dashSpacing = 6

  return `${dashLength}, ${dashSpacing}`
}

const getPreviousAndNextPoint = (line: vector[], lines: vector[][]) => {
  const lineIndex = lines.indexOf(line)
  const previousPoint: vector = lineIndex > 0 ? lines[lineIndex - 1][lines[lineIndex - 1].length - 1] : [0, 0]
  const nextPoint: vector = lineIndex < lines.length - 1 ? lines[lineIndex + 1][0] : [0, 0]

  return {
    previousPoint,
    nextPoint
  }
}
