import { OneUxElement } from '../OneUxElement'
import { log } from './log'

export class Deprecation {
  #logged = false
  constructor(private $element: OneUxElement, private title: string, private message: string) {}

  notify() {
    if (this.#logged) {
      return
    }
    this.#logged = true
    log.deprecation({
      title: this.title,
      message: this.message,
      details: {
        element: this.$element
      }
    })
  }
}
