import { html, nothing } from 'lit'
import { distributionMetadata, distributionValues } from '../../common/types'
import { styleMap } from 'lit/directives/style-map.js'

export function DistributionPopout({
  name,
  unit,
  metadata,
  values,
  referenceElement,
  direction,
  alignment,
  hidePercentage
}: {
  name: string
  unit?: string
  metadata: distributionMetadata[]
  values: distributionValues
  referenceElement: Element
  direction?: 'vertical' | 'horizontal'
  alignment?: 'start' | 'center' | 'end'
  hidePercentage?: boolean
}) {
  const getValue = (key: string) => values[key] || 0
  const total = metadata.reduce((sum, x) => sum + getValue(x.key), 0)

  return html`
    <one-ux-popout
      aria-hidden="true"
      style="
        pointer-events: none;
        color: var(--one-ux-palette--brand-neutral-600);
      "
      direction=${direction || 'vertical'}
      alignment=${alignment || 'start'}
      .reference=${referenceElement}
    >
      <div class="one-ux-typography--body-variant-400" style="margin: 0 0 var(--one-ux-spacing--300) 0;">
        ${name} (${unit ? `${total} ${unit}` : total})
      </div>
      <div
        class="one-ux-typography--body-300"
        style=${styleMap({
          width: '300px',
          display: 'grid',
          'grid-template-columns': hidePercentage ? 'auto 1fr auto' : 'auto 1fr auto auto',
          gap: 'var(--one-ux-spacing--300) var(--one-ux-spacing--200)',
          'white-space': 'nowrap',
          margin: '0'
        })}
      >
        ${metadata.map((metadataEntry) => {
          const value = getValue(metadataEntry.key)
          if (value == null) {
            return nothing
          }

          return html`
            <one-ux-icon
              size="200"
              icon=${metadataEntry.icon.name}
              set=${metadataEntry.icon.set}
              style=${styleMap({
                'align-self': 'baseline',
                color: metadataEntry.color
              })}
            ></one-ux-icon>
            <div
              style="
                white-space: normal;
                overflow-wrap: anywhere;
              "
            >
              ${metadataEntry.name}
            </div>
            <div
              class="one-ux-typography--body-variant-300"
              style="
                text-align: right;
                margin: 0;
              "
            >
              ${value} ${unit}
            </div>
            ${hidePercentage
              ? nothing
              : html`
                  <div
                    style="
                      text-align: right;
                      color: var(--one-ux-palette--brand-neutral-400);
                    "
                  >
                    (${Math.round((100 * value) / total)}%)
                  </div>
                `}
          `
        })}
      </div>
    </one-ux-popout>
  `
}
