import { OneUxDistributionElement } from './OneUxDistributionElement'
import { getLangCode, langCode, LanguageSets } from '@/one-ux/common/utils/getLangCode'

export type language = {
  locale: langCode
  translations: translationSet
}

export type translationSet = {
  distributionArc: string
  distributionBar: string
  distributionCircle: string
}

export const languages: LanguageSets<translationSet> = {
  en: {
    distributionArc: 'Distribution arc',
    distributionBar: 'Distribution bar',
    distributionCircle: 'Distribution Circle'
  },
  sv: {
    distributionArc: 'Fördelningsbåge',
    distributionBar: 'Fördelningsfält',
    distributionCircle: 'Fördelningscirkel'
  },
  nb: {
    distributionArc: 'Fordelingsbue',
    distributionBar: 'Fordelingslinje',
    distributionCircle: 'Fordelingssirkel'
  },
  fi: {
    distributionArc: 'Jakaumakaari',
    distributionBar: 'Jakelupalkki',
    distributionCircle: 'Jakelupiiri'
  },
  da: {
    distributionArc: 'Fordelingsbue',
    distributionBar: 'Fordelingsbjælke',
    distributionCircle: 'Fordelingskreds'
  }
}

export const getLanguage = (element: OneUxDistributionElement): language => {
  const locale = getLangCode(element)
  const translations = languages[locale]
  return {
    locale,
    translations
  }
}
