import { keyValue, serie } from '../types'
import { legendData, legendKeyData } from './types'

export const mapLegendData = (series: serie[]): legendData[] =>
  series.map((s) => {
    return {
      name: s.name,
      type: s.type,
      color: s.color
    }
  })

export const mapKeyLegendData = (series: serie[], key: keyValue): legendKeyData[] =>
  series
    .map((s) => ({
      name: s.name,
      type: s.type,
      color: s.color,
      value: s.data.find((d) => d.key?.toString() === key.toString())?.value
    }))
    .filter((x) => Number.isFinite(x.value))
