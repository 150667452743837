import { css } from 'lit'

export const style = css`
  .key-hover--band:hover::before {
    content: '';
    display: block;
    height: 100%;
    margin: 0 auto;
    width: 1px;
    background-color: var(--one-ux-palette--gray-700);
  }

  :host(:hover) {
    background: rgba(var(--one-ux-rgb--brand-neutral-500), 0.07);
  }

  :host([state-keyboard-focus]:focus) {
    box-shadow: inset 0 0 0 2px var(--one-ux-palette--keyboard-focus-400);
    background: var(--one-ux-palette--keyboard-focus-100);
  }

  .one-ux-element--root {
    display: flex;
    flex-direction: column;
    outline: none;
  }

  .slots {
    display: flex;
    flex-direction: column;
    flex: 0;
    margin: 0;
    margin-bottom: 2px;
    text-align: center;
  }

  .slots .slot-description {
    margin: 0;
    line-height: 16px;
  }

  .slots {
    animation: fadeIn ease-in; /* duration is set from template */
  }

  :is(.slot-description)::slotted(:not(one-ux-icon, one-ux-link)) {
    position: fixed !important;
    visibility: hidden !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
