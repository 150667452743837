import { SpecialVersion } from './SpecialVersion';
const keyword = 'stable';
export class StableVersion extends SpecialVersion {
    constructor() {
        super(keyword);
    }
    fulfills(version) {
        return version instanceof StableVersion;
    }
    static matches(versionString) {
        return versionString === keyword;
    }
}
