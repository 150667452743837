var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _PlacementController_element, _PlacementController_animateRequest, _PlacementController_prevTop, _PlacementController_prevLeft, _PlacementController_prevPositionState, _PlacementController_animate, _PlacementController_getPositionState;
export class PlacementController {
    constructor($element) {
        _PlacementController_element.set(this, void 0);
        _PlacementController_animateRequest.set(this, void 0);
        _PlacementController_prevTop.set(this, 0);
        _PlacementController_prevLeft.set(this, 0);
        _PlacementController_prevPositionState.set(this, void 0);
        _PlacementController_animate.set(this, () => {
            __classPrivateFieldSet(this, _PlacementController_animateRequest, requestAnimationFrame(() => {
                const $element = __classPrivateFieldGet(this, _PlacementController_element, "f");
                const parentElement = $element.parentElement || $element.getRootNode().host;
                const parentRect = parentElement.getBoundingClientRect();
                let top = parentRect.bottom;
                let left = parentRect.left;
                let flippedVertical = false;
                let flippedHorizontal = false;
                if (top + $element.offsetHeight > document.documentElement.clientHeight) {
                    top -= parentRect.height + $element.offsetHeight;
                    flippedVertical = true;
                }
                if (left + $element.offsetWidth > document.documentElement.clientWidth) {
                    left -= $element.offsetWidth - parentRect.width;
                    flippedHorizontal = true;
                }
                top = Math.floor(top);
                left = Math.floor(left);
                if (top !== __classPrivateFieldGet(this, _PlacementController_prevTop, "f") && Math.abs(top - __classPrivateFieldGet(this, _PlacementController_prevTop, "f")) > 1) {
                    $element.style.top = top + 'px';
                    __classPrivateFieldSet(this, _PlacementController_prevTop, top, "f");
                }
                if (left !== __classPrivateFieldGet(this, _PlacementController_prevLeft, "f") && Math.abs(left - __classPrivateFieldGet(this, _PlacementController_prevLeft, "f")) > 1) {
                    $element.style.left = left + 'px';
                    __classPrivateFieldSet(this, _PlacementController_prevLeft, left, "f");
                }
                const positionState = __classPrivateFieldGet(this, _PlacementController_getPositionState, "f").call(this, flippedVertical, flippedHorizontal);
                if (positionState !== __classPrivateFieldGet(this, _PlacementController_prevPositionState, "f")) {
                    $element.setAttribute('state-position', positionState);
                    __classPrivateFieldSet(this, _PlacementController_prevPositionState, positionState, "f");
                }
                __classPrivateFieldGet(this, _PlacementController_animate, "f").call(this);
            }), "f");
        });
        _PlacementController_getPositionState.set(this, (flippedVertical, flippedHorizontal) => {
            return flippedVertical ? (flippedHorizontal ? 'flip-v-h' : 'flip-v') : flippedHorizontal ? 'flip-h' : '';
        });
        __classPrivateFieldSet(this, _PlacementController_element, $element, "f");
        __classPrivateFieldGet(this, _PlacementController_element, "f").addController(this);
    }
    hostConnected() {
        __classPrivateFieldGet(this, _PlacementController_animate, "f").call(this);
    }
    hostDisconnected() {
        cancelAnimationFrame(__classPrivateFieldGet(this, _PlacementController_animateRequest, "f"));
    }
}
_PlacementController_element = new WeakMap(), _PlacementController_animateRequest = new WeakMap(), _PlacementController_prevTop = new WeakMap(), _PlacementController_prevLeft = new WeakMap(), _PlacementController_prevPositionState = new WeakMap(), _PlacementController_animate = new WeakMap(), _PlacementController_getPositionState = new WeakMap();
