import { ValueFormatter } from '@/one-ux/charts/common/format'
import { getTextSize } from '@/one-ux/charts/common/getTextSize'
import { getTypography } from '@/one-ux/charts/common/typography'

export const getValueTicksMaxWidth = (ticks: number[], valueFormatter: ValueFormatter) => {
  const { font, letterSpacing } = getTypography('mono-100')
  let tick = ''
  for (let i = ticks.length - 1; i >= 0; i--) {
    const nextTick = valueFormatter.formatValue(ticks[i])
    tick = nextTick.length > tick.length ? nextTick : tick
  }

  return getTextSize(tick, font, letterSpacing).width
}
