import { animationFrameTimer } from './animation-frame-timing';
export const animate = async (toast) => {
    const $toastElement = toast.$ref.value;
    if (!$toastElement) {
        return false;
    }
    const $progressElement = $toastElement?.querySelector('progress');
    if (!$progressElement) {
        return false;
    }
    const duration = Number(toast.duration);
    await animationFrameTimer((elapsed) => {
        const percent = Math.min(Math.round((elapsed / duration) * 100), 100);
        $progressElement.setAttribute('value', percent.toString());
        return $toastElement.isConnected;
    }, duration);
    return $toastElement.isConnected;
};
