import { KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DOWN } from '../../helpers/keyCodes'
import { addPlaceholderBeforeGadget } from './helpers'

export class ResizeHandler {
  #gadgetElement
  #verticalScrollingHandler

  constructor($gadget, $resizeHandle, verticalScrollingHandler) {
    this.#gadgetElement = $gadget
    this.#verticalScrollingHandler = verticalScrollingHandler

    $gadget.addEventListener('keydown', this.#handleKeyDown)
    $resizeHandle.addEventListener('mousedown', this.#handleMouseDown)
  }

  #handleKeyDown = (event) => {
    const $gadget = this.#gadgetElement

    if (event.ctrlKey && $gadget.resizable) {
      if (event.which == KEY_LEFT) {
        this.#keyboardGadgetResize('columns', $gadget.columns - 1, event)
      }
      if (event.which == KEY_RIGHT) {
        this.#keyboardGadgetResize('columns', Math.min($gadget.columns + 1, this.#maxColumns), event)
      }
      if (event.which == KEY_UP) {
        this.#keyboardGadgetResize('rows', $gadget.rows - 1, event)
      }
      if (event.which == KEY_DOWN) {
        this.#keyboardGadgetResize('rows', Math.min($gadget.rows + 1, this.#maxRows), event)
      }
    }
  }

  #keyboardGadgetResize = (dimension, value, event) => {
    const $gadget = this.#gadgetElement

    event.preventDefault()
    $gadget[dimension] = value
    $gadget.blur()
    $gadget.focus()
    $gadget.dispatchEvent(new CustomEvent('gadget-resize'))
  }

  #handleMouseDown = (event) => {
    const $gadget = this.#gadgetElement

    if (!$gadget.resizable) {
      return
    }

    // Prevent text selection
    event.preventDefault()
    $gadget.focus()

    const gadgetStartRect = $gadget.getBoundingClientRect()
    const widthStep = gadgetStartRect.width / $gadget.columns
    const heightStep = gadgetStartRect.height / $gadget.rows
    const $placeholder = addPlaceholderBeforeGadget($gadget)
    const remainingColumns = this.#lockPlaceholderAndCountRemainingColumns(
      $placeholder,
      widthStep,
      heightStep,
      gadgetStartRect
    )

    $gadget.style.width = gadgetStartRect.width + 'px'
    $gadget.style.height = gadgetStartRect.height + 'px'
    $gadget.style.top = gadgetStartRect.top + 'px'
    $gadget.style.left = gadgetStartRect.left + 'px'
    $gadget.toggleAttribute('state-resizing', true)

    const startMouseX = event.clientX
    const startMouseY = event.clientY
    let currentMouseX = startMouseX
    let currentMouseY = startMouseY
    let currentColumns = $gadget.columns
    let currentRows = $gadget.rows

    const resizing = (event) => {
      currentMouseX = event.clientX || currentMouseX
      currentMouseY = event.clientY || currentMouseY
      const currentRect = $gadget.getBoundingClientRect()
      const deltaX = currentMouseX - startMouseX - (currentRect.left - gadgetStartRect.left)
      const deltaY = currentMouseY - startMouseY - (currentRect.top - gadgetStartRect.top)

      const newWidth = Math.min(Math.max(gadgetStartRect.width + deltaX, widthStep), remainingColumns * widthStep)
      const newHeight = Math.min(Math.max(gadgetStartRect.height + deltaY, heightStep), this.#maxRows * heightStep)
      $gadget.style.width = newWidth + 'px'
      $gadget.style.height = newHeight + 'px'

      currentColumns = Math.min(Math.max(Math.ceil(newWidth / widthStep), 1), remainingColumns)
      currentRows = Math.min(Math.max(Math.ceil(newHeight / heightStep), 1), this.#maxRows)

      $placeholder.style.setProperty('--pdr-dashboard-gadget-columns', currentColumns)
      $placeholder.style.setProperty('--pdr-dashboard-gadget-rows', currentRows)

      this.#verticalScrollingHandler.startIfNeeded(currentMouseY)

      const placeholderRect = $placeholder.getBoundingClientRect()
      $gadget.style.top = placeholderRect.top + 'px'
      $gadget.style.left = placeholderRect.left + 'px'
    }

    const done = () => {
      $gadget.columns = currentColumns
      $gadget.rows = currentRows

      $gadget.style.width = ''
      $gadget.style.height = ''
      $gadget.style.top = ''
      $gadget.style.left = ''
      $gadget.toggleAttribute('state-resizing', false)

      $placeholder.remove()

      this.#verticalScrollingHandler.stop()

      document.removeEventListener('mousemove', resizing, true)
      document.removeEventListener('scroll', resizing, true)
      document.removeEventListener('mouseup', done, true)

      $gadget.dispatchEvent(new CustomEvent('gadget-resize'))
    }

    document.addEventListener('mousemove', resizing, true)
    document.addEventListener('scroll', resizing, true)
    document.addEventListener('mouseup', done, true)
  }

  #lockPlaceholderAndCountRemainingColumns = ($placeholder, widthStep, heightStep, gadgetStartRect) => {
    const dashboardRect = this.#dashboard.getBoundingClientRect()

    const columnIndex = Math.round((gadgetStartRect.left - dashboardRect.left) / widthStep)
    const rowIndex = Math.round((gadgetStartRect.top - dashboardRect.top) / heightStep)

    $placeholder.style.gridColumnStart = columnIndex + 1
    $placeholder.style.gridRowStart = rowIndex + 1

    return this.#maxColumns - columnIndex
  }

  get #maxColumns() {
    return this.#dashboard.maxGadgetColumns
  }

  get #maxRows() {
    return this.#dashboard.maxGadgetRows
  }

  get #dashboard() {
    return this.#gadgetElement.closest('pdr-dashboard')
  }
}
