import { FULL_CIRCLE_IN_RADIANS } from '../constants'
import { path } from '../path'

/*
  Outline of points generated:

        -------
    p1--       --p2
  p0               p3
   \               /
    p7    ---    p4
      p6--   --p5

*/

export function curvedSegment(
  radius: number,
  startAngle: number,
  lengthInRadians: number,
  thickness: number,
  borderRadius: number
) {
  const outerRadius = radius
  const innerRadius = outerRadius - thickness
  const endAngleRadian = startAngle - lengthInRadians
  const borderRadiusAngle = (borderRadius / (2 * outerRadius * Math.PI)) * FULL_CIRCLE_IN_RADIANS
  const isLongTrack = lengthInRadians - 2 * borderRadiusAngle > Math.PI

  const point0 = {
    x: Math.cos(startAngle) * (outerRadius - borderRadius),
    y: -Math.sin(startAngle) * (outerRadius - borderRadius)
  }

  const point1 = {
    x: Math.cos(startAngle - borderRadiusAngle) * outerRadius,
    y: -Math.sin(startAngle - borderRadiusAngle) * outerRadius
  }

  const point2 = {
    x: Math.cos(endAngleRadian + borderRadiusAngle) * outerRadius,
    y: -Math.sin(endAngleRadian + borderRadiusAngle) * outerRadius
  }

  const point3 = {
    x: Math.cos(endAngleRadian) * (outerRadius - borderRadius),
    y: -Math.sin(endAngleRadian) * (outerRadius - borderRadius)
  }

  const point4 = {
    x: Math.cos(endAngleRadian) * (innerRadius + borderRadius),
    y: -Math.sin(endAngleRadian) * (innerRadius + borderRadius)
  }

  const point5 = {
    x: Math.cos(endAngleRadian + borderRadiusAngle) * innerRadius,
    y: -Math.sin(endAngleRadian + borderRadiusAngle) * innerRadius
  }

  const point6 = {
    x: Math.cos(startAngle - borderRadiusAngle) * innerRadius,
    y: -Math.sin(startAngle - borderRadiusAngle) * innerRadius
  }

  const point7 = {
    x: Math.cos(startAngle) * (innerRadius + borderRadius),
    y: -Math.sin(startAngle) * (innerRadius + borderRadius)
  }

  return path()
    .moveTo(point0.x, point0.y)
    .arcTo(borderRadius, borderRadius, 0, false, true, point1.x, point1.y)
    .arcTo(outerRadius, outerRadius, 0, isLongTrack, true, point2.x, point2.y)
    .arcTo(borderRadius, borderRadius, 0, false, true, point3.x, point3.y)
    .lineTo(point4.x, point4.y)
    .arcTo(borderRadius, borderRadius, 0, false, true, point5.x, point5.y)
    .arcTo(innerRadius, innerRadius, 0, isLongTrack, false, point6.x, point6.y)
    .arcTo(borderRadius, borderRadius, 0, false, true, point7.x, point7.y)
    .close()
    .toString()
}
