var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _MicrofrontendsSettings_config;
export class MicrofrontendsSettings {
    constructor(config) {
        _MicrofrontendsSettings_config.set(this, void 0);
        __classPrivateFieldSet(this, _MicrofrontendsSettings_config, config, "f");
    }
    static build(config) {
        if (config.microfrontends) {
            return new MicrofrontendsSettings(config.microfrontends);
        }
    }
    get baseUrl() {
        if (!__classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").baseUrl) {
            this.invalidConfig('baseUrl is required');
        }
        if (__classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").baseUrl.endsWith('/')) {
            return __classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").baseUrl;
        }
        // For Webpack 5 publicPath: 'auto' it is relevant that the URL always ends with a / otherwise following chunk loads will fail.
        // The baseUrl http://example.com/{package}/{version} will result in the public path http://example.com/{package}/ and chunks will fail to load.
        // In contrast the url http://example.com/{package}/{version}/ will maintain the version in the public path.
        return __classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").baseUrl + '/';
    }
    get mode() {
        return __classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").mode || 'dynamic';
    }
    get dependencies() {
        if (__classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").dependencies) {
            if (Array.isArray(__classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").dependencies)) {
                return __classPrivateFieldGet(this, _MicrofrontendsSettings_config, "f").dependencies;
            }
            this.invalidConfig('dependencies has to be an array');
        }
        return [];
    }
    invalidConfig(reason) {
        throw new Error(`'Invalid dependencies configuration passed to PDR.init: ${reason}!`);
    }
}
_MicrofrontendsSettings_config = new WeakMap();
