import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement'
import { Constructor } from '../utils'

export declare class IDisabled {
  disabled: boolean
}

export const Disabled = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  // TODO: remove any
  if (!(SuperClass as any).__one_ux_mixin_focusable__) {
    throw new Error(`Can only apply Disabled mixin if Focusable mixin is already applied. `)
  }

  class DisabledClass extends SuperClass {
    /**
     * Disables the element. It will still be visible but not possible to interact with.
     * Focus and blur will also be disabled.
     * Affects visual appearance and accessibility.
     */
    @property({ type: Boolean, reflect: true })
    public disabled = false

    constructor(...args: any[]) {
      super(...args)

      this.addEventListener(
        'click',
        (event) => {
          if (this.disabled) {
            event.preventDefault()
            event.stopImmediatePropagation()
          }
        },
        { capture: true }
      )
    }
  }

  return DisabledClass as Constructor<IDisabled> & TSuperClass
}
