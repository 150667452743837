import { OneUxSpacingToken } from '../../../one-ux/generated/design-tokens'
import { css, ReactiveController } from 'lit'
import { property } from 'lit/decorators.js'
import { OneUxElement, mapDimensionFractions } from '../OneUxElement'
import { Constructor } from '../utils'
import { StyledFactory } from './Styled'

type spacing =
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | 'large'
  | 'larger'
  | 'largest'
  | 'none'
  | 'normal'
  | 'small'
  | 'smaller'
  | 'smallest'

type layout = 'normal' | 'columns' | 'rows'

export declare class ILayout<TSpacing extends OneUxSpacingToken> {
  layout: layout
  indent: TSpacing
  indentTop?: TSpacing
  indentRight?: TSpacing
  indentBottom?: TSpacing
  indentLeft?: TSpacing
  gutters: TSpacing
  separate: TSpacing
}

const Styled = StyledFactory(style())

export const Layout = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class LayoutClass extends Styled(SuperClass) {
    constructor(...args: any[]) {
      super(...args)
      new LayoutController(this)
    }

    /**
     * The space from the edges of the parent to the content.
     *
     * Related to padding, but is not guaranteed to be implemented as padding.
     */
    @property({ type: String })
    public indent: spacing = 'none'

    /**
     * The space from the top edge of the parent to the content. Overrides `indent`.
     */
    @property({ attribute: 'indent-top', type: String })
    public indentTop?: spacing

    /**
     * The space from the right edge of the parent to the content. Overrides `indent`.
     */
    @property({ attribute: 'indent-right', type: String })
    public indentRight?: spacing

    /**
     * The space from the bottom edge of the parent to the content. Overrides `indent`.
     */
    @property({ attribute: 'indent-bottom', type: String })
    public indentBottom?: spacing

    /**
     * The space from the left edge of the parent to the content. Overrides `indent`.
     */
    @property({ attribute: 'indent-left', type: String })
    public indentLeft?: spacing

    /**
     * The space between the children of the component.
     */
    @property({ type: String, reflect: true })
    public gutters: spacing = 'none'

    /**
     * The space between the components and its siblings.
     *
     * Related to margin, but is not guaranteed to be implemented as margin.
     */
    @property({ type: String })
    public separate: spacing = 'none'

    /**
     * Controls the layout of the children.
     */
    @property({ type: String, reflect: true })
    public layout: layout = 'normal'
  }
  return LayoutClass as Constructor<ILayout<spacing>> & TSuperClass
}

class LayoutController implements ReactiveController {
  constructor(private host: OneUxElement & ILayout<spacing>) {
    this.host.addController(this)
  }

  hostUpdate(): void {
    const indentTop = this.host.indentTop || this.host.indent
    const indentRight = this.host.indentRight || this.host.indent
    const indentBottom = this.host.indentBottom || this.host.indent
    const indentLeft = this.host.indentLeft || this.host.indent

    this.host.style.setProperty('--one-ux-element--indent-top', this.#getOneUxProperty(indentTop), 'important')
    this.host.style.setProperty('--one-ux-element--indent-right', this.#getOneUxProperty(indentRight), 'important')
    this.host.style.setProperty('--one-ux-element--indent-bottom', this.#getOneUxProperty(indentBottom), 'important')
    this.host.style.setProperty('--one-ux-element--indent-left', this.#getOneUxProperty(indentLeft), 'important')

    this.host.toggleAttribute('state-indent-top', indentTop && indentTop !== 'none')
    this.host.toggleAttribute('state-indent-bottom', indentBottom && indentBottom !== 'none')

    this.host.style.setProperty('--one-ux-element--gutters', this.#getOneUxProperty(this.host.gutters), 'important')
    this.host.style.setProperty('--one-ux-element--separate', this.#getOneUxProperty(this.host.separate), 'important')
  }

  #getOneUxProperty(spacing: OneUxSpacingToken) {
    if (/[^0-9a-zA-Z-]/.test(spacing as string)) {
      spacing = 'none'
    }
    return `var(--one-ux-spacing--${spacing})`
  }
}

function style() {
  return css`
    .one-ux-element--content {
      padding: var(--one-ux-element--indent-top) var(--one-ux-element--indent-right)
        var(--one-ux-element--indent-bottom) var(--one-ux-element--indent-left);
    }

    :host([layout='normal'][state-indent-top]) .one-ux-element--content {
      padding-top: 0;
    }

    :host([layout='normal'][state-indent-bottom]) .one-ux-element--content {
      padding-bottom: 0;
    }

    :host([layout='normal'][state-indent-top]) .one-ux-element--content::before,
    :host([layout='normal'][state-indent-bottom]) .one-ux-element--content::after {
      display: block;
      content: '';
      overflow: hidden; /* Create new block formatting context to prevent margins from leaking through */
    }

    :host([layout='normal'][state-indent-top]) .one-ux-element--content::before {
      margin-bottom: var(--one-ux-element--indent-top);
    }

    :host([layout='normal'][state-indent-bottom]) .one-ux-element--content::after {
      margin-top: var(--one-ux-element--indent-bottom);
    }

    :host([layout='normal']:not([gutters='none'])) .one-ux-element--content {
      --one-ux-element--slotted-margins: calc(
        var(--one-ux-element--gutters) / 2
      ); /* Use intermediate css variable to avoid collision with children's own css variables */
    }

    :host([layout='normal']:not([gutters='none'])) .one-ux-element--content > ::slotted(*) {
      margin: 0 var(--one-ux-element--slotted-margins) var(--one-ux-element--slotted-margins) 0 !important;
    }

    :host(:is([layout='columns'], [layout='rows'])) .one-ux-element--content {
      display: flex;
      gap: var(--one-ux-element--gutters);
      align-items: flex-start;
    }

    :host(:is([layout='columns'], [layout='rows'])) .one-ux-element--content > ::slotted(*) {
      flex: 0 0 auto !important;
      box-sizing: border-box !important;
    }

    :host([layout='columns']) .one-ux-element--content > ::slotted(:is([width='auto'], .layout-width--auto)) {
      flex: 1 1 auto !important;
      min-width: 0;
    }

    :host([layout='rows']) .one-ux-element--content > ::slotted([height='auto']) {
      height: 100% !important;
    }

    :host([layout='columns'])
      .one-ux-element--content
      > ::slotted(:is([height='max'], [height='auto'], .layout-height--max, .layout-height--auto)) {
      height: auto !important;
      align-self: stretch !important;
    }

    ${mapDimensionFractions(
      (x) => `
        :host([layout='columns']) .one-ux-element--content > ::slotted(:is([width='${x}/12'], .layout-width--${x}-12)) {
          width: calc(100% * ${x} / 12 - var(--one-ux-element--gutters) + (var(--one-ux-element--gutters) * ${x} / 12)) !important;
        }
      `
    )}

    :host([layout='rows']) .one-ux-element--content {
      flex-direction: column;
    }

    :host([layout='rows']) .one-ux-element--content > ::slotted(:is([height='auto'], .layout-height--auto)) {
      flex: 1 1 auto !important;
      min-height: 0;
    }

    :host([layout='rows']) .one-ux-element--content > ::slotted([width='auto']) {
      width: 100% !important;
    }

    ${mapDimensionFractions(
      (x) => `
        :host([layout='rows']) .one-ux-element--content > ::slotted(:is([height='${x}/12'], .layout-height--${x}-12)) {
          height: calc(100% * ${x} / 12 - var(--one-ux-element--gutters) + (var(--one-ux-element--gutters) * ${x} / 12)) !important;
        }
      `
    )}
  `
}
