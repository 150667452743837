import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { ListContextMixin } from '../contexts/list/ListContextMixin'
import { DelegateAria } from '../common/mixins/DelegateAria'
import { Focusable } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { OneUxElement } from '../common/OneUxElement'

const BaseClass = DelegateAria(Focusable(Implicit(OneUxElement)))

/**
 * A list component that allows for single and multi select.
 */
@customElement('one-ux-list')
export class OneUxListElement extends ListContextMixin(BaseClass) {
  protected render() {
    return html`
      <contextual-one-ux-list
        class="one-ux-element--root"
        .delegateAria=${this.delegateAria}
        ?implicit=${this.implicit}
        @input="${(event: Event) => this.dispatchEvent(new Event(event.type, event))}"
      ></contextual-one-ux-list>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-list': OneUxListElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-list': OneUxListElement
    }
  }
}
