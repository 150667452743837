import defaultSettings from '../constants/defaultSettings'
import responsiveSize from '../constants/responsiveSize'
import settingsBuilder from './settings/builder'
import isElementVisible from './is-element-visible'
import { detectIsInViewport } from './is-in-viewport'
import { onResize } from './resize-listener'

let pdrChartInstanceIdGenerator = 1

export class ElementInternalSettings {
  constructor(element) {
    this.element = element
    this.id = pdrChartInstanceIdGenerator++

    this.margin = Object.assign({}, defaultSettings.margins)
    this._size = {}
    this._builder = import(
      /* webpackChunkName: "pdr-chart-pipeline-chart-builder" */
      '../pipeline/chart-builder'
    ).then(({ ChartBuilder }) => new ChartBuilder(this.element))
    this.selectedLegend = null
  }

  get value() {
    return this._value
  }
  set value(value) {
    this._value = value
    this.settings = settingsBuilder.build(value)
    this.duration = this.settings.transitionDuration
    this.build()
  }

  get responsiveName() {
    if (this.size.width < 320) {
      return responsiveSize.SMALL
    }
    return responsiveSize.MEDIUM
  }

  get size() {
    return {
      width: this._size.width,
      height: this._size.height,
      innerWidth: this._size.width - this.margin.left - this.margin.right,
      innerHeight: this._size.height - this.margin.top - this.margin.bottom,
      isSetByClient: this._size.isSetByClient || false
    }
  }
  set size(size) {
    this._size = Object.assign({}, this._size, size, { isSetByClient: true })
    this.build()
  }

  async build() {
    if (!this.value) {
      return
    }

    if (this.settings.renderWhenInViewport) {
      await detectIsInViewport(this.element)
    }

    // size is set from beginning
    if (this.size.isSetByClient) {
      await isElementVisible(this.element)
      this._builder.then(async (builder) => {
        await builder.start()
      })
      return
    }

    this._builder.then((builder) => {
      onResize(this.element, async (size) => {
        // size is set during runtime
        if (this.size.isSetByClient) {
          return false
        }

        this._size = Object.assign({}, this._size, size)
        await builder.start()

        return true
      })
    })
  }
}
