import { OneUxTypographyToken } from '@/one-ux/generated/design-tokens'

const typographyInfo: Map<string, { font: string; letterSpacing: string }> = new Map()

export const loadTypography = ($el: HTMLElement, tokens: OneUxTypographyToken[]) => {
  let computedStyle: CSSStyleDeclaration | null = null
  for (const token of tokens) {
    if (typographyInfo.has(token)) {
      continue
    }

    if (!computedStyle) {
      computedStyle = getComputedStyle($el)
    }

    const font = computedStyle.getPropertyValue(`--one-ux-font--${token}`)
    if (!font) {
      throw new Error(`Couldn't find font for token "${token}"`)
    }

    const letterSpacing = computedStyle.getPropertyValue(`--one-ux-letter-spacing--${token}`)
    if (!letterSpacing) {
      throw new Error(`Couldn't find letter-spacing for token "${token}"`)
    }

    typographyInfo.set(token, { font, letterSpacing })
  }
}

export const getTypography = (token: OneUxTypographyToken) => {
  if (typographyInfo.has(token)) {
    return typographyInfo.get(token)!
  }

  throw new Error(`Couldn't find font for token "${token}", make sure to call 'loadTypography'`)
}
