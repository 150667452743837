import { CustomElementBase } from '../CustomElementBase'

export class InputColorOptionElement extends CustomElementBase {
  onInitialized() {
    this.tabIndex = -1
  }

  onConnected() {
    this.style.background = this.value
  }

  static get observedAttributes() {
    return ['value']
  }

  onAttributeChanged() {
    this.style.background = this.value
  }

  get value() {
    return this.getAttribute('value')
  }

  set value(value) {
    if (value !== undefined) {
      this.setAttribute('value', value)
    }
  }
}
