import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalData } from '../types'
import { getInlineStatsSpecs } from './getInlineStatsSpecs'
import { getInternalSegments } from './getInternalSegments'
import { calculateGauge } from './calculateGauge'
import { getSnapSizeConfig } from './getSnapSizeConfig'

export const getInternalData = (host: OneUxGaugeChartElement): InternalData => {
  const snapSizeConfig = getSnapSizeConfig(host)
  const { totalValue, gaugePercent } = calculateGauge(host.segments)
  const segments = getInternalSegments(host.segments, gaugePercent)
  const inlineStatsSpecs = getInlineStatsSpecs(host, host.segments, snapSizeConfig)

  return {
    segments,
    gaugePercent,
    totalValue,
    inlineStatsSpecs,
    snapSizeConfig
  }
}
