import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .one-ux-element--root:focus-within {
    outline: 2px solid var(--one-ux-palette--keyboard-focus-400) !important;
    background: var(--one-ux-palette--keyboard-focus-100) !important;
  }

  svg {
    display: flex;
    width: 100%;
  }

  one-ux-popout {
    pointer-events: none;
  }

  .gauge--total {
    fill: var(--one-ux-palette--brand-neutral-600);
  }

  .gauge--label {
    font: var(--one-ux-font--body-200);
    letter-spacing: var(--one-ux-letter-spacing--body-200);
    fill: var(--one-ux-palette--brand-neutral-500);
  }

  .gauge--inline-stats-symbol {
    font: var(--one-ux-font--mono-400);
    letter-spacing: var(--one-ux-letter-spacing--mono-400);
    fill: var(--one-ux-palette--brand-neutral-600);
  }

  .summary--label {
    font: var(--one-ux-font--heading-200);
    letter-spacing: var(--one-ux-letter-spacing--heading-200);
    color: var(--one-ux-rgb--brand-neutral-600);
    text-align: left;
    margin: 0;
  }

  .summary--table {
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    color: var(--one-ux-rgb--brand-neutral-600);
    padding-top: var(--one-ux-spacing--400);
  }

  .summary--table-name {
    white-space: nowrap;
  }

  .summary--table-name one-ux-icon {
    margin-left: calc(var(--one-ux-spacing--200) * -1);
    vertical-align: text-top;
  }

  .summary--table-pcs {
    font-weight: 700;
    text-align: right;
    padding-left: var(--one-ux-spacing--600);
  }

  .summary--table-percent {
    color: var(--one-ux-rgb--brand-neutral-500);
    text-align: right;
  }
`
