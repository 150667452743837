import { DEG_TO_RAD } from './constants'

export const getRotatedBoxSize = (width: number, height: number, degrees: number) => ({
  width: getRotatedBoxWidth(width, height, degrees),
  height: getRotatedBoxHeight(width, height, degrees)
})

export const getRotatedBoxWidth = (width: number, height: number, degrees: number) => {
  const radians = degrees * DEG_TO_RAD

  return width * Math.abs(Math.cos(radians)) + height * Math.abs(Math.sin(radians))
}

export const getRotatedBoxHeight = (width: number, height: number, degrees: number) => {
  const radians = degrees * DEG_TO_RAD

  return height * Math.abs(Math.cos(radians)) + width * Math.abs(Math.sin(radians))
}
