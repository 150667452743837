import { OneUxProgressElement } from './OneUxProgressElement'
import { getLangCode, langCode, LanguageSets } from '@/one-ux/common/utils/getLangCode'

export type language = {
  locale: langCode
  translations: translationSet
}

export type translationSet = {
  progressBar: string
  progressCircle: string
}

export const languages: LanguageSets<translationSet> = {
  en: {
    progressBar: 'Progress bar',
    progressCircle: 'Progress circle'
  },
  sv: {
    progressBar: 'Förloppsindikator',
    progressCircle: 'Förloppscirkel'
  },
  nb: {
    progressBar: 'Fremdriftsindikator',
    progressCircle: 'Fremdriftssirkel'
  },
  fi: {
    progressBar: 'Edistymispalkki',
    progressCircle: 'Edistyminen ympyrä'
  },
  da: {
    progressBar: 'Fremskridtslinje',
    progressCircle: 'Fremskridtscirkel'
  }
}

export const getLanguage = (element: OneUxProgressElement): language => {
  const locale = getLangCode(element)
  const translations = languages[locale]
  return {
    locale,
    translations
  }
}
