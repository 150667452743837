import { Version } from '../versions/Version';
import { Load as LoadAction } from '../actions/Load';
import { Prefetch as PrefetchAction } from '../actions/Prefetch';
import versionFactory from '../versions/Factory';
import { Install as InstallAction } from '../actions/Install';
import { log } from '../log';
import { NullVersion } from '../versions/NullVersion';
let baseUrl = '';
const AUTO_LOAD_THRESHOLD = 100;
export class Microfrontend {
    static setBaseUrl(url) {
        baseUrl = url;
    }
    constructor(config) {
        this.validate(config);
        this.config = config;
        if (typeof this.config.prefetch === 'undefined') {
            this.config.prefetch = true;
        }
        this.tag = config.tag;
        this.version = config.version ? versionFactory.create(config.version) : new NullVersion();
        this.packageName = config.packageName || config.tag;
        this.url = config.url || '';
    }
    patchConfig(mfe) {
        const config = mfe.config;
        if (config.prefetch && !this.config.prefetch) {
            this.config.prefetch = config.prefetch;
        }
        if (config.load && !this.config.load) {
            this.config.load = config.load;
        }
    }
    validate(config) {
        this.validationError(!config.tag, 'tag is a required property');
        this.validationError(!!(config.version && config.url), 'the version and url properties are mutually exclusive');
        this.validationError(!config.version && !config.url, 'either version or url is required');
        this.validationError(!!(!config.url && config.version && !Version.valid(config.version)), 'version string is not valid.');
    }
    validationError(failed, reason) {
        if (failed) {
            throw new Error(`Invalid microfrontend configuration: ${reason}!`);
        }
    }
    compatible(microfrontend) {
        if (this.tag !== microfrontend.tag || this.packageName !== microfrontend.packageName) {
            return false;
        }
        if (this.url || microfrontend.url) {
            return this.url === microfrontend.url;
        }
        return this.version.fulfills(microfrontend.version);
    }
    getUrl() {
        if (this.url) {
            return this.url;
        }
        if (!baseUrl) {
            throw new Error('Base URL must be set before generating a microfrontend URL.');
        }
        return baseUrl.replace('{package}', this.packageName).replace('{version}', this.version.toString());
    }
    prefetchOrAutoLoad() {
        if (this.config.load && !this.loading) {
            const delay = typeof this.config.load === 'number' ? +this.config.load : undefined;
            window.PDR.utils.defer(() => window.PDR.mfe.load({ ...this.config, required: false }), delay);
        }
        const isNotFastAutoLoad = !this.config.load || Number(this.config.load) >= AUTO_LOAD_THRESHOLD;
        const shouldPrefetch = this.config.prefetch && isNotFastAutoLoad && !this.loading && !this.prefetched;
        if (shouldPrefetch) {
            this.prefetch();
        }
    }
    prefetch() {
        if (this.prefetched || this.loading || this.installed) {
            return;
        }
        log.debug(`Prefetching ${this.tag}.`);
        this.prefetchAction = this.prefetchAction || new PrefetchAction(this.tag, this.getUrl());
        this.prefetchAction.execute();
    }
    async load() {
        if (this.installed || this.loading) {
            return;
        }
        log.debug(`Loading ${this.tag}`);
        this.loadAction = this.loadAction || new LoadAction(this.tag, this.getUrl());
        if (this.prefetchAction) {
            this.prefetchAction.abort();
        }
        await this.loadAction.execute();
    }
    install(implementation) {
        if (this.installed) {
            return;
        }
        log.debug(`Installing ${this.tag}`);
        this.installAction = this.installAction || new InstallAction(implementation);
        return this.installAction.execute();
    }
    get installed() {
        return !!this.installAction && this.installAction.completed;
    }
    get implementation() {
        return this.installAction ? this.installAction.implementation : { version: '' };
    }
    get autoLoad() {
        return this.config.load || false;
    }
    get loading() {
        return !!this.installed || !!(this.loadAction && this.loadAction.started);
    }
    get loaded() {
        return !!this.installed || !!(this.loadAction && this.loadAction.completed);
    }
    get prefetched() {
        return !!this.installed || !!(this.prefetchAction && this.prefetchAction.started);
    }
    asObject() {
        const result = {
            tag: this.tag
        };
        if (typeof this.config.required === 'boolean') {
            result.required = this.config.required;
        }
        if (typeof this.config.load !== 'undefined') {
            result.load = this.config.load;
        }
        if (typeof this.config.prefetch === 'boolean') {
            result.prefetch = this.config.prefetch;
        }
        if (this.packageName !== this.tag) {
            result.packageName = this.packageName;
        }
        if (this.version) {
            result.version = this.version.toString();
        }
        else {
            result.url = this.url;
        }
        return result;
    }
    toString() {
        return JSON.stringify(this.asObject(), null, 2);
    }
}
