var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _AccessibilityService_enabled, _AccessibilityService_element, _AccessibilityService_snapshotHandler;
import { log } from '../log';
const storageKey = 'PDR-RUNTIME-DEBUG-ACCESSIBILITY__ENABLED';
export class AccessibilityService {
    constructor() {
        _AccessibilityService_enabled.set(this, false);
        _AccessibilityService_element.set(this, null);
        _AccessibilityService_snapshotHandler.set(this, void 0);
    }
    _init() {
        if (localStorage.getItem(storageKey) === 'true') {
            this.enable();
        }
    }
    async enable() {
        log.deprecation('PDR.debug.accessibility.enable() is deprecated, use PDR.debug.accessibility.snapshot() instead.');
        if (__classPrivateFieldGet(this, _AccessibilityService_enabled, "f") === false) {
            __classPrivateFieldSet(this, _AccessibilityService_element, document.createElement('accessibility-debug'), "f");
            document.body.appendChild(__classPrivateFieldGet(this, _AccessibilityService_element, "f"));
        }
        __classPrivateFieldSet(this, _AccessibilityService_enabled, true, "f");
        localStorage.setItem(storageKey, JSON.stringify(true));
    }
    disable() {
        log.deprecation('PDR.debug.accessibility.disable() is deprecated, use PDR.debug.accessibility.snapshot() instead.');
        if (__classPrivateFieldGet(this, _AccessibilityService_enabled, "f") && __classPrivateFieldGet(this, _AccessibilityService_element, "f") !== null) {
            document.body.removeChild(__classPrivateFieldGet(this, _AccessibilityService_element, "f"));
            __classPrivateFieldSet(this, _AccessibilityService_element, null, "f");
        }
        __classPrivateFieldSet(this, _AccessibilityService_enabled, false, "f");
        localStorage.setItem(storageKey, JSON.stringify(false));
    }
    async snapshot() {
        if (!__classPrivateFieldGet(this, _AccessibilityService_snapshotHandler, "f")) {
            const { AccessibilityTreeSnapshot } = await import('./AccessibilityTreeSnapshot/AccessibilityTreeSnapshot');
            __classPrivateFieldSet(this, _AccessibilityService_snapshotHandler, new AccessibilityTreeSnapshot(), "f");
        }
        __classPrivateFieldGet(this, _AccessibilityService_snapshotHandler, "f").create();
    }
}
_AccessibilityService_enabled = new WeakMap(), _AccessibilityService_element = new WeakMap(), _AccessibilityService_snapshotHandler = new WeakMap();
