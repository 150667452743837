import { observeInline } from '../../sem-2.0/helpers/is-observers'
import { AnimatedCustomElement } from '../AnimatedCustomElement'

export class PdrFieldElement extends AnimatedCustomElement {
  #mouseOver = false
  #previousErrorMessage = ''
  #popoutElement = document.createElement('layout-popout')

  onInitialized() {
    observeInline(this)
    this.#stylePopout()
    this.#attachEventListeners()
  }

  animate() {
    const $popout = this.#popoutElement
    if ($popout.parentElement !== this) {
      this.append($popout)
    }
    const errorMessage = this.getAttribute('error')
    if (errorMessage !== this.#previousErrorMessage) {
      if (errorMessage) {
        $popout.textContent = errorMessage
      } else {
        $popout.hidden = true
      }
      this.#previousErrorMessage = errorMessage
    }

    $popout.hidden = !errorMessage || (!this.contains(document.activeElement) && !this.#mouseOver)

    for (let i = 0; i < this.children.length; ++i) {
      let child = this.children[i]
      if (child !== $popout) {
        child.classList.toggle('is-caution', errorMessage)
        child.classList.toggle('sem-caution', errorMessage)
      }
    }
  }

  #stylePopout() {
    // TODO: sem2 or custom styling?
    this.#popoutElement.classList.add('sem1', 'sem-caution')
  }

  #attachEventListeners() {
    this.addEventListener('mouseover', this.#handleMouseOver)
    this.addEventListener('mouseleave', this.#handleMouseLeave)
  }

  #handleMouseOver = () => {
    this.#mouseOver = true
  }

  #handleMouseLeave = () => {
    this.#mouseOver = false
  }
}
