import { CustomElementBase } from '../CustomElementBase.js'

export class PdrMenuButtonOptionsElement extends CustomElementBase {
  onInitialized() {
    this.setAttribute('role', 'menu')
    this.addEventListener('option-activated', this.#handleOptionActivated)
  }

  clearActiveOptions() {
    this.#getActiveOptions().forEach(($option) => ($option.active = false))
  }

  #handleOptionActivated = ({ target }) => {
    this.#getActiveOptions().forEach(($option) => {
      if ($option !== target) {
        $option.active = false
      }
    })
  }

  #getActiveOptions() {
    return this.querySelectorAll('pdr-menu-button-option[state-active]')
  }
}
