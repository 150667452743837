import { ElementInternalSettings } from './logic/element-internal-settings'
import { cleanupIsInViewport } from './logic/is-in-viewport'
import { CustomElementBase } from '../CustomElementBase'
export class PdrChartElement extends CustomElementBase {
  constructor() {
    super()
    this.__internal__ = new ElementInternalSettings(this)
  }

  onInitialized() {
    this.value = JSON.parse(this.getAttribute('value'))
    this.size = JSON.parse(this.getAttribute('size'))
  }

  onDisconnected() {
    cleanupIsInViewport(this)
  }

  get value() {
    return this.__internal__.value
  }

  set value(value) {
    if (value && typeof value !== 'undefined') {
      this.__internal__.value = typeof value === 'string' ? JSON.parse(value) : value
    }
  }

  get size() {
    return this.__internal__.size
  }

  set size(size) {
    if (size && typeof size !== 'undefined') {
      this.__internal__.size = typeof size === 'string' ? JSON.parse(size) : size
    }
  }
}
