import { render } from 'lit';
import { ToastList } from './components/ToastList';
import * as ToastAnimations from './animations/toast-animations';
import * as ProgressAnimations from './animations/progress-animations';
const toasts = [];
export const add = async (toast) => {
    toasts.unshift(toast);
    update();
    await ToastAnimations.show(toast);
    if (Number.isInteger(toast.duration)) {
        const isProgressCompleted = await ProgressAnimations.animate(toast);
        if (isProgressCompleted) {
            toast.close();
        }
    }
};
export const remove = async (toast) => {
    const index = toasts.indexOf(toast);
    if (index < 0) {
        return;
    }
    toasts.splice(index, 1);
    await ToastAnimations.hide(toast);
    update();
};
export const update = () => {
    const rootId = '__PDR_toast_root__';
    let $root = document.getElementById(rootId);
    if (!$root) {
        $root = document.createElement('div');
        $root.id = rootId;
        $root.classList.add('sem', 'sem-context', 'sem-reset');
        document.body.appendChild($root);
    }
    render(ToastList(toasts), $root);
};
