import { CustomElementBase } from '../CustomElementBase'
export class PdrDashboardElement extends CustomElementBase {
  static get observedAttributes() {
    return ['max-gadget-columns']
  }

  onInitialized() {
    this.setAttribute('role', 'list')
    this.setAttribute('aria-label', 'Dashboard')
  }

  onAttributeRendered() {
    this.style.setProperty('--pdr-dashboard-columns', this.maxGadgetColumns)
  }

  get maxGadgetColumns() {
    return Math.max(parseInt(this.getAttribute('max-gadget-columns')) || 1, 1)
  }

  set maxGadgetColumns(maxGadgetColumns) {
    this.setAttribute('max-gadget-columns', Math.max(parseInt(maxGadgetColumns) || 1, 1))
  }

  get maxGadgetRows() {
    return Math.max(parseInt(this.getAttribute('max-gadget-rows')) || 1, 1)
  }

  set maxGadgetRows(maxGadgetRows) {
    this.setAttribute('max-gadget-rows', Math.max(parseInt(maxGadgetRows) || 1, 1))
  }
}
