export default function debounce(func, delay, immediate) {
  let _immediate = !!immediate
  let _timer

  return (...args) => {
    if (_immediate) {
      func.apply(this, args)
      _immediate = false
      return
    }

    clearTimeout(_timer)
    _timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
