import { KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DOWN } from '../../helpers/keyCodes'
import { addPlaceholderBeforeGadget } from './helpers'

export class MoveHandler {
  #gadgetElement
  #resizeHandleElement
  #verticalScrollingHandler
  #movePlaceholderInsertRequest

  constructor($gadget, $resizeHandle, verticalScrollingHandler) {
    this.#gadgetElement = $gadget
    this.#resizeHandleElement = $resizeHandle
    this.#verticalScrollingHandler = verticalScrollingHandler

    $gadget.addEventListener('keydown', this.#handleKeyDown)
    $gadget.addEventListener('mousedown', this.#handleMouseDown)
  }

  #handleKeyDown = (event) => {
    const $gadget = this.#gadgetElement

    if (event.shiftKey && $gadget.movable) {
      if ([KEY_LEFT, KEY_UP].includes(event.which)) {
        this.#keyboardGadgetMove('beforebegin', $gadget.previousElementSibling, event)
      }
      if ([KEY_RIGHT, KEY_DOWN].includes(event.which)) {
        this.#keyboardGadgetMove('afterend', $gadget.nextElementSibling, event)
      }
    }
  }

  #keyboardGadgetMove = (placement, $targetGadget, event) => {
    const $gadget = this.#gadgetElement

    event.preventDefault()
    if ($targetGadget) {
      $targetGadget.insertAdjacentElement(placement, $gadget)
      $gadget.focus()
      $gadget.dispatchEvent(new CustomEvent('gadget-move'))
    }
  }

  #handleMouseDown = (event) => {
    const $gadget = this.#gadgetElement

    if (event.target == $gadget) {
      // Prevent text selection (leading later on to an interfering drag event) when grabbing gutters
      event.preventDefault()
    }

    // Delay in order to see which element currently has focus
    requestAnimationFrame(() => {
      if (!$gadget.movable || !this.#isValidMouseHandle(event)) {
        return
      }

      const startMouseX = event.clientX
      const startMouseY = event.clientY
      const startRect = $gadget.getBoundingClientRect()
      const $placeholder = addPlaceholderBeforeGadget($gadget)
      let currentMouseX = startMouseX
      let currentMouseY = startMouseY

      $gadget.style.width = startRect.width + 'px'
      $gadget.style.height = startRect.height + 'px'
      $gadget.style.top = startRect.top + 'px'
      $gadget.style.left = startRect.left + 'px'
      $gadget.toggleAttribute('state-moving', true)

      const moving = (event) => {
        currentMouseX = event.clientX || currentMouseX
        currentMouseY = event.clientY || currentMouseY
        const deltaMouseX = currentMouseX - startMouseX
        const deltaMouseY = currentMouseY - startMouseY

        $gadget.style.top = startRect.top + deltaMouseY + 'px'
        $gadget.style.left = startRect.left + deltaMouseX + 'px'

        this.#updatePlaceholderPlacement($placeholder, currentMouseX, currentMouseY)
        this.#verticalScrollingHandler.startIfNeeded(currentMouseY)
      }

      const done = () => {
        // The DOM-manipulation needs to be deferred since it interferes with the dblclick event.
        requestAnimationFrame(() => {
          $placeholder.parentElement.insertBefore($gadget, $placeholder)
          $gadget.focus()

          $gadget.style.width = ''
          $gadget.style.height = ''
          $gadget.style.top = ''
          $gadget.style.left = ''
          $gadget.toggleAttribute('state-moving', false)

          $placeholder.remove()

          $gadget.dispatchEvent(new CustomEvent('gadget-move'))
        })

        cancelAnimationFrame(this.#movePlaceholderInsertRequest)
        this.#verticalScrollingHandler.stop()

        document.removeEventListener('mousemove', moving, true)
        document.removeEventListener('scroll', moving, true)
        document.removeEventListener('mouseup', done, true)
      }

      document.addEventListener('mousemove', moving, true)
      document.addEventListener('scroll', moving, true)
      document.addEventListener('mouseup', done, true)
    })
  }

  #isValidMouseHandle = (event) => {
    return (
      document.activeElement == this.#gadgetElement &&
      event.target !== this.#resizeHandleElement &&
      this.#gadgetElement.contains(event.target.closest('pdr-dashboard-gadget-header'))
    )
  }

  #updatePlaceholderPlacement = ($placeholder, currentMouseX, currentMouseY) => {
    const targetGadget = document
      .elementsFromPoint(currentMouseX, currentMouseY)
      .find((x) => !this.#gadgetElement.contains(x))
      ?.closest('pdr-dashboard-gadget')

    if (targetGadget) {
      const targetGadgetRect = targetGadget.getBoundingClientRect()
      const shouldPlaceBefore = currentMouseX <= targetGadgetRect.left + targetGadgetRect.width / 2

      cancelAnimationFrame(this.#movePlaceholderInsertRequest)
      this.#movePlaceholderInsertRequest = requestAnimationFrame(() => {
        targetGadget.insertAdjacentElement(shouldPlaceBefore ? 'beforebegin' : 'afterend', $placeholder)
      })
    }
  }
}
