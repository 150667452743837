export const ApiErrors = {
    error,
    requestFailed,
    requestCancelled,
    requestErrorResponse
};
const API_ERROR_STATUS = -1;
function requestFailed(errorEvent) {
    const error = new Error('[PDR.api] Request failed');
    error.status = API_ERROR_STATUS;
    error.data = errorEvent;
    return error;
}
const API_CANCELED_STATUS = -2;
function requestCancelled() {
    const error = new Error('[PDR.api] Request cancelled');
    error.status = API_CANCELED_STATUS;
    error.data = error.message;
    return error;
}
function requestErrorResponse(result) {
    const error = new Error(`[PDR.api] Request received with HTTP failure code ${result.status}.`);
    error.status = result.status;
    error.data = result.response;
    error.result = result;
    return error;
}
function error(message) {
    const error = new Error(message);
    error.status = API_ERROR_STATUS;
    error.data = message;
    return error;
}
