import { CustomElementBase } from '../CustomElementBase'
export class InputColorGroupElement extends CustomElementBase {
  onConnected() {
    Array.from(this.childNodes).forEach(($child) => {
      if ($child.nodeType === document.TEXT_NODE) {
        $child.remove()
      }
    })
  }
}
