import { NumberedVersion } from './NumberedVersion';
export class PrereleaseVersion extends NumberedVersion {
    constructor(versionString) {
        super(versionString, 'prerelease');
        this.prerelease = versionString.split('-').slice(1).join('-');
    }
    fulfills(version) {
        if (version instanceof PrereleaseVersion) {
            return super.equal(version) && this.prerelease === version.prerelease;
        }
        return false;
    }
    static matches(versionString) {
        return versionString.includes('-');
    }
}
