import { timingFunction } from '../common/utils/animation-utils'
import { easing as oneUxEasing } from '../generated/design-tokens.json'

export const showAnimation = () => [
  {
    transform: 'scale(0.85)',
    opacity: 0,
    easing: timingFunction(oneUxEasing.default)
  },
  {
    opacity: 1
  }
]

export const hideAnimation = () => [
  {
    display: 'flex',
    opacity: 1,
    easing: timingFunction(oneUxEasing.default)
  },
  {
    transform: 'scale(0.85)',
    opacity: 0
  }
]
