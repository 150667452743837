function createObserver(className) {
  return new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        addClass(mutation.target, className)
      }
    }
  })
}

const isBlockClass = 'is-block'
const isInlineClass = 'is-inline'
function addClass(target, className) {
  const hasBlockClass = target.classList.contains(isBlockClass)
  const hasInlineClass = target.classList.contains(isInlineClass)
  const hasBothClasses = hasBlockClass && hasInlineClass
  const hasNoClasses = !hasBlockClass && !hasInlineClass
  if (hasBothClasses) {
    // Allows overriding the default value
    target.classList.remove(className)
  } else if (hasNoClasses) {
    target.classList.add(className)
  }
}

function generateObserve(observer, className) {
  return function observe(target) {
    addClass(target, className)
    observer.observe(target, {
      attributes: true,
      attributeFilter: ['class']
    })
  }
}
const blockObserver = createObserver(isBlockClass)
export const observeBlock = generateObserve(blockObserver, isBlockClass)

const inlineObserver = createObserver(isInlineClass)
export const observeInline = generateObserve(inlineObserver, isInlineClass)
