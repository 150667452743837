import { createContext } from '@lit/context'
import { listOptions } from './contextual-one-ux-list/types'

export interface IListContext {
  options: listOptions
  multiple: boolean
  value: unknown[]
  setValue(value: unknown[]): void
}

export const listContext = createContext<IListContext>('listContext')
