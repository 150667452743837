import { svg } from 'lit'

export const sigilWidth = 7

export function Sigil({
  weight,
  svgWidth,
  svgHeight
}: {
  weight: 'high' | 'normal' | 'low'
  svgWidth: number
  svgHeight: number
}) {
  return svg`
    <text
      x=${svgWidth - sigilWidth}
      y=${svgHeight - (weight == 'high' ? 5 : weight == 'low' ? 1 : 3)}
      fill="var(--one-ux-palette--brand-neutral-300)"
      class="one-ux-typography--label-variant-100"
    >%</text>
  `
}
