import { serie, serieType } from '../types'
import { getColumnRenderGroups } from '../column/getColumnRenderGroups'
import { getColumnStackedRenderGroups } from '../column-stacked/getColumnStackedRenderGroups'
import { getLineRenderGroups } from '../line-area/line/getLineRenderGroups'
import { RenderGroup, typeSet } from './types'
import { getColumnOverlappedRenderGroups } from '../column-overlapped/getColumnOverlappedRenderGroups'
import { getAreaRenderGroups } from '../line-area/area/getAreaRenderGroups'
import { KeyValueSet } from '../axis/key/KeyValueSet'

export const collectRenderGroups = (series: serie[], keyValueSet: KeyValueSet) => {
  const sets = toSets(series, keyValueSet)
  const groups: RenderGroup[] = []
  const renderOrder: serieType[] = ['column', 'column-stacked', 'column-overlapped', 'area', 'line']

  for (const type of renderOrder) {
    collect(type, sets[type], groups)
  }

  return groups
}

const collect = (type: serieType, set: typeSet, groups: RenderGroup[]) => {
  if (typeof set === 'undefined') {
    return
  }

  switch (type) {
    case 'column':
      groups.push(...getColumnRenderGroups(set))
      break
    case 'column-stacked':
      groups.push(...getColumnStackedRenderGroups(set))
      break
    case 'column-overlapped':
      groups.push(...getColumnOverlappedRenderGroups(set))
      break
    case 'line':
      groups.push(...getLineRenderGroups(set))
      break
    case 'area':
      groups.push(...getAreaRenderGroups(set))
      break
    default:
      throw new Error("Unknown type for a serie detected, can't map...")
  }
}

const toSets = (series: serie[], keyValueSet: KeyValueSet) => {
  const sets = {} as Record<serieType, typeSet>

  for (let i = 0; i < series.length; i++) {
    const serie = series[i]

    if (typeof sets[serie.type] === 'undefined') {
      sets[serie.type] = []
    }

    const group = sets[serie.type]
    for (const datapoint of serie.data) {
      if (keyValueSet.hasKey(datapoint.key)) {
        group.push({
          serie,
          datapoint
        })
      }
    }
  }

  return sets
}
