var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _HttpResult_request;
import { log } from '../log';
export class HttpResult {
    constructor(request) {
        _HttpResult_request.set(this, void 0);
        __classPrivateFieldSet(this, _HttpResult_request, request, "f");
    }
    get headers() {
        return __classPrivateFieldGet(this, _HttpResult_request, "f")
            .getAllResponseHeaders()
            .split('\r\n')
            .reduce((acc, next) => {
            const parts = (next || '').split(':');
            if (parts.length >= 2) {
                acc[parts[0].toLowerCase().trim()] = parts.slice(1).join(':').trim();
            }
            return acc;
        }, {});
    }
    get status() {
        return __classPrivateFieldGet(this, _HttpResult_request, "f").status;
    }
    get successful() {
        return __classPrivateFieldGet(this, _HttpResult_request, "f").status >= 200 && __classPrivateFieldGet(this, _HttpResult_request, "f").status < 300;
    }
    get response() {
        if (__classPrivateFieldGet(this, _HttpResult_request, "f").responseType === 'blob') {
            return __classPrivateFieldGet(this, _HttpResult_request, "f").response;
        }
        const contentType = (__classPrivateFieldGet(this, _HttpResult_request, "f").getResponseHeader('Content-Type') || '').toLowerCase();
        if (contentType.includes('application/zip')) {
            log.warning("Response with header Content-Type: application/zip is parsed to blob, consider passing option responseType: 'blob' for [GET] request");
            return __classPrivateFieldGet(this, _HttpResult_request, "f").response ? new Blob([__classPrivateFieldGet(this, _HttpResult_request, "f").response], { type: 'application/zip' }) : null;
        }
        const responseText = (__classPrivateFieldGet(this, _HttpResult_request, "f").responseText || '').trim();
        if (contentType.includes('application/json')) {
            return responseText ? JSON.parse(responseText) : null;
        }
        if (contentType.includes('text/plain')) {
            return responseText;
        }
        return responseText;
    }
    get responseText() {
        return __classPrivateFieldGet(this, _HttpResult_request, "f").responseText;
    }
}
_HttpResult_request = new WeakMap();
