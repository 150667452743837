import { CancellationToken } from './CancellationToken';
import { HttpResult } from './HttpResult';
import { ApiErrors } from './ApiErrors';
export class HttpClient {
    async send(method, url, { data = null, headers = {}, options = {}, cancellationToken = null } = {}) {
        const isValidCancellationToken = cancellationToken instanceof CancellationToken;
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.open(method, url);
            if (headers) {
                Object.keys(headers).forEach((key) => {
                    request.setRequestHeader(key, headers[key]);
                });
            }
            if (options) {
                if (typeof options.responseType === 'string') {
                    request.responseType = options.responseType;
                }
            }
            if (isValidCancellationToken) {
                const cancel = () => {
                    request.abort();
                    reject(ApiErrors.requestCancelled());
                };
                cancellationToken.addCallback(cancel);
                request.addEventListener('loadend', () => {
                    cancellationToken.removeCallback(cancel);
                });
            }
            request.addEventListener('load', () => {
                const result = new HttpResult(request);
                if (result.successful) {
                    resolve(result);
                }
                else {
                    reject(ApiErrors.requestErrorResponse(result));
                }
            });
            request.addEventListener('error', (error) => {
                reject(ApiErrors.requestFailed(error));
            });
            if (data === null) {
                request.send();
            }
            else if (data instanceof FormData || data instanceof Blob) {
                request.send(data);
            }
            else {
                request.setRequestHeader('Content-Type', 'application/json');
                request.send(JSON.stringify(data));
            }
        });
    }
}
