import { html, nothing, svg } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { transitionTiming } from '../animation'
import { AxisScale } from '../../common/AxisScale'

export function Guides({
  axisScale,
  shadowRoot,
  width,
  tickSize,
  hasHardMin,
  hasHardMax
}: {
  axisScale: AxisScale
  shadowRoot: ShadowRoot
  width: number
  tickSize: number
  hasHardMin: boolean
  hasHardMax: boolean
}) {
  const height = shadowRoot?.querySelector('.js-guides')?.clientHeight || 0
  const ticks = axisScale.ticks.slice(hasHardMin ? 0 : 1, hasHardMax ? undefined : -1)

  if (!width) {
    return nothing
  }

  return html`
    <svg class="js-guides guides">
      ${repeat(
        ticks,
        (tick) => tick,
        (tick) => svg`
          <rect
            fill="var(--one-ux-palette--brand-neutral-200)"
            height=${height}
            width=${tickSize}
            ${svgTransition({
              timing: transitionTiming,
              attributes: {
                x: interpolate.constant(Math.floor(axisScale.position(tick))),
                opacity: interpolate.standard(0, 1, 0)
              }
            })}
          ></rect>
        `
      )}
    </svg>
  `
}
