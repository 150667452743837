import { html, TemplateResult } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { OptionData } from '../../types'
import { Icon } from './Icon'
import { MenuItemOptions } from './MenuItem'
import { hasMouseMovement } from '@/one-ux/common/utils/mouse-helper'

export function Option({
  item,
  currentItem,
  callbacks,
  rootDisabled,
  itemTypeIndex
}: MenuItemOptions<OptionData>): TemplateResult {
  const isActive = item === currentItem
  return html`
    <div
      role="menuitem"
      class="option"
      one-ux-tooltip=${ifDefined(item.tooltip || undefined)}
      aria-disabled=${item.disabled || rootDisabled}
      id=${ifDefined(isActive ? 'active-menu-item' : undefined)}
      pdr-test-hook=${`one-ux-menu-option-${itemTypeIndex}`}
      @click=${() => {
        callbacks.onActivateItem(item)
      }}
      @mouseenter=${() => {
        // Primary way to activate an option
        if (hasMouseMovement() && !item.disabled && !rootDisabled && !isActive) {
          callbacks.onChangeCurrentItem(item, true)
        }
      }}
      @mousemove=${() => {
        // Secondary way to activate an option in enter is not triggered
        // This can for example happen on scroll.
        if (!item.disabled && !rootDisabled && !isActive) {
          callbacks.onChangeCurrentItem(item, true)
        }
      }}
    >
      <div class="menu-item-content">${Icon(item.icon)} ${item.text}</div>
    </div>
  `
}
