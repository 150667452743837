import { bounds, keyValue } from '../../types'
import { Scale } from '../types'
import { KeyValueSet } from './KeyValueSet'

export class KeyScale implements Scale<keyValue> {
  #keyValueSet: KeyValueSet
  #bounds: bounds

  constructor(bounds: bounds, keyValueSet: KeyValueSet) {
    this.#keyValueSet = keyValueSet
    this.#bounds = bounds
  }

  public get bounds() {
    return { ...this.#bounds }
  }

  public position(value: keyValue): number {
    const index = this.#keyValueSet.indexOf(value)
    const keyStep = this.size * index

    return this.#bounds.left + keyStep
  }

  public get size(): number {
    const width = this.#bounds.right - this.#bounds.left
    return width / this.#keyValueSet.length
  }

  public start(value: keyValue): number {
    return this.position(value)
  }

  public center(value: keyValue): number {
    return this.start(value) + this.size / 2
  }

  public end(value: keyValue): number {
    return this.start(value) + this.size
  }

  public get keys() {
    return this.#keyValueSet.keys
  }

  public tick(key: keyValue) {
    return [...this.#keyValueSet.getWrappedKey(key)]
  }

  public getRotatedSize(key: keyValue) {
    return this.#keyValueSet.getRotatedSize(key, this.#keyValueSet.keyRotation)
  }

  public hasKey(key: keyValue) {
    return this.#keyValueSet.hasKey(key)
  }

  public get fontToken() {
    return this.#keyValueSet.fontToken
  }

  public get lineHeight() {
    return this.#keyValueSet.lineHeight
  }

  public get isNumeric() {
    return this.#keyValueSet.isNumeric
  }

  public get isWrapped() {
    return this.#keyValueSet.isWrapped
  }

  public get maxWidthKeySize() {
    return this.#keyValueSet.maxWidthKeySize
  }

  public get keyRotation() {
    return this.#keyValueSet.keyRotation
  }
}
