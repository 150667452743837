import chartType from '../../constants/chartType'
import styling from '../../constants/styling'
import defaultSettings from '../../constants/defaultSettings'

// VALUE
export const getTransitionDuration = (value) => {
  return Number.isInteger(value.transitionDuration) ? value.transitionDuration : defaultSettings.transitionDuration
}
export const getY1 = (value) => value.y || value.y1 || {}
export const getY2 = (value) => value.y2 || {}
export const getTitle = (value) => value.title
export const getShowGuidelines = (value) => (value.showGuidelines !== undefined ? !!value.showGuidelines : true)
export const getUseSISuffix = (value) => (value.useSISuffix !== undefined ? !!value.useSISuffix : true)
export const getPrune = (value) => !!value.prune
export const getRenderWhenInViewport = (value) => !!value.renderWhenInViewport
export const getLocale = (value) => value.locale || 'sv-SE'
export const getDisablePointLabelsOptimization = (value) => value.disablePointLabelsOptimization || false
export const getTooltipAppearance = (value) =>
  ['none', 'single', 'all'].includes(value.tooltipAppearance) ? value.tooltipAppearance : 'single'

// SERIES
export const getSeriesNumber = (value, serie) => value.series.indexOf(serie) + 1
export const getName = (serie) => serie.name
export const getKey = (datapoint) => datapoint.key
export const getTitleFromDataPoint = (datapoint) => datapoint.title
export const getHighlight = (datapoint) => !!datapoint.highlight
export const getValue = (datapoint) => (datapoint.value !== undefined ? datapoint.value : null)
export const getColor = (serie, datapoint) => serie.color || datapoint.color
export const getPointLabels = (serie) => serie.pointLabels
export const getProgressTotal = (serie) => serie.progressTotal
export const getYTarget = (serie) => serie.yTarget || 'y1'

export function getChartTypes(value) {
  return (value.series || []).map((serie) => getChartType(value, serie))
}

export function getChartType(value, serie) {
  // DEPRECATED
  const columnGrouping = {
    beside: 'beside',
    stacked: 'stacked',
    shadow: 'shadow'
  }

  // HANDLE DEPRECATED columnGrouping
  if (serie.type === 'column') {
    switch (value.columnGrouping) {
      case columnGrouping.stacked:
        return chartType.columnStacked
      case columnGrouping.shadow:
        return chartType.columnShadow
      default:
        return chartType.columnBeside
    }
  }

  return serie.type
}

export const getLineStyle = (value, serie) => {
  const lineStyleValue = serie.lineStyle || value.lineStyle
  const style = {
    curved: false,
    dashed: false,
    strokeWidth: styling.size.x2
  }

  if (!lineStyleValue) {
    return style
  }

  // HANDLE DEPRECATED lineStyle
  if (typeof lineStyleValue === 'string') {
    style.curved = lineStyleValue === 'curved'
    return style
  }

  return Object.assign({}, style, lineStyleValue)
}
