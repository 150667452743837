import './idiom.less?manual'

import { on, off, attr, hasAttr } from '../helpers/helpers'
import { KEY_ENTER, KEY_SPACE } from '../helpers/keyCodes'
const { document, requestAnimationFrame } = window

let mouseDown = false
on(
  document,
  'focusin',
  () => {
    document.activeElement &&
      document.activeElement !== window &&
      document.activeElement !== document &&
      attr(document.activeElement, 'clicked-focus', mouseDown ? '' : null)
  },
  true
)
on(
  document,
  'mousedown',
  () => {
    mouseDown = true
  },
  true
)
on(
  document,
  'mouseup',
  () => {
    mouseDown = false
    requestAnimationFrame(() => {
      document.activeElement &&
        document.activeElement !== window &&
        document.activeElement !== document &&
        !hasAttr(document.activeElement, 'clicked-focus') &&
        attr(document.activeElement, 'clicked-focus', '')
    })
  },
  true
)

on(
  document,
  'mousedown',
  (e) => {
    let target = e.target

    if (!elementInSemRoot(target)) {
      return
    }

    if (target.tagName === 'BUTTON') {
      attr(target, 'pressed', '')
      on(
        document,
        'mouseup',
        function mouseup() {
          off(document, 'mouseup', mouseup)
          attr(target, 'pressed', null)
        },
        true
      )
    }
  },
  true
)

on(
  document,
  'keydown',
  (e) => {
    let target = e.target

    if (!elementInSemRoot(target)) {
      return
    }

    if (target.tagName === 'BUTTON' && [KEY_ENTER, KEY_SPACE].indexOf(e.keyCode) > -1) {
      attr(target, 'pressed', '')
      on(
        document,
        'keyup',
        function keyup() {
          off(document, 'keyup', keyup)
          attr(target, 'pressed', null)
        },
        true
      )
    }
  },
  true
)

let selects = document.getElementsByTagName('select') // Use live node list for performance
requestAnimationFrame(function updateSelectPlaceholders() {
  for (let i = 0, n = selects.length; i < n; ++i) {
    let select = selects[i]

    if (!elementInSemRoot(select)) {
      continue
    }

    let statePlaceholder = select.selectedIndex === 0 && attr(select.options[0], 'value') === ''
    if (select.__statePlaceholder__ !== statePlaceholder) {
      select.__statePlaceholder__ = statePlaceholder
      attr(select, 'state-placeholder', statePlaceholder)
    }
  }
  requestAnimationFrame(updateSelectPlaceholders)
})

function elementInSemRoot(element) {
  let sem1Roots = document.getElementsByClassName('sem1') // Use live node list for performance
  for (let i = 0; i < sem1Roots.length; ++i) {
    if (sem1Roots[i].contains(element)) {
      return true
    }
  }

  return false
}
