import { html } from 'lit'
import { componentProps, listGrouping } from '../types'
import { Option } from './Option'

let instanceGenerator = 0

export type optionProps = componentProps & {
  group: listGrouping
  indexRef: { value: number }
}

export const Group = ({ group, indexRef, activeIndex, values, multiple, onChange, onActivate }: optionProps) => {
  const labelId = `group-${instanceGenerator++}`

  return html`<div role="group" aria-labelledby=${labelId} class="group">
    <div role="presentation" id=${labelId} class="group-heading">${group.heading}</div>

    ${group.options.map((entry) => {
      const index = indexRef.value++
      return Option({
        option: entry,
        index,
        activeIndex,
        multiple,
        values,
        onChange,
        onActivate
      })
    })}
  </div>`
}
