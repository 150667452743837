export default {
  line: 'line',
  scatter: 'scatter',
  columnBeside: 'columnBeside',
  columnStacked: 'columnStacked',
  columnShadow: 'columnShadow',
  area: 'area',
  radar: 'radar',
  pie: 'pie',
  donut: 'donut',
  progressDonut: 'progressDonut',
  simpleDistribution: 'simpleDistribution'
}
