import * as helpers from './helpers'

import builders from './builders'
import builderType from './builderType'

const build = (value) => {
  const type = helpers.getBuilderType(value)
  switch (type) {
    case builderType.progressDonut:
      return builders.progressDonut(value)
    case builderType.donut:
      return builders.donut(value)
    case builderType.pie:
      return builders.pie(value)
    case builderType.simpleDistribution:
      return builders.simpleDistribution(value)
    case builderType.axis:
      return builders.axis(value)
    default:
      throw new Error(`<PDR-CHART/> Can't find a valid type for type "${type}" for any series.`)
  }
}

export default {
  build
}
