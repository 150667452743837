import './input-date.css'
import { isDefined, placePopup } from '../../helpers/helpers'
import { KEY_BACKSPACE, KEY_DELETE, KEY_TAB, KEY_ENTER, KEY_SPACE, KEY_A } from '../../helpers/keyCodes'
import { AnimatedCustomElement } from '../AnimatedCustomElement'
import { DateCalculator } from './DateCalculator'
import { TextSelectionManager } from './TextSelectionManager'

let instanceIdGenerator = 0

export class InputDateElement extends AnimatedCustomElement {
  #instanceId = instanceIdGenerator++
  #dateCalculator = new DateCalculator()
  #textSelectionManager = new TextSelectionManager()

  #value = ''
  #valueDirty = false
  #selectTextWhenFocus = true
  #expanded = false

  #currentOptionsYear
  #currentOptionsMonth

  #dynamicPreviewElement = document.createElement('dynamic-input-date-preview')
  #dynamicPopoutContainerElement = document.createElement('dynamic-input-date-popout-container')
  #dynamicPopoutElement = document.createElement('dynamic-input-date-popout')
  #dynamicOptionsHeaderElement = document.createElement('dynamic-input-date-options-header')
  #dynamicOptionsHeaderTextElement = document.createElement('dynamic-input-date-options-header-text')
  #dynamicPrevMonthElement = document.createElement('dynamic-input-date-options-prev-month')
  #dynamicNextMonthElement = document.createElement('dynamic-input-date-options-next-month')
  #dynamicOptionsElement = document.createElement('dynamic-input-date-options')

  onInitialized() {
    if (!this.#valueDirty) {
      this.#value = this.#dateCalculator.parseDateValue(this.defaultValue)
    }

    this.#renderDynamicElements()
    this.#associateWithLabelAncestor()
    this.#setPreviewText(this.value)
    this.#resetCurrentOptionsYearAndMonth()
    this.#update()
    this.#attachEvents()

    this.setAttribute('inputmode', 'numeric')
    this.setAttribute('aria-multiline', false)
    this.shouldAnimate = false
  }

  static get observedAttributes() {
    return ['disabled', 'max', 'min', 'placeholder', 'type', 'value']
  }

  onAttributeChanged() {
    this.#update()
  }

  #prevLeft
  #prevTop
  animate() {
    const { left, top } = placePopup(this.#dynamicPopoutElement, this, {
      prevLeft: this.#prevLeft,
      prevTop: this.#prevTop
    })
    this.#prevLeft = left
    this.#prevTop = top
  }

  get defaultValue() {
    return this.getAttribute('value') || ''
  }

  set defaultValue(value) {
    if (isDefined(value)) {
      this.setAttribute('value', value)
    }
  }

  get disabled() {
    return this.hasAttribute('disabled')
  }

  set disabled(value) {
    if (isDefined(value)) {
      this.toggleAttribute('disabled', value)
      this.#update()
    }
  }

  get emptyDate() {
    return this.#dateCalculator.parseDateValue(this.getAttribute('empty-date'))
  }

  set emptyDate(value) {
    if (isDefined(value)) {
      this.setAttribute('empty-date', this.#dateCalculator.parseDateValue(value))
      this.#resetCurrentOptionsYearAndMonth()
      this.#update()
    }
  }

  get expanded() {
    return this.#expanded
  }

  set expanded(value) {
    if (this.type !== 'dropdown') {
      return
    }

    if (value) {
      if (!this.disabled) {
        this.#expanded = true
        this.shouldAnimate = true
        this.#resetCurrentOptionsYearAndMonth()
        this.#update()
      }
    } else {
      this.#expanded = false
      this.shouldAnimate = false
      this.#update()
    }
  }

  get max() {
    return this.#dateCalculator.parseDateValue(this.getAttribute('max'))
  }

  set max(value) {
    if (isDefined(value)) {
      this.setAttribute('max', this.#dateCalculator.parseDateValue(value))
      this.#update()
    }
  }

  get min() {
    return this.#dateCalculator.parseDateValue(this.getAttribute('min'))
  }

  set min(value) {
    if (isDefined(value)) {
      this.setAttribute('min', this.#dateCalculator.parseDateValue(value))
      this.#update()
    }
  }

  get placeholder() {
    return this.getAttribute('placeholder')
  }

  set placeholder(value) {
    if (isDefined(value)) {
      this.setAttribute('placeholder', value)
      this.#update()
    }
  }

  // TODO: Remove
  get required() {
    return this.hasAttribute('required')
  }

  // TODO: Remove
  set required(value) {
    if (isDefined(value)) {
      this.toggleAttribute('required', value)
      this.#update()
    }
  }

  get type() {
    return (this.getAttribute('type') || '').toLowerCase() == 'box' ? 'box' : 'dropdown'
  }

  set type(value) {
    if (isDefined(value)) {
      this.setAttribute('type', value)
      this.#update()
    }
  }

  get value() {
    return this.#value
  }

  set value(value) {
    if (isDefined(value)) {
      this.#value = this.#dateCalculator.parseDateValue(value)
      this.#valueDirty = true
      this.#setPreviewText(this.value)
      this.#resetCurrentOptionsYearAndMonth()
      this.#update()
    }
  }

  get selectionStart() {
    const startOffset = this.#textSelectionManager.getSelectionStartOffsetWithinNode(this.#dynamicPreviewElement)
    return startOffset != null
      ? startOffset - 1 // Ignore zero-width space
      : this.#getPreviewText().length
  }

  set selectionStart(value) {
    if (isDefined(value)) {
      this.setSelectionRange(value, this.selectionEnd)
    }
  }

  get selectionEnd() {
    const endOffset = this.#textSelectionManager.getSelectionEndOffsetWithinNode(this.#dynamicPreviewElement)
    return endOffset != null
      ? endOffset - 1 // Ignore zero-width space
      : this.#getPreviewText().length
  }

  set selectionEnd(value) {
    if (isDefined(value)) {
      this.setSelectionRange(this.selectionStart, value)
    }
  }

  select() {
    this.setSelectionRange(0, this.#getPreviewText().length)
  }

  setRangeText(replacement, start, end) {
    if (arguments.length === 2) {
      start = this.selectionStart
      end = this.selectionEnd
    }

    if (start > end) {
      const swapTemp = start
      start = end
      end = swapTemp
    }

    const previewText = this.#getPreviewText()
    this.#setPreviewText(previewText.substring(0, start) + replacement + previewText.substring(end, previewText.length))
    const offset = start + replacement.length

    // Which case/browser needs double cursor manipulation?
    this.setSelectionRange(offset, offset)
    requestAnimationFrame(() => {
      this.setSelectionRange(offset, offset)
    })
  }

  setSelectionRange(selectionStart, selectionEnd) {
    this.#setPreviewText(this.#dynamicPreviewElement.textContent) // Ensure Zero-width space
    this.#textSelectionManager.setSelectionForNode(
      this.#dynamicPreviewElement.lastChild,
      selectionStart + 1, // Ignore zero-width space
      selectionEnd + 1 // Ignore zero-width space
    )
  }

  #renderDynamicElements() {
    this.#dynamicOptionsHeaderElement.append(
      this.#dynamicPrevMonthElement,
      this.#dynamicOptionsHeaderTextElement,
      this.#dynamicNextMonthElement
    )

    this.#dynamicPopoutElement.append(this.#dynamicOptionsHeaderElement, this.#dynamicOptionsElement)

    this.#dynamicPopoutContainerElement.append(this.#dynamicPopoutElement)
    this.#dynamicPopoutContainerElement.setAttribute('aria-hidden', true)
    this.#dynamicPopoutContainerElement.contentEditable = false

    this.append(this.#dynamicPreviewElement, this.#dynamicPopoutContainerElement)
  }

  #associateWithLabelAncestor() {
    const defaultLabelId = 'input-date__label-' + this.#instanceId

    const prevLabel = document.querySelector('#' + defaultLabelId)
    if (prevLabel) {
      prevLabel.removeAttribute('id')
    }

    const label = this.closest('label')
    if (label) {
      label.id = label.id || defaultLabelId
      this.setAttribute('aria-labelledby', label.id)
    } else {
      this.removeAttribute('aria-labelledby')
    }
  }

  #attachEvents() {
    this.addEventListener('focus', this.#handleFocus)
    this.addEventListener('blur', this.#handleBlur)
    this.addEventListener('mousedown', this.#handleMouseDown)
    this.addEventListener('click', this.#handleClick, true)
    this.addEventListener('keydown', this.#handleKeyDown)
    this.addEventListener('paste', this.#handlePaste)

    this.addEventListener('drop', (e) => {
      e.preventDefault()
    })

    this.addEventListener('drag', (e) => {
      e.preventDefault()
    })

    this.#dynamicPopoutContainerElement.addEventListener('mousedown', (e) => {
      e.preventDefault() // Figure out why and place comment here. Prevent change of focus/selection?
      if (document.activeElement !== this) {
        requestAnimationFrame(() => {
          this.focus()
          this.#setCursorEnd()
        })
      }
    })
  }

  #handleFocus = () => {
    this.contentEditable = !this.disabled
    if (this.disabled) {
      return
    }

    this.expanded = true

    requestAnimationFrame(() => {
      this.#ensureTextSelectionWithinPreview()

      if (this.#selectTextWhenFocus) {
        this.select()
      }
    })
  }

  #handleBlur = () => {
    this.contentEditable = false
    this.#selectTextWhenFocus = true

    this.#updateValueFromPreview()
    this.#setPreviewText(this.value)

    this.expanded = false
  }

  #handleMouseDown = () => {
    this.#selectTextWhenFocus = false
    this.contentEditable = !this.disabled
  }

  #handleClick = (e) => {
    if (!this.disabled && this.#dynamicPrevMonthElement.contains(e.target)) {
      if (this.#currentOptionsMonth > 1) {
        this.#currentOptionsMonth--
      } else {
        this.#currentOptionsYear--
        this.#currentOptionsMonth = 12
      }
      this.#update()
    }

    if (!this.disabled && this.#dynamicNextMonthElement.contains(e.target)) {
      if (this.#currentOptionsMonth < 12) {
        this.#currentOptionsMonth++
      } else {
        this.#currentOptionsYear++
        this.#currentOptionsMonth = 1
      }
      this.#update()
    }

    if (e.target.tagName === 'DYNAMIC-INPUT-DATE-OPTION') {
      if (!this.disabled && e.target.getAttribute('disabled') == null) {
        this.value = e.target.getAttribute('value')
        this.#setCursorEnd()

        this.expanded = false
        this.#update()

        this.dispatchEvent(new CustomEvent('input'))
        this.dispatchEvent(new CustomEvent('change'))
      }
    }

    if (e.target === this || e.target === this.#dynamicPreviewElement) {
      this.expanded = true
    }
  }

  #handleKeyDown = (e) => {
    this.#ensureTextSelectionWithinPreview()
    const selection = document.getSelection()

    this.expanded = true

    switch (e.which) {
      case KEY_BACKSPACE:
        if (selection.focusOffset === 1 && selection.anchorOffset === 1) {
          e.preventDefault()
        }
        break
      case KEY_DELETE:
        if (
          selection.focusOffset === selection.focusNode.length &&
          selection.anchorOffset === selection.focusNode.length
        ) {
          e.preventDefault()
        }
        break
      case KEY_ENTER:
      case KEY_SPACE:
        e.preventDefault()
        break
      case KEY_A:
        if (e.ctrlKey) {
          e.preventDefault()
          this.select()
        }
        break
      default:
        if (e.which !== KEY_TAB) {
          requestAnimationFrame(() => {
            this.#ensureTextSelectionWithinPreview()
          })
        }
    }

    requestAnimationFrame(() => {
      this.scrollLeft = 0
      this.scrollTop = 0
      this.#updateValueFromPreview()
      this.#update() // Ensure placeholder is rendered correctly
    })
  }

  #handlePaste = (e) => {
    e.preventDefault()
    this.#ensureTextSelectionWithinPreview()
    this.setRangeText((e.clipboardData || window.clipboardData).getData('text'))
  }

  #update() {
    this.setAttribute('aria-disabled', this.disabled)
    this.setAttribute('aria-expanded', this.#expanded)
    this.setAttribute('contenteditable', document.activeElement === this && !this.disabled)

    if (!this.disabled) {
      this.setAttribute('tabindex', 0)
    } else {
      this.removeAttribute('tabindex')
    }

    if (!this.#getPreviewText()) {
      this.#dynamicPreviewElement.setAttribute('placeholder', this.placeholder || '')
    } else {
      this.#dynamicPreviewElement.removeAttribute('placeholder')
    }

    if (this.type === 'box' || this.#expanded) {
      this.#updateOptions()
    }
  }

  #setPreviewText(text) {
    text = text || ''
    this.#dynamicPreviewElement.textContent = text[0] !== '\u200B' ? '\u200B' + text : text
  }

  #resetCurrentOptionsYearAndMonth() {
    const [year, month] = (
      this.value ||
      this.#dateCalculator.clamp(this.emptyDate || this.#dateCalculator.getISODate(new Date()), this.min, this.max)
    ).split('-')

    this.#currentOptionsYear = year
    this.#currentOptionsMonth = month
  }

  #updateValueFromPreview() {
    const previewText = this.#getPreviewText()
    if (previewText) {
      const currentPreviewDate = this.#dateCalculator.parseDateValue(previewText)
      if (
        currentPreviewDate &&
        this.#dateCalculator.checkValidDateValueRange(currentPreviewDate, this.min, this.max) &&
        currentPreviewDate !== this.value
      ) {
        this.#setValueAndPreservePreviewText(currentPreviewDate)
      }
    } else if (this.value !== '') {
      this.#setValueAndPreservePreviewText('')
    }
  }

  #setValueAndPreservePreviewText(value) {
    // element.value will reset preview text and cursor incorrectly, using internal instead
    this.#value = value
    this.#resetCurrentOptionsYearAndMonth()
    this.#update()

    this.dispatchEvent(new CustomEvent('input'))
    this.dispatchEvent(new CustomEvent('change'))
  }

  #updateOptions() {
    const year = this.#currentOptionsYear
    const month = this.#currentOptionsMonth
    const daysInMonth = new Date(year, month, 0).getDate()
    const firstWeekdayInMonth = new Date(year, month - 1, 1).getDay()
    const firstWeekdayOffset = 1 // Can offset be calculated from locale?
    const currentISODate = this.#dateCalculator.getISODate(new Date())

    const langElement = this.closest('[lang]')
    const locale = langElement ? langElement.getAttribute('lang') : undefined
    const monthsLang = this.#dateCalculator.getMonthsForLocale(locale)
    const weekdaysLang = this.#dateCalculator.getWeekdaysForLocale(locale)

    this.#dynamicOptionsHeaderTextElement.textContent = `${year} ${monthsLang[month - 1]}`
    this.#dynamicOptionsElement.textContent = ''

    for (var i = 0; i < 7; ++i) {
      const weekday = document.createElement('dynamic-input-date-options-weekday')
      weekday.textContent = weekdaysLang[(i + firstWeekdayOffset) % 7]
      this.#dynamicOptionsElement.append(weekday)
    }

    for (let i = 0; i < daysInMonth; ++i) {
      const option = document.createElement('dynamic-input-date-option')
      const day = i + 1
      const ISODate = this.#dateCalculator.getISODate(new Date(year, month - 1, day))
      option.textContent = day
      option.setAttribute('value', ISODate)
      option.toggleAttribute('current-date', ISODate === currentISODate)
      option.toggleAttribute('selected', ISODate === this.value)
      option.toggleAttribute('disabled', !this.#dateCalculator.checkValidDateValueRange(ISODate, this.min, this.max))
      if (i === 0) {
        const indent = (((firstWeekdayInMonth - firstWeekdayOffset) % 7) + 7) % 7
        if (indent >= 1) {
          option.setAttribute('indent', indent)
        } else {
          option.removeAttribute('indent')
        }
      }
      this.#dynamicOptionsElement.append(option)
    }
  }

  #ensureTextSelectionWithinPreview() {
    const selection = document.getSelection()
    const $preview = this.#dynamicPreviewElement

    if (
      (selection.anchorNode &&
        !($preview.compareDocumentPosition(selection.anchorNode) & document.DOCUMENT_POSITION_CONTAINED_BY)) ||
      (selection.focusNode &&
        !($preview.compareDocumentPosition(selection.focusNode) & document.DOCUMENT_POSITION_CONTAINED_BY))
    ) {
      if (selection.focusNode === this) {
        if (selection.focusOffset === 0) {
          this.#setCursorStart()
        } else {
          this.#setCursorEnd()
        }
      } else {
        if (selection.focusNode.compareDocumentPosition($preview) & document.DOCUMENT_POSITION_FOLLOWING) {
          this.#setCursorStart()
        } else {
          this.#setCursorEnd()
        }
      }
    } else if (selection.focusOffset < 1) {
      this.#setCursorStart()
    }
  }

  #setCursorStart() {
    this.setSelectionRange(0, 0)
  }

  #setCursorEnd() {
    const endOffset = this.#getPreviewText().length
    this.setSelectionRange(endOffset, endOffset)
  }

  #getPreviewText() {
    return this.#dynamicPreviewElement.textContent.trim().replace(/\u200B/g, '')
  }
}
