import { PDRModule } from '../PDRModule';
import { CallbackQueue } from './CallbackQueue';
const poolLimit = 256;
const messageChannelPool = [];
export class UtilsModule extends PDRModule {
    lang() {
        return document.documentElement.getAttribute('lang') || 'en';
    }
    defer(operation, delay) {
        if (typeof delay === 'number') {
            setTimeout(operation, delay);
        }
        else {
            const channel = messageChannelPool.length ? messageChannelPool.pop() : new MessageChannel();
            channel.port1.onmessage = function () {
                try {
                    operation();
                }
                finally {
                    channel.port1.onmessage = null;
                    if (messageChannelPool.length <= poolLimit) {
                        messageChannelPool.push(channel);
                    }
                }
            };
            channel.port2.postMessage('');
        }
    }
    createQueue() {
        return new CallbackQueue();
    }
    download(target, fileName = 'download') {
        const url = getUrl(target);
        const $anchor = document.createElement('a');
        $anchor.setAttribute('href', url);
        $anchor.setAttribute('download', fileName);
        $anchor.setAttribute('aria-hidden', 'true');
        $anchor.style.display = 'none';
        document.body.appendChild($anchor);
        $anchor.click();
        if (target instanceof Blob) {
            window.URL.revokeObjectURL(url);
        }
        document.body.removeChild($anchor);
    }
}
function getUrl(target) {
    let url = '';
    if (target instanceof Blob) {
        url = window.URL.createObjectURL(target);
    }
    else if (target instanceof URL) {
        url = target.href;
    }
    else if (typeof target === 'string') {
        url = target;
    }
    if (!url.length) {
        throw new Error("Parameter 'target' couldn't be parsed to a valid url.");
    }
    return url;
}
