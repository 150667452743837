import { CustomElementBase } from '../CustomElementBase'

export class InputSelectPopupElement extends CustomElementBase {
  onInitialized() {
    this.setAttribute('role', 'listbox')
    this.addEventListener('click', (event) => event.stopPropagation())
    this.addEventListener('#option-filtered', this.#handleOptionFiltered, true)
  }

  get multiple() {
    return this.hasAttribute('aria-multiselectable')
  }

  set multiple(multiple) {
    if (multiple) {
      this.setAttribute('aria-multiselectable', true)
    } else {
      this.removeAttribute('aria-multiselectable')
    }
  }

  #searchEmptyText
  get searchEmptyText() {
    return this.#searchEmptyText
  }

  set searchEmptyText(searchEmptyText) {
    this.#searchEmptyText = searchEmptyText
  }

  get #searchEmpty() {
    return this.getAttribute('state-search-empty')
  }

  set #searchEmpty(searchEmpty) {
    if (searchEmpty) {
      this.setAttribute('aria-live', 'assertive')
      this.setAttribute('state-search-empty', this.#searchEmptyText)
    } else {
      this.removeAttribute('aria-live')
      this.removeAttribute('state-search-empty')
    }
  }

  #updateFilteredRequest
  #handleOptionFiltered = () => {
    cancelAnimationFrame(this.#updateFilteredRequest)
    this.#updateFilteredRequest = requestAnimationFrame(() => {
      this.#searchEmpty = !this.querySelector('input-select-option:not([state-filtered])')
    })
  }
}
