export class TextSelectionManager {
  setSelectionForNode(node, start, end) {
    const range = document.createRange()
    range.setStart(node, start)
    range.setEnd(node, end)

    const selection = document.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }

  getSelectionStartOffsetWithinNode(node) {
    return this.#getSelectionOffsetWithinNode(node, 'anchor')
  }

  getSelectionEndOffsetWithinNode(node) {
    return this.#getSelectionOffsetWithinNode(node, 'focus')
  }

  #getSelectionOffsetWithinNode(node, offsetType) {
    const nodeProperty = offsetType + 'Node'
    const offsetProperty = offsetType + 'Offset'

    const selection = document.getSelection()
    return selection[nodeProperty] &&
      !!(node.compareDocumentPosition(selection[nodeProperty]) & document.DOCUMENT_POSITION_CONTAINED_BY)
      ? selection[offsetProperty]
      : null
  }
}
