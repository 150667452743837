import { OneUxTypographyToken } from '@/one-ux/generated/design-tokens'
import { select, Selection } from 'd3-selection'
import { pieces } from '../../common/format'
import { getTextSize } from '../../common/getTextSize'
import { getTypography } from '../../common/typography'
import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalData } from '../types'
import { getArcsCenter, getArcsInnerRadius, getScaleFactor, isHiddenVisual } from '../utils'

export const renderTotal = (host: OneUxGaugeChartElement, data: InternalData) => {
  const svgSelection = select(host.$svg)

  let textSelection = svgSelection.select<SVGTextElement>('.gauge--total')
  if (isHiddenVisual(host, 'text-total')) {
    textSelection.remove()
    return
  }

  if (textSelection.empty()) {
    textSelection = svgSelection.append('text')
  }

  textSelection
    .attr('class', 'gauge--total')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', isHiddenVisual(host, 'text-label') ? 'middle' : ' auto')
    .attr('transform', getLabelTransform(host))

  const tokens = getTotalTextScaledTypographyToken(host, data)
  renderTotalText(data, textSelection, tokens[0])
  renderPiecesText(host, textSelection, tokens[1])
}

const renderTotalText = (
  data: InternalData,
  textSelection: Selection<SVGTextElement, unknown, null, undefined>,
  token: OneUxTypographyToken
) => {
  let totalSubtext = textSelection.select<SVGTSpanElement>('tspan.total')
  if (totalSubtext.empty()) {
    totalSubtext = textSelection.append('tspan').attr('class', 'total')
  }

  totalSubtext
    .style('font', `var(--one-ux-font--${token})`)
    .style('letter-spacing', `var(--one-ux-letter-spacing--${token})`)
    .text(`${data.totalValue} `)
}

const renderPiecesText = (
  host: OneUxGaugeChartElement,
  textSelection: Selection<SVGTextElement, unknown, null, undefined>,
  token: OneUxTypographyToken
) => {
  let pcsSubtext = textSelection.select<SVGTSpanElement>('tspan.pcs')
  if (pcsSubtext.empty()) {
    pcsSubtext = textSelection.append('tspan').attr('class', 'pcs')
  }
  pcsSubtext
    .style('font', `var(--one-ux-font--${token})`)
    .style('letter-spacing', `var(--one-ux-letter-spacing--${token})`)
    .text(pieces(host._locale))
}

const getLabelTransform = (host: OneUxGaugeChartElement) => {
  const center = getArcsCenter(host)
  const scaleFactor = Math.max(1, getScaleFactor(host) / 2)
  return `translate(${center[0]}, ${center[1]}) scale(${scaleFactor})`
}

const getTotalTextScaledTypographyToken = (host: OneUxGaugeChartElement, data: InternalData) => {
  const tokens: OneUxTypographyToken[][] = [
    ['heading-500', 'heading-variant-500'],
    ['heading-400', 'heading-variant-400'],
    ['heading-300', 'heading-variant-300'],
    ['heading-200', 'heading-variant-200'],
    ['heading-100', 'heading-variant-100']
  ]

  for (const token of tokens) {
    if (getTextToInnerDiameterRatio(host, data, token[0]) < 0.5) {
      return token
    }
  }

  return tokens[tokens.length - 1]
}

const getTextToInnerDiameterRatio = (host: OneUxGaugeChartElement, data: InternalData, token: OneUxTypographyToken) => {
  const text = `${data.totalValue} ${pieces(host._locale)}`
  const typography = getTypography(token)
  const textSize = getTextSize(text, typography.font, typography.letterSpacing)
  const innerRadius = getArcsInnerRadius(host, data)

  return textSize.width / (innerRadius * 2)
}
