import { getTextSize } from '../../common/getTextSize'
import { getTypography } from '../../common/typography'

export const calculatePointLabelRect = (
  text: string,
  x: number,
  y: number,
  dx: number,
  dy: number,
  textAnchor: 'middle' | 'start' | 'end',
  dominantBaseline: 'auto' | 'middle' | 'hanging'
): DOMRect => {
  const { font, letterSpacing } = getTypography('mono-100')
  const { width, fontHeight } = getTextSize(text, font, letterSpacing)

  let textAnchorOffsetX = 0
  switch (textAnchor) {
    case 'middle':
      textAnchorOffsetX = width / 2
      break
    case 'end':
      textAnchorOffsetX = width
      break
  }

  let dominantBaselineOffsetY = 0
  switch (dominantBaseline) {
    case 'auto':
      dominantBaselineOffsetY = -fontHeight
      break
    case 'middle':
      dominantBaselineOffsetY = -fontHeight / 2
      break
    case 'hanging':
      dominantBaselineOffsetY = fontHeight / 2
      break
  }

  const left = x + dx + textAnchorOffsetX
  const top = y + dy + dominantBaselineOffsetY

  return new DOMRect(left, top, width, fontHeight)
}
