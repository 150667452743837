export const slope = (x0: number, y0: number, x1: number, y1: number) => (y1 - y0) / (x1 - x0)

export const lerpX = (x0: number, y0: number, x1: number, y1: number, knownY: number) =>
  slopeLerpX(x0, y0, slope(x0, y0, x1, y1), knownY)

export const lerp = (x0: number, y0: number, x1: number, y1: number, t: number) => {
  const s = 1 - t
  return [x0 * s + x1 * t, y0 * s + y1 * t]
}

export const slopeLerpX = (x0: number, y0: number, slope: number, knownY: number) => (knownY - y0) / slope + x0
export const slopeLerpY = (x0: number, y0: number, slope: number, knownX: number) => slope * (knownX - x0) + y0

export const lerpY = (x0: number, y0: number, x1: number, y1: number, knownX: number) =>
  slopeLerpY(x0, y0, slope(x0, y0, x1, y1), knownX)

export const angle = (x0: number, y0: number, x1: number, y1: number) => Math.atan2(y1 - y0, x1 - x0)

export const distance = (x0: number, y0: number, x1: number, y1: number) => {
  const a = x0 - x1
  const b = y0 - y1
  const result = Math.sqrt(a * a + b * b)
  if (!Number.isFinite(result)) {
    return 0
  }
  return result
}
