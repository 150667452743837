const spacing = 40

export const getValueTicks = (
  height: number,
  minValue: number,
  maxValue: number,
  minLimitValue?: number,
  maxLimitValue?: number
): number[] => {
  const ticks = []

  const { tickStepAmount, minTickValue, maxTickValue } = calculateTickStep(
    height,
    minValue,
    maxValue,
    minLimitValue,
    maxLimitValue
  )
  const steps = Math.floor((maxTickValue - minTickValue) / tickStepAmount)

  if (Number.isFinite(minLimitValue)) {
    ticks.push(Number(minLimitValue))
  }

  for (let i = 0; i <= steps; i++) {
    const tickValue = minTickValue + i * tickStepAmount

    const isLowerOk = !Number.isFinite(minLimitValue) || tickValue > Number(minLimitValue)
    const isUpperOk = !Number.isFinite(maxLimitValue) || tickValue < Number(maxLimitValue)

    if (isLowerOk && isUpperOk) {
      ticks.push(tickValue)
    }
  }

  if (Number.isFinite(maxLimitValue)) {
    ticks.push(Number(maxLimitValue))
  }

  return ticks
}

const calculateTickStep = (
  height: number,
  minValue: number,
  maxValue: number,
  minLimitValue?: number,
  maxLimitValue?: number
) => {
  const min = minLimitValue ?? minValue
  const max = maxLimitValue ?? maxValue

  const valueDiff = Math.abs(max - min)
  const tickValue = valueDiff / estimatedTickCount(height)
  const tickStepAmount = calculateTickStepAmount(tickValue)
  const minTickValue = Math.floor(min / tickStepAmount) * tickStepAmount
  const maxTickValue = Math.ceil(max / tickStepAmount) * tickStepAmount

  return {
    tickStepAmount,
    minTickValue,
    maxTickValue
  }
}

const calculateTickStepAmount = (value: number) => {
  const baseValue = Math.pow(10, Math.floor(Math.log10(value)))
  let factor = value / baseValue
  if (factor < 1) {
    factor = 1
  } else if (factor < 2) {
    factor = 2
  } else if (factor < 5) {
    factor = 5
  } else {
    factor = 10
  }

  return Math.ceil(factor) * baseValue
}

const estimatedTickCount = (height: number) => Math.max(2, Math.abs(Math.floor(height / spacing)))
