import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: middle;
  }

  .one-ux-element--root {
    display: flex;
    flex-direction: column;
  }

  .label {
    font: var(--one-ux-font--label-400);
    letter-spacing: var(--one-ux-letter-spacing--label-400);
    color: var(--contextual-one-ux-field-element--label-color);
  }

  .asterisk {
    color: var(--one-ux-palette--red-500);
  }

  .field-set {
    --contextual-one-ux-field-set-element--border-width: var(--one-ux-border-width--500);

    display: grid;
    padding: calc(var(--one-ux-spacing--normal) - var(--contextual-one-ux-field-set-element--border-width));
    gap: 0 var(--one-ux-spacing--large);
    background: var(--one-ux-palette--gray-100);
    border: var(--contextual-one-ux-field-set-element--border-width) solid var(--one-ux-palette--brand-neutral-300);
    border-radius: var(--one-ux-radius--300);

    position: relative;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--400);
  }

  .field-set:after {
    content: '';
    display: block;
    position: absolute;
    left: calc(0px - var(--contextual-one-ux-field-set-element--border-width));
    width: var(--contextual-one-ux-field-set-element--border-width);

    background: inherit;
    background-repeat: no-repeat;
    background-position-y: var(--one-ux-spacing--smaller);
    background-size: 100% calc(100% - 2 * var(--one-ux-spacing--smaller));

    top: var(--one-ux-spacing--small);
    bottom: var(--one-ux-spacing--small);

    opacity: 0;
    transition-property: opacity, background;
    transition-duration: var(--one-ux-duration--400);

    background-image: linear-gradient(
      var(--one-ux-palette--brand-neutral-300),
      var(--one-ux-palette--brand-neutral-300)
    );
  }

  .field-set.error:after {
    opacity: 1;
    background-image: linear-gradient(var(--one-ux-palette--red-400), var(--one-ux-palette--red-400));
  }

  :host(:not([disabled]):not(:is(:hover, :focus-within))) .errors-popout {
    visibility: hidden;
    pointer-events: none;
  }

  .field-set ::slotted(*) {
    min-width: 100% !important;
    box-sizing: border-box !important;

    grid-column: var(--contextual-one-ux-field-set-element--grid-column);
    grid-row: var(--contextual-one-ux-field-set-element--grid-row);
  }

  :host([implicit]) .field-set {
    background: none;
    border-color: transparent;
  }

  :host([implicit]) .field-set.error {
    background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.03);
  }

  :host([state-keyboard-focus]) .field-set:focus-within {
    background: var(--one-ux-palette--keyboard-focus-100) !important;
    border-color: var(--one-ux-palette--keyboard-focus-400) !important;
  }
`
