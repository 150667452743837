import { SpecialVersion } from './SpecialVersion';
const keyword = 'latest';
export class LatestVersion extends SpecialVersion {
    constructor() {
        super(keyword);
    }
    fulfills(version) {
        return version instanceof LatestVersion;
    }
    static matches(versionString) {
        return versionString === keyword;
    }
}
