import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { StyledFactory } from '../common/mixins/Styled'
import { OneUxElement } from '../common/OneUxElement'
import { style } from './style'
import { IValue, ValueFactory } from '../common/mixins/Value'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../common/mixins/Validated'
import { Placeholder } from '../common/mixins/Placeholder'
import { Focusable } from '../common/mixins/Focusable'
import { ifDefined } from 'lit/directives/if-defined.js'
import { IRequired, Required } from '../common/mixins/Required'
import { Disabled } from '../common/mixins/Disabled'
import { Label } from '../common/mixins/Label'
import { Implicit } from '../common/mixins/Implicit'
import { Weight } from '../common/mixins/Weight'
import { Compact } from '../common/mixins/Compact'
import { Errors } from '../common/mixins/Errors'
import { classMap } from 'lit/directives/class-map.js'
import type { OneUxEditableTextElement } from '../one-ux-editable-text/OneUxEditableTextElement'
import { FormAssociated } from '../common/mixins/FormAssociated'

const Styled = StyledFactory(style)
type valueType = string
const Value = ValueFactory<valueType>({
  type: String
})

const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldIsRequired } = getFormValidationLanguage(this)
    const valid = hasValue(this.value)
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldIsRequired]
    }
  }
})
const BaseClass = FormAssociated(
  Errors(
    Weight(Implicit(Compact(Label(Disabled(Required(Validated(Value(Placeholder(Focusable(Styled(OneUxElement)))))))))))
  )
)

function hasValue(value: unknown) {
  return typeof value === 'string' ? !!value : value != null
}

const DEFAULT_LINE_COUNT = 3
const AUTO_SIZE_LINE_COUNT = 2.5

/**
 * An input component for multiple rows of text. Can either be configured with a fixed height or to automatically resize.
 */
@customElement('one-ux-textarea')
export class OneUxTextareaElement extends BaseClass {
  /**
   * Defines the number of lines of text that a `<one-ux-textarea>` will have.
   *
   * It is also possible to provide the value `auto` so that the size will match the text automatically.
   */
  @property({ attribute: 'line-count', type: String })
  public lineCount = DEFAULT_LINE_COUNT as number | 'auto'

  render() {
    const autoResize = this.lineCount === 'auto'
    const scrollStyle = autoResize
      ? ''
      : `height: calc(${this.lineCount} * var(--one-ux-textarea-element--line-height));`

    const containerStyle = `min-height: calc(${
      autoResize ? AUTO_SIZE_LINE_COUNT : this.lineCount
    } * var(--one-ux-textarea-element--line-height));`

    const $textarea = html`<one-ux-editable-text
      class=${classMap({
        ['auto-resize']: autoResize
      })}
      .delegateAria=${{
        'aria-label': this.label,
        'aria-required': this.required
      }}
      style=${containerStyle}
      ?disabled=${this.disabled}
      .value=${this.value || ''}
      enable-line-breaks
      placeholder=${ifDefined(this.placeholder)}
      @input=${(e: InputEvent) => {
        e.stopPropagation()
        const $editableText = e.target as OneUxEditableTextElement
        const oldValue = this.value
        // TODO: Replace with `beforeinput` in PDR 11.0.0
        this.value = $editableText.value
        const event = new InputEvent('input', {
          composed: true,
          bubbles: true,
          cancelable: true
        })
        if (!this.dispatchEvent(event)) {
          this.value = oldValue
          $editableText.value = oldValue
        }
      }}
      @change=${(e: Event) => {
        e.stopPropagation()
        this.dispatchEvent(new Event('change', e))
      }}
    ></one-ux-editable-text>`
    return html`<one-ux-field
      class="one-ux-element--root"
      multi-line
      .label=${this.label}
      .compact=${this.compact}
      .required=${this.required}
      .disabled=${this.disabled}
      .implicit=${this.implicit}
      .weight=${this.weight}
      .empty=${!hasValue(this.value)}
      .errors=${this.errors}
    >
      ${autoResize
        ? $textarea
        : html`<one-ux-scroll width="max" weight="low" style=${scrollStyle}> ${$textarea} </one-ux-scroll>`}
    </one-ux-field>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-textarea': OneUxTextareaElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-textarea': OneUxTextareaElement
    }
  }
}
