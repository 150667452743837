import { placePopup } from '../../helpers/helpers'
import { MOUSE_LEFT } from '../../helpers/mouseCodes'
import { AnimatedCustomElement } from '../AnimatedCustomElement'
import { KeyboardEventHandler } from './KeyboardEventHandler'
import { SearchEvent } from './events/SearchEvent'

export class InputSelectElement extends AnimatedCustomElement {
  #previewElement
  #popupElement
  #dynamicSearchElement = document.createElement('dynamic-input-select-search')

  #observer = new MutationObserver((mutationsList) => {
    if (mutationsList.some((mutation) => mutation.target.tagName === 'INPUT-SELECT-POPUP')) {
      this.#reset()
    }
  })

  onInitialized() {
    this.setAttribute('role', 'combobox')

    this.addEventListener('click', this.#handleClick)
    this.addEventListener('keydown', new KeyboardEventHandler(this).handleKeyDown)
    this.addEventListener('blur', this.#handleBlur)

    this.addEventListener('#option-activated', this.#handleOptionActivated, true)
    this.addEventListener('#option-triggered', this.#handleOptionTriggered, true)
    this.addEventListener('#option-changed', this.#handleOptionChanged, true)

    this.#observer.observe(this, { childList: true, subtree: true })
  }

  onRendered() {
    let $preview = this.querySelector('input-select-preview')
    if (!$preview) {
      $preview = document.createElement('input-select-preview')
      $preview.toggleAttribute('state-automatic', true)
      this.insertBefore($preview, this.firstChild)
    }
    this.#previewElement = $preview

    this.insertBefore(this.#dynamicSearchElement, $preview.nextSibling)
    this.#dynamicSearchElement.hidden = true

    this.#popupElement =
      this.querySelector('input-select-popup') || this.appendChild(document.createElement('input-select-popup'))

    this.expanded = false
    this.#updatePlaceholderState()
    this.#updatePreview()
  }

  onConnected() {
    this.#reset()
  }

  static get observedAttributes() {
    return ['disabled', 'multiple', 'placeholder', 'search-empty']
  }

  onAttributeRendered({ name }) {
    switch (name) {
      case 'disabled':
        this.#disabledChanged()
        break
      case 'multiple':
        this.#multipleChanged()
        break
      case 'placeholder':
        this.#placeholderChanged()
        break
      case 'search-empty':
        this.#searchEmptyChanged()
        break
    }
  }

  #prevLeft
  #prevTop
  animate() {
    this.#popupElement.style.minWidth = Math.round(this.offsetWidth) + 'px'
    const { left, top } = placePopup(this.#popupElement, this, {
      prevLeft: this.#prevLeft,
      prevTop: this.#prevTop
    })
    this.#prevLeft = left
    this.#prevTop = top
  }

  get disabled() {
    return this.hasAttribute('disabled')
  }

  set disabled(disabled) {
    if (typeof disabled !== 'undefined') {
      this.toggleAttribute('disabled', disabled)
    }
  }

  get expanded() {
    return this.getAttribute('aria-expanded') === 'true'
  }

  set expanded(expanded) {
    if (expanded) {
      if (this.disabled || document.activeElement !== this) {
        return
      }

      this.shouldAnimate = true
      this.setAttribute('aria-expanded', true)

      const $newActiveOption = this.#getSelectedOptions()[0] || this.options[0]
      if ($newActiveOption) {
        $newActiveOption.active = true
      }
      this.#updateActiveOption()

      const searchActive = this.expanded && this.#isSearchable()
      this.#previewElement.hidden = searchActive
      this.#dynamicSearchElement.hidden = !searchActive
      this.searchTerm = ''
      this.searchCursor = 0

      if (this.#isSearchable()) {
        this.dispatchEvent(new SearchEvent(''))
      }
    } else {
      this.shouldAnimate = false
      this.setAttribute('aria-expanded', false)
      this.removeAttribute('aria-activedescendant')

      this.#previewElement.hidden = false
      this.#dynamicSearchElement.hidden = true

      this.options.forEach(($option) => ($option.filtered = false))
    }
  }

  get multiple() {
    return this.hasAttribute('multiple')
  }

  set multiple(multiple) {
    if (typeof multiple !== 'undefined') {
      this.toggleAttribute('multiple', multiple)
    }
  }

  get options() {
    return Array.from(this.querySelectorAll('input-select-option'))
  }

  get placeholder() {
    return this.getAttribute('placeholder') || ''
  }

  set placeholder(placeholder) {
    this.setAttribute('placeholder', placeholder)
  }

  get searchable() {
    return this.getAttribute('searchable')
  }

  set searchable(searchable) {
    if (typeof searchable !== 'undefined') {
      if (searchable != null) {
        this.setAttribute('searchable', searchable)
      } else {
        this.removeAttribute('searchable')
      }
    }
  }

  get searchEmpty() {
    return this.getAttribute('search-empty') || ''
  }

  set searchEmpty(searchEmtpy) {
    this.setAttribute('search-empty', searchEmtpy)
  }

  #searchTerm
  get searchTerm() {
    return this.#searchTerm
  }

  set searchTerm(searchTerm) {
    this.#searchTerm = searchTerm

    const $search = this.#dynamicSearchElement
    $search.term = searchTerm || this.searchable || ''

    const searchActive = this.expanded && this.#isSearchable()
    const userHasNotTypedSearchTerm = !searchTerm
    this.toggleAttribute('state-search-placeholder', searchActive && userHasNotTypedSearchTerm)

    const lowerCaseSearchterm = searchTerm.toLowerCase().trim()
    this.options.forEach((option) => {
      option.filtered = searchActive && lowerCaseSearchterm && !option.text.toLowerCase().includes(lowerCaseSearchterm)
    })
  }

  get searchCursor() {
    return this.#dynamicSearchElement.cursorIndex
  }

  set searchCursor(searchCursor) {
    this.#dynamicSearchElement.cursorIndex = searchCursor
  }

  get value() {
    const $selectedOptions = this.#getSelectedOptions()
    if (this.multiple) {
      return $selectedOptions.map(($option) => $option.value)
    } else {
      const $selectedOption = $selectedOptions[0]
      return $selectedOption ? $selectedOption.value : undefined
    }
  }

  set value(value) {
    if (typeof value !== 'undefined') {
      if (this.multiple) {
        if (!Array.isArray(value)) {
          value = [value]
        }
        value = value.map((x) => x + '')

        this.options.forEach(($option) => {
          $option.selected = value.indexOf($option.value) > -1
        })
      } else {
        value = value + ''
        const $selectedOption = this.options.find(($option) => $option.value === value)
        this.options.forEach(($option) => {
          $option.selected = $option === $selectedOption
        })
      }

      this.#updatePreview()
    }
  }

  #reset() {
    if (!this.multiple) {
      const selectedOptions = this.#getSelectedOptions()
      if (selectedOptions.length > 1) {
        for (let i = 0; i < selectedOptions.length - 1; ++i) {
          selectedOptions[i].selected = false
        }
      }
    }
  }

  #getSelectedOptions() {
    return this.options.filter(($option) => $option.selected)
  }

  #updateActiveOption() {
    let $activeOption = this.options.find(($option) => $option.active && !$option.filtered)
    if (!$activeOption) {
      $activeOption = this.options.find(($option) => !$option.filtered)
    }

    if ($activeOption) {
      $activeOption.active = true
      this.setAttribute('aria-activedescendant', $activeOption.id)
    }
  }

  #handleClick = (event) => {
    if (event.which === MOUSE_LEFT) {
      this.expanded = !this.expanded
    }
  }

  #isSearchable() {
    return this.searchable != null
  }

  #handleBlur = () => {
    this.expanded = false
  }

  #handleOptionActivated = (event) => {
    this.querySelectorAll('input-select-option[state-active]').forEach(($option) => {
      if ($option !== event.target) {
        $option.active = false
      }
    })

    event.target.scrollIntoView({ block: 'nearest' })
  }

  #handleOptionTriggered = (event) => {
    const triggerReason = event.detail
    let shouldNotifyChanges = true

    if (!this.multiple) {
      this.options.forEach(($option) => {
        if ($option.selected && $option !== event.target) {
          $option.selected = false
        }
      })
      if (!event.target.selected) {
        event.target.selected = true
        shouldNotifyChanges = false
      }
    } else if (!event.target.selected && ['keyboard-enter', 'keyboard-tab'].includes(triggerReason)) {
      event.target.selected = true
      shouldNotifyChanges = false
    }

    if ((triggerReason == 'mouse-click' && !this.multiple) || triggerReason == 'keyboard-enter') {
      this.expanded = false
    }

    if (shouldNotifyChanges) {
      this.dispatchEvent(new CustomEvent('input', { bubbles: true }))
      this.dispatchEvent(new CustomEvent('change', { bubbles: true }))
    }
  }

  #handleOptionChanged = () => {
    this.#updatePlaceholderState()
    this.#updatePreview()
  }

  #updatePlaceholderState() {
    this.toggleAttribute('state-placeholder', !this.#getSelectedOptions().length)
  }

  #updatePreview() {
    if (this.#previewElement) {
      this.#previewElement.value = this.#getSelectedOptions()
    }
  }

  #disabledChanged() {
    if (this.disabled) {
      this.setAttribute('aria-disabled', true)
      this.removeAttribute('tabindex')
      this.expanded = false
    } else {
      this.removeAttribute('aria-disabled')
      this.setAttribute('tabindex', 0)
    }
  }

  #multipleChanged() {
    this.#popupElement.multiple = this.multiple
  }

  #placeholderChanged() {
    if (this.#previewElement) {
      this.#previewElement.placeholder = this.placeholder
    }
  }

  #searchEmptyChanged() {
    this.#popupElement.searchEmptyText = this.searchEmpty
  }
}
