import { invLerp, lerp } from './math'

export class AxisScale {
  private _ticks: number[]

  constructor(
    private options: {
      size: number
      spacing: number
      minValue: number
      maxValue: number
      minHardLimit?: number
      maxHardLimit?: number
    }
  ) {
    this._ticks = this.getValueTicks()
  }

  public get ticks(): number[] {
    return [...this._ticks]
  }

  public position(value: number): number {
    const min = this._ticks[0]
    const max = this._ticks[this._ticks.length - 1]
    const t = invLerp(min, max, value)
    return lerp(0, this.options.size, t)
  }

  private getValueTicks = (): number[] => {
    const ticks = []

    const { minHardLimit, maxHardLimit } = this.options
    const { tickStepAmount, minTickValue, maxTickValue } = this.calculateTickStep()
    const steps = Math.floor((maxTickValue - minTickValue) / tickStepAmount)

    if (Number.isFinite(minHardLimit)) {
      ticks.push(Number(minHardLimit))
    }

    for (let i = 0; i <= steps; i++) {
      const tickValue = minTickValue + i * tickStepAmount

      const isLowerOk = !Number.isFinite(minHardLimit) || tickValue > Number(minHardLimit)
      const isUpperOk = !Number.isFinite(maxHardLimit) || tickValue < Number(maxHardLimit)

      if (isLowerOk && isUpperOk) {
        ticks.push(tickValue)
      }
    }

    if (Number.isFinite(maxHardLimit)) {
      ticks.push(Number(maxHardLimit))
    }

    return ticks
  }

  private calculateTickStep = () => {
    const min = this.options.minHardLimit ?? this.options.minValue
    const max = this.options.maxHardLimit ?? this.options.maxValue

    const valueDiff = Math.abs(max - min)
    const tickValue = valueDiff / this.estimatedTickCount()
    const tickStepAmount = this.calculateTickStepAmount(tickValue)
    const minTickValue = Math.floor(min / tickStepAmount) * tickStepAmount
    const maxTickValue = Math.ceil(max / tickStepAmount) * tickStepAmount

    return {
      tickStepAmount,
      minTickValue,
      maxTickValue
    }
  }

  private estimatedTickCount = () => Math.max(2, Math.abs(Math.floor(this.options.size / this.options.spacing)))

  private calculateTickStepAmount = (value: number) => {
    const baseValue = Math.pow(10, Math.floor(Math.log10(value)))
    let factor = value / baseValue
    if (factor < 1) {
      factor = 1
    } else if (factor < 2) {
      factor = 2
    } else if (factor < 5) {
      factor = 5
    } else {
      factor = 10
    }

    return Math.ceil(factor) * baseValue
  }
}
