import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InputSegment, InternalInlineSpecs, InternalInlineSpecsDetail, InternalSnapSizeConfig } from '../types'
import { getTypography } from '../../common/typography'
import { getTextSize } from '../../common/getTextSize'
import { isHiddenVisual } from '../utils'

export const getInlineStatsSpecs = (
  host: OneUxGaugeChartElement,
  segments: InputSegment[],
  snapSizeConfig: InternalSnapSizeConfig
): InternalInlineSpecs => {
  if (isHiddenVisual(host, 'inline-stats')) {
    return {
      rowCount: 0,
      details: []
    }
  }

  const { inlineStatsIconSize, inlineStatsHorizontalGap, inlineStatsVerticalGap, inlineStatsOffsetTop } = snapSizeConfig

  const { font, letterSpacing } = getTypography('mono-400')
  const availableWidth = host._boundary!.width
  let rowIndex = 0
  let columnIndex = 0
  let accStatWidth = 0

  const details: InternalInlineSpecsDetail[] = []
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i]
    const textSize = getTextSize(String(segment.value), font, letterSpacing)
    const textWidth = textSize.width
    const statWidth = inlineStatsIconSize + textWidth + inlineStatsHorizontalGap
    if (i > 0 && availableWidth < accStatWidth + statWidth) {
      ++rowIndex
      accStatWidth = 0
      columnIndex = 0
    }

    details.push({
      rowIndex,
      columnIndex,
      statWidth,
      accStatWidth,
      offsetX: 0,
      offsetY: 0
    })

    accStatWidth += statWidth
    columnIndex++
  }

  const maxAccRowWidth = getMaxAccRowWidth(details)
  for (const detail of details) {
    const accRowWidth = getAccRowWidth(detail, details)
    const moveXToCenterOffset = (maxAccRowWidth - accRowWidth) / 2
    detail.offsetX = detail.accStatWidth + (host._boundary!.width - maxAccRowWidth) / 2 + moveXToCenterOffset
    detail.offsetY = detail.rowIndex * (inlineStatsIconSize + inlineStatsVerticalGap) + inlineStatsOffsetTop
  }

  return {
    rowCount: rowIndex + 1,
    details
  }
}

const getMaxAccRowWidth = (details: InternalInlineSpecsDetail[]) =>
  Math.max(...details.map((d) => d.accStatWidth + d.statWidth))

const getAccRowWidth = (detail: InternalInlineSpecsDetail, details: InternalInlineSpecsDetail[]) =>
  Math.max(...details.filter((x) => x.rowIndex === detail.rowIndex).map((d) => d.accStatWidth + d.statWidth))
