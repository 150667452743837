import { html, nothing, PropertyValues } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { createRef, ref } from 'lit/directives/ref.js'
import { styleMap } from 'lit/directives/style-map.js'
import { keyCodes } from '../../common/utils'
import { OneUxTypographyToken } from '@/one-ux/generated/design-tokens'
import { StyledFactory } from '../../common/mixins/Styled'
import { Boundary } from '../common/mixins/Boundary'
import { style } from './style'
import { getSvgHeight } from './utils'
import { HiddenVisual, InputSegment, InternalData } from './types'
import { renderSegments } from './renderers/renderSegments'
import { getInternalData } from './internal-data/getInternalData'
import { renderPointer } from './renderers/renderPointer'
import { renderLabel } from './renderers/renderLabel'
import { Popout } from './components/Popout'
import { Lang } from '../common/mixins/Lang'
import { getLanguageSet } from './getLanguageSet'
import { renderInlineStats } from './renderers/renderInlineStats'
import { renderTotal } from './renderers/renderTotal'
import { log } from '../../common/utils/log'
import { OneUxElement } from '../../common/OneUxElement'
import { loadTypography } from '../common/typography'

const Styled = StyledFactory(style)

const BaseClass = Lang(Boundary(Styled(OneUxElement)))

/**
 * A gauge presenting the weighted mean of segments.
 */
@customElement('one-ux-gauge-chart')
export class OneUxGaugeChartElement extends BaseClass {
  #refs = {
    $svg: createRef()
  }

  #typographyTokens: OneUxTypographyToken[] = [
    'body-200',
    'mono-400',
    'heading-500',
    'heading-400',
    'heading-300',
    'heading-200',
    'heading-100',
    'heading-variant-100',
    'heading-variant-200',
    'heading-variant-300',
    'heading-variant-400',
    'heading-variant-500'
  ]

  public get $svg() {
    return this.#refs.$svg.value! as HTMLElement
  }

  constructor() {
    super()
  }

  /**
   * The label associated with the gauge.
   * This is a required property and the gauge will not render if it is omitted.
   */
  @property({ type: String })
  public label!: string

  /**
   * InputSegment:
   * The visual presentation of segment within the gauge.
   * * name: Name of the segment.
   * * value: Value for the segment, used to calculate the weighted mean of segments.
   * * color: Color for the segment.
   * * icon: Optional icon, icon must be from the icon set "status".
   **/
  @property({ type: Array })
  public segments!: InputSegment[]

  /**
   * List of visual elements to be hidden
   **/
  @property({ type: Array, attribute: 'hide-visuals' })
  public hideVisuals: HiddenVisual[] = []

  @state()
  private _expanded = false

  @state()
  private _mouseOver = false

  #data!: InternalData

  protected willUpdate(changedProperties: PropertyValues): void {
    if (this.#isDefined(changedProperties)) {
      this.#data = getInternalData(this)
    }
  }

  protected updated(): void {
    if (this.#data) {
      this.#draw()
    }
  }

  #isDefined(changedProperties: PropertyValues) {
    return (
      (changedProperties.has('_boundary') || changedProperties.has('segments')) &&
      this._boundary &&
      this.segments?.length
    )
  }

  protected render() {
    loadTypography(this, this.#typographyTokens)

    if (!this.label) {
      log.error('<one-ux-gauge-chart> Missing label, not rendering.')
      return
    }

    if (!this.segments?.length) {
      log.error('<one-ux-gauge-chart> Missing segments, not rendering.')
      return
    }

    const languageSet = getLanguageSet(this._locale)
    return html` <div
      class="one-ux-element--root"
      role="group"
      aria-roledescription=${languageSet.gaugeChart}
      aria-label=${this.#getRoleGroupAriaLabel()}
      @mouseenter=${() => (this._mouseOver = true)}
      @mouseleave=${() => (this._mouseOver = false)}
      @keydown=${this.#handleKeydown}
    >
      <button
        class="one-ux-accessibility--screen-reader"
        aria-label=${languageSet.showDetails}
        aria-controls=${this._expanded ? 'summary' : nothing}
        aria-expanded=${this._expanded}
        @click=${() => (this._expanded = !this._expanded)}
        @focusout=${() => (this._expanded = false)}
      ></button>
      <svg
        ${ref(this.#refs.$svg)}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        aria-hidden="true"
        style=${styleMap({
          height: this.#data ? getSvgHeight(this, this.#data) + 'px' : null
        })}
      ></svg>
      ${this._expanded || this._mouseOver ? Popout(this, this.#data, !this._expanded) : nothing}
    </div>`
  }

  #getRoleGroupAriaLabel() {
    if (!this.#data) {
      return ''
    }

    const highlighted = this.#data.segments.find((x) => x.highlighted)!
    const percent = Math.round(this.#data.gaugePercent * 100)

    return `${this.label}: ${percent}%, "${highlighted.name}`
  }

  #draw() {
    renderSegments(this, this.#data)
    renderPointer(this, this.#data)
    renderTotal(this, this.#data)
    renderLabel(this, this.#data)
    renderInlineStats(this, this.#data)
  }

  #handleKeydown(e: KeyboardEvent) {
    const handled = () => {
      e.preventDefault()
      e.stopPropagation()
    }

    switch (e.code) {
      case keyCodes.ESCAPE:
        {
          handled()
          this._expanded = false
        }
        break
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-gauge-chart': OneUxGaugeChartElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-gauge-chart': OneUxGaugeChartElement
    }
  }
}
