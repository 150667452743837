import { margin, size, yAxisRange } from './types'
import { getRenderGroupsMinMaxValue } from './render-groups/utils'
import { RenderGroup } from './render-groups/types'
import { ValueFormatter } from '../common/format'
import { getValueTicks } from './axis/value/getValueTicks'
import { getRotatedBoxHeight } from '../common/getRotatedBoxSize'
import { getLegendsOffset } from './legend/legendSizes'
import { KeyValueSet } from './axis/key/KeyValueSet'
import { getValueTicksMaxWidth } from './axis/value/getValueTicksMaxWidth'

const exteriorMargin = {
  top: 8,
  bottom: 4,
  left: 4,
  right: 4
}

export const calculateSvgMargin = (
  svgSize: size,
  valueFormatter: ValueFormatter,
  leftRenderGroups: RenderGroup[],
  rightRenderGroups: RenderGroup[],
  legendNames: string[],
  keyValueSet: KeyValueSet,
  axisLeftRange?: yAxisRange,
  axisRightRange?: yAxisRange
): margin => {
  let marginLeft = calculateLeftMargin(svgSize, valueFormatter, leftRenderGroups, axisLeftRange)
  const marginRight = calculateRightMargin(svgSize, valueFormatter, rightRenderGroups, axisRightRange)
  let marginTop = calculateTopMargin(svgSize, marginLeft, legendNames)

  updateKeyRotation(svgSize, marginLeft, marginRight, keyValueSet)

  let marginBottom = calculateMarginBottom(keyValueSet)
  const marginLeftBasedOnFirstKey = calculateLeftMarginBasedOnFirstKey(svgSize, marginLeft, marginRight, keyValueSet)
  if (marginLeft < marginLeftBasedOnFirstKey) {
    marginLeft = marginLeftBasedOnFirstKey
    marginTop = calculateTopMargin(svgSize, marginLeft, legendNames)
    marginBottom = calculateMarginBottom(keyValueSet)
  }

  return {
    top: marginTop,
    left: marginLeft,
    right: marginRight,
    bottom: marginBottom
  }
}

const calculateLeftMargin = (
  svgSize: size,
  valueFormatter: ValueFormatter,
  leftRenderGroups: RenderGroup[],
  axisLeftRange?: yAxisRange
) => {
  const { minValue: leftMinValue, maxValue: leftMaxValue } = getRenderGroupsMinMaxValue(leftRenderGroups)
  const leftValueTicks = getValueTicks(
    svgSize.height,
    leftMinValue,
    leftMaxValue,
    axisLeftRange?.min,
    axisLeftRange?.max
  )
  const marginLeft = getValueTicksMaxWidth(leftValueTicks, valueFormatter)
  return marginLeft > 0 ? marginLeft + exteriorMargin.left : 0
}

const calculateLeftMarginBasedOnFirstKey = (
  svgSize: size,
  marginLeft: number,
  marginRight: number,
  keyValueSet: KeyValueSet
) => {
  const keySize = calculateKeySize(svgSize, marginLeft, marginRight, keyValueSet)
  if (keyValueSet.keyRotation === 45) {
    const { boxWidth: boxWidthOfFirstKey } = keyValueSet.getRotatedSize(keyValueSet.keys[0], keyValueSet.keyRotation)
    const innerKeyPosition = keyValueSet.isWrapped ? keySize : keySize / 2
    const availableSpaceToLeft = marginLeft + innerKeyPosition
    if (availableSpaceToLeft < boxWidthOfFirstKey) {
      return marginLeft + Math.abs(boxWidthOfFirstKey - availableSpaceToLeft)
    }
  }

  return marginLeft
}

const calculateRightMargin = (
  svgSize: size,
  valueFormatter: ValueFormatter,
  rightRenderGroups: RenderGroup[],
  axisRightRange?: yAxisRange
) => {
  const { minValue: rightMinValue, maxValue: rightMaxValue } = getRenderGroupsMinMaxValue(rightRenderGroups)
  const rightValueTicks = getValueTicks(
    svgSize.height,
    rightMinValue,
    rightMaxValue,
    axisRightRange?.min,
    axisRightRange?.max
  )
  const marginRight = getValueTicksMaxWidth(rightValueTicks, valueFormatter)
  return marginRight > 0 ? marginRight + exteriorMargin.right : 0
}

const calculateTopMargin = (svgSize: size, marginLeft: number, legendNames: string[]) => {
  const legendsFitWidth = svgSize.width - marginLeft
  const legendsOffset = getLegendsOffset(legendNames, legendsFitWidth)
  const marginTop = legendsOffset[legendsOffset.length - 1].bottom + exteriorMargin.top

  return marginTop > 0 ? marginTop + exteriorMargin.top : 0
}

const calculateMarginBottom = (keyValueSet: KeyValueSet) => {
  const { boxWidth, boxHeight } =
    keyValueSet.keyRotation === 0 ? keyValueSet.maxHeightKeySize : keyValueSet.maxWidthKeySize
  const marginBottom = getRotatedBoxHeight(boxWidth, boxHeight, keyValueSet.keyRotation) + exteriorMargin.bottom

  return marginBottom > 0 ? marginBottom + exteriorMargin.bottom : 0
}

const updateKeyRotation = (svgSize: size, marginLeft: number, marginRight: number, keyValueSet: KeyValueSet) => {
  const keySize = calculateKeySize(svgSize, marginLeft, marginRight, keyValueSet)
  keyValueSet.updateKeyRotation(keySize)
}

const calculateKeySize = (svgSize: size, marginLeft: number, marginRight: number, keyValueSet: KeyValueSet) => {
  const keysFitWidth = svgSize.width - (marginLeft + marginRight)
  return keysFitWidth / keyValueSet.length
}
