import { OneUxIndicationElement } from './OneUxIndicationElement'
import { getLangCode, langCode, LanguageSets } from '@/one-ux/common/utils/getLangCode'

export type language = {
  locale: langCode
  translations: translationSet
}

export type translationSet = {
  indicationArc: string
  indicationBar: string
  arrowAt: string
  highlightedSegments: string
  noHighlightedSegments: string
}

export const languages: LanguageSets<translationSet> = {
  en: {
    indicationArc: 'Indication arc',
    indicationBar: 'Indication bar',
    arrowAt: 'Arrow at',
    highlightedSegments: 'Highlighted segments',
    noHighlightedSegments: 'No highlighted segments'
  },
  sv: {
    indicationArc: 'Indikeringsbåge',
    indicationBar: 'Indikationsfält',
    arrowAt: 'Pil på',
    highlightedSegments: 'Markerade segment',
    noHighlightedSegments: 'Inga markerade segment'
  },
  nb: {
    indicationArc: 'Indikasjonsbue',
    indicationBar: 'Indikasjonslinje',
    arrowAt: 'Pil på',
    highlightedSegments: 'Uthevede segmenter',
    noHighlightedSegments: 'Ingen uthevede segmenter'
  },
  fi: {
    indicationArc: 'Indikaatiokaari',
    indicationBar: 'Merkkipalkki',
    arrowAt: 'Nuoli',
    highlightedSegments: 'Korostetut segmentit',
    noHighlightedSegments: 'Ei korostettuja segmenttejä'
  },
  da: {
    indicationArc: 'Indikationsbue',
    indicationBar: 'Indikationsbjælke',
    arrowAt: 'Pil ved',
    highlightedSegments: 'Fremhævede segmenter',
    noHighlightedSegments: 'Ingen fremhævede segmenter'
  }
}

export const getLanguage = (element: OneUxIndicationElement): language => {
  const locale = getLangCode(element)
  const translations = languages[locale]
  return {
    locale,
    translations
  }
}
