import { ReactiveController } from 'lit'
import { OneUxPopoutElement } from './OneUxPopoutElement'

export class PlacementController implements ReactiveController {
  private animateRequest = 0
  private prevTop = 0
  private prevLeft = 0

  constructor(private $element: OneUxPopoutElement) {
    this.$element.addController(this)
  }

  hostConnected() {
    this.$element.toggleAttribute('state-initial', true)
    this.animate()
  }

  hostDisconnected() {
    cancelAnimationFrame(this.animateRequest as number)
  }

  private animate = () => {
    this.animateRequest = requestAnimationFrame(() => {
      this.$element.toggleAttribute('state-initial', false)

      const $element = this.$element
      let $referenceElement: Element | null

      if ($element.reference instanceof Element) {
        $referenceElement = $element.reference
      } else if ($element.reference === 'previous') {
        $referenceElement = $element.previousElementSibling
      } else {
        let depth = $element.referenceDepth
        $referenceElement = $element
        while (depth > 0) {
          $referenceElement =
            $referenceElement.parentElement || (($referenceElement.getRootNode() as ShadowRoot).host as HTMLElement)
          --depth
        }
      }

      if ($referenceElement) {
        const referenceRect = $referenceElement.getBoundingClientRect()

        let { top, left } = this.getPositions(referenceRect)
        top = Math.floor(top)
        left = Math.floor(left)

        if (top !== this.prevTop && Math.abs(top - this.prevTop) > 1) {
          $element.style.top = top + 'px'
          this.prevTop = top
        }
        if (left !== this.prevLeft && Math.abs(left - this.prevLeft) > 1) {
          $element.style.left = left + 'px'
          this.prevLeft = left
        }
      }

      this.animate()
    })
  }

  private getPositions = (referenceRect: DOMRect): Position => {
    const $element = this.$element
    const elementWidth = $element.offsetWidth
    const elementHeight = $element.offsetHeight
    const viewportWidth = document.documentElement.clientWidth
    const viewportHeight = document.documentElement.clientHeight

    const checkOverflows = (top: number, left: number) => ({
      top: top < 0,
      bottom: top + elementHeight > viewportHeight,
      left: left < 0,
      right: left + elementWidth > viewportWidth
    })

    const getTopPosition = ({ placement, alignment }: PositioningOptions): number => {
      let offset = 0
      if (this.$element.direction === 'vertical') {
        offset = placement === 'before' ? -this.$element.offsetReference : this.$element.offsetReference
      } else if (this.$element.direction === 'horizontal') {
        offset = alignment === 'end' ? -this.$element.offsetAlignment : this.$element.offsetAlignment
      }

      if ($element.direction === 'vertical') {
        if (placement === 'before') {
          return referenceRect.top - elementHeight + offset
        } else if (placement === 'center') {
          return referenceRect.top + referenceRect.height / 2 - elementHeight / 2
        } else {
          return referenceRect.bottom + offset
        }
      } else {
        if (alignment === 'start') {
          return referenceRect.top + offset
        } else if (alignment === 'center') {
          return referenceRect.top + referenceRect.height / 2 - elementHeight / 2
        } else {
          return referenceRect.bottom - elementHeight + offset
        }
      }
    }

    const getLeftPosition = ({ placement, alignment }: PositioningOptions): number => {
      let offset = 0
      if (this.$element.direction === 'horizontal') {
        offset = placement === 'before' ? -this.$element.offsetReference : this.$element.offsetReference
      } else if (this.$element.direction === 'vertical') {
        offset = alignment === 'end' ? -this.$element.offsetAlignment : this.$element.offsetAlignment
      }

      if ($element.direction === 'horizontal') {
        if (placement === 'before') {
          return referenceRect.left - elementWidth + offset
        } else if (placement === 'center') {
          return referenceRect.left + referenceRect.width / 2 - elementWidth / 2
        } else {
          return referenceRect.right + offset
        }
      } else {
        if (alignment === 'start') {
          return referenceRect.left + offset
        } else if (alignment === 'center') {
          return referenceRect.left + referenceRect.width / 2 - elementWidth / 2
        } else {
          return referenceRect.right - elementWidth + offset
        }
      }
    }

    // stage 1: Normal attempt, position popout at desired alignment and placement.
    const positioning: PositioningOptions = {
      placement: $element.placement,
      alignment: $element.alignment
    }
    let top = getTopPosition(positioning)
    let left = getLeftPosition(positioning)
    let overflows = checkOverflows(top, left)
    if (!overflows.top && !overflows.bottom && !overflows.left && !overflows.right) {
      return {
        top,
        left
      }
    }

    // stage 2: Flipped attempt. Popout could not be positioned on available viewport,
    // try to flip alignment and placement.
    if ($element.direction === 'horizontal') {
      if (($element.alignment === 'end' || $element.alignment === 'center') && overflows.top) {
        positioning.alignment = 'start'
      }
      if (($element.alignment === 'start' || $element.alignment === 'center') && overflows.bottom) {
        positioning.alignment = 'end'
      }
      if ($element.placement === 'after' && overflows.right) {
        positioning.placement = 'before'
      }
      if ($element.placement === 'before' && overflows.left) {
        positioning.placement = 'after'
      }
    } else {
      if (($element.alignment === 'end' || $element.alignment === 'center') && overflows.left) {
        positioning.alignment = 'start'
      }
      if (($element.alignment === 'start' || $element.alignment === 'center') && overflows.right) {
        positioning.alignment = 'end'
      }
      if ($element.placement === 'after' && overflows.bottom) {
        positioning.placement = 'before'
      }
      if ($element.placement === 'before' && overflows.top) {
        positioning.placement = 'after'
      }
    }

    top = getTopPosition(positioning)
    left = getLeftPosition(positioning)
    overflows = checkOverflows(top, left)
    if (!overflows.top && !overflows.bottom && !overflows.left && !overflows.right) {
      return {
        top,
        left
      }
    }

    // stage 3: Centered attempt. Popout could not be fully visible on viewport by flipping
    // placement and alignment, so it will be centered on top of reference element
    // if allowed.
    if (!$element.preventOverlap) {
      if (
        ($element.direction === 'horizontal' && (overflows.left || overflows.right)) ||
        ($element.direction === 'vertical' && (overflows.top || overflows.bottom))
      ) {
        positioning.placement = 'center'
        top = getTopPosition(positioning)
        left = getLeftPosition(positioning)
      }
    }

    return {
      top,
      left
    }
  }
}

type PositioningOptions = {
  placement: 'before' | 'after' | 'center'
  alignment: 'start' | 'center' | 'end'
}

type Position = {
  top: number
  left: number
}
