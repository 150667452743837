import { groupBy } from '../../../common/groupBy'
import { typeSet } from '../../render-groups/types'
import { serieLine } from '../../types'
import { LineRenderGroup, lineRenderGroupData } from './types'

export const getLineRenderGroups = (set: typeSet): LineRenderGroup[] => {
  const groups = Object.values(groupBy(set, (x) => `${x.serie.yTarget}:${x.serie.name}`))

  const collection: LineRenderGroup[] = []
  for (const group of groups) {
    let minValue = 0
    let maxValue = 0

    const line: lineRenderGroupData[] = []
    for (const { datapoint, serie } of group) {
      const hasValue = typeof datapoint.value !== 'undefined'
      if (hasValue) {
        minValue = Math.min(minValue, datapoint.value)
        maxValue = Math.max(maxValue, datapoint.value)
      }
      line.push({ key: datapoint.key, value: datapoint.value, showPointLabel: serie.showPointLabels !== false })
    }

    const serie = group[0].serie as serieLine
    collection.push({
      type: 'line',
      yTarget: serie.yTarget,
      strokeDashed: serie.strokeDashed,
      strokeWidth: serie.strokeWidth,
      color: serie.color,
      name: serie.name,
      curved: serie.curved,
      line,
      minValue,
      maxValue
    })
  }

  return collection
}
