import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement'
import { Constructor } from '../utils'
import { log } from '../utils/log'

export declare class ILabel {
  label: string
}

export const Label = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class LabelClass extends SuperClass {
    constructor(...args: any[]) {
      super(...args)
      this._renderGuards.push(() => {
        if (!this.label?.trim()) {
          log.error({
            title: 'The property "label" is required.',
            message:
              'Including a label is critical for accessibility and the component will not render if the label is omitted. ',
            details: {
              element: this
            }
          })
          return true
        }
        return false
      })
    }

    /**
     * A label is used to support assistive technologies.
     * It is a required property that will prevent rendering if omitted.
     */
    @property({ type: String })
    public label!: string
  }
  return LabelClass as Constructor<ILabel> & TSuperClass
}
