import { html, nothing } from 'lit'
import { customElement } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Disabled } from '../common/mixins/Disabled'
import { Focusable } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { Weight } from '../common/mixins/Weight'
import { OneUxElement } from '../common/OneUxElement'
import { DelegateAria } from '../common/mixins/DelegateAria'
import { createRef, ref } from 'lit/directives/ref.js'
import { Label } from '../common/mixins/Label'
import { Required } from '../common/mixins/Required'
import { Compact } from '../common/mixins/Compact'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import { Errors } from '../common/mixins/Errors'
import { keyCodes } from '../common/utils'
import { StyledFactory } from '../common/mixins/Styled'
import { style } from './style'
import { FieldSetProps } from './FieldSetProps'
import { ErrorsPopout } from '../common/components/ErrorsPopout'

const Styled = StyledFactory(style)
const BaseClass = FieldSetProps(
  Styled(Errors(Compact(Required(Label(DelegateAria(Disabled(Focusable(Implicit(Weight(OneUxElement))))))))))
)
/**
 * A base component that is used internally to create field sets.
 * This is not recommended for use, you should use one of the field sets available instead.
 * $$internal$$
 */
@customElement('one-ux-field-set')
export class OneUxFieldSetElement extends BaseClass {
  #grid = {
    template: null as string | null,
    indexToPos: {} as Record<number, { column: number; row: number }>,
    posToIndex: {} as Record<string, number>
  }

  #slotElement = createRef<HTMLSlotElement>()
  protected guardedRender() {
    this.#updateGrid()

    return html`
      <div class="one-ux-element--root">
        ${!this.compact
          ? html`<span class="label">
              ${this.label} ${this.required ? html` <span class="asterisk">*</span> ` : nothing}
            </span>`
          : nothing}

        <div
          role="group"
          aria-label="${this.label}"
          aria-required=${ifDefined(this.required ? 'true' : undefined)}
          ${this._ariaTarget()}
          class=${classMap({
            'field-set': true,
            error: this.errors ? this.errors.length : false
          })}
          style=${styleMap({
            'grid-template': this.#grid.template
          })}
          @keydown=${this.#handleKeydown}
        >
          <slot
            ${ref(this.#slotElement)}
            @slotchange=${() => {
              this.requestUpdate()
            }}
          ></slot>
        </div>

        ${ErrorsPopout({
          reference: 'previous',
          errors: this.errors,
          hidden: this.hideErrors
        })}
      </div>
    `
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const assignedElements = this.#slotElement.value!.assignedElements({ flatten: true }) as HTMLElement[]
    const currentElementIndex = assignedElements.indexOf(event.target as HTMLElement)
    const currentPosition = this.#grid.indexToPos[currentElementIndex]
    const getElementFromPosition = (column: number, row: number) =>
      assignedElements[this.#grid.posToIndex[`${column}-${row}`]]

    switch (event.code) {
      case keyCodes.UP:
        event.preventDefault()
        assignedElements[Math.max(currentElementIndex - 1, 0)].focus()
        break
      case keyCodes.DOWN:
        event.preventDefault()
        assignedElements[Math.min(currentElementIndex + 1, assignedElements.length - 1)].focus()
        break
      case keyCodes.HOME:
        event.preventDefault()
        assignedElements[0].focus()
        break
      case keyCodes.END:
        event.preventDefault()
        assignedElements[assignedElements.length - 1].focus()
        break
      case keyCodes.LEFT: {
        event.preventDefault()
        const $prevElement =
          getElementFromPosition(currentPosition.column - 1, currentPosition.row) ||
          getElementFromPosition(this.columns, currentPosition.row - 1)
        $prevElement?.focus()
        break
      }
      case keyCodes.RIGHT: {
        event.preventDefault()
        const $nextElement =
          getElementFromPosition(currentPosition.column + 1, currentPosition.row) ||
          getElementFromPosition(1, currentPosition.row + 1)
        $nextElement?.focus()
        break
      }
    }
  }

  #updateGrid() {
    this.#grid = {
      template: null,
      indexToPos: {},
      posToIndex: {}
    }

    if (!this.#slotElement.value) {
      return
    }

    const slottedElements = this.#slotElement.value!.assignedElements({ flatten: true }) as HTMLElement[]
    const elementCount = slottedElements.length
    const numberOfColumns = this.columns
    const numberOfRows = Math.ceil(slottedElements.length / numberOfColumns)

    this.#grid.template = `repeat(${numberOfRows}, auto) / repeat(${numberOfColumns}, auto)`

    let currentColumn = 1
    let currentRow = 1
    slottedElements.forEach(($el, index) => {
      $el.style.setProperty('--contextual-one-ux-field-set-element--grid-column', currentColumn + '')
      $el.style.setProperty('--contextual-one-ux-field-set-element--grid-row', currentRow + '')

      this.#grid.indexToPos[index] = { column: currentColumn, row: currentRow }
      this.#grid.posToIndex[`${currentColumn}-${currentRow}`] = index

      const remainingElements = elementCount - (index + 1)
      const remainingColumns = numberOfColumns - currentColumn

      if (currentRow < numberOfRows && remainingElements > remainingColumns) {
        currentRow++
      } else {
        currentRow = 1
        currentColumn++
      }
    })
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-field-set': OneUxFieldSetElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-field-set': OneUxFieldSetElement
    }
  }
}
