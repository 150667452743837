import { html, nothing } from 'lit'
import { legendItem, barItem, barChartType } from '../types'
import { Distribution } from './Distribution'
import { Grouped } from './Grouped'
import { Stacked } from './Stacked'
import { AxisScale } from '../../common/AxisScale'

export function Bar({
  type,
  legend,
  bar,
  axisScale,
  width,
  implicit
}: {
  type: barChartType
  legend: legendItem[]
  bar: barItem
  axisScale: AxisScale
  width: number
  implicit: boolean
}) {
  if (!width) {
    return nothing
  }

  switch (type) {
    case 'distribution':
      return Distribution({
        legend,
        bar,
        width,
        implicit
      })
    case 'grouped':
      return Grouped({
        legend,
        bar,
        axisScale,
        width,
        implicit
      })
    case 'stacked':
      return Stacked({
        legend,
        bar,
        axisScale,
        width,
        implicit
      })
    default:
      return html`<div>Invalid bar type "${type}"</div>`
  }
}
