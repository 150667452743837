import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement'

import { Constructor } from '../utils'
import { InternalValueChangedEvent } from '@/one-ux/events/internal/InternalValueChangedEvent'
import { InternalValidityVisibleEvent } from '@/one-ux/events/internal/InternalValidityVisibleEvent'

export interface IValue<TValue> {
  value: TValue
  initialValue: TValue
  internalValue: TValue
  _applyUserValue(value: TValue): void
}

export type valueFactoryOptions<TValue, TExtraProperties = void> = ThisType<
  OneUxElement & IValue<TValue> & TExtraProperties
> &
  Partial<{
    type: typeof String | typeof Boolean | typeof Number | typeof Array | typeof Object
    reflect: boolean
    converter(value: string | null): TValue
    getter(): TValue
    setter(value: TValue): void
  }>

export const ValueFactory = <TValue, TExtraProperties = void>(
  options: valueFactoryOptions<TValue, TExtraProperties>
) => {
  const hasType = Object.hasOwn(options, 'type')
  const hasConverter = Object.hasOwn(options, 'converter')
  if (hasType && hasConverter) {
    throw new Error('The options type and converter are mutually exclusive')
  }

  return <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
    class ValueClass extends SuperClass {
      static get __one_ux_mixin_value__() {
        return true as const
      }

      #allowInitial = true
      constructor(...args: any[]) {
        super(...args)
        requestAnimationFrame(() => {
          this.#allowInitial = false
        })
      }

      internalValue!: TValue
      /*
       * Holds the current value
       */
      @property({ type: options.type, reflect: options.reflect, converter: options.converter })
      public get value(): TValue {
        if (options.getter) {
          return options.getter.call(this)
        }
        return this.internalValue
      }
      public set value(value: TValue) {
        if (this.#allowInitial && typeof this.initialValue === 'undefined') {
          this.initialValue = value
        }

        const oldValue = this.internalValue
        this.internalValue = value

        if (options.setter) {
          options.setter.call(this, value)
        } else {
          this.requestUpdate('value', oldValue)
        }
        this.dispatchEvent(new InternalValueChangedEvent())
      }
      initialValue?: TValue

      public _applyUserValue(value: TValue) {
        this.value = value
        this.dispatchEvent(new InternalValidityVisibleEvent())
      }
    }
    return ValueClass as Constructor<IValue<TValue>> & TSuperClass
  }
}
