import { html, nothing } from 'lit'
import { serie } from '../../types'
import { ValueFormatter } from '../../../common/format'
import { Legend } from '../../legend/Legend'
import { mapKeyLegendData } from '../../legend/mapLegendData'

export function KeyHoverPopout(referenceElement: Element, series: serie[], valueFormatter: ValueFormatter) {
  const key = (referenceElement as HTMLElement).dataset.key
  if (!key) {
    return nothing
  }

  const legendData = mapKeyLegendData(series, key)
  if (!legendData.length) {
    return nothing
  }

  return html`
    <one-ux-popout
      aria-hidden="true"
      style="
        pointer-events: none;
        color: var(--one-ux-palette--brand-neutral-600);
        "
      .reference=${referenceElement}
      direction="horizontal"
      alignment="center"
      .offsetReference=${-referenceElement.clientWidth / 2 + 8}
    >
      ${legendData.map((x) => Legend(x.type, x.color, x.name, valueFormatter.formatValue(Number(x.value), false)))}
    </one-ux-popout>
  `
}
