import { CustomElementBase } from '../CustomElementBase'

export class DynamicInputSelectSearchElement extends CustomElementBase {
  #dynamicCaretElement = document.createElement('dynamic-input-select-search-caret')

  onInitialized() {
    this.#render()
  }

  static get observedAttributes() {
    return ['hidden']
  }

  onAttributeRendered({ name }) {
    switch (name) {
      case 'hidden':
        this.#hiddenChanged()
        break
    }
  }

  #term = ''
  get term() {
    return this.#term
  }

  set term(term) {
    this.#term = term
    this.#render()
  }

  #cursorIndex = 0
  get cursorIndex() {
    return this.#cursorIndex
  }

  set cursorIndex(cursorIndex) {
    this.#cursorIndex = cursorIndex
    this.#render()
  }

  #render() {
    this.innerHTML = ''
    this.appendChild(document.createTextNode(this.term.slice(0, this.cursorIndex)))
    this.appendChild(this.#dynamicCaretElement)
    this.appendChild(document.createTextNode(this.term.slice(this.cursorIndex)))
  }

  #hiddenChanged() {
    if (this.hidden) {
      this.removeAttribute('aria-live')
    } else {
      this.setAttribute('aria-live', 'polite')
    }
  }
}
