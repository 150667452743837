import { TemplateResult } from 'lit'
import { GroupData, ItemData, OptionData, RootCallbacks } from '../../types'
import { Divider } from './Divider'
import { Group } from './Group'
import { Option } from './Option'

export type MenuItemOptions<T extends ItemData> = {
  item: T
  currentItem?: ItemData
  callbacks: RootCallbacks
  rootDisabled: boolean
  itemTypeIndex: number
}

export function MenuItem(options: MenuItemOptions<ItemData>): TemplateResult | string {
  if (options.item.type == 'divider') {
    return Divider()
  }

  if (options.item.type == 'group') {
    return Group(options as MenuItemOptions<GroupData>)
  }

  return Option(options as MenuItemOptions<OptionData>)
}
