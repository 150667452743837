import { html, nothing } from 'lit'
import { weight } from '../../../common/mixins/Weight'
import { Legend } from '../../common/components/Legend'
import { distributionMetadata, distributionValues } from '../../common/types'
import { BarSegments } from './BarSegments'
import { styleMap } from 'lit/directives/style-map.js'
import { Axis } from './Axis'
import { Sigil, sigilWidth } from './Sigil'

const weightStyles: Record<
  weight,
  {
    svgHeight: number
  }
> = {
  high: { svgHeight: 16 },
  normal: { svgHeight: 12 },
  low: { svgHeight: 8 }
}

export function ShapeBar({
  metadata,
  distribution,
  weight,
  compact,
  implicit,
  slots,
  width
}: {
  metadata: distributionMetadata[]
  distribution: distributionValues
  weight: weight
  compact: boolean
  implicit: boolean
  slots: {
    hasSummary: boolean
    hasDescription: boolean
    summaryChanged: (event: Event) => void
    descriptionChanged: (event: Event) => void
  }
  width: number
}) {
  const weightStyle = weightStyles[weight]

  const axisHeight = compact ? 0 : 20
  const axisWidth = width - sigilWidth - 4
  const showLegend = weight === 'high' && !compact && !implicit

  return html`
    <div class="barShape">
      <div
        class="slotsAndLegend"
        style=${styleMap({
          display: slots.hasSummary || slots.hasDescription || showLegend ? null : 'none'
        })}
      >
        <div
          class="slots"
          style=${styleMap({
            display: slots.hasSummary || slots.hasDescription ? null : 'none'
          })}
        >
          ${weight === 'high'
            ? html`
                <slot
                  name="summary"
                  @slotchange=${slots.summaryChanged}
                  class="summary one-ux-typography--heading-500"
                  style=${styleMap({ display: slots.hasSummary ? null : 'none' })}
                ></slot>
              `
            : nothing}

          <slot
            name="description"
            @slotchange=${slots.descriptionChanged}
            class="description one-ux-typography--label-300"
            style=${styleMap({ display: slots.hasDescription ? null : 'none' })}
          ></slot>

          ${weight !== 'high'
            ? html`
                <span
                  class="summary one-ux-typography--label-300"
                  style=${styleMap({ display: slots.hasSummary ? null : 'none' })}
                >
                  (<slot name="summary" @slotchange=${slots.summaryChanged}></slot>)
                </span>
              `
            : nothing}
        </div>

        ${showLegend
          ? Legend({
              metadata,
              values: distribution
            })
          : nothing}
      </div>

      <svg width="${width}" height="${weightStyle.svgHeight + axisHeight}" role="presentation">
        <g>
          ${BarSegments({
            distribution,
            metadata,
            width: axisWidth,
            x: 0,
            y: 0,
            weight
          })}
        </g>

        ${compact
          ? Sigil({
              svgWidth: width,
              svgHeight: weightStyle.svgHeight,
              weight
            })
          : Axis({
              numberOfTicks: 6,
              showTicks: true,
              axisWidth: axisWidth,
              x: 0,
              y: weightStyle.svgHeight + 2
            })}
      </svg>
    </div>
  `
}
