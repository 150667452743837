import { OneUxElement } from '../../../common/OneUxElement'
import { PropertyValues, html } from 'lit'
import { customElement, state } from 'lit/decorators.js'
import { createRef, Ref, ref } from 'lit/directives/ref.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { style } from './style'
import { ItemData, OptionData, GroupData, RootCallbacks } from './types'
import { scrollElementIntoView, traverseNodes } from '../../../common/utils'
import { StyledFactory } from '../../../common/mixins/Styled'
import { Focusable } from '../../../common/mixins/Focusable'
import { Disabled } from '../../../common/mixins/Disabled'
import { MenuItem } from './components/items/MenuItem'
import { MenuKeyboardHandler } from './MenuKeyboardHandler'
import { ContextConsumer } from '@lit/context'
import { menuContext } from '../IMenuContext'
import { findIndexBasedOnType } from './utils'

const Styled = StyledFactory(style)

const BaseClass = Disabled(Focusable(Styled(OneUxElement)))

type RootRefs = {
  $button: Ref<HTMLElement>
  $menu: Ref<HTMLElement>
}

const ACTIVE_ELEMENT_ID = 'active-menu-item'

/**
 * $$internal$$
 */
@customElement('contextual-one-ux-menu')
export class ContextualOneUxMenuElement extends BaseClass {
  #state = new ContextConsumer(this, {
    context: menuContext,
    callback: (state) => {
      this.#onChangeCurrentItem(state.items[0])
    },
    subscribe: true
  })

  @state()
  private _currentItem?: ItemData
  constructor() {
    super()

    this.addEventListener('mouseleave', () => {
      const isActive = (this.getRootNode() as any).activeElement === this
      if (!isActive) {
        this.#collapseItems()
      }
    })

    this.addEventListener('blur', () => {
      this.#collapseItems()
    })
  }

  protected willUpdate(changedProperties: PropertyValues): void {
    if (changedProperties.has('_currentItem')) {
      requestAnimationFrame(() => {
        const $active = this.shadowRoot?.getElementById(ACTIVE_ELEMENT_ID)
        if ($active) {
          let $scroll = $active.closest('one-ux-scroll')
          if (!$scroll) {
            let $root = $active.getRootNode() as Node | null
            while ($root instanceof ShadowRoot) {
              $scroll = $root.host.closest('one-ux-scroll')
              $root = $scroll ? null : $root.host.getRootNode()
            }
          }

          if ($scroll) {
            scrollElementIntoView($scroll, $active)
          }
        }
      })
    }
  }

  protected render() {
    const items = this.#state.value!.items.map((item: ItemData) =>
      MenuItem({
        callbacks: this.#callbacks,
        item,
        currentItem: this._currentItem,
        rootDisabled: this.disabled,
        itemTypeIndex: findIndexBasedOnType(this.#state.value!.items, item)
      })
    )

    const keyboardHandler = new MenuKeyboardHandler({
      callbacks: this.#callbacks,
      items: this.#state.value!.items,
      currentItem: this._currentItem
    })

    return html`<one-ux-container
      ${ref(this.#refs.$menu)}
      role="menu"
      indent="normal"
      aria-activedescendant=${ifDefined(this._currentItem ? ACTIVE_ELEMENT_ID : undefined)}
      aria-disabled=${this.disabled}
      tabindex=${!this.disabled ? 0 : -1}
      @keydown=${keyboardHandler.handleKeydown}
      class="one-ux-element--root menu"
    >
      ${items}
    </one-ux-container> `
  }

  #onChangeCurrentItem = (item?: ItemData, expandNewItem?: boolean) => {
    if (item) {
      this._currentItem = item

      traverseNodes(this.#state.value!.items, (item) => {
        if (item.type === 'group') {
          const group = item as GroupData
          group.expanded = false
        }
      })

      if (expandNewItem && item.type === 'group') {
        const group = item as GroupData
        group.expanded = true
      }

      let parent = item.parent
      while (parent) {
        parent.expanded = true
        parent = parent.parent
      }

      this.requestUpdate()
    }
  }

  #onActivateItem = (item?: ItemData) => {
    if (item?.type === 'option') {
      const option = item as OptionData
      if (this.disabled || option.disabled) {
        return
      }
      if (option.action) {
        option.action()
      }
      this.dispatchEvent(new CustomEvent('option', { detail: option.value, composed: true }))
    } else if (item?.type === 'group') {
      const group = item as GroupData
      group.expanded = !group.expanded
      this.requestUpdate()
    }
  }

  #collapseItems = () => {
    this.#onChangeCurrentItem(this.#state.value!.items[0])
    traverseNodes(this.#state.value!.items, (item) => {
      if (item.type === 'group') {
        const group = item as GroupData
        group.expanded = false
        this.requestUpdate()
      }
    })
  }

  #refs: RootRefs = {
    $button: createRef(),
    $menu: createRef()
  }

  #callbacks: RootCallbacks = {
    onChangeCurrentItem: this.#onChangeCurrentItem,
    onActivateItem: this.#onActivateItem
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'contextual-one-ux-menu': ContextualOneUxMenuElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'contextual-one-ux-menu': ContextualOneUxMenuElement
    }
  }
}
