import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement'

import { Constructor } from '../utils'

export interface IHidableTooltip {
  hideTooltip: boolean
}

export const HidableTooltip = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class HidableTooltipClass extends SuperClass {
    /**
     * Prevents any internal tooltip from being rendered.
     * Please note that this can make a component substantially harder to use for the end user.
     */
    @property({ attribute: 'hide-tooltip', type: Boolean })
    public hideTooltip = false
  }

  return HidableTooltipClass as Constructor<IHidableTooltip> & TSuperClass
}
