import { InputSegment } from '../types'

export const calculateGauge = (segments: InputSegment[]) => {
  if (segments.length === 1) {
    return { totalValue: segments[0].value, gaugePercent: 0.5 }
  }

  const normalizedIndex = segments.map((_, i) => i / (segments.length - 1))

  const totalValue = segments.reduce((acc, current) => acc + current.value, 0)
  const gaugePercent = Number(
    normalizedIndex
      .map((normalIndex, i) => normalIndex * segments[i].value)
      .reduce((acc, current) => acc + current, 0) / Math.max(1, totalValue)
  )

  return { totalValue, gaugePercent }
}
