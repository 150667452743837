import { CustomElementBase } from '../CustomElementBase'

export class InputSelectOptionGroupElement extends CustomElementBase {
  onInitialized() {
    this.setAttribute('role', 'group')
    this.addEventListener('click', this.#handleClick, true)
    this.addEventListener('#option-filtered', this.#handleOptionFiltered, true)
  }

  static get observedAttributes() {
    return ['label']
  }

  onAttributeRendered({ name }) {
    switch (name) {
      case 'label':
        this.#labelChanged()
        break
    }
  }

  get label() {
    return this.getAttribute('label')
  }

  set label(label) {
    this.setAttribute('label', label)
  }

  #handleClick = (event) => {
    if (this.hasAttribute('disabled')) {
      event.stopPropagation()
    }
  }

  #updateFilteredRequest
  #handleOptionFiltered = () => {
    cancelAnimationFrame(this.#updateFilteredRequest)
    this.#updateFilteredRequest = requestAnimationFrame(() => {
      this.toggleAttribute('state-filtered', !this.querySelector('input-select-option:not([state-filtered])'))
    })
  }

  #labelChanged() {
    if (this.label != null) {
      this.setAttribute('aria-label', this.label)
    } else {
      this.removeAttribute('aria-label')
    }
  }
}
