import { select } from 'd3-selection'
import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalData, InternalSegment } from '../types'
import { createArcGenerator } from '../createArcGenerator'
import { getArcsCenter } from '../utils'

export const renderSegments = (host: OneUxGaugeChartElement, data: InternalData) => {
  const arcGenerator = createArcGenerator(host, data)
  const svgSelection = select(host.$svg)
  svgSelection
    .selectAll<SVGPathElement, InternalSegment>('path.gauge--segment')
    .data(data.segments, (d) => d.name)
    .join(
      (enter) =>
        enter
          .append('path')
          .attr('class', 'gauge--segment')
          .attr('d', arcGenerator)
          .attr('transform', getSegmentTransform(host))
          .attr('fill', (d) => getSegmentColor(d)),
      (update) =>
        update
          .attr('d', arcGenerator)
          .attr('transform', getSegmentTransform(host))
          .attr('fill', (d) => getSegmentColor(d)),
      (exit) => exit.remove()
    )
}

const getSegmentTransform = (host: OneUxGaugeChartElement) => {
  const arcsCenter = getArcsCenter(host)
  return `translate(${arcsCenter[0]}, ${arcsCenter[1]})`
}

const getSegmentColor = (segment: InternalSegment) => {
  if (segment.highlighted) {
    return `var(--one-ux-palette--${segment.color})`
  }

  return 'var(--one-ux-palette--brand-neutral-200)'
}
