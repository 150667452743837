import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalSnapSizeConfig } from '../types'

export const getSnapSizeConfig = (host: OneUxGaugeChartElement): InternalSnapSizeConfig => {
  const closestReferenceSize = [32, 64, 128, 256, 512].reduce((prev, curr) =>
    Math.abs(curr - host._boundary!.width) < Math.abs(prev - host._boundary!.width) ? curr : prev
  )

  switch (closestReferenceSize) {
    case 32:
      return {
        arcsMargin: 1,
        segmentDefaultSize: 2,
        segmentHighlightSize: 4,
        pointerSize: 5,
        pointerOffset: -3,
        segmentPadAngleDegrees: 4,
        segmentCornerRadius: 1,
        textLabelOffset: 4,
        inlineStatsOffsetTop: 2,
        inlineStatsIconSize: 12,
        inlineStatsHorizontalGap: 4,
        inlineStatsVerticalGap: 0,
        inlineStatsTextGap: 2
      }
    case 64:
      return {
        arcsMargin: 2,
        segmentDefaultSize: 4,
        segmentHighlightSize: 8,
        pointerSize: 6,
        pointerOffset: -4,
        segmentPadAngleDegrees: 2,
        segmentCornerRadius: 2,
        textLabelOffset: 0,
        inlineStatsOffsetTop: 4,
        inlineStatsIconSize: 12,
        inlineStatsHorizontalGap: 4,
        inlineStatsVerticalGap: 4,
        inlineStatsTextGap: 2
      }
    case 128:
      return {
        arcsMargin: 4,
        segmentDefaultSize: 6,
        segmentHighlightSize: 12,
        pointerSize: 10,
        pointerOffset: -6,
        segmentPadAngleDegrees: 1.5,
        segmentCornerRadius: 2,
        textLabelOffset: 8,
        inlineStatsOffsetTop: 8,
        inlineStatsIconSize: 12,
        inlineStatsHorizontalGap: 8,
        inlineStatsVerticalGap: 12,
        inlineStatsTextGap: 4
      }
    case 256:
      return {
        arcsMargin: 8,
        segmentDefaultSize: 8,
        segmentHighlightSize: 16,
        segmentPadAngleDegrees: 1.5,
        segmentCornerRadius: 2,
        pointerSize: 10,
        pointerOffset: -6,
        textLabelOffset: 12,
        inlineStatsOffsetTop: 8,
        inlineStatsIconSize: 12,
        inlineStatsHorizontalGap: 8,
        inlineStatsVerticalGap: 12,
        inlineStatsTextGap: 4
      }
    default:
      return {
        arcsMargin: 16,
        segmentDefaultSize: 8,
        segmentHighlightSize: 16,
        pointerSize: 20,
        pointerOffset: -8,
        segmentPadAngleDegrees: 1,
        segmentCornerRadius: 2,
        textLabelOffset: 16,
        inlineStatsOffsetTop: 8,
        inlineStatsIconSize: 12,
        inlineStatsHorizontalGap: 8,
        inlineStatsVerticalGap: 12,
        inlineStatsTextGap: 4
      }
  }
}
