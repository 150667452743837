import { vector } from '../../common/types'
import { distance, lerp } from '../../common/vector'
import { bounds } from '../types'

type bezierCurve = [vector, vector, vector, vector]

/*
  tension - describes line tension (0 = curvy, 1 = straight)
  alpha - describes which type of catmull (0 = uniform, 0.5 = centripedal, 1 = chordal)
*/
export const calculateCatmullBezierCurves = (vertices: vector[], alpha = 0.5, tension = 0): bezierCurve[] => {
  const result: bezierCurve[] = []

  if (vertices.length < 4) {
    return result
  }

  for (let i = 0; i < vertices.length - 3; i++) {
    const [x0, y0] = vertices[i]
    const [x1, y1] = vertices[i + 1]
    const [x2, y2] = vertices[i + 2]
    const [x3, y3] = vertices[i + 3]

    const t0 = t(vertices, i, alpha)
    const t1 = t(vertices, i + 1, alpha)
    const t2 = t(vertices, i + 2, alpha)
    const t3 = t(vertices, i + 3, alpha)

    const cp0x =
      ((1 - tension) * (t2 - t1) * ((x1 - x0) / (t1 - t0) - (x2 - x0) / (t2 - t0) + (x2 - x1) / (t2 - t1))) / 3

    const cp0y =
      ((1 - tension) * (t2 - t1) * ((y1 - y0) / (t1 - t0) - (y2 - y0) / (t2 - t0) + (y2 - y1) / (t2 - t1))) / 3

    const cp1x =
      ((1 - tension) * (t2 - t1) * ((x2 - x1) / (t2 - t1) - (x3 - x1) / (t3 - t1) + (x3 - x2) / (t3 - t2))) / 3

    const cp1y =
      ((1 - tension) * (t2 - t1) * ((y2 - y1) / (t2 - t1) - (y3 - y1) / (t3 - t1) + (y3 - y2) / (t3 - t2))) / 3

    result.push([
      [x1, y1],
      [x1 + cp0x, y1 + cp0y],
      [x2 - cp1x, y2 - cp1y],
      [x2, y2]
    ])
  }

  return result
}

const t = (vertices: vector[], i: number, alpha: number): number => {
  if (i === 0) {
    return 0
  }

  const [x0, y0] = vertices[i - 1]
  const [x1, y1] = vertices[i]

  return t(vertices, i - 1, alpha) + Math.pow(distance(x0, y0, x1, y1), alpha)
}

export const fitBezierCurveToBoundary = (curve: bezierCurve, boundary: bounds, epsilon = 0.001) => {
  const y0 = curve[0][1]
  const y1 = curve[3][1]

  const isY0TopSafe = y0 >= boundary.top
  const isY0BottomSafe = y0 <= boundary.bottom
  const isY0Safe = isY0TopSafe && isY0BottomSafe

  const isY1TopSafe = y1 >= boundary.top
  const isY1BottomSafe = y1 <= boundary.bottom
  const isY1Safe = isY1TopSafe && isY1BottomSafe
  const isY0Y1SameSideOutOfBounds = (!isY0TopSafe && !isY1TopSafe) || (!isY0BottomSafe && !isY1BottomSafe)

  if (isY0Safe && isY1Safe) {
    return curve
  } else if (!isY0Safe && isY1Safe) {
    if (!isY0TopSafe) {
      const t = rootFindInterpolationValue(curve, (y) => y > boundary.top, epsilon)
      if (t) {
        return subdivideBezierCurve(curve, t)[1]
      }
    } else {
      const t = rootFindInterpolationValue(curve, (y) => y < boundary.bottom, epsilon)
      if (t) {
        return subdivideBezierCurve(curve, t)[1]
      }
    }
  } else if (isY0Safe && !isY1Safe) {
    if (!isY1TopSafe) {
      const t = rootFindInterpolationValue(curve, (y) => y < boundary.top, epsilon)
      if (t) {
        return subdivideBezierCurve(curve, t)[0]
      }
    } else {
      const t = rootFindInterpolationValue(curve, (y) => y > boundary.bottom, epsilon)
      if (t) {
        return subdivideBezierCurve(curve, t)[0]
      }
    }
  } else if (!isY0Y1SameSideOutOfBounds) {
    if (!isY0BottomSafe && !isY1TopSafe) {
      const t0 = rootFindInterpolationValue(curve, (y) => y < boundary.bottom, epsilon) ?? 0
      const curve0 = subdivideBezierCurve(curve, t0)[1]
      const t1 = rootFindInterpolationValue(curve0, (y) => y < boundary.top, epsilon) ?? 0
      return subdivideBezierCurve(curve0, t1)[0]
    } else if (!isY0TopSafe && !isY1BottomSafe) {
      const t0 = rootFindInterpolationValue(curve, (y) => y > boundary.top, epsilon) ?? 0
      const curve0 = subdivideBezierCurve(curve, t0)[1]
      const t1 = rootFindInterpolationValue(curve0, (y) => y > boundary.bottom, epsilon) ?? 0
      return subdivideBezierCurve(curve0, t1)[0]
    }
  }

  return undefined
}

const rootFindInterpolationValue = (curve: bezierCurve, condition: (y: number) => boolean, epsilon = 0.001) => {
  const steps = 1 / epsilon
  for (let i = 0; i < steps; i++) {
    const t = (i + 1) / steps
    const y = interpolateBezierCurveY(curve, t)
    if (condition(y)) {
      return i / steps
    }
  }

  return undefined
}

const interpolateBezierCurveY = (curve: bezierCurve, t: number) => {
  const y0 = curve[0][1]
  const y1 = curve[1][1]
  const y2 = curve[2][1]
  const y3 = curve[3][1]

  return (
    (1 - t) * (1 - t) * (1 - t) * y0 + 3 * ((1 - t) * (1 - t)) * t * y1 + 3 * (1 - t) * (t * t) * y2 + t * t * t * y3
  )
}

const subdivideBezierCurve = (curve: bezierCurve, t: number): [bezierCurve, bezierCurve] => {
  {
    t = Math.min(Math.abs(t), 1)

    const [x0, y0] = curve[0]
    const [x1, y1] = curve[1]
    const [x2, y2] = curve[2]
    const [x3, y3] = curve[3]

    const [x4, y4] = lerp(x0, y0, x1, y1, t)
    const [x5, y5] = lerp(x1, y1, x2, y2, t)
    const [x6, y6] = lerp(x2, y2, x3, y3, t)
    const [x7, y7] = lerp(x4, y4, x5, y5, t)
    const [x8, y8] = lerp(x5, y5, x6, y6, t)
    const [x9, y9] = lerp(x7, y7, x8, y8, t)

    return [
      [
        [x0, y0],
        [x4, y4],
        [x7, y7],
        [x9, y9]
      ],
      [
        [x9, y9],
        [x8, y8],
        [x6, y6],
        [x3, y3]
      ]
    ]
  }
}
