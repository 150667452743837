export type Constructor<T> = new (...args: any[]) => T

export const keyCodes = {
  BACKSPACE: 'Backspace',
  ESCAPE: 'Escape',
  RETURN: 'Enter',
  NUMPADRETURN: 'NumpadEnter',
  SPACE: 'Space',
  PAGEUP: 'PageUp',
  PAGEDOWN: 'PageDown',
  END: 'End',
  HOME: 'Home',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown'
}

export function findPreviousBefore<T>(items: T[], startItem: T, critera: (item: T) => boolean) {
  let index = items.indexOf(startItem)
  if (index < 0) {
    return null
  }
  while (--index >= 0) {
    const candidate = items[index]
    if (critera(candidate)) {
      return candidate
    }
  }
  return null
}

export function findNextAfter<T>(items: T[], startItem: T, critera: (item: T) => boolean) {
  let index = items.indexOf(startItem)
  if (index < 0) {
    return null
  }
  while (++index < items.length) {
    const candidate = items[index]
    if (critera(candidate)) {
      return candidate
    }
  }
  return null
}

export function traverseNodes<T>(nodes: T[], callback: (node: T) => void, childrenPropertyName = 'children') {
  if (!nodes) {
    return
  }
  nodes.forEach((node) => {
    callback(node)
    traverseNodes((node as any)[childrenPropertyName], callback)
  })
}

export function scrollElementIntoView($scroll?: Element, $element?: Element) {
  if (!$scroll || !$element) {
    return
  }

  const elementRect = $element.getBoundingClientRect()
  const scrollRect = $scroll.getBoundingClientRect()

  if (elementRect.bottom > scrollRect.bottom) {
    $scroll.scrollTop += elementRect.bottom - scrollRect.bottom
  } else if (elementRect.top < scrollRect.top) {
    $scroll.scrollTop -= scrollRect.top - elementRect.top
  }
}
