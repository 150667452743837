import { OneUxElement } from '../common/OneUxElement'
import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { Focusable } from '../common/mixins/Focusable'
import { Disabled } from '../common/mixins/Disabled'
import { MenuContextMixin } from '../contexts/menu/MenuContextMixin'

const BaseClass = Disabled(Focusable(OneUxElement))

/**
 * Nested menu of actions that can be executed either by listening on "option" event or define
 * an action on the item itself, in the latter property items must be set on the DOM instance.
 */
@customElement('one-ux-menu')
export class OneUxMenuElement extends MenuContextMixin(BaseClass) {
  protected render() {
    return html`<contextual-one-ux-menu
      class="one-ux-element--root"
      ?disabled=${this.disabled}
    ></contextual-one-ux-menu> `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-menu': OneUxMenuElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-menu': OneUxMenuElement
    }
  }
}
