var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _ToastModule_instances, _ToastModule_show;
import { PDRModule } from '../PDRModule';
import Toast from './Toast';
export class ToastModule extends PDRModule {
    constructor() {
        super(...arguments);
        _ToastModule_instances.add(this);
    }
    async confirmation(title, description, duration, copyable) {
        return __classPrivateFieldGet(this, _ToastModule_instances, "m", _ToastModule_show).call(this, 'confirmation', title, description, duration, copyable);
    }
    async info(title, description, duration, copyable) {
        return __classPrivateFieldGet(this, _ToastModule_instances, "m", _ToastModule_show).call(this, 'info', title, description, duration, copyable);
    }
    async warning(title, description, duration, copyable) {
        return __classPrivateFieldGet(this, _ToastModule_instances, "m", _ToastModule_show).call(this, 'warning', title, description, duration, copyable);
    }
}
_ToastModule_instances = new WeakSet(), _ToastModule_show = async function _ToastModule_show(type, title, description, duration, copyable) {
    const toast = new Toast(type, title, description, duration, !!copyable);
    return toast.show();
};
