import { ease } from '../common/directives/svgTransition'

export const timing = (animate: boolean) =>
  animate
    ? {
        easing: ease.outCubic,
        duration: 500
      }
    : {
        easing: ease.outCubic,
        duration: 0
      }
