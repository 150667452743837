import { AnimatedCustomElement } from '../AnimatedCustomElement'

export class LayoutOverlayElement extends AnimatedCustomElement {
  #dynamicScreenOffsetsElement = document.createElement('dynamic-layout-overlay-screen-offsets')

  onConnected() {
    this.append(this.#dynamicScreenOffsetsElement)
  }

  animate() {
    const parentRect = this.parentElement.getBoundingClientRect()
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    this.style.left = parentRect.left + 'px'
    this.style.top = parentRect.top + 'px'
    this.style.width = parentRect.width + 'px'
    this.style.height = parentRect.height + 'px'

    const content = this.content
    if (content) {
      const screenOffsetsRect = this.#dynamicScreenOffsetsElement.getBoundingClientRect()
      const contentLeftEdge = Math.max(-screenOffsetsRect.left, 0)
      const contentRightEdge = Math.max(screenOffsetsRect.right - windowWidth, 0)
      const contentTopEdge = Math.max(-screenOffsetsRect.top, 0)
      const contentBottomEdge = Math.max(screenOffsetsRect.bottom - windowHeight, 0)

      content.style.left = Math.min(contentLeftEdge, parentRect.width) + 'px'
      content.style.right = contentRightEdge + 'px'
      content.style.top = Math.min(contentTopEdge, parentRect.height) + 'px'
      content.style.bottom = contentBottomEdge + 'px'
    }
  }

  get content() {
    for (let i = 0; i < this.children.length; ++i) {
      const $child = this.children[i]
      if ($child.tagName === 'LAYOUT-OVERLAY-CONTENT') {
        return $child
      }
    }
    return null
  }
}
