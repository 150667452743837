import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { OneUxElement } from '../common/OneUxElement'

import { Implicit } from '../common/mixins/Implicit'
import { Focusable } from '../common/mixins/Focusable'
import { Disabled } from '../common/mixins/Disabled'
import { TreeContextMixin } from '../contexts/tree/TreeContextMixin'
import { StyledFactory } from '../common/mixins/Styled'
import { style } from './style'

const Styled = StyledFactory(style)
const BaseClass = Disabled(Focusable(Implicit(Styled(OneUxElement))))

/**
 * Tree component for selecting single och multiple nodes
 */
@customElement('one-ux-tree')
export class OneUxTreeElement extends TreeContextMixin(BaseClass) {
  protected render() {
    return html`
      <contextual-one-ux-tree
        class="one-ux-element--root"
        ?disabled=${this.disabled}
        ?implicit=${this.implicit}
        @input="${(event: Event) => this.dispatchEvent(new Event(event.type, event))}"
      ></contextual-one-ux-tree>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tree': OneUxTreeElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tree': OneUxTreeElement
    }
  }
}
