var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _PDRLibrary_debug, _PDRLibrary_utils, _PDRLibrary_jsx, _PDRLibrary_prompt, _PDRLibrary_mfe, _PDRLibrary_session, _PDRLibrary_api, _PDRLibrary_element, _PDRLibrary_toast, _PDRLibrary_test, _PDRLibrary_libraries, _PDRLibrary_log;
import { PDRModule } from './PDRModule';
import { ApiModule } from './api/ApiModule';
import { DebugModule } from './debug/DebugModule';
import { ElementModule } from './element/ElementModule';
import { JSXModule } from './jsx/JSXModule';
import { MicrofrontendsModule } from './microfrontends/MicrofrontendsModule';
import { PromptModule } from './prompt/PromptModule';
import { ToastModule } from './toast/ToastModule';
import { TestModule } from './test/TestModule';
import { SessionModule } from './session/SessionModule';
import { UtilsModule } from './utils/UtilsModule';
import { LibrariesModule } from './libraries/LibrariesModule';
import { LogModule } from './log/LogModule';
import { log } from './log';
export class PDRLibrary {
    constructor() {
        this._started = false;
        this._initialized = false;
        _PDRLibrary_debug.set(this, new DebugModule());
        _PDRLibrary_utils.set(this, new UtilsModule());
        _PDRLibrary_jsx.set(this, new JSXModule());
        _PDRLibrary_prompt.set(this, new PromptModule());
        _PDRLibrary_mfe.set(this, new MicrofrontendsModule());
        _PDRLibrary_session.set(this, new SessionModule());
        _PDRLibrary_api.set(this, new ApiModule());
        _PDRLibrary_element.set(this, new ElementModule());
        _PDRLibrary_toast.set(this, new ToastModule());
        _PDRLibrary_test.set(this, new TestModule());
        _PDRLibrary_libraries.set(this, new LibrariesModule());
        _PDRLibrary_log.set(this, new LogModule());
    }
    get debug() {
        return __classPrivateFieldGet(this, _PDRLibrary_debug, "f");
    }
    get utils() {
        return __classPrivateFieldGet(this, _PDRLibrary_utils, "f");
    }
    get jsx() {
        log.deprecation('PDR.jsx is deprecated and will be removed the next major version.');
        return __classPrivateFieldGet(this, _PDRLibrary_jsx, "f");
    }
    get prompt() {
        return __classPrivateFieldGet(this, _PDRLibrary_prompt, "f");
    }
    get mfe() {
        return __classPrivateFieldGet(this, _PDRLibrary_mfe, "f");
    }
    get session() {
        return __classPrivateFieldGet(this, _PDRLibrary_session, "f");
    }
    get api() {
        return __classPrivateFieldGet(this, _PDRLibrary_api, "f");
    }
    get element() {
        return __classPrivateFieldGet(this, _PDRLibrary_element, "f");
    }
    get toast() {
        return __classPrivateFieldGet(this, _PDRLibrary_toast, "f");
    }
    get test() {
        return __classPrivateFieldGet(this, _PDRLibrary_test, "f");
    }
    get libraries() {
        return __classPrivateFieldGet(this, _PDRLibrary_libraries, "f");
    }
    get log() {
        return __classPrivateFieldGet(this, _PDRLibrary_log, "f");
    }
    start() {
        if (this._started) {
            return;
        }
        if (window.PDR_runtime || window.PDR) {
            throw new Error('Only a single instance of the PDR runtime can be started!');
        }
        window.PDR = this;
        window.PDR_runtime = this;
        PDRModule.notify('started', this);
        this._started = true;
    }
    version() {
        return VERSION;
    }
    init(jsonConfig) {
        if (!this._started) {
            throw new Error("Can't initialize PDR if it is not started. Please call .start() before .init().");
        }
        if (this._initialized) {
            return;
        }
        PDRModule.notify('configured', jsonConfig);
        this._initialized = true;
    }
}
_PDRLibrary_debug = new WeakMap(), _PDRLibrary_utils = new WeakMap(), _PDRLibrary_jsx = new WeakMap(), _PDRLibrary_prompt = new WeakMap(), _PDRLibrary_mfe = new WeakMap(), _PDRLibrary_session = new WeakMap(), _PDRLibrary_api = new WeakMap(), _PDRLibrary_element = new WeakMap(), _PDRLibrary_toast = new WeakMap(), _PDRLibrary_test = new WeakMap(), _PDRLibrary_libraries = new WeakMap(), _PDRLibrary_log = new WeakMap();
