import { observeBlock } from '../../sem-2.0/helpers/is-observers'
import { AnimatedCustomElement } from '../AnimatedCustomElement'
export class AnimationHeightElement extends AnimatedCustomElement {
  onInitialized() {
    observeBlock(this)
  }

  animate() {
    let firstVisibleElement = this.firstElementChild
    while (firstVisibleElement && !firstVisibleElement.offsetParent) {
      firstVisibleElement = firstVisibleElement.nextElementSibling
    }

    if (firstVisibleElement) {
      let lastVisibleElement = this.lastElementChild
      while (lastVisibleElement && !lastVisibleElement.offsetParent) {
        lastVisibleElement = lastVisibleElement.previousElementSibling
      }
      this.style.height = `${
        lastVisibleElement.offsetTop +
        lastVisibleElement.offsetHeight -
        0 /* TODO: must differentiate between self padding and child margins */
      }px`
    } else {
      this.style.height = 0
    }
  }
}
