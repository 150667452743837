import { select } from 'd3-selection'
import { RAD_TO_DEG } from '../../common/constants'
import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import {
  ARCS_ORIGIN_START_ANGLE_RAD,
  ARCS_ORIGIN_END_ANGLE_RAD,
  ARCS_TOTAL_SPAN_ANGLE_RAD,
  getArcsCenter,
  getArcsInnerRadius
} from '../utils'
import { InternalData } from '../types'

export const renderPointer = (host: OneUxGaugeChartElement, data: InternalData) => {
  const svgSelection = select(host.$svg)

  let pointerSelection = svgSelection.select<SVGPathElement>('.gauge--pointer')
  if (pointerSelection.empty()) {
    pointerSelection = svgSelection.append('path')
  }

  pointerSelection
    .attr('class', 'gauge--pointer')
    .attr('d', pointerPath.definition)
    .attr('fill', 'var(--one-ux-palette--brand-neutral-600)')
    .attr('transform', getPointerTransform(host, data))
}

const getPointerTransform = (host: OneUxGaugeChartElement, data: InternalData) => {
  const center = getArcsCenter(host)
  const rotation =
    (ARCS_ORIGIN_START_ANGLE_RAD * (1 - data.gaugePercent) + ARCS_ORIGIN_END_ANGLE_RAD * data.gaugePercent) * RAD_TO_DEG

  const { pointerSize, pointerOffset, segmentHighlightSize } = data.snapSizeConfig
  const pointerScaleFactor = pointerSize / pointerPath.size
  const pointerEdgeOffset = (segmentHighlightSize - pointerSize) / 2
  const radius = getArcsInnerRadius(host, data) - segmentHighlightSize + pointerEdgeOffset + pointerOffset
  const angle = ARCS_ORIGIN_START_ANGLE_RAD + ARCS_TOTAL_SPAN_ANGLE_RAD * data.gaugePercent - (Math.PI * 2) / 4
  const position = [center[0] + radius * Math.cos(angle), center[1] + radius * Math.sin(angle)]

  return `translate(${position[0]}, ${position[1]}) rotate(${rotation}) scale(${pointerScaleFactor})`
}

const pointerPath = {
  size: 10,
  definition:
    'M -0.8913 -4.5567 C -0.5517 -5.3836 0.6193 -5.3836 0.9588 -4.5568 L 4.3952 3.8109 C 4.6655 4.469 4.1816 5.1909 3.4703 5.1908 L -3.4027 5.1908 C -4.1141 5.1908 -4.598 4.469 -4.3278 3.8109 L -0.8913 -4.5567 Z'
}
