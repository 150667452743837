import { RenderGroup } from '../../render-groups/types'
import { getRenderGroupsMinMaxValue } from '../../render-groups/utils'
import { bounds, yAxisRange } from '../../types'
import { ValueScale } from './ValueScale'

export const getValueScale = (bounds: bounds, groups: RenderGroup[], yAxisRange?: yAxisRange) => {
  if (!groups.length) {
    return undefined
  }

  const { minValue, maxValue } = getRenderGroupsMinMaxValue(groups)

  return new ValueScale(bounds, minValue, maxValue, yAxisRange?.min, yAxisRange?.max)
}
