import { Version } from '../Version';
const numberedVersionRegex = /([0-9]+)\.([0-9]+)\.([0-9]+)/;
export class NumberedVersion extends Version {
    constructor(version, type = '') {
        super(version, type || 'numbered');
        const matches = (numberedVersionRegex.exec(version) || []).slice(1);
        if (matches.length !== 3) {
            throw new Error(`Unknown version format "${version}"`);
        }
        this.major = +matches[0];
        this.minor = +matches[1];
        this.patch = +matches[2];
    }
    fulfills(version) {
        if (version.type === 'special' || version.type === 'prerelease') {
            return false;
        }
        if (version.type === 'wildcard') {
            return version.includes(this);
        }
        return this.equal(version);
    }
    equal(version) {
        return this.major === version.major && this.minor === version.minor && this.patch === version.patch;
    }
}
