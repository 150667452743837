import { svg } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { interpolate, svgTransition } from '@/one-ux/charts/common/directives/svgTransition'
import { yTarget, bounds } from '../../types'
import { ValueScale } from './ValueScale'
import { ValueFormatter } from '../../../common/format'
import { timing } from '../../animation'

export function ValueAxis(
  bounds: bounds,
  valueScale: ValueScale,
  valueFormatter: ValueFormatter,
  yTarget: yTarget,
  animate: boolean
) {
  return svg`
    <g transform="translate(0, 0)" fill="none" class="value-axis">
      ${repeat(
        valueScale.ticks,
        (tick) => `value-axis:${valueFormatter.formatValue(tick)}`,
        (tick) => {
          const x = yTarget === 'left' ? bounds.right - 2 : bounds.left + 2
          const y = Math.floor(valueScale.position(tick)) + 0.5
          const anchor = yTarget === 'left' ? 'end' : 'start'
          const text = valueFormatter.formatValue(tick)

          return svg`<text
            class="one-ux-typography--mono-100"
            alignment-baseline="middle"
            text-anchor=${anchor}
            fill="var(--one-ux-palette--brand-neutral-600)"
            x=${x}
            ${svgTransition({
              timing: timing(animate),
              attributes: {
                x: interpolate.constant(x, (x) => x.toString()),
                y: interpolate.constant(y, (y) => y.toString()),
                opacity: interpolate.standard(0, 1, 0)
              }
            })}>
            ${text}
          </text>`
        }
      )}
    </g>
  `
}
