import { OneUxElement } from '../common/OneUxElement'
import { html, PropertyValues } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ContextProvider, ContextRoot } from '@lit/context'
import { ITreeContext, treeContext } from '../contexts/tree/ITreeContext'

let contextRootAttached = false

const keyMap = {
  tree: treeContext
}

type contextMap = {
  tree: ITreeContext
}

const BaseClass = OneUxElement
/**
 * Element that allows you to provide context in order to consume contextual OneUX elements.
 */
@customElement('one-ux-context-provider')
export class OneUxContextProviderElement<TKey extends keyof typeof keyMap | unknown = unknown> extends BaseClass {
  /**
   * Specifies which context to provide.
   * - `tree`: Provides context for `<contextual-one-ux-tree>`
   */
  @property({ attribute: false })
  public key!: TKey

  /**
   * Your own context implementation.
   */
  @property({ attribute: false })
  public context?: TKey extends keyof typeof keyMap ? contextMap[TKey] : unknown

  private provider?: ContextProvider<{
    __context__: unknown
  }>

  constructor() {
    super()
    if (!contextRootAttached) {
      const root = new ContextRoot()
      root.attach(document.documentElement)
      contextRootAttached = true
    }
  }

  protected willUpdate(_changedProperties: PropertyValues): void {
    if (_changedProperties.has('key')) {
      this.#setupProvider()
    }

    if (_changedProperties.has('context')) {
      this.#setupProvider()
      if (this.provider && this.context) {
        this.provider.setValue(this.context, true)
      }
    }
  }

  protected render() {
    return html`
      <div class="one-ux-element--root">
        <slot></slot>
      </div>
    `
  }

  #setupProvider() {
    const key = keyMap[this.key as keyof typeof keyMap]
    if (!this.provider && this.context && key) {
      this.provider = new ContextProvider<{
        __context__: unknown
      }>(this, key, this.context)
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-context-provider': OneUxContextProviderElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-context-provider': OneUxContextProviderElement
    }
  }
}
