import './PdrMenuButtonOptionElement'
import './PdrMenuButtonOptionsElement'
import { placePopup } from '../../helpers/helpers'
import { KeyboardEventHandler } from './KeyboardEventHandler'
import { AnimatedCustomElement } from '../AnimatedCustomElement'

let instanceIdGenerator = 0

export class PdrMenuButtonElement extends AnimatedCustomElement {
  #prevTop
  #prevLeft
  #instanceId = instanceIdGenerator++

  onInitialized() {
    this.#setInitialData()
    this.#attachEvents()
    this.shouldAnimate = false
  }

  animate() {
    const options = this.querySelector('pdr-menu-button-options')
    const { left, top } = placePopup(options, this, { prevLeft: this.#prevLeft, prevTop: this.#prevTop })
    this.#prevTop = top
    this.#prevLeft = left
  }

  get activeDescendantId() {
    return `pdr-menu-button-${this.#instanceId}__active`
  }

  get options() {
    return Array.from(this.querySelectorAll('pdr-menu-button-option'))
  }

  get expanded() {
    return this.getAttribute('aria-expanded') === 'true'
  }

  set expanded(expanded) {
    this.setAttribute('aria-expanded', expanded)
    this.shouldAnimate = expanded
    if (expanded) {
      this.options[0].active = true
    } else {
      this.querySelector('pdr-menu-button-options').clearActiveOptions()
      this.removeAttribute('aria-activedescendant')
    }
  }

  #setInitialData() {
    this.tabIndex = 0
    this.setAttribute('role', 'button')
    this.setAttribute('aria-haspopup', true)
    this.setAttribute('aria-expanded', false)
  }

  #attachEvents() {
    this.addEventListener('keydown', new KeyboardEventHandler(this).handleKeydown)
    this.addEventListener('blur', this.#handleBlur)
    this.addEventListener('click', this.#handleClick)
    this.addEventListener('option-activated', this.#handleOptionActivated)
    this.addEventListener('option-triggered', this.#handleOptionTriggered)
  }

  #handleBlur = () => {
    this.expanded = false
  }

  #handleClick = (event) => {
    if (event.target === this) {
      this.expanded = !this.expanded
    }
  }

  #handleOptionActivated = (event) => {
    event.stopPropagation()
    this.setAttribute('aria-activedescendant', event.target.id)
  }

  #handleOptionTriggered = (event) => {
    event.stopPropagation()
    if (this.dispatchEvent(new CustomEvent('option', { cancelable: true, detail: event.target }))) {
      const { href, target } = event.detail
      if (href != null) {
        window.open(href, target)
      }
      this.expanded = false
    }
  }
}
