import { html, nothing, svg } from 'lit'
import { styleMap } from 'lit/directives/style-map.js'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { legendItem, barItem } from '../types'
import { transitionTiming } from '../animation'
import { AxisScale } from '../../common/AxisScale'

export function Grouped({
  legend,
  bar,
  axisScale,
  width,
  implicit
}: {
  legend: legendItem[]
  bar: barItem
  axisScale: AxisScale
  width: number
  implicit: boolean
}) {
  const axisMin = axisScale.ticks[0]
  const axisMax = axisScale.ticks[axisScale.ticks.length - 1]

  const scaleFactor = Math.abs(width / (axisMax - axisMin))

  const segments = legend
    .filter((x) => bar.data[x.key] != null)
    .map((x) => ({
      item: x,
      value: bar.data[x.key]
    }))

  const labelTextBaseline = 10
  const barGap = 1
  const barHeight = 12 + 2 * barGap
  const totalHeight = 14 * segments.length

  return html`
    <svg
      style=${styleMap({
        height: `${totalHeight}px`,
        width: '100%',
        display: 'block',
        overflow: 'hidden'
      })}
    >
      ${segments.map((segment, segmentIndex) => {
        const barWidth = Math.abs(segment.value) * scaleFactor
        const barY = segmentIndex * barHeight + barGap
        const barStartX = (Math.min(segment.value, 0) - axisMin) * scaleFactor
        const barEndX = barStartX + barWidth

        const labelWidth = 50
        const labelGap = 4
        let labelOverflow = false
        let labelX
        let labelAnchor

        if (segment.value < 0) {
          labelOverflow = barStartX - labelGap - labelWidth < 0
          labelX = labelOverflow ? Math.max(barStartX, 0) + labelGap : Math.min(barStartX, width) - labelGap
          labelAnchor = labelOverflow ? 'start' : 'end'
        } else {
          labelOverflow = barEndX + labelGap + labelWidth > width
          labelX = labelOverflow ? Math.min(barEndX, width) - labelGap : Math.max(barEndX, 0) + labelGap
          labelAnchor = labelOverflow ? 'end' : 'start'
        }

        return svg`
          <g
            ${svgTransition({
              timing: transitionTiming,
              attributes: {
                opacity: interpolate.standard(0, 1, 0)
              }
            })}
          >
            <rect
              height="12"
              fill=${segment.item.color}
              rx="2"
              y=${barY}
              ${svgTransition({
                timing: transitionTiming,
                attributes: {
                  x: interpolate.constant(barStartX),
                  width: interpolate.standard(0, barWidth, 0)
                }
              })}
            ></rect>

            ${
              implicit
                ? nothing
                : svg`
                  <text
                    text-anchor=${labelAnchor}
                    y=${barY + labelTextBaseline}
                    fill="var(--one-ux-palette--brand-neutral-600)"
                    paint-order="stroke"
                    stroke=${labelOverflow ? 'var(--one-ux-palette--gray-100)' : 'none'}
                    stroke-width="2"
                    class="one-ux-typography--mono-variant-400"
                    ${svgTransition({
                      timing: transitionTiming,
                      attributes: {
                        x: interpolate.constant(labelX)
                      }
                    })}
                  >
                    ${segment.value}
                  </text>
                `
            }
          </g>
        `
      })}
    </svg>
  `
}
