import { CustomElementBase } from '../CustomElementBase.js'

export class PdrMenuButtonOptionElement extends CustomElementBase {
  onInitialized() {
    this.setAttribute('role', 'menuitem')
    this.setAttribute('aria-disabled', this.disabled)
    this.addEventListener('click', this.#handleClick)
    this.addEventListener('mousemove', this.#handleMousemove)
  }

  get active() {
    return this.hasAttribute('state-active')
  }

  set active(active) {
    if (active) {
      this.id = this.id || this.closest('pdr-menu-button').activeDescendantId
      this.setAttribute('state-active', '')
      this.dispatchEvent(new CustomEvent('option-activated', { bubbles: true, detail: { id: this.id } }))
    } else {
      if (this.id === this.closest('pdr-menu-button').activeDescendantId) {
        this.removeAttribute('id')
      }
      this.removeAttribute('state-active')
    }
  }

  get disabled() {
    return this.hasAttribute('disabled')
  }

  set disabled(disabled) {
    this.toggleAttribute('disabled', disabled)
    this.setAttribute('aria-disabled', disabled)
  }

  trigger() {
    if (this.disabled) {
      return
    }

    if (this.dispatchEvent(new CustomEvent('action', { cancelable: true }))) {
      this.dispatchEvent(
        new CustomEvent('option-triggered', {
          bubbles: true,
          detail: {
            href: this.getAttribute('href'),
            target: this.getAttribute('target')
          }
        })
      )
    }
  }

  #handleMousemove = (event) => {
    this.active = event.target === this
  }

  #handleClick = () => {
    this.trigger()
  }
}
