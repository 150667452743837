import { OneUxElement } from '../common/OneUxElement'
import { customElement, property } from 'lit/decorators.js'
import { until } from 'lit/directives/until.js'
import { style } from './style'
import { StyledFactory } from '../common/mixins/Styled'
import { OneUxIconToken } from '../generated/design-tokens'
import { NotFoundIcon } from './components/NotFoundIcon'
import { PropertyValues } from 'lit'
import { iconManager } from './IconManager'
import { log } from '../common/utils/log'
import { HidableTooltip } from '../common/mixins/HidableTooltip'

const Styled = StyledFactory(style)

const BaseClass = HidableTooltip(Styled(OneUxElement))

// TODO: Make type "dynamic" based on current set
type icons = OneUxIconToken[keyof OneUxIconToken]

/**
 * An icon component that allows selection from predefined sets of icons deemed compatible with OneUx.
 */
@customElement('one-ux-icon')
export class OneUxIconElement extends BaseClass {
  /**
   * The set that the icon provided by the `icon` property will be looked up in.
   */
  @property({ type: String })
  public set = 'default' as keyof OneUxIconToken

  /**
   * The icon name as defined in whatever `set` you want to use.
   *
   * If no `set` is provided it will default to only show icons from set `default`.
   */
  @property({ type: String })
  public icon!: icons

  /**
   * Fixed size for icon based on sizes 100-700.
   */
  @property({ type: String, reflect: true })
  public size!: '100' | '200' | '300' | '400' | '500' | '600' | '700'

  /**
   * Makes the icon available for assistive technologies.
   * If no value is provided the icon will be hidden from assistive technologies.
   */
  @property({ type: String })
  public label!: string

  protected render() {
    return until(this.#fetchIcon(), iconManager.get(this.icon, this.set))
  }

  protected firstUpdated(): void {
    this.#updateAccessibility()

    if (this.title) {
      log.warning('<one-ux-icon> should not use "title". Use "label" instead.')
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('label') || changedProperties.has('hideTooltip')) {
      this.#updateAccessibility()
    }
  }

  async #fetchIcon() {
    try {
      await iconManager.update(this.icon, this.set)
    } catch (err: any) {
      log.warning({
        title: `Icon "${this.icon}" from source "${this.set}" not available. ${err.message}`,
        details: this
      })
    }
    const $icon = iconManager.get(this.icon, this.set)
    if (!$icon) {
      return NotFoundIcon()
    }
    return $icon
  }

  #updateAccessibility() {
    if (this.label) {
      this.setAttribute('role', 'img')
      if (!this.hideTooltip) {
        this.setAttribute('one-ux-tooltip', this.label)
        this.toggleAttribute('one-ux-tooltip-custom-aria', true)
      } else {
        this.removeAttribute('one-ux-tooltip')
        this.removeAttribute('one-ux-tooltip-custom-aria')
      }
      this.setAttribute('aria-label', this.label)
      this.removeAttribute('aria-hidden')
    } else {
      this.removeAttribute('role')
      this.removeAttribute('one-ux-tooltip')
      this.removeAttribute('one-ux-tooltip-custom-aria')
      this.removeAttribute('aria-label')
      this.setAttribute('aria-hidden', 'true')
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-icon': OneUxIconElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-icon': OneUxIconElement
    }
  }
}
