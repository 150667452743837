import { css, unsafeCSS } from 'lit'
import themes from '../styling/themes'

export const style = css`
  :host {
    text-align: inherit;
  }

  :host([explicit]) {
    border: 1px solid currentColor;
  }

  .container {
    --one-ux-container-element--background: none;

    border-radius: inherit;
    background: var(--one-ux-container-element--background);
    display: flex;
  }

  :host([explicit]) .container {
    --one-ux-container-element--background: var(--one-ux-palette--gray-100);
  }

  .container:not([state-align-horizontal]) .one-ux-element--content {
    flex-grow: 1;
    min-width: 0;
  }

  .container[state-align-vertical='start'] {
    align-items: flex-start;
  }

  .container[state-align-vertical='center'] {
    align-items: center;
  }

  .container[state-align-vertical='end'] {
    align-items: flex-end;
  }

  .container[state-align-horizontal='start'] {
    justify-content: flex-start;
  }

  .container[state-align-horizontal='center'] {
    justify-content: center;
  }

  .container[state-align-horizontal='end'] {
    justify-content: flex-end;
  }

  :host .container[theme='light'] {
    color: var(--one-ux-palette--brand-neutral-600);
    ${unsafeCSS(
      Object.keys(themes.light)
        .map((key) => `${key}: ${(themes as any).light[key]};`)
        .join('\n')
    )};
  }

  :host .container[theme='dark'] {
    color: var(--one-ux-palette--gray-100);
    ${unsafeCSS(
      Object.keys(themes.dark)
        .map((key) => `${key}: ${(themes as any).dark[key]};`)
        .join('\n')
    )};
  }
`
