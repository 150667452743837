import { CustomElementBase } from '../CustomElementBase'
import { ResizeHandler } from './ResizeHandler'
import { MoveHandler } from './MoveHandler'
import { VerticalScrollingHandler } from './VerticalScrollingHandler'

export class PdrDashboardGadgetElement extends CustomElementBase {
  #resizeHandleElement = document.createElement('dynamic-pdr-dashboard-gadget-resize')

  static get observedAttributes() {
    return ['columns', 'rows']
  }

  onInitialized() {
    this.setAttribute('tabindex', 0)
    this.setAttribute('role', 'listitem')
    this.#resizeHandleElement.setAttribute('aria-hidden', 'true')

    const verticalScrollingHandler = new VerticalScrollingHandler(this)
    new ResizeHandler(this, this.#resizeHandleElement, verticalScrollingHandler)
    new MoveHandler(this, this.#resizeHandleElement, verticalScrollingHandler)
  }

  onRendered() {
    this.appendChild(this.#resizeHandleElement)
  }

  onAttributeRendered() {
    this.style.setProperty('--pdr-dashboard-gadget-columns', this.getAttribute('columns'))
    this.style.setProperty('--pdr-dashboard-gadget-rows', this.getAttribute('rows'))
  }

  get movable() {
    return this.getAttribute('movable') !== 'false'
  }

  set movable(movable) {
    this.setAttribute('movable', !!movable)
  }

  get resizable() {
    return this.getAttribute('resizable') !== 'false'
  }

  set resizable(resizable) {
    this.setAttribute('resizable', !!resizable)
  }

  get columns() {
    return Math.max(parseInt(this.getAttribute('columns')) || 1, 1)
  }

  set columns(columns) {
    this.setAttribute('columns', Math.max(parseInt(columns) || 1, 1))
  }

  get rows() {
    return Math.max(parseInt(this.getAttribute('rows')) || 1, 1)
  }

  set rows(rows) {
    this.setAttribute('rows', Math.max(parseInt(rows) || 1, 1))
  }

  get position() {
    return Array.from(this.closest('pdr-dashboard').children).indexOf(this)
  }
}
