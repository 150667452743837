import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    border-radius: var(--one-ux-radius--300);

    transition-property: background, border-radius;
    transition-duration: var(--one-ux-duration--400);
  }

  .one-ux-element--root.empty {
    background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.03);
  }

  :host([state-keyboard-focus]) .one-ux-element--root:focus-within {
    background: var(--one-ux-palette--keyboard-focus-200);
  }

  .one-ux-element--root:is(:hover, :focus-within, .has-errors) {
    border-bottom-left-radius: var(--one-ux-radius--100);
    border-bottom-right-radius: var(--one-ux-radius--100);
  }

  .indicator {
    height: var(--one-ux-border-width--500);
    border-radius: var(--one-ux-radius--100);
    background: var(--one-ux-palette--brand-neutral-400);
    opacity: 0;

    transition-property: opacity, background, border-radius;
    transition-duration: var(--one-ux-duration--400);
  }

  .one-ux-element--root:is(:hover, :focus-within, .has-errors) .indicator {
    opacity: 1;
  }

  :is(.one-ux-element--root.empty, :host([state-keyboard-focus]) .one-ux-element--root:focus-within) .indicator {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .one-ux-element--root:focus-within .indicator {
    background: var(--one-ux-palette--focus-400);
  }

  .one-ux-element--root.has-errors .indicator {
    background: var(--one-ux-palette--red-400);
  }

  :host([state-keyboard-focus]) .one-ux-element--root:focus-within .indicator {
    background: var(--one-ux-palette--keyboard-focus-400);
  }

  .one-ux-element--root:not(:hover, :focus-within) .errors-popout {
    visibility: hidden;
    pointer-events: none;
  }

  ::slotted(*) {
    margin: 0 !important;
  }
`
