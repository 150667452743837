import { vector } from '../../common/types'
import { KeyScale } from '../axis/key/KeyScale'
import { ValueScale } from '../axis/value/ValueScale'
import { lineRenderGroupData } from './line/types'

export const split = (keyScale: KeyScale, valueScale: ValueScale, line: lineRenderGroupData[]): vector[][] => {
  const lines: vector[][] = []

  const newLine: vector[] = []

  for (let i = 0; i < line.length; i++) {
    const current = line[i]
    if (!hasValue(current)) {
      addLine(newLine, lines)
    } else {
      newLine.push([keyScale.center(current.key), valueScale.position(current.value)])
    }
  }

  addLine(newLine, lines)

  return lines
}

const hasValue = (dp: lineRenderGroupData) => dp && typeof dp.value !== 'undefined'

const addLine = (line: vector[], lines: vector[][]) => {
  if (line.length) {
    lines.push(line.splice(0, line.length))
  }
}
