import { GroupData, ItemData, RootCallbacks } from './types'
import { findNextAfter, findPreviousBefore, keyCodes } from '../../../common/utils'
export type MenuKeyboardHandlerOptions = {
  callbacks: RootCallbacks
  items: ItemData[]
  currentItem?: ItemData
}
export class MenuKeyboardHandler {
  constructor(private options: MenuKeyboardHandlerOptions) {}

  public handleKeydown = (event: KeyboardEvent) => {
    const { currentItem, items, callbacks } = this.options

    const handled = () => {
      event.preventDefault()
      event.stopPropagation()
    }

    if (!currentItem) {
      return
    }

    if (event.code === keyCodes.UP || event.code === keyCodes.DOWN) {
      const findNew = event.code === keyCodes.UP ? findPreviousBefore : findNextAfter
      const siblings = currentItem.parent?.children || items
      const newItem = findNew(siblings, currentItem, (item) => item.type !== 'divider')
      if (newItem) {
        callbacks.onChangeCurrentItem(newItem)
      }
      handled()
    }

    if (event.code === keyCodes.RIGHT) {
      if (currentItem.type == 'group') {
        const group = currentItem as GroupData
        callbacks.onChangeCurrentItem(group.children[0])
      }
      handled()
    }

    if (event.code === keyCodes.LEFT) {
      callbacks.onChangeCurrentItem(currentItem.parent)
      handled()
    }

    if (event.code === keyCodes.RETURN || event.code === keyCodes.NUMPADRETURN || event.code === keyCodes.SPACE) {
      callbacks.onActivateItem(currentItem)
      handled()
    }

    if (event.code === keyCodes.ESCAPE) {
      if (currentItem?.parent) {
        callbacks.onChangeCurrentItem(currentItem.parent)
        handled()
      }
    }
  }
}
