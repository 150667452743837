// TODO: Evaluate persistent cache solution with localStorage, probably NOT needed due to prefetch.
const svgUrlMaps = {}
const downloadedSvgMap = {}

const skippedSvgAttributes = ['xmlns', 'xmlns:xlink', 'version']
export class SvgService {
  #parser = new DOMParser()
  async loadSvg(sourceName, iconName) {
    if (!svgUrlMaps[sourceName]) {
      const svgUrlMapModule = await this.#loadSvgUrlMap(sourceName)
      if (svgUrlMapModule) {
        const svgUrlMap = svgUrlMapModule.default
        svgUrlMaps[sourceName] = svgUrlMap
      }
    }
    const url = svgUrlMaps[sourceName][iconName]
    if (!url) {
      throw new Error(`Can't find the icon in the provided source.`)
    }
    if (!downloadedSvgMap[url]) {
      downloadedSvgMap[url] = fetch(url)
        .then((result) => {
          if (!result.ok) {
            throw new Error(`Can't download icon from "${url}".`)
          }
          return result.text()
        })
        .then(this.#parseSvgSourceAsSymbol)
    }
    return await downloadedSvgMap[url]
  }

  #parseSvgSourceAsSymbol = (svgText) => {
    const $doc = this.#parser.parseFromString(svgText, 'image/svg+xml')
    const $error = $doc.querySelector('parsererror')
    if ($error) {
      throw new Error(`Can't parse icon markup.`)
    }
    const $svg = $doc.firstElementChild
    const $symbol = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    Array.from($svg.attributes).forEach((attr) => {
      if (!skippedSvgAttributes.includes(attr.nodeName)) {
        $symbol.setAttribute(attr.nodeName, attr.nodeValue)
      }
    })
    $symbol.append(...$svg.children)
    return $symbol
  }

  #loadSvgUrlMap(sourceName) {
    switch (sourceName) {
      case 'type':
        return import(
          /* webpackChunkName: "icons-default" */
          /* webpackPrefetch: true, */
          '@/icon-sets/defaultIconSet'
        )
      case 'stratsys':
        return import(
          /* webpackChunkName: "icons-stratsys" */
          /* webpackPrefetch: true, */
          '@/icon-sets/stratsys'
        )
      case 'stratsys-byos':
        return import(
          /* webpackChunkName: "icons-stratsys-byos" */
          /* webpackPrefetch: true, */
          '@/icon-sets/stratsys-byos'
        )
      case 'stratsys-configuration':
        return import(
          /* webpackChunkName: "icons-stratsys-configuration" */
          /* webpackPrefetch: true, */
          '@/icon-sets/stratsys-configuration'
        )
      case 'stratsys-flags':
        return import(
          /* webpackChunkName: "icons-stratsys-flags" */
          /* webpackPrefetch: true, */
          '@/icon-sets/stratsys-flags'
        )
      case 'stratsys-misc':
        return import(
          /* webpackChunkName: "icons-stratsys-misc" */
          /* webpackPrefetch: true, */
          '@/icon-sets/stratsys-misc'
        )
      case 'stratsys-products':
        return import(
          /* webpackChunkName: "icons-stratsys-products" */
          /* webpackPrefetch: true, */
          '@/icon-sets/stratsys-products'
        )
      case 'stratsys-all':
        return import(
          /* webpackChunkName: "icons-stratsys-all" */
          /* webpackPrefetch: true, */
          '@/icon-sets/stratsys-all'
        )

      case 'fontawesome-regular':
        return import(
          /* webpackChunkName: "icon-set-fontawesome-regular" */
          /* webpackPrefetch: true, */
          '@/icon-sets/fontawesome-regular'
        )
      case 'fontawesome-light':
        return import(
          /* webpackChunkName: "icon-set-fontawesome-light" */
          /* webpackPrefetch: true, */
          '@/icon-sets/fontawesome-light'
        )
      case 'fontawesome-solid':
        return import(
          /* webpackChunkName: "icon-set-fontawesome-solid" */
          /* webpackPrefetch: true, */
          '@/icon-sets/fontawesome-solid'
        )
      case 'fontawesome-brands':
        return import(
          /* webpackChunkName: "icon-set-fontawesome-brands" */
          /* webpackPrefetch: true, */
          '@/icon-sets/fontawesome-brands'
        )
    }

    throw new Error(`The provided source is not supported.`)
  }
}
