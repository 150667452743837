import {
  KEY_UP,
  KEY_DOWN,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_ENTER,
  KEY_SPACE,
  KEY_HOME,
  KEY_END,
  KEY_ESCAPE,
  KEY_PAGEUP,
  KEY_PAGEDOWN
} from '../../helpers/keyCodes'

export class KeyboardEventHandler {
  #menuButton
  constructor($menuButton) {
    this.#menuButton = $menuButton
  }

  handleKeydown = (event) => {
    const key = event.which

    if (!this.#menuButton.expanded) {
      switch (key) {
        case KEY_ENTER:
        case KEY_SPACE:
        case KEY_UP:
        case KEY_LEFT:
        case KEY_DOWN:
        case KEY_RIGHT:
        case KEY_HOME:
        case KEY_END:
        case KEY_PAGEUP:
        case KEY_PAGEDOWN:
          event.preventDefault()
          this.#menuButton.expanded = true
          break
      }
    } else {
      switch (key) {
        case KEY_ENTER:
        case KEY_SPACE: {
          event.preventDefault()
          const $option = this.#menuButton.options.find(($option) => $option.hasAttribute('state-active'))
          if ($option) {
            $option.trigger()
          }
          break
        }
        case KEY_ESCAPE:
          event.preventDefault()
          event.stopPropagation()
          this.#menuButton.expanded = false
          break
        case KEY_UP:
        case KEY_LEFT:
          event.preventDefault()
          this.#stepActiveOption(-1)
          break
        case KEY_DOWN:
        case KEY_RIGHT:
          event.preventDefault()
          this.#stepActiveOption(1)
          break
        case KEY_HOME:
          event.preventDefault()
          this.#stepActiveOption('first')
          break
        case KEY_END:
          event.preventDefault()
          this.#stepActiveOption('last')
          break
        case KEY_PAGEUP:
          event.preventDefault()
          this.#stepActiveOption(-10)
          break
        case KEY_PAGEDOWN:
          event.preventDefault()
          this.#stepActiveOption(10)
          break
      }
    }
  }

  #stepActiveOption(value) {
    const maxIndex = this.#menuButton.options.length - 1
    const currentActiveIndex = this.#menuButton.options.findIndex(($option) => $option.hasAttribute('state-active'))
    let activeIndex = currentActiveIndex
    if (value === 'first') {
      activeIndex = 0
    } else if (value === 'last') {
      activeIndex = maxIndex
    } else {
      activeIndex = Math.min(Math.max(currentActiveIndex + value, 0), maxIndex)
    }
    this.#menuButton.options[activeIndex].active = true
  }
}
