import { html, nothing } from 'lit'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import { FULL_CIRCLE_IN_RADIANS } from '../../../common/constants'
import { ease, interpolate, svgTransition } from '../../../common/directives/svgTransition'
import { weight } from '../../../../common/mixins/Weight'
import { curvedSegment } from '../../../common/paths/curvedSegment'

const weightStyles: Record<
  weight,
  {
    svgSize: number
    radius: number
    height: number
    innerSlotOffset: number
  }
> = {
  high: {
    svgSize: 128,
    radius: 2,
    height: 6.5,
    innerSlotOffset: 14
  },
  normal: {
    svgSize: 64,
    radius: 1,
    height: 4,
    innerSlotOffset: 8
  },
  low: {
    svgSize: 32,
    radius: 1,
    height: 3,
    innerSlotOffset: 4
  }
}

export function ShapeCircle({
  compact,
  label,
  shadowRoot,
  total,
  value,
  valueText,
  weight,
  slots
}: {
  compact: boolean
  label: string
  shadowRoot: ShadowRoot
  total: number
  value: number
  valueText: string
  weight: weight
  slots: {
    hasDescription: boolean
    descriptionChanged: (event: Event) => void
  }
}) {
  const completed = value >= total
  const { svgSize: minSize, radius: borderRadius, innerSlotOffset, height } = weightStyles[weight]

  let width = minSize
  if (weight === 'high') {
    const $svg = shadowRoot.querySelector('svg')
    if ($svg) {
      width = $svg.clientWidth
    }
  }

  const size = weight === 'high' ? width : minSize
  const radius = size / 2
  const startPos = 0.25
  const endPos = Math.min(1, value / total)
  const startAngle = startPos * FULL_CIRCLE_IN_RADIANS

  return html`<div
    class=${classMap({
      circleShape: true,
      completed: completed
    })}
  >
    <div
      class="svgContent"
      style=${styleMap({
        'aspect-ratio': weight === 'high' ? '1' : 'auto',
        width: weight === 'high' ? '100%' : 'auto'
      })}
    >
      <svg
        style=${styleMap({
          'min-width': minSize + 'px',
          'min-height': minSize + 'px',
          width: weight === 'high' ? 'auto' : size + 'px',
          height: weight === 'high' ? 'auto' : size + 'px'
        })}
        role="presentation"
        one-ux-tooltip=${label}
      >
        <g>
          <circle
            class="circle--plate"
            r="${radius - height / 2}"
            cx="${radius}"
            cy="${radius}"
            stroke-width="${height}"
            fill="none"
          />
          <g transform=${`translate(${radius},${radius})`}>
            <path
              class="circle--value"
              ${svgTransition({
                timing: {
                  easing: ease.outCubic,
                  duration: 500
                },
                attributes: {
                  d: interpolate.standard(startPos, endPos, 0, (segmentWidth) =>
                    curvedSegment(
                      size / 2,
                      startAngle,
                      (segmentWidth - 0.000001) * FULL_CIRCLE_IN_RADIANS, // Offset is a fix to render full circle correctly
                      height,
                      completed ? 0 : borderRadius
                    )
                  )
                }
              })}
            />
          </g>
        </g>
        <foreignObject
          x="${innerSlotOffset}"
          y="${innerSlotOffset}"
          width="${size - 2 * innerSlotOffset}"
          height=${size - 2 * innerSlotOffset}
        >
          <div class="innerContent">
            ${!compact && weight !== 'low'
              ? html`<div
                  class=${classMap({
                    valueText: true,
                    'one-ux-typography--heading-500': weight === 'high',
                    'one-ux-typography--heading-100': weight === 'normal'
                  })}
                  aria-hidden="true"
                >
                  ${valueText}
                </div>`
              : nothing}
            ${weight !== 'high'
              ? nothing
              : html`
                  <div
                    class="slotArea one-ux-typography--label-400"
                    style=${styleMap({
                      display: !slots.hasDescription ? 'none' : null
                    })}
                  >
                    <slot @slotchange=${slots.descriptionChanged}></slot>
                  </div>
                `}
          </div>
        </foreignObject>
      </svg>
    </div>

    ${weight === 'high'
      ? nothing
      : html`
          <div
            class="outerContent one-ux-typography--label-300"
            style=${styleMap({
              display: (!compact && weight === 'low') || slots.hasDescription ? null : 'none'
            })}
          >
            ${!compact && weight === 'low'
              ? html`<div class="valueText one-ux-typography--heading-100" aria-hidden="true">${valueText}</div>`
              : nothing}
            <slot @slotchange=${slots.descriptionChanged}></slot>
          </div>
        `}
  </div>`
}
