import { html } from 'lit';
export function PromptModal(options) {
    const confirm = options.type === 'confirm';
    return html `<layout-modal
    id="__PDR_prompt_modal__"
    class="sem is-sem sem-context sem-reset"
    style="top: 20%; width: 95%; max-width: 480px;"
    ?hidden=${!options.open}
    @close=${() => options.onClose(false)}
  >
    <layout-modal-header>
      <h1 class="sem is-sem is-prio--low" lang="${options.lang}">${options.header || html `&nbsp;`}</h1>
    </layout-modal-header>
    <layout-modal-content>
      ${options.content instanceof HTMLElement
        ? options.content
        : html `<p class="set-white-space--pre-wrap">${options.content}</p>`}
    </layout-modal-content>
    <layout-modal-footer class="sem is-sem layout-mode--baseline">
      ${!confirm
        ? null
        : html `<button
            lang="${options.lang}"
            class="sem is-sem is-mode--content is-prio--high"
            @click=${() => options.onClose(false)}
          >
            ${ButtonContent(options.cancel, options.cancelIcon)}
          </button>`}
      <div class="sem is-sem layout-width--auto"></div>
      <button
        lang="${options.lang}"
        class="sem is-sem is-prio--high ${confirm && options.danger ? 'is-caution' : 'is-main'}"
        @click=${() => options.onClose(true)}
      >
        ${ButtonContent(options.ok, options.okIcon)}
      </button>
    </layout-modal-footer>
  </layout-modal>`;
}
function ButtonContent(text, icon) {
    if (!icon) {
        return text;
    }
    if (typeof icon === 'string') {
        icon = { sheet: 'type', icon: icon };
    }
    return html `<pdr-icon set="${icon.sheet}" icon="${icon.icon}"></pdr-icon>&nbsp;${text}`;
}
