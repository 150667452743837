import chartType from '../../constants/chartType'
import { getChartTypes } from './getters'
import builderType from './builderType'

export const flattenSeries = (value, fn) =>
  (value.series || []).reduce((collection, serie) => {
    for (const datapoint of serie.data) {
      collection.push(fn(serie, datapoint))
    }
    return collection
  }, [])

export const getBuilderType = (value) => {
  const chartTypes = getChartTypes(value)

  if (chartTypes.includes(chartType.progressDonut)) {
    return builderType.progressDonut
  }

  if (chartTypes.includes(chartType.donut)) {
    return builderType.donut
  }

  if (chartTypes.includes(chartType.pie)) {
    return builderType.pie
  }

  if (chartTypes.includes(chartType.simpleDistribution)) {
    return builderType.simpleDistribution
  }

  const axisType = [
    chartType.columnBeside,
    chartType.columnStacked,
    chartType.columnShadow,
    chartType.line,
    chartType.area,
    chartType.scatter
  ]

  if (chartTypes.some((chartType) => axisType.includes(chartType))) {
    return builderType.axis
  }
}
