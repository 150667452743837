import { html, nothing } from 'lit'
import { distributionData, distributionMetadata } from '../../common/types'
import { getLanguage } from '../lang'
import { langCode } from '@/one-ux/common/utils/getLangCode'
import { ifDefined } from 'lit/directives/if-defined.js'

export function AccessibleDistributionTable({
  metadata,
  data,
  locale,
  unit,
  description,
  id
}: {
  metadata: distributionMetadata[]
  data: distributionData
  locale: langCode
  unit?: string
  description?: string
  id?: string
}) {
  const language = getLanguage(locale)

  const getValue = (key: string) => data.values[key] || 0
  const total = metadata.reduce((sum, x) => sum + getValue(x.key), 0)

  return html`
    <table class="one-ux-accessibility--screen-reader" aria-label=${data.name} id=${ifDefined(id)}>
      ${!description
        ? nothing
        : html`
            <caption lang=${locale}>
              ${description}
            </caption>
          `}
      <thead>
        <tr>
          <th scope="col" lang=${language.locale}>${language.translations.total}</th>
          ${metadata.map((metadataEntry) => html`<th>${metadataEntry.name}</th>`)}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${total} ${unit}</td>
          ${metadata.map(
            (x) => html`<td>${getValue(x.key)} ${unit} (${Math.round((100 * getValue(x.key)) / total)}%)</td>`
          )}
        </tr>
      </tbody>
    </table>
  `
}
