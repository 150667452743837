export function findAllTestHooks($element) {
    const hasOwnHook = $element.hasAttribute('pdr-test-hook');
    const $testHooks = [];
    if (hasOwnHook) {
        $testHooks.push($element);
    }
    const isWebComponent = !!$element.shadowRoot;
    const $children = Array.from($element.children || []).filter(($child) => isWebComponent ? !!$child.assignedSlot : true);
    if (isWebComponent) {
        $children.push(...Array.from($element.shadowRoot.children));
    }
    $testHooks.push(...$children.flatMap(($child) => findAllTestHooks($child)));
    return $testHooks;
}
export function findTestHook($element, hookId) {
    const $hooks = findAllTestHooks($element);
    return $hooks.find(($hook) => $hook.getAttribute('pdr-test-hook') === hookId);
}
export function awaitTestHook($element, hookId, config) {
    return new Promise((resolve, reject) => {
        let raf;
        const timeout = setTimeout(() => {
            if (raf) {
                cancelAnimationFrame(raf);
            }
            reject();
        }, config?.timeout ?? 10 * 1000);
        const loop = () => {
            const $hook = findTestHook($element, hookId);
            if (!$hook) {
                raf = requestAnimationFrame(loop);
            }
            else {
                clearTimeout(timeout);
                const resolveCallback = () => resolve($hook);
                if (typeof config?.delay !== 'undefined') {
                    setTimeout(resolveCallback, config.delay);
                }
                else {
                    requestAnimationFrame(resolveCallback);
                }
            }
        };
        loop();
    });
}
