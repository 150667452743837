import { domReady } from '@/helpers/helpers.js';
import { PDRModule } from '../PDRModule';
import { AccessibilityService } from './AccessibilityService';
export class DebugModule extends PDRModule {
    constructor() {
        super();
        this.accessibility = new AccessibilityService();
        domReady.then(() => {
            this.accessibility._init();
        });
    }
}
