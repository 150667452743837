import { WildcardVersion } from './WildcardVersion';
import { MajorVersion } from './MajorVersion';
import { MinorVersion } from './MinorVersion';
const wildcard = '~';
export class PatchVersion extends WildcardVersion {
    fulfills(version) {
        if (version instanceof MajorVersion) {
            if (this.major === version.major) {
                if (this.minor === version.minor) {
                    return this.patch >= version.patch;
                }
                return this.minor > version.minor;
            }
            return this.major >= version.major;
        }
        else if (version instanceof MinorVersion) {
            if (this.major !== version.major) {
                return false;
            }
            if (this.minor === version.minor) {
                return this.patch >= version.patch;
            }
            return this.minor > version.minor;
        }
        else if (version instanceof PatchVersion) {
            return this.major === version.major && this.minor === version.minor && this.patch >= version.patch;
        }
        return false;
    }
    includes(version) {
        if (version.type !== 'numbered') {
            return false;
        }
        const numberedVersion = version;
        return (this.major === numberedVersion.major &&
            this.minor === numberedVersion.minor &&
            this.patch <= numberedVersion.patch);
    }
    static matches(versionString) {
        return versionString[0] === wildcard;
    }
}
