import { observeBlock } from '../../sem-2.0/helpers/is-observers'
import { KEY_UP, KEY_LEFT, KEY_DOWN, KEY_RIGHT, KEY_ENTER, KEY_SPACE } from '../../helpers/keyCodes'
import { CustomElementBase } from '../CustomElementBase'

export class AccessibilityRovingTabsElement extends CustomElementBase {
  #observer = new MutationObserver(() => {
    this.#updateTabIndexes()
  })

  onInitialized() {
    observeBlock(this)
  }

  onConnected() {
    this.#updateTabIndexes()

    this.#observer.observe(this, {
      attributeFilter: ['roving-tab', 'disabled'],
      attributes: true,
      childList: true,
      subtree: true
    })
    this.addEventListener('focusin', this.#handleFocusChange)
    this.addEventListener('focusout', this.#handleFocusChange)
    this.addEventListener('keydown', this.#handleKeydown)
  }

  onDisconnected() {
    this.#observer.disconnect()

    this.removeEventListener('focusin', this.#handleFocusChange)
    this.removeEventListener('focusout', this.#handleFocusChange)
    this.removeEventListener('keydown', this.#handleKeydown)
  }

  get disabled() {
    return this.hasAttribute('disabled')
  }

  set disabled(disabled) {
    if (typeof disabled !== 'undefined') {
      this.setAttribute('disabled', disabled)
    }
  }

  #handleFocusChange = () => {
    this.#updateTabIndexes()
  }

  #handleKeydown = (event) => {
    const focusedRovingTab = this.#getFocusedRovingTab()
    if (!focusedRovingTab) {
      return
    }

    switch (event.which) {
      case KEY_UP:
      case KEY_LEFT:
        event.preventDefault()
        this.#step(-1)
        break
      case KEY_DOWN:
      case KEY_RIGHT:
        event.preventDefault()
        this.#step(1)
        break
      case KEY_ENTER:
      case KEY_SPACE: {
        event.preventDefault()
        focusedRovingTab.click()
        break
      }
    }
  }

  #step(step) {
    const $focusedRovingTab = this.#getFocusedRovingTab()
    if (!$focusedRovingTab) {
      return
    }

    const $rovingTabs = Array.from(this.querySelectorAll('[roving-tab]')).filter(
      (x) => x.offsetParent && x.closest('accessibility-roving-tabs') === this
    )
    const currentIndex = $rovingTabs.indexOf($focusedRovingTab)
    $rovingTabs[(currentIndex + step + $rovingTabs.length) % $rovingTabs.length].focus()
  }

  #updateTabIndexes() {
    const $focusedRovingTab = this.#getFocusedRovingTab()
    const $rovingTabs = Array.from(this.querySelectorAll('[roving-tab]')).filter(
      (x) => x.closest('accessibility-roving-tabs') === this
    )
    const noSelected = !$rovingTabs.find((x) => (x.getAttribute('roving-tab') || '').toLowerCase() === 'selected')

    $rovingTabs.forEach(($rovingTab, index) => {
      const getTabIndex = () => {
        if (this.disabled) {
          return -1
        }
        if ($rovingTab == $focusedRovingTab) {
          return 0
        }
        if (!$focusedRovingTab) {
          if (noSelected && index === 0) {
            return 0
          }
          if ($rovingTab.getAttribute('roving-tab')?.toLowerCase() === 'selected') {
            return 0
          }
        }
        return -1
      }

      $rovingTab.tabIndex = getTabIndex()
    })
  }

  #getFocusedRovingTab() {
    const $focusedElement = document.activeElement
    if (
      $focusedElement.hasAttribute('roving-tab') &&
      this.contains($focusedElement) &&
      $focusedElement.closest('accessibility-roving-tabs') === this
    ) {
      return $focusedElement
    }
    return null
  }
}
