var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
import { Action } from './Action';
export class Install extends Action {
    constructor(implementation) {
        super();
        this.implementation = implementation;
    }
    execute() {
        const className = this.getClassName();
        if (!window[className]) {
            window[className] = registerCustomElement(this.implementation);
            this.completed = true;
        }
        return window[className];
    }
    getClassName() {
        const parts = this.implementation.tag.split('-').map((x) => x.substring(0, 1).toUpperCase() + x.substring(1));
        return `PDRMicrofrontend${parts.join('')}`;
    }
}
function registerCustomElement(implementation) {
    var _CustomElement_instances, _CustomElement_import, _CustomElement_placeholderElement, _CustomElement_rootElement, _CustomElement_initialized, _CustomElement_cleanUpToken, _CustomElement_currentRunId, _CustomElement_initializeCustomElement, _CustomElement_runApplication, _CustomElement_initialAttributes, _CustomElement_invalidateRunId, _CustomElement_queueCleanUp, _CustomElement_abortCleanUp;
    const { tag, methods, version, placeholder, style, provide, root, attributes, useMutationObserverForAttributes, cleanupDelay } = implementation;
    async function getApplication() {
        await Promise.all(Object.keys(provide).map((name) => {
            const provided = provide[name];
            return window.PDR.mfe.provide({
                source: tag,
                name,
                version: provided.version,
                value: provided.value
            });
        }));
        return implementation.application();
    }
    class CustomElement extends HTMLElement {
        constructor() {
            super();
            _CustomElement_instances.add(this);
            _CustomElement_import.set(this, void 0);
            _CustomElement_placeholderElement.set(this, placeholder());
            _CustomElement_rootElement.set(this, void 0);
            _CustomElement_initialized.set(this, false);
            _CustomElement_cleanUpToken.set(this, null);
            _CustomElement_currentRunId.set(this, 1);
            this.app = null;
            __classPrivateFieldSet(this, _CustomElement_import, getApplication(), "f");
            if (useMutationObserverForAttributes) {
                // TODO: Deprecate
                const observer = new MutationObserver((mutationList) => {
                    mutationList.forEach((mutation) => {
                        if (mutation.type === 'attributes') {
                            this.attributeChangedCallback(mutation.attributeName || '', mutation.oldValue || '', this.getAttribute(mutation.attributeName || '') || '');
                        }
                    });
                });
                observer.observe(this, {
                    attributes: true,
                    attributeOldValue: true
                });
            }
            const methodEntries = Object.entries(methods || {});
            for (const [methodName, methodImplementation] of methodEntries) {
                const _this = this;
                _this[methodName] = async (...args) => {
                    await __classPrivateFieldGet(this, _CustomElement_import, "f");
                    return methodImplementation.call(this, ...args);
                };
            }
        }
        static get version() {
            return version;
        }
        get version() {
            return version;
        }
        async connectedCallback() {
            __classPrivateFieldGet(this, _CustomElement_instances, "m", _CustomElement_abortCleanUp).call(this);
            __classPrivateFieldGet(this, _CustomElement_instances, "m", _CustomElement_initializeCustomElement).call(this);
            __classPrivateFieldGet(this, _CustomElement_instances, "m", _CustomElement_runApplication).call(this);
        }
        disconnectedCallback() {
            __classPrivateFieldGet(this, _CustomElement_instances, "m", _CustomElement_invalidateRunId).call(this);
            __classPrivateFieldGet(this, _CustomElement_instances, "m", _CustomElement_queueCleanUp).call(this);
        }
        static get observedAttributes() {
            return attributes.map((attribute) => (typeof attribute === 'string' ? attribute : attribute.name));
        }
        async attributeChangedCallback(name, oldValue, newValue) {
            if (this.app) {
                const app = await this.app;
                app.update(name, newValue);
            }
        }
    }
    _CustomElement_import = new WeakMap(), _CustomElement_placeholderElement = new WeakMap(), _CustomElement_rootElement = new WeakMap(), _CustomElement_initialized = new WeakMap(), _CustomElement_cleanUpToken = new WeakMap(), _CustomElement_currentRunId = new WeakMap(), _CustomElement_instances = new WeakSet(), _CustomElement_initializeCustomElement = function _CustomElement_initializeCustomElement() {
        if (!__classPrivateFieldGet(this, _CustomElement_initialized, "f")) {
            Object.assign(this.style, style);
            __classPrivateFieldSet(this, _CustomElement_initialized, true, "f");
        }
        if (!this.app && __classPrivateFieldGet(this, _CustomElement_placeholderElement, "f")) {
            this.append(__classPrivateFieldGet(this, _CustomElement_placeholderElement, "f"));
        }
    }, _CustomElement_runApplication = async function _CustomElement_runApplication() {
        const runId = __classPrivateFieldGet(this, _CustomElement_currentRunId, "f");
        const application = await __classPrivateFieldGet(this, _CustomElement_import, "f");
        const shouldRun = runId === __classPrivateFieldGet(this, _CustomElement_currentRunId, "f") && !this.app;
        if (shouldRun) {
            while (this.firstChild) {
                this.removeChild(this.lastChild);
            }
            __classPrivateFieldSet(this, _CustomElement_rootElement, root ? document.createElement(typeof root === 'string' ? root : 'div') : this, "f");
            if (__classPrivateFieldGet(this, _CustomElement_rootElement, "f") !== this) {
                this.appendChild(__classPrivateFieldGet(this, _CustomElement_rootElement, "f"));
            }
            this.app = Promise.resolve(application.main(__classPrivateFieldGet(this, _CustomElement_rootElement, "f"), __classPrivateFieldGet(this, _CustomElement_instances, "m", _CustomElement_initialAttributes).call(this)));
        }
    }, _CustomElement_initialAttributes = function _CustomElement_initialAttributes() {
        if (useMutationObserverForAttributes) {
            // TODO: Deprecate
            return Array.from(this.attributes).reduce((result, attr) => Object.assign(result, { [attr.name]: attr.value }), {});
        }
        return attributes.reduce((result, attr) => {
            const attrName = typeof attr === 'string' ? attr : attr.name;
            if (typeof attr !== 'string') {
                if (!this.hasAttribute(attrName) && typeof attr.default !== 'undefined') {
                    this.setAttribute(attrName, attr.default);
                }
            }
            return Object.assign(result, { [attrName]: this.getAttribute(attrName) });
        }, {});
    }, _CustomElement_invalidateRunId = function _CustomElement_invalidateRunId() {
        var _a;
        __classPrivateFieldSet(this, _CustomElement_currentRunId, (_a = __classPrivateFieldGet(this, _CustomElement_currentRunId, "f"), ++_a), "f");
    }, _CustomElement_queueCleanUp = function _CustomElement_queueCleanUp() {
        __classPrivateFieldGet(this, _CustomElement_instances, "m", _CustomElement_abortCleanUp).call(this);
        __classPrivateFieldSet(this, _CustomElement_cleanUpToken, setTimeout(() => {
            if (this.app) {
                this.app.then((app) => {
                    if (__classPrivateFieldGet(this, _CustomElement_cleanUpToken, "f")) {
                        app.destroy();
                    }
                });
                this.app = null;
            }
        }, cleanupDelay), "f");
    }, _CustomElement_abortCleanUp = function _CustomElement_abortCleanUp() {
        if (__classPrivateFieldGet(this, _CustomElement_cleanUpToken, "f") !== null) {
            clearTimeout(__classPrivateFieldGet(this, _CustomElement_cleanUpToken, "f"));
            __classPrivateFieldSet(this, _CustomElement_cleanUpToken, null, "f");
        }
    };
    customElements.define(tag, CustomElement);
    return customElements.get(tag);
}
