import { css } from 'lit';
export const style = css `
  :host {
    display: block;
    position: fixed;
    backface-visibility: hidden;
    background: #fff;
    border-radius: 4px;
    background-clip: padding-box !important;
    padding: 2px;
    z-index: 1000;
    border: 1px solid #7b9dd4;
    box-shadow: 0 0 2px 0 gray;
  }

  :host([hidden]) {
    display: none;
  }

  :host(:not([state-position])) {
    opacity: 0;
  }
`;
