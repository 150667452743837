import progressDonut from './progressDonut'
import donut from './donut'
import pie from './pie'
import simpleDistribution from './simpleDistribution'
import axis from './axis'

export default {
  progressDonut,
  donut,
  pie,
  simpleDistribution,
  axis
}
