import { select } from 'd3-selection'
import { OneUxGaugeChartElement } from '../OneUxGaugeChartElement'
import { InternalData } from '../types'
import { getArcsCenter, getArcsInnerRadius, isHiddenVisual } from '../utils'
import { getScaleFactor } from '../utils'
import { getTypography } from '../../common/typography'
import { getTextSize } from '../../common/getTextSize'
import { OneUxTypographyToken } from '@/one-ux/generated/design-tokens'

export const renderLabel = (host: OneUxGaugeChartElement, data: InternalData) => {
  const svgSelection = select(host.$svg)

  let labelSelection = svgSelection.select<SVGTextElement>('.gauge--label')
  if (isHiddenVisual(host, 'text-label')) {
    labelSelection.remove()
    return
  }

  if (labelSelection.empty()) {
    labelSelection = svgSelection.append('text')
  }

  labelSelection
    .attr('class', 'gauge--label')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', isHiddenVisual(host, 'text-total') ? 'middle' : 'hanging')
    .attr('transform', getLabelTransform(host, data))
    .attr('title', host.label)
    .text(getText(host, data, 'body-200'))
}

const getLabelTransform = (host: OneUxGaugeChartElement, data: InternalData) => {
  const center = getArcsCenter(host)
  const scaleFactor = Math.max(1, getScaleFactor(host))
  const offsetY = isHiddenVisual(host, 'text-total') ? 0 : data.snapSizeConfig.textLabelOffset

  return `translate(${center[0]}, ${center[1] + offsetY}) scale(${Math.max(1, scaleFactor / 2)})`
}

const getText = (host: OneUxGaugeChartElement, data: InternalData, token: OneUxTypographyToken) => {
  const availableWidth = getArcsInnerRadius(host, data)
  const typography = getTypography(token)
  const textSize = getTextSize(host.label, typography.font, typography.letterSpacing)
  if (textSize.width > availableWidth) {
    const length = Math.max(4, host.label.length / (textSize.width / availableWidth))
    return `${host.label.slice(0, length).trim()}...`
  }

  return host.label
}
