import { RenderGroup } from './types'

export const getRenderGroupsMinMaxValue = (groups: RenderGroup[]) =>
  groups.reduce(
    (acc, current) => {
      acc.minValue = acc.minValue < current.minValue ? acc.minValue : current.minValue
      acc.maxValue = acc.maxValue > current.maxValue ? acc.maxValue : current.maxValue
      return acc
    },
    {
      minValue: 0,
      maxValue: 0
    }
  )

export const splitRenderGroupsToLeftAndRight = (groups: RenderGroup[]) => {
  const leftRenderGroups = groups.filter((x) => x.yTarget !== 'right')
  const rightRenderGroups = groups.filter((x) => x.yTarget === 'right')

  return [leftRenderGroups, rightRenderGroups]
}
