import { svg } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { bounds, keyValue } from '../../types'
import { interpolate, svgTransition } from '../../../common/directives/svgTransition'
import { KeyScale } from './KeyScale'
import { timing } from '../../animation'

export function KeyAxis(bounds: bounds, keyScale: KeyScale, animate: boolean) {
  const typographyClassName = `one-ux-typography--${keyScale.fontToken}`

  const keys = getKeys(keyScale)

  return svg`
    <g transform="translate(0, 0)" fill="none" class="key-axis">
      ${repeat(
        keys,
        (key) => `key-axis:${key}`,
        (key) => {
          const tick = keyScale.tick(key)
          const [offsetX, offsetY] = getTranslateOffset(keyScale, key)
          const x = Math.floor(keyScale.center(key) + offsetX)
          const y = Math.floor(bounds.top + offsetY)
          const rotation = keyScale.keyRotation
          const lineHeight = keyScale.lineHeight

          return svg`<text
            class=${typographyClassName} dominant-baseline="hanging"
            fill="var(--one-ux-palette--brand-neutral-600)"
            ${svgTransition({
              timing: timing(animate),
              attributes: {
                transform: interpolate.constant([x, y], ([x, y]) => `translate(${x}, ${y}) rotate(${-rotation})`),
                opacity: interpolate.standard(0, 1, 0)
              }
            })}>
          ${tick.map(
            (line, i) => svg`<tspan text-anchor="middle" x="0" dy=${i > 0 ? lineHeight : 0}>${line.trim()}</tspan>`
          )}
        </text>`
        }
      )})}
    </g>
  `
}

const getKeys = (keyScale: KeyScale) => {
  const keys = keyScale.keys
  if (keyScale.isWrapped) {
    return keys
  }

  const continousSkipCount = getContinousSkipCount(keyScale)
  if (continousSkipCount === 0) {
    return keys
  }

  let currentSkipCount = 0

  const filteredKeys = []
  for (let i = 0; i < keys.length; i++) {
    if (i === 0) {
      filteredKeys.push(keys[i])
      continue
    }

    if (currentSkipCount === continousSkipCount) {
      currentSkipCount = 0
      filteredKeys.push(keys[i])
    } else {
      currentSkipCount++
    }
  }

  return filteredKeys
}

const getContinousSkipCount = (keyScale: KeyScale) => {
  if (keyScale.keyRotation === 90) {
    const ratio = keyScale.maxWidthKeySize.lineActualHeight / keyScale.size
    if (Math.floor(ratio) < Math.floor(ratio + 0.2)) {
      return Math.floor(ratio + 0.2)
    }

    return Math.floor(ratio)
  }

  return 0
}

const getTranslateOffset = (keyScale: KeyScale, key: keyValue) => {
  const isWrapped = keyScale.tick(key).length > 1
  const { boxWidth, boxHeight } = keyScale.getRotatedSize(key)

  if (keyScale.keyRotation === 45) {
    if (isWrapped) {
      return [0, boxHeight * 0.5]
    } else {
      return [-boxWidth / 2 + 4, boxHeight * 0.5]
    }
  }

  if (keyScale.keyRotation === 90) {
    if (keyScale.isNumeric) {
      return [-boxWidth / 2 + 2, boxHeight * 0.5 + 2]
    } else {
      return [-boxWidth / 2 + 2, boxHeight * 0.5 + 4]
    }
  }

  return [0, 4]
}
