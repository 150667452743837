import { svg } from 'lit'
import { getLineSegments } from '../../common/getLineSegments'
import { svgTransition, ease, interpolate } from '../../common/directives/svgTransition'
import { weight } from '../../../common/mixins/Weight'
import { distributionMetadata, distributionValues } from '../../common/types'

const weightStyles: Record<
  weight,
  {
    gap: number
    height: number
    borderRadius: number
  }
> = {
  high: {
    gap: 4,
    height: 16,
    borderRadius: 4
  },
  normal: {
    gap: 4,
    height: 12,
    borderRadius: 2
  },
  low: {
    gap: 2,
    height: 8,
    borderRadius: 2
  }
}

export function BarSegments({
  distribution,
  metadata,
  width,
  x,
  y,
  weight
}: {
  distribution: distributionValues
  metadata: distributionMetadata[]
  width: number
  x: number
  y: number
  weight: weight
}) {
  const weightStyle = weightStyles[weight]

  const transitionTiming = {
    easing: ease.outCubic,
    duration: 500
  }

  const segments = getLineSegments({
    segments: metadata.map((x) => ({
      item: x,
      value: distribution[x.key] || 0
    })),
    width: width,
    minSegmentWidth: 0,
    gap: weightStyle.gap
  })

  return svg`
    <g transform=${`translate(${x},${y})`}>
      ${segments.map((segment) => {
        return svg`
          <rect
            class="bar--shape"
            height="${weightStyle.height}"
            fill=${segment.item.color}
            rx="${weightStyle.borderRadius}"
            ${svgTransition({
              timing: transitionTiming,
              attributes: {
                x: interpolate.standard(segment.x, segment.x, segment.x + segment.width / 2),
                width: interpolate.standard(0, segment.width, 0)
              }
            })}
          ></rect>
        `
      })}
  </g>
    `
}
