import { PropertyValues, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { StyledFactory } from '../common/mixins/Styled'
import { OneUxElement } from '../common/OneUxElement'
import { style } from './style'
import { DialogController } from './DialogController'
import { classMap } from 'lit/directives/class-map.js'
import { log } from '../common/utils/log'
import { FocusableFactory } from '../common/mixins/Focusable'
import type { OneUxButtonElement } from '../one-ux-button/OneUxButtonElement'
import { keyCodes } from '../common/utils'
import { flushAnimations } from '../common/utils/animation-utils'
import { hideAnimation, showAnimation } from './animations'

import { duration as oneUxDuration } from '../generated/design-tokens.json'
import { getLanguage } from './language'
import { ifDefined } from 'lit/directives/if-defined.js'

const Styled = StyledFactory(style)

const Focusable = FocusableFactory(false)
const BaseClass = Focusable(Styled(OneUxElement))

const FOCUSABLE_TARGETS = [
  'button:not(disabled)',
  '[href]',
  'input:not(disabled)',
  'select:not(disabled)',
  'textarea:not(disabled)',
  '[tabindex]:not([tabindex="-1"])',
  '[one-ux-focusable]:not([disabled])'
]

/**
 * Represents a modal dialog that can contain mostly any content. Requires a header and content to render.
 *
 * $$preview$$
 */
@customElement('one-ux-dialog')
export class OneUxDialogElement extends BaseClass {
  #controller = new DialogController(this)

  /**
   * Displays the dialog depending on if it is set or not.
   */
  @property({ type: Boolean, reflect: true })
  visible = false

  /**
   * Removes the button for closing the modal. Only considered permanent from a user's point of view, can still be closed programmatically.
   */
  @property({ type: Boolean })
  permanent = false

  /**
   * Makes the dialog modal by adding a backdrop that prevent the user from interacting with the rest of the page.
   */
  @property({ type: Boolean })
  modal = false

  /**
   * Closes the dialog. Will emit a cancellable close event.
   */
  close() {
    this.visible = false
  }

  /**
   * Opens the dialog.
   */
  open() {
    this.visible = true
  }

  @state()
  _hasHeader = false

  @state()
  _hasContent = false

  @state()
  _hasFooter = false

  render() {
    const { translations, lang } = getLanguage(this)
    const shouldShow = this.visible && this._hasHeader && this._hasContent
    return html`<div
        class=${classMap({
          backdrop: true,
          visible: this.modal && shouldShow
        })}
      ></div>
      <div
        role="dialog"
        aria-labeledby="headerText"
        class=${classMap({
          dialog: true,
          permanent: this.permanent,
          'has-footer': this._hasFooter,
          visible: shouldShow
        })}
        @keydown=${this.#handleKeydown}
      >
        ${this.modal ? html`<div tabindex="0" @focus=${() => this.#focusOn('end')}></div>` : nothing}
        <div class="header" tabindex="-1">
          <slot name="pre-header"></slot>
          <div id="headerText">
            <slot name="header" @slotchange=${this.#checkHeaderSlot}></slot>
          </div>
          <slot name="post-header"></slot>
          ${this.permanent
            ? nothing
            : html`
                <one-ux-button
                  compact
                  implicit
                  label=${translations.close}
                  lang=${ifDefined(lang)}
                  class="close"
                  implicit
                  hide-tooltip
                  @click=${this.#cancelableClose}
                >
                  <one-ux-icon icon="close"></one-ux-icon>
                </one-ux-button>
              `}
        </div>

        <one-ux-scroll class="content" gutter>
          <slot name="content" @slotchange=${this.#checkContentSlot}></slot>
        </one-ux-scroll>

        <div class="footer">
          <slot name="footer" @slotchange=${this.#checkFooterSlot}></slot>
        </div>
        ${this.modal ? html`<div tabindex="0" @focus=${() => this.#focusOn('beginning')}></div>` : nothing}
      </div>`
  }

  protected async updated(dirty: PropertyValues) {
    if (dirty.has('visible') && dirty.get('visible') != null) {
      const shouldShow = this._hasHeader && this._hasContent
      if (!shouldShow) {
        return
      }

      const $dialog = this.shadowRoot?.querySelector('.dialog') as HTMLElement
      if ($dialog) {
        flushAnimations($dialog)

        if (this.visible) {
          this.#controller.startPositioning()

          $dialog.animate(showAnimation(), {
            duration: oneUxDuration[200]
          })

          const $header = this.shadowRoot?.querySelector('.header') as HTMLElement
          $header?.focus()
        } else {
          await $dialog.animate(hideAnimation(), {
            duration: oneUxDuration[200]
          }).finished

          // Validation of the state is need as a long animation is awaited.
          // State could have changed programmatically whilst the animation was running.
          if (!this.visible) {
            this.#controller.stopPositioning()
            this.blur()
          }
        }
      }
    }
  }

  #cancelableClose = () => {
    const close = new Event('close', { cancelable: true })
    if (this.dispatchEvent(close)) {
      this.close()
    }
  }

  #checkHeaderSlot = (event: Event) => {
    const $slot = event.target as HTMLSlotElement
    const assignedElements = $slot.assignedElements()
    const hasSingleElement = assignedElements.length === 1
    const hasInvalidElements = assignedElements.some(($el) => $el.tagName !== 'ONE-UX-TEXT')
    this._hasHeader = hasSingleElement && !hasInvalidElements
    if (!this._hasHeader) {
      log.error({
        title: 'Invalid <one-ux-dialog>, missing or invalid header.',
        message:
          'A <one-ux-dialog> requires that a single <one-ux-text> node has been assigned to the "header" slot for accessibility purposes. If you want to add other things to the header area use the "pre-header" and "post-header" slots.',
        details: this
      })
    }
  }

  #checkContentSlot = (event: Event) => {
    const $slot = event.target as HTMLSlotElement
    const assignedElements = $slot.assignedElements()

    this._hasContent = assignedElements.length > 0
    if (!this._hasContent) {
      log.error({
        title: 'Invalid <one-ux-dialog>, missing content.',
        message: 'A <one-ux-dialog> requires that content has been assigned to the "content" slot.',
        details: this
      })
    }
  }

  #checkFooterSlot = (event: Event) => {
    const $slot = event.target as HTMLSlotElement
    this._hasFooter = $slot.assignedElements().length > 0
  }

  #focusOn = (to: 'beginning' | 'end') => {
    const $focusable = Array.from(this.querySelectorAll(FOCUSABLE_TARGETS.join(', ')))
    const $close = this.shadowRoot?.querySelector('.close') as OneUxButtonElement
    if (to === 'beginning') {
      if (this.permanent) {
        const $first = $focusable.at(0) as HTMLElement
        if ($first) {
          $first.focus()
        } else {
          log.error({
            title: 'Invalid <one-ux-dialog>!',
            message: 'A <one-ux-dialog> with the "permanent" flag set needs to have at least one focusable element.',
            details: this
          })
          this.visible = false
        }
      } else {
        const $first = ($focusable.find(($el) => !!$el.closest('[slot="header"]')) ?? $close) as HTMLElement
        $first.focus()
      }
    } else {
      const $last = ([...$focusable].reverse().find(($el) => !!$el.closest(':not([slot="header"])')) ??
        $close) as HTMLElement
      $last.focus()
    }
  }

  #handleKeydown = (event: KeyboardEvent) => {
    if (!this.open || this.permanent) {
      return
    }

    if (event.code === keyCodes.ESCAPE) {
      this.#cancelableClose()
      event.stopPropagation()
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-dialog': OneUxDialogElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-dialog': OneUxDialogElement
    }
  }
}
