import * as getters from '../getters'
import * as helpers from '../helpers'

export default (value) => ({
  transitionDuration: getters.getTransitionDuration(value),
  renderWhenInViewport: getters.getRenderWhenInViewport(value),
  locale: getters.getLocale(value),
  tooltipAppearance: getters.getTooltipAppearance(value),
  series: helpers.flattenSeries(value, (serie, datapoint) => ({
    type: getters.getChartType(value, serie),
    series: getters.getSeriesNumber(value, serie),
    name: getters.getName(serie),
    key: getters.getKey(datapoint),
    value: getters.getValue(datapoint),
    color: getters.getColor(serie, datapoint),
    highlight: getters.getHighlight(datapoint)
  }))
})
