import { html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { createRef, ref } from 'lit/directives/ref.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { styleMap } from 'lit/directives/style-map.js'

import { TreeContextMixin } from '../contexts/tree/TreeContextMixin'
import { StyledFactory } from '../common/mixins/Styled'
import { style } from './style'

import { ID_TREE_BOX } from './constants'

import { getLanguage, LanguageSet } from './language'
import { keyCodes, traverseNodes } from '../common/utils'

import { InputNode } from '../contexts/tree/contextual-one-ux-tree/types'
import { Disabled } from '../common/mixins/Disabled'
import { Focusable } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { Weight } from '../common/mixins/Weight'
import { Placeholder } from '../common/mixins/Placeholder'
import { OneUxElement } from '../common/OneUxElement'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../common/mixins/Validated'
import { ContextualOneUxTreeElement } from '../contexts/tree/contextual-one-ux-tree/ContextualOneUxTreeElement'
import { OneUxPopoutElement } from '../one-ux-popout/OneUxPopoutElement'
import { Required, IRequired } from '../common/mixins/Required'
import { IValue } from '../common/mixins/Value'
import { FormAssociated } from '../common/mixins/FormAssociated'
import { Label } from '../common/mixins/Label'
import { Errors } from '../common/mixins/Errors'
import { Compact } from '../common/mixins/Compact'
import type { OneUxFieldElement } from '../one-ux-field/OneUxFieldElement'
import { log } from '../common/utils/log'

const Styled = StyledFactory(style)

const Validated = ValidatedFactory<IValue<string | string[]> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldYouHaveToMakeChoice } = getFormValidationLanguage(this)
    const valid = hasValue(this.value)
    return {
      valid: valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldYouHaveToMakeChoice]
    }
  }
})

function hasValue(value: string | string[] | undefined) {
  return !!(Array.isArray(value) ? value.length : value)
}

const BaseClass = Errors(
  Label(Compact(Validated(Required(Placeholder(Styled(Disabled(Focusable(Implicit(Weight(OneUxElement))))))))))
)
/**
 * A tree component that presents a tree in a dropdown.
 */
@customElement('one-ux-tree-dropdown')
export class OneUxTreeDropdownElement extends FormAssociated(TreeContextMixin(BaseClass)) {
  /**
   * A template sting that will have all occurrences of `$0` replaced with the count of selected nodes.
   * Only applied when `multiple` is set.
   */
  @property({ attribute: 'multiple-selected-template', type: String })
  public multipleSelectedTemplate!: string

  constructor() {
    super()

    this.addEventListener('blur', () => {
      this._open = false
    })
    this.addEventListener('keydown', this.#handleKeydown)
  }

  @state()
  private _open = false

  #valueOnOpen: unknown | unknown[]

  #fieldElement = createRef<OneUxFieldElement>()
  #popoutElement = createRef<OneUxPopoutElement>()
  #contextualTreeElement = createRef<ContextualOneUxTreeElement>()
  protected render() {
    const { translations, lang } = getLanguage(this)

    const $reference = this.#fieldElement.value?.shadowRoot?.querySelector('.js-field')

    return html`<one-ux-field
      ${ref(this.#fieldElement)}
      class="one-ux-element--root"
      width="auto"
      .label=${this.label}
      .compact=${this.compact}
      .required=${this.required}
      .disabled=${this.disabled}
      .implicit=${this.implicit}
      .weight=${this.weight}
      .empty=${!hasValue(this._treeContextProvider.value.value)}
      .errors=${this.errors}
      .hideErrors=${this._open}
      @click=${this.#toggleOpen}
    >
      <div
        class="field-inner"
        role="combobox"
        lang=${ifDefined(lang)}
        aria-required=${!!this.required}
        aria-label=${this.label}
        aria-expanded=${this._open}
        aria-controls=${ifDefined(this._open ? ID_TREE_BOX : undefined)}
        tabindex=${this.disabled || this._open ? -1 : 0}
      >
        <div class="field-inner-content">${this.#renderText(translations)}</div>
        <one-ux-icon class="field-icon" icon="toggle-down" aria-hidden="true" size="200"></one-ux-icon>
      </div>

      ${!this._open
        ? nothing
        : html`
            <one-ux-popout
              ${ref(this.#popoutElement)}
              @click=${(e: Event) => e.stopPropagation()}
              .reference=${$reference}
              indent="none"
              indent-top="normal"
              indent-bottom="normal"
              style=${styleMap({
                'min-width': $reference ? `${$reference.getBoundingClientRect().width}px` : null
              })}
            >
              <contextual-one-ux-tree
                ${ref(this.#contextualTreeElement)}
                id=${ID_TREE_BOX}
                implicit
                width="max"
                @input=${() => {
                  if (!this.multiple) {
                    this.#toggleOpen()
                  }
                  this.requestUpdate()
                  this.dispatchEvent(new CustomEvent('input'))
                }}
                @blur=${() => {
                  if (this.#changed) {
                    this.dispatchEvent(new Event('change'))
                  }
                }}
              ></contextual-one-ux-tree>
            </one-ux-popout>
          `}
    </one-ux-field>`
  }

  protected async getUpdateComplete() {
    const result = await super.getUpdateComplete()
    await this.#fieldElement.value?.updateComplete
    await this.#popoutElement.value?.updateComplete
    await this.#contextualTreeElement.value?.updateComplete
    return result
  }

  #renderText = (translations: LanguageSet) => {
    const values = typeof this.value == 'string' ? this.value.split(',') : this.value
    if (!values.length) {
      return this.placeholder
    }
    const text =
      values.length > 1
        ? (this.multipleSelectedTemplate || translations.selected).replace(/\$0/g, values.length.toFixed())
        : this.#getNodeText(values[0] as string)

    return text || this.placeholder
  }

  #getNodeText = (value: string) => {
    let text
    traverseNodes(this.nodes, (node: InputNode) => {
      if (node.value == value) {
        text = node.text
      }
    })
    return text
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    if (!this._open) {
      switch (event.code) {
        case keyCodes.SPACE:
        case keyCodes.UP:
        case keyCodes.DOWN:
        case keyCodes.RETURN:
          this.#toggleOpen()
          return handled()
      }
    } else {
      switch (event.code) {
        case keyCodes.ESCAPE:
          this.#toggleOpen()
          return handled()
      }
    }
  }

  #toggleOpen = async () => {
    if (this._open) {
      // Fix for bug in chromium where mouse leave events are not triggered if you remove a DOM element below the mouse and the mouse afterwards is outside the ShadowDOM
      this.shadowRoot!.querySelector<HTMLElement>('.field-inner')!.focus()
      this.shadowRoot!.querySelector<HTMLElement>('one-ux-popout')!.hidden = true
      requestAnimationFrame(() => {
        this._open = false
      })
    } else {
      this.#valueOnOpen = this.value
      this._open = true
      await this.updateComplete
      requestAnimationFrame(() => {
        this.shadowRoot!.querySelector('contextual-one-ux-tree')!.focus()
      })
    }
  }

  get #changed() {
    if (!this.multiple) {
      return this.value != this.#valueOnOpen
    }
    return !(
      (this.value as unknown[]).length === (this.#valueOnOpen as unknown[]).length &&
      (this.#valueOnOpen as unknown[]).every((element) => {
        return (this.value as unknown[]).includes(element)
      })
    )
  }

  /**
   * **DEPRECATED** Use `compact` instead.
   */
  @property({ attribute: 'label-hidden', type: Boolean })
  public get labelHidden() {
    return this.compact
  }
  public set labelHidden(value: boolean) {
    log.deprecation({
      title: '"label-hidden" is deprecated in favour of the property "compact".',
      details: this
    })
    this.compact = value
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-tree-dropdown': OneUxTreeDropdownElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-tree-dropdown': OneUxTreeDropdownElement
    }
  }
}
