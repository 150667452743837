import { css } from 'lit';
export const style = css `
  :host {
    display: inline-block;
    line-height: 0;
    height: 1em;
    width: 1em;
    vertical-align: -0.125em;
  }

  svg {
    display: inline-block;
    fill: currentColor;
    font-size: inherit;
    height: 100%;
    width: 100%;
  }
`;
