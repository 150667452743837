var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _CallbackQueue_instances, _CallbackQueue_busy, _CallbackQueue_queue, _CallbackQueue_flush;
export class CallbackQueue {
    constructor() {
        _CallbackQueue_instances.add(this);
        _CallbackQueue_busy.set(this, false);
        _CallbackQueue_queue.set(this, []);
    }
    enqueue(callback) {
        return new Promise((resolve, reject) => {
            __classPrivateFieldGet(this, _CallbackQueue_queue, "f").push({
                resolve,
                reject,
                callback
            });
            __classPrivateFieldGet(this, _CallbackQueue_instances, "m", _CallbackQueue_flush).call(this);
        });
    }
}
_CallbackQueue_busy = new WeakMap(), _CallbackQueue_queue = new WeakMap(), _CallbackQueue_instances = new WeakSet(), _CallbackQueue_flush = async function _CallbackQueue_flush() {
    if (__classPrivateFieldGet(this, _CallbackQueue_busy, "f")) {
        return;
    }
    __classPrivateFieldSet(this, _CallbackQueue_busy, true, "f");
    while (__classPrivateFieldGet(this, _CallbackQueue_queue, "f").length) {
        const { resolve, reject, callback } = __classPrivateFieldGet(this, _CallbackQueue_queue, "f").shift();
        try {
            const result = await callback();
            resolve(result);
        }
        catch (err) {
            reject(err);
        }
    }
    __classPrivateFieldSet(this, _CallbackQueue_busy, false, "f");
};
