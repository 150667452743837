import { svg } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { KeyScale } from '../axis/key/KeyScale'
import { ValueScale } from '../axis/value/ValueScale'
import { ColumnRenderGroup } from './types'
import { createInnerKeyScale, getHeight, getX, getY } from './calculations'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { timing } from '../animation'

export function Column(keyScale: KeyScale, valueScale: ValueScale, group: ColumnRenderGroup, animate: boolean) {
  const innerKeyScale = createInnerKeyScale(keyScale, group)
  const data = group.data.filter((dp) => {
    if (typeof dp.value === 'undefined') {
      return false
    }

    const height = getHeight(valueScale, dp)
    if (height === 0) {
      return false
    }

    return true
  })

  return svg`
    <g transform="translate(0, 0)" fill="none" class="series-column">
      ${repeat(
        data,
        (dp) => `column:${dp.name}:${dp.keyIndex}`,
        (dp) => {
          const height = getHeight(valueScale, dp)
          const x = getX(innerKeyScale, dp)
          const y = getY(valueScale, dp)
          const width = innerKeyScale.size

          return svg`<rect
            fill=${dp.color}
            ${svgTransition({
              timing: timing(animate),
              attributes: {
                x: interpolate.constant(x, (x) => x.toString()),
                y: interpolate.constant(y, (y) => y.toString()),
                width: interpolate.constant(width, (width) => width.toString()),
                height: interpolate.constant(height, (height) => height.toString()),
                opacity: interpolate.standard(0, 1, 0)
              }
            })} />`
        }
      )}}
    </g>
  `
}
