import { svg } from 'lit'
import { bounds, serie } from '../types'
import { mapLegendData } from './mapLegendData'
import { getLegendsOffset, getLegendSize } from './legendSizes'
import { Legend } from './Legend'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { timing } from '../animation'

export function Legends(bounds: bounds, series: serie[], animate: boolean) {
  const legendData = mapLegendData(series)

  const offsets = getLegendsOffset(
    legendData.map((x) => x.name),
    bounds.right - bounds.left
  )

  return svg`
    <g
      transform="translate(0, 0)"
      fill="none"
      class="legends"
      ${svgTransition({
        timing: timing(animate),
        attributes: {
          opacity: interpolate.standard(0, 1, 0)
        }
      })}>
      ${legendData.map((legend, i) => {
        const { width, height } = getLegendSize(legend.name)
        const { top, left } = offsets[i]

        return svg`
        <foreignObject width=${width} height=${height + 1} x=${bounds.left + left} y=${bounds.top + top}>
          ${Legend(legend.type, legend.color, legend.name)}
        </foreignObject>`
      })}
    </g>
  `
}
