import { ARCS_ORIGIN_START_ANGLE_RAD, ARCS_TOTAL_SPAN_ANGLE_RAD } from '../utils'
import { InputSegment } from '../types'

export const getInternalSegments = (segments: InputSegment[], gaugePercent: number) => {
  const _segments = segments.map((segment, i) => {
    const startAngle = getSegmentStartAngle(segment, segments)
    const endAngle = getSegmentEndAngle(segment, segments)

    const gaugeRad = ARCS_ORIGIN_START_ANGLE_RAD + ARCS_TOTAL_SPAN_ANGLE_RAD * gaugePercent
    const isHighlighted = gaugeRad >= startAngle && gaugeRad <= endAngle

    return {
      index: i,
      name: segment.name,
      value: segment.value,
      color: segment.color,
      icon: segment.icon,
      startAngle: startAngle,
      endAngle: endAngle,
      highlighted: isHighlighted
    }
  })

  /* this is to ensure only one is highlighted if gauge is equally weighted */
  const highlighted = _segments.filter((s) => s.highlighted)
  for (let i = 0; i < highlighted.length - 1; i++) {
    highlighted[i].highlighted = false
  }

  return _segments
}

const getSegmentStartAngle = (segment: InputSegment, segments: InputSegment[]) =>
  ARCS_ORIGIN_START_ANGLE_RAD + segments.indexOf(segment) * getSegmentSpanAngle(segments)

const getSegmentEndAngle = (segment: InputSegment, segments: InputSegment[]) =>
  getSegmentStartAngle(segment, segments) + getSegmentSpanAngle(segments)

const getSegmentSpanAngle = (segments: InputSegment[]) => ARCS_TOTAL_SPAN_ANGLE_RAD / segments.length
