import { PDRLibrary } from './PDRLibrary';
import { updateSettings } from './log/Log';
import { log } from './log';
if (!window.SKIP_OVERRIDE_LOG_SETTINGS) {
    updateSettings({
        message: true,
        info: true,
        debug: true,
        error: true,
        timer: true,
        warning: true,
        tracker: true
    });
}
export const runtime = new PDRLibrary();
// Deprecated properties that can be removed in PDR 11.0.0
Object.defineProperties(runtime, {
    getUser: {
        value(...args) {
            log.deprecation('PDR.getUser() is deprecated, use PDR.session.getUser() instead.');
            return runtime.session.getUser(...args);
        },
        enumerable: true,
        configurable: false
    },
    signoutRedirect: {
        value() {
            log.deprecation('PDR.signoutRedirect() is deprecated, use PDR.session.signOut() instead.');
            return runtime.session.signOut();
        },
        enumerable: true,
        configurable: false
    }
});
runtime.start();
