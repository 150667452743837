import { html, PropertyValues } from 'lit'
import { customElement, state } from 'lit/decorators.js'
import { ContextConsumer } from '@lit/context'

import { StyledFactory } from '../../../common/mixins/Styled'
import { style } from './style'
import { listContext } from '../IListContext'

import { Option } from './components/Option'
import { ID_ACTIVE_OPTION } from './constants'

import { keyCodes, scrollElementIntoView } from '../../../common/utils'
import { defer } from '../../../common/utils/defer'

import { DelegateAria } from '../../../common/mixins/DelegateAria'
import { Focusable } from '../../../common/mixins/Focusable'
import { Implicit } from '../../../common/mixins/Implicit'
import { OneUxElement } from '../../../common/OneUxElement'
import { Group } from './components/Group'
import { listGrouping, listOption } from './types'
import { OneUxScrollElement } from '@/one-ux/one-ux-scroll/OneUxScrollElement'
import { createRef, ref } from 'lit/directives/ref.js'

const Style = StyledFactory(style)
const BaseClass = Style(DelegateAria(Focusable(Implicit(OneUxElement))))

/**
 * A base component that is used internally to create list.
 * This is not recommended for use, you should use one of the lists available instead.
 * $$internal$$
 */
@customElement('contextual-one-ux-list')
export class ContextualOneUxListElement extends BaseClass {
  #state = new ContextConsumer(this, listContext, undefined, true)
  constructor() {
    super()
    this.addEventListener('keydown', this.#handleKeydown)
  }

  @state()
  private _activeIndex = 0

  protected willUpdate(changedProperties: PropertyValues): void {
    if (changedProperties.has('_activeIndex')) {
      defer(() => {
        const $active = this.shadowRoot?.getElementById(ID_ACTIVE_OPTION)
        if ($active) {
          const $scroll = $active.closest('one-ux-scroll')
          if ($scroll) {
            scrollElementIntoView($scroll, $active)
          }
        }
      })
    }
  }

  firstUpdated() {
    const options = (this.#state.value!.options || []).flatMap((optionOrGroup) => {
      if (Object.hasOwn(optionOrGroup, 'options')) {
        return (optionOrGroup as listGrouping).options
      }
      return optionOrGroup as listOption
    })
    const values = this.#state.value!.value || []
    if (!values.length || !options.length) {
      return
    }
    const firstSelectedIndex = options.findIndex((option) => values.includes(option.value))
    if (firstSelectedIndex !== -1) {
      this._activeIndex = firstSelectedIndex
    }
  }

  #scrollElement = createRef<OneUxScrollElement>()
  protected render() {
    const values = this.#state.value!.value
    const indexRef = {
      value: 0
    }
    return html`<one-ux-scroll class="one-ux-element--root" implicit ${ref(this.#scrollElement)}>
      <div class="list-box" ${this._ariaTarget()} tabindex="0" role="listbox" aria-activedescendant=${ID_ACTIVE_OPTION}>
        ${this.#state.value!.options.map((entry) => {
          if ('options' in entry) {
            return Group({
              group: entry,
              indexRef,
              activeIndex: this._activeIndex,
              multiple: this.#state.value!.multiple,
              values,
              onChange: this.#handleChange,
              onActivate: this.#handleActivation
            })
          } else {
            return Option({
              option: entry,
              index: indexRef.value++,
              activeIndex: this._activeIndex,
              multiple: this.#state.value!.multiple,
              values,
              onChange: this.#handleChange,
              onActivate: this.#handleActivation
            })
          }
        })}
      </div>
    </one-ux-scroll>`
  }
  protected async getUpdateComplete() {
    const result = await super.getUpdateComplete()
    await this.#scrollElement.value?.updateComplete
    return result
  }

  #handleActivation = (index: number) => {
    this._activeIndex = index
  }

  #handleKeydown = (event: KeyboardEvent) => {
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    const options = this.#state.value!.options.flatMap((entry) => ('options' in entry ? entry.options : entry))
    switch (event.code) {
      case keyCodes.DOWN:
        this._activeIndex = Math.min(options.length - 1, this._activeIndex + 1)
        return handled()
      case keyCodes.UP:
        this._activeIndex = Math.max(0, this._activeIndex - 1)
        return handled()
      case keyCodes.END:
        this._activeIndex = options.length - 1
        return handled()
      case keyCodes.HOME:
        this._activeIndex = 0
        return handled()
      case keyCodes.PAGEDOWN:
        this._activeIndex = Math.min(options.length - 1, this._activeIndex + 10)
        return handled()
      case keyCodes.PAGEUP:
        this._activeIndex = Math.max(0, this._activeIndex - 10)
        return handled()
      case keyCodes.RETURN:
      case keyCodes.SPACE:
        this.#handleChange(options[this._activeIndex])
        return handled()
    }

    const keyboardLetterKey = /^\S$/.test(event.key) ? event.key.toLowerCase() : null
    if (keyboardLetterKey !== null) {
      const shouldCycle = options[this._activeIndex].text[0].toLowerCase() === keyboardLetterKey
      const index = options.findIndex((option, index) => {
        const matches = option.text[0].toLowerCase() === keyboardLetterKey.toLowerCase()
        if (shouldCycle) {
          return matches && index > this._activeIndex
        }

        return matches
      })
      if (index !== -1) {
        this._activeIndex = index
        return handled()
      }
    }
  }

  #handleChange = (option: listOption) => {
    if (option.disabled) {
      return
    }
    const value = option.value
    if (this.#state.value!.multiple) {
      const values = this.#state.value!.value
      if (values.includes(value)) {
        this.#state.value!.setValue(values.filter((x) => x !== value))
      } else {
        this.#state.value!.setValue([...values, value])
      }
    } else {
      this.#state.value!.setValue([value])
    }
    this.dispatchEvent(new Event('input'))
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'contextual-one-ux-list': ContextualOneUxListElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'contextual-one-ux-list': ContextualOneUxListElement
    }
  }
}
