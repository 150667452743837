import { svg } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { KeyScale } from '../axis/key/KeyScale'
import { ValueScale } from '../axis/value/ValueScale'
import { ColumnStackedRenderGroup, columnStackedRenderGroupData } from './types'
import { KeyValueSet } from '../axis/key/KeyValueSet'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { timing } from '../animation'

export function ColumnStacked(
  keyScale: KeyScale,
  valueScale: ValueScale,
  group: ColumnStackedRenderGroup,
  animate: boolean
) {
  const innerKeyScale = createInnerKeyScale(keyScale, group)

  const data = group.data.filter((dp) => {
    const height = getHeight(valueScale, dp)
    if (height === 0) {
      return false
    }

    return true
  })

  return svg`
    <g transform="translate(0, 0)" fill="none" class="series-column">
      ${repeat(
        data,
        (dp) => `column-stacked:${dp.name}:${dp.keyIndex}`,
        (dp) => {
          const height = getHeight(valueScale, dp)
          const x = innerKeyScale.start(0)
          const y = getY(valueScale, dp)
          const width = innerKeyScale.size

          return svg`<rect fill=${dp.color}
            ${svgTransition({
              timing: timing(animate),
              attributes: {
                x: interpolate.constant(x, (x) => x.toString()),
                y: interpolate.constant(y, (y) => y.toString()),
                width: interpolate.constant(width, (width) => width.toString()),
                height: interpolate.constant(height, (height) => height.toString()),
                opacity: interpolate.standard(0, 1, 0)
              }
            })}
          />`
        }
      )}
    </g>
  `
}

const getY = (valueScale: ValueScale, dp: columnStackedRenderGroupData) => {
  if (dp.value < 0) {
    return valueScale.clampPosition(dp.value) - getHeight(valueScale, dp) + 1
  }

  return valueScale.clampPosition(dp.value)
}

const getHeight = (valueScale: ValueScale, dp: columnStackedRenderGroupData) => {
  const prevY = valueScale.clampPosition(dp.previousValue)
  const currentY = valueScale.clampPosition(dp.value)

  return Math.abs(prevY - currentY)
}

const createInnerKeyScale = (keyScale: KeyScale, group: ColumnStackedRenderGroup) => {
  const left = keyScale.start(group.key)
  const right = keyScale.end(group.key)
  const margin = keyScale.size * 0.2
  const bounds = keyScale.bounds
  bounds.left = left + margin
  bounds.right = right - margin

  return new KeyScale(bounds, new KeyValueSet([0]))
}
