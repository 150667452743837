import { ElementPart, nothing } from 'lit'
import { AsyncDirective, PartInfo, PartType, directive } from 'lit/async-directive.js'
import { prefersReducedMotion } from '@/one-ux/common/utils/prefersReducedMotion'

type state = 'enter' | 'update' | 'exit'
type context = {
  state: state
  interpolate: (a: number | number[], b: number | number[]) => number | number[]
  oldValue?: number | number[]
}
type options = {
  timing: {
    easing: (t: number) => number
    duration: number
  }
  attributes: Record<string, (context: context) => string>
}

class SvgTransitionDirective extends AsyncDirective {
  private $element!: Element
  private options!: options
  private firstUpdate = true
  private oldValues: Record<string, number | number[]> = {}
  private currentFinalFrame!: Record<string, { value: number | number[]; result: string }>
  private currentAnimationFrame = 0

  constructor(part: PartInfo) {
    super(part)
    if (part.type !== PartType.ELEMENT) {
      throw new Error('The `svgTransition` directive must be used in element position.')
    }
  }

  render(options: options) {
    return nothing
  }

  update(part: ElementPart, [options]: Parameters<this['render']>) {
    this.$element = part.element as Element
    this.options = options

    if (this.firstUpdate) {
      this.firstUpdate = false
      this.transition('enter')
    } else {
      this.transition('update')
    }
  }

  async disconnected() {
    const parent = this.$element.parentElement!
    await new Promise(requestAnimationFrame)
    // Check that element was actually removed and not that the host element disconnected
    if (!this.$element.parentElement) {
      parent.appendChild(this.$element)
      this.transition('exit')
    }
  }

  private transition(state: state) {
    if (!this.checkDirtyFinalFrameAndFastForwardIfNeeded(state)) {
      return
    }

    const { easing, duration } = this.options.timing
    const startTime = performance.now()

    const animate = () => {
      const t = prefersReducedMotion() ? 1 : (performance.now() - startTime) / duration
      const done = t >= 1
      const easingValue = easing(done ? 1 : t)

      for (const attr of Object.keys(this.options.attributes)) {
        const callback = this.options.attributes[attr]
        const lerpAndSetOldValue = (a: number | number[], b: number | number[]) => {
          const isArrayArguments = Array.isArray(a) && Array.isArray(b)
          if (isArrayArguments) {
            if (a.length !== b.length) {
              throw new Error('Cannot interpolate arrays of different length')
            }
          } else {
            if (typeof a !== 'number' || typeof b !== 'number') {
              throw new Error('Cannot interpolate between non-numbers')
            }
          }

          const result = isArrayArguments
            ? a.map((x, i) => lerp(x, b[i], easingValue))
            : lerp(a as number, b as number, easingValue)
          if (done) {
            this.oldValues[attr] = result
          }
          return result
        }

        // Important: Old value can become undefined in callback if you do not always call interpolate function within callback
        this.$element.setAttribute(
          attr,
          callback({
            state,
            interpolate: lerpAndSetOldValue,
            oldValue: this.oldValues[attr]
          })
        )
      }

      if (!done) {
        this.currentAnimationFrame = requestAnimationFrame(animate)
      } else if (state === 'exit') {
        this.$element.remove()
      }
    }
    this.currentAnimationFrame = requestAnimationFrame(animate)
  }

  private checkDirtyFinalFrameAndFastForwardIfNeeded(state: state) {
    const newFinalFrame = this.getFinalFrame(state)
    if (this.currentFinalFrame) {
      if (!this.isNewFinalFrameDirty(newFinalFrame)) {
        return false
      }

      cancelAnimationFrame(this.currentAnimationFrame)
      this.fastForwardCurrentFinalFrame()
    }
    this.currentFinalFrame = newFinalFrame
    return true
  }

  private getFinalFrame(state: state) {
    const finalFrame = {} as any
    for (const attr of Object.keys(this.options.attributes)) {
      const callback = this.options.attributes[attr]

      let value
      const lerpAndGetFinalValue = (a: number | number[], b: number | number[]) => (value = b)

      const result = callback({
        state,
        interpolate: lerpAndGetFinalValue,
        oldValue: undefined // Irrelevant as we force the final new value
      })

      // Do not run result callback inline as that will make value undefined
      finalFrame[attr] = {
        value,
        result
      }
    }
    return finalFrame
  }

  private isNewFinalFrameDirty(newFinalFrame: any) {
    for (const attr of Object.keys(this.options.attributes)) {
      if (newFinalFrame[attr].result !== this.currentFinalFrame[attr].result) {
        return true
      }
    }
    return false
  }

  private fastForwardCurrentFinalFrame() {
    for (const attr of Object.keys(this.options.attributes)) {
      this.oldValues[attr] = this.currentFinalFrame[attr].value
      this.$element.setAttribute(attr, this.currentFinalFrame[attr].result)
    }
  }
}

function lerp(v0: number, v1: number, t: number) {
  return (1 - t) * v0 + t * v1
}

export const svgTransition = directive(SvgTransitionDirective)

// https://easings.net/
export const ease = {
  linear: (t: number) => t,
  inCubic: (t: number) => t * t * t,
  outCubic: (t: number) => 1 - Math.pow(1 - t, 3)
}

export const interpolate = {
  standard:
    <T extends number | number[]>(enterValue: T, updateValue: T, exitValue: T, transform?: (value: T) => string) =>
    ({ state, interpolate, oldValue }: context): string => {
      transform = transform || String
      switch (state) {
        case 'enter':
          return transform(interpolate(enterValue, updateValue) as T)
        case 'update':
          return transform(interpolate(oldValue!, updateValue) as T)
        case 'exit':
          return transform(interpolate(oldValue!, exitValue) as T)
      }
    },

  constant:
    <T extends number | number[]>(value: T, transform?: (value: T) => string) =>
    ({ interpolate, oldValue }: context): string => {
      transform = transform || String
      return transform(interpolate(oldValue ?? value, value) as T)
    }
}
