import { log } from '../log';
export class CancellationToken {
    constructor() {
        this.cancelCallbacks = [];
    }
    cancel() {
        const callbacks = [...this.cancelCallbacks];
        this.cancelCallbacks.length = 0;
        callbacks.forEach((callback) => {
            try {
                callback();
            }
            catch (ex) {
                // eslint-disable-next-line no-console
                log.warning('Could not execute callback in cancellation token.');
            }
        });
    }
    addCallback(callback) {
        const isCallable = callback instanceof Function;
        const isNotRegistered = !this.cancelCallbacks.includes(callback);
        if (isCallable && isNotRegistered) {
            this.cancelCallbacks.push(callback);
        }
    }
    removeCallback(callback) {
        if (this.cancelCallbacks.includes(callback)) {
            this.cancelCallbacks.splice(this.cancelCallbacks.indexOf(callback), 1);
        }
    }
}
