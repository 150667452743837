export class DateCalculator {
  clamp(date, min, max) {
    let [year, month, day] = date.split('-')

    if (min) {
      const minParts = min.split('-')
      if (year < +minParts[0]) {
        year = +minParts[0]
        month = +minParts[1]
      } else if (month < +minParts[1]) {
        month = +minParts[1]
      }
    }
    if (max) {
      const maxParts = max.split('-')
      if (year > +maxParts[0]) {
        year = +maxParts[0]
        month = +maxParts[1]
      } else if (month > +maxParts[1]) {
        month = +maxParts[1]
      }
    }

    return [year, month, day].join('-')
  }

  getMonthsForLocale(locale) {
    var format = new Intl.DateTimeFormat(locale, { month: 'long' })
    var months = []
    for (var month = 0; month < 12; month++) {
      var testDate = new Date(2000, month, 1, 0, 0, 0)
      months.push(format.format(testDate))
    }
    return months
  }

  getWeekdaysForLocale(locale) {
    var format = new Intl.DateTimeFormat(locale, { weekday: 'short' })
    var days = []
    for (var day = 0; day < 7; day++) {
      var testDate = new Date(2000, 0, 2 + day, 0, 0, 0)
      days.push(format.format(testDate))
    }
    return days
  }

  checkValidDateValueRange(value, min, max) {
    const valueParts = value.split('-')
    const valueDate = new Date(valueParts[0], valueParts[1] - 1, valueParts[2])

    const minDateParts = min.split('-')
    const maxDateParts = max.split('-')
    const minDate = minDateParts.length ? new Date(minDateParts[0], minDateParts[1] - 1, minDateParts[2]) : null
    const maxDate = maxDateParts.length ? new Date(maxDateParts[0], maxDateParts[1] - 1, maxDateParts[2]) : null

    if (!value && (minDate || maxDate)) {
      return false
    }
    return !((minDate && valueDate < minDate) || (maxDate && valueDate > maxDate))
  }

  getISODate(date) {
    const year = date.getFullYear().toString().padStart(4, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
  }

  parseDateValue(value) {
    const parts = ((value || '') + '').split('-')
    if (parts[0] && !parts[1] && !parts[2]) {
      if (parts[0].length === 6) {
        parts[1] = parts[0].substr(2, 2)
        parts[2] = parts[0].substr(4, 2)
        parts[0] = parts[0].substr(0, 2)
      }
      if (parts[0].length === 8) {
        parts[1] = parts[0].substr(4, 2)
        parts[2] = parts[0].substr(6, 2)
        parts[0] = parts[0].substr(0, 4)
      }
    }

    const year = +this.#ensureFullYear(parts[0])
    const month = +parts[1] - 1
    const day = +parts[2]
    if (month < 0 || month > 11 || day < 1 || day > new Date(year, month + 1, 0).getDate()) {
      return ''
    }

    const date = new Date(year, month, day)
    return !isNaN(date) ? this.getISODate(date) : ''
  }

  #ensureFullYear(year) {
    return year && year.length === 2 ? +((new Date().getFullYear() + '').substr(0, 2) + year) : year
  }
}
