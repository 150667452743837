import { PropertyValueMap, html, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { live } from 'lit/directives/live.js'
import { Compact } from '../common/mixins/Compact'
import { Disabled } from '../common/mixins/Disabled'
import { Focusable } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { Purpose } from '../common/mixins/Purpose'
import { StyledFactory } from '../common/mixins/Styled'
import { OneUxElement } from '../common/OneUxElement'
import { style } from './style'
import { log } from '../common/utils/log'
import { InternalValueChangedEvent } from '../events/internal/InternalValueChangedEvent'
import { FormAssociatedFactory } from '../common/mixins/FormAssociated'
import { IValue, ValueFactory } from '../common/mixins/Value'
import { Checked, IChecked } from '../common/mixins/Checked'
import { HidableTooltip } from '../common/mixins/HidableTooltip'

const Styled = StyledFactory(style)

type valueType = unknown

const Value = ValueFactory<valueType, IValue<unknown> & IChecked>({
  getter: function () {
    if (this.checked) {
      return this.internalValue
    }
    return null
  }
})

const FormAssociated = FormAssociatedFactory<valueType, IChecked>({
  reset: function () {
    this.checked = this.initialChecked
  }
})

const BaseClass = HidableTooltip(
  FormAssociated(Value(Checked(Purpose(Disabled(Compact(Implicit(Focusable(Styled(OneUxElement)))))))))
)

/**
 * A switch component to be used for user input
 */
@customElement('one-ux-switch')
export class OneUxSwitchElement extends BaseClass {
  /*
   * The text associated with the switch.
   * This is a required property and the switch will not render if it is omitted.
   */
  @property({ type: String })
  public text!: string

  /*
   * Placement of the switch against the text
   */
  @property({ attribute: 'switch-position', type: String })
  public switchPosition = 'before-text' as 'before-text' | 'after-text'

  protected willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    const hasChecked = _changedProperties.has('checked')
    const hasValue = _changedProperties.has('value')

    if (hasChecked || hasValue) {
      this.dispatchEvent(new InternalValueChangedEvent())
    }
  }

  render() {
    if (!this.text) {
      log.error('Missing text, not rendering.')
      return
    }

    const tooltip = this.compact && !this.hideTooltip ? this.text : undefined

    const input = html`
      <input
        type="checkbox"
        role="switch"
        .checked=${live(this.checked)}
        .disabled=${this.disabled}
        one-ux-tooltip=${ifDefined(tooltip)}
        ?one-ux-tooltip-custom-aria=${!!tooltip}
        aria-label=${ifDefined(this.compact ? this.text : undefined)}
        @input=${this.#handleInput}
      />
    `

    const text = html`${!this.compact ? html`<span>${this.text}</span>` : nothing}`

    return html`<label class="one-ux-element--root">
      ${this.switchPosition == 'after-text' ? [text, input] : [input, text]}
    </label> `
  }

  #handleInput = (event: Event) => {
    event.stopPropagation()

    this.checked = !this.checked

    this.dispatchEvent(new Event('input'))
    this.dispatchEvent(new Event('change'))
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-switch': OneUxSwitchElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-switch': OneUxSwitchElement
    }
  }
}
