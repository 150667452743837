class PathCommands {
  #commands: string[] = []

  move(dx: number, dy: number) {
    this.#commands.push(`m ${dx} ${dy}`)
    return this
  }

  moveTo(x: number, y: number) {
    this.#commands.push(`M ${x} ${y}`)
    return this
  }

  line(dx: number, dy: number) {
    this.#commands.push(`l ${dx} ${dy}`)
    return this
  }

  lineTo(x: number, y: number) {
    this.#commands.push(`L ${x} ${y}`)
    return this
  }

  horizontalLine(dx: number) {
    this.#commands.push(`h ${dx}`)
    return this
  }

  horizontalLineTo(x: number) {
    this.#commands.push(`H ${x}`)
    return this
  }

  verticalLine(dy: number) {
    this.#commands.push(`v ${dy}`)
    return this
  }

  verticalLineTo(y: number) {
    this.#commands.push(`V ${y}`)
    return this
  }

  cubicBezier(dx1: number, dy1: number, dx2: number, dy2: number, dx: number, dy: number) {
    this.#commands.push(`c ${dx1} ${dy1} ${dx2} ${dy2} ${dx} ${dy}`)
    return this
  }

  cubicBezierTo(x1: number, y1: number, x2: number, y2: number, x: number, y: number) {
    this.#commands.push(`C ${x1} ${y1} ${x2} ${y2} ${x} ${y}`)
    return this
  }

  smoothCubicBezier(dx2: number, dy2: number, dx: number, dy: number) {
    this.#commands.push(`s ${dx2} ${dy2} ${dx} ${dy}`)
    return this
  }

  smoothCubicBezierTo(x2: number, y2: number, x: number, y: number) {
    this.#commands.push(`S ${x2} ${y2} ${x} ${y}`)
    return this
  }

  quadraticBezier(dx1: number, dy1: number, dx: number, dy: number) {
    this.#commands.push(`q ${dx1} ${dy1} ${dx} ${dy}`)
    return this
  }

  quadraticBezierTo(x1: number, y1: number, x: number, y: number) {
    this.#commands.push(`Q ${x1} ${y1} ${x} ${y}`)
    return this
  }

  smoothQuadraticBezier(dx: number, dy: number) {
    this.#commands.push(`t ${dx} ${dy}`)
    return this
  }

  smoothQuadraticBezierTo(x: number, y: number) {
    this.#commands.push(`T ${x} ${y}`)
    return this
  }

  arc(rx: number, ry: number, angle: number, large: boolean, clockwise: boolean, dx: number, dy: number) {
    this.#commands.push(`a ${rx} ${ry} ${angle} ${large ? 1 : 0} ${clockwise ? 1 : 0} ${dx} ${dy}`)
    return this
  }

  arcTo(rx: number, ry: number, angle: number, large: boolean, clockwise: boolean, x: number, y: number) {
    this.#commands.push(`A ${rx} ${ry} ${angle} ${large ? 1 : 0} ${clockwise ? 1 : 0} ${x} ${y}`)
    return this
  }

  close() {
    this.#commands.push('z')
    return this
  }

  toString() {
    return `\n${this.#commands.join('\n')}\n`
  }

  isEmpty() {
    return this.#commands.length === 0
  }
}

export const path = () => new PathCommands()
