import debounce from './debounce'

export function onResize(element, callback) {
  const resizeObserverCallback = async (entries) => {
    const size = { width: null, height: null }
    entries.forEach((entry) => {
      size.width = entry.contentRect.width
      size.height = entry.contentRect.height
    })

    const disconnect = (await callback(size)) === false
    if (disconnect) {
      observer.disconnect(element)
    }
  }

  const observer = new ResizeObserver(debounce(resizeObserverCallback, 500, true))
  observer.observe(element)
}
