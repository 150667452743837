import { size, bounds, margin } from './types'

export const boundsLegends = (svgSize: size, margin: bounds): bounds => ({
  top: 0,
  bottom: margin.top,
  left: margin.left,
  right: svgSize.width
})

export const boundsLeftAxis = (svgSize: size, margin: margin): bounds => ({
  top: margin.top,
  bottom: svgSize.height - margin.bottom,
  left: 0,
  right: margin.left
})

export const boundsRightAxis = (svgSize: size, margin: margin): bounds => ({
  top: margin.top,
  bottom: svgSize.height - margin.bottom,
  left: svgSize.width - margin.right,
  right: svgSize.width
})

export const boundsKeyAxis = (svgSize: size, margin: bounds): bounds => ({
  top: svgSize.height - margin.bottom,
  bottom: svgSize.height,
  left: margin.left,
  right: svgSize.width - margin.right
})

export const boundsPlotArea = (svgSize: size, margin: margin): bounds => ({
  top: margin.top,
  bottom: svgSize.height - margin.bottom,
  left: margin.left,
  right: svgSize.width - margin.right
})
