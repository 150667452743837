import { AxisScale } from '../common/AxisScale'
import { barChartType, barItem, legendItem } from './types'

export const createAxisScale = ({
  type,
  legend,
  bars,
  axisWidth,
  tickSpacing,
  minHardLimit,
  maxHardLimit
}: {
  type: barChartType
  legend: legendItem[]
  bars: barItem[]
  axisWidth: number
  tickSpacing: number
  minHardLimit?: number
  maxHardLimit?: number
}) => {
  let minFromValues = 0
  let maxFromValues = 0

  if (type === 'distribution') {
    maxFromValues = 100
  } else {
    bars.forEach((bar) => {
      if (type === 'stacked') {
        const total = legend.reduce((total, legendItem) => total + Math.max(bar.data[legendItem.key] || 0, 0), 0)
        maxFromValues = Math.max(maxFromValues, total)
      }
      if (type === 'grouped') {
        legend.forEach((x) => {
          minFromValues = Math.min(minFromValues, bar.data[x.key] || 0)
          maxFromValues = Math.max(maxFromValues, bar.data[x.key] || 0)
        })
      }
    })
  }

  return new AxisScale({
    size: axisWidth,
    spacing: tickSpacing,
    minValue: minFromValues,
    maxValue: maxFromValues,
    minHardLimit,
    maxHardLimit
  })
}
