import { createContext } from '@lit/context'

export interface OneUxFieldContext {
  setEmpty(value: boolean): void
  setRequired(value: boolean): void
  setDisabled(value: boolean): void
  setMultiLine(value: boolean): void
  label: string
}

export const oneUxFieldContext = createContext<OneUxFieldContext>('one-ux-field-context')
