import { path } from '../path'

/*
  Outline of points generated:

    p1-----------p2
  p0               p3
  |                 |
  p7               p4
    p6-----------p5

*/

export function roundedRectangle(
  width: number,
  height: number,
  radiusTopLeft: number,
  radiusTopRight: number,
  radiusBottomRight: number,
  radiusBottomLeft: number
) {
  const maxHorizontalRadius = width / 2
  const maxVerticalRadius = height / 2
  const maxRadius = Math.min(maxHorizontalRadius, maxVerticalRadius)
  const antialiasBias = 0.01

  radiusTopLeft = Math.min(radiusTopLeft + antialiasBias, maxRadius)
  radiusTopRight = Math.min(radiusTopRight + antialiasBias, maxRadius)
  radiusBottomRight = Math.min(radiusBottomRight + antialiasBias, maxRadius)
  radiusBottomLeft = Math.min(radiusBottomLeft + antialiasBias, maxRadius)

  const point0 = {
    x: 0,
    y: radiusTopLeft
  }

  const point1 = {
    x: radiusTopLeft,
    y: 0
  }

  const point2 = {
    x: width - radiusTopRight,
    y: 0
  }

  const point3 = {
    x: width,
    y: radiusTopRight
  }

  const point4 = {
    x: width,
    y: height - radiusBottomRight
  }

  const point5 = {
    x: width - radiusBottomRight,
    y: height
  }

  const point6 = {
    x: radiusBottomLeft,
    y: height
  }

  const point7 = {
    x: 0,
    y: height - radiusBottomLeft
  }

  return path()
    .moveTo(point0.x, point0.y)
    .arcTo(radiusTopLeft, radiusTopLeft, 0, false, true, point1.x, point1.y)
    .lineTo(point2.x, point2.y)
    .arcTo(radiusTopRight, radiusTopRight, 0, false, true, point3.x, point3.y)
    .lineTo(point4.x, point4.y)
    .arcTo(radiusBottomRight, radiusBottomRight, 0, false, true, point5.x, point5.y)
    .lineTo(point6.x, point6.y)
    .arcTo(radiusBottomLeft, radiusBottomLeft, 0, false, true, point7.x, point7.y)
    .close()
    .toString()
}
