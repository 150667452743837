import { svg } from 'lit'
import { getLineSegments } from '../../common/getLineSegments'
import { svgTransition, ease, interpolate } from '../../common/directives/svgTransition'
import { indicationData } from '../types'
import { path } from '../../common/path'
import { weight } from '@/one-ux/common/mixins/Weight'

const weightStyles: Record<
  weight,
  {
    gap: number
    height: number
    selectedHeight: number
  }
> = {
  high: {
    gap: 4,
    height: 12,
    selectedHeight: 16
  },
  normal: {
    gap: 4,
    height: 8,
    selectedHeight: 12
  },
  low: {
    gap: 3,
    height: 8,
    selectedHeight: 8
  }
}

export function BarSegments({
  indication,
  width,
  x,
  y,
  weight
}: {
  indication: indicationData[]
  width: number
  x: number
  y: number
  weight: weight
}) {
  const weightStyle = weightStyles[weight]

  const segments = getLineSegments({
    segments: indication.map((x) => ({
      value: 1,
      item: x
    })),
    width: width,
    minSegmentWidth: 0,
    gap: weightStyle.gap
  })

  const anyHighlighted = segments.some((segment) => segment.item.highlighted)

  return svg`
    <g transform=${`translate(${x},${y})`}>
      ${segments.map((segment, index) => {
        const height = segment.item.highlighted ? weightStyle.selectedHeight : weightStyle.height
        const yOffset = segment.item.highlighted ? 0 : (weightStyle.selectedHeight - weightStyle.height) / 2

        const previousSegmentHighlighted = segments[index - 1]?.item.highlighted
        const nextSegmentHighlighted = segments[index + 1]?.item.highlighted

        const bulgeLeft =
          segment.item.highlighted ||
          (!previousSegmentHighlighted && (index <= (segments.length - 1) / 2 || index == segments.length / 2))
        const bulgeRight = segment.item.highlighted || (!nextSegmentHighlighted && index >= (segments.length - 1) / 2)

        const radiusLeft =
          0.5 *
          (bulgeLeft
            ? height
            : 2 * weightStyle.gap + (previousSegmentHighlighted ? weightStyle.selectedHeight : weightStyle.height))
        const radiusRight =
          0.5 *
          (bulgeRight
            ? height
            : 2 * weightStyle.gap + (nextSegmentHighlighted ? weightStyle.selectedHeight : weightStyle.height))

        const getIndentationOffset = (radius: number) =>
          radius - Math.sqrt(Math.pow(radius, 2) - Math.pow(height / 2, 2))

        const leftXOffset = bulgeLeft ? radiusLeft : -getIndentationOffset(radiusLeft)
        const rightXOffset = bulgeRight ? -radiusRight : getIndentationOffset(radiusRight)

        const opacity = !anyHighlighted || segment.item.highlighted ? 1 : 0.2

        return svg`
            <path
              class="segment"
              fill=${segment.item.color}
              ${svgTransition({
                timing: {
                  easing: ease.outCubic,
                  duration: 500
                },
                attributes: {
                  opacity: interpolate.standard(0, opacity, 0),
                  d: interpolate.constant(
                    [
                      segment.x + leftXOffset,
                      yOffset,
                      segment.width - leftXOffset + rightXOffset,
                      height,
                      radiusRight,
                      radiusLeft
                    ],
                    ([segmentX, segmentY, segmentWidth, segmentHeight, segmentRadiusRight, segmentRadiusLeft]) =>
                      path()
                        .moveTo(segmentX + segmentWidth, segmentY)
                        .arcTo(
                          segmentRadiusRight,
                          segmentRadiusRight,
                          0,
                          false,
                          bulgeRight,
                          segmentX + segmentWidth,
                          segmentY + segmentHeight
                        )
                        .lineTo(segmentX, segmentY + segmentHeight)
                        .arcTo(segmentRadiusLeft, segmentRadiusLeft, 0, false, bulgeLeft, segmentX, segmentY)
                        .close()
                        .toString()
                  )
                }
              })}
            />
          `
      })}
  </g>
    `
}
