import './sem-2.0.less?manual'
import './animations'

const selects = document.getElementsByTagName('select') // Use live node list for performance
requestAnimationFrame(function updateSelectPlaceholders() {
  for (let i = 0, n = selects.length; i < n; ++i) {
    const select = selects[i]

    if (!select.matches('.sem[class*="is-"]')) {
      continue
    }

    const placeholderSelected = select.selectedIndex === 0 && select.options[0].getAttribute('value') === ''
    select.toggleAttribute('data-state-placeholder', placeholderSelected)
  }
  requestAnimationFrame(updateSelectPlaceholders)
})

const keyboardFocusAttribute = '_kf'
let mouseActive = false
let pendingMouseInactive

document.addEventListener(
  'mousedown',
  () => {
    if (validCurrentFocus()) {
      document.activeElement.removeAttribute(keyboardFocusAttribute)
    }
    mouseActive = true
    clearTimeout(pendingMouseInactive)
  },
  true
)

document.addEventListener(
  'mouseup',
  () => {
    pendingMouseInactive = setTimeout(() => {
      mouseActive = false
    }, 500)
  },
  true
)

document.addEventListener(
  'focus',
  () => {
    if (validCurrentFocus()) {
      document.activeElement.toggleAttribute(keyboardFocusAttribute, !mouseActive)
    }
  },
  true
)

function validCurrentFocus() {
  const currentFocus = document.activeElement
  return (
    currentFocus &&
    currentFocus !== window &&
    currentFocus !== document &&
    (currentFocus.matches('.sem') || currentFocus.nodeName.toLowerCase().startsWith('dynamic-'))
  )
}
