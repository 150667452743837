import { PropertyValues, html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { classMap } from 'lit/directives/class-map.js'
import { Ref, createRef, ref } from 'lit/directives/ref.js'
import { StyledFactory } from '../common/mixins/Styled'
import { OneUxElement } from '../common/OneUxElement'
import { style } from './style'
import { Weight } from '../common/mixins/Weight'
import { Disabled } from '../common/mixins/Disabled'
import { Implicit } from '../common/mixins/Implicit'
import { Focusable } from '../common/mixins/Focusable'
import { Placeholder } from '../common/mixins/Placeholder'
import { IValue, ValueFactory } from '../common/mixins/Value'
import { FormAssociated } from '../common/mixins/FormAssociated'
import { getLangCode } from '../common/utils/getLangCode'
import { ContextConsumer } from '@lit/context'
import { oneUxFieldContext } from '../one-ux-field/OneUxFieldContext'
import { IRequired, Required } from '../common/mixins/Required'
import { log } from '@/pdr/log'
import { Errors } from '../common/mixins/Errors'
import { Label } from '../common/mixins/Label'
import { Compact } from '../common/mixins/Compact'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../common/mixins/Validated'
import { live } from 'lit/directives/live.js'

const Styled = StyledFactory(style)
type valueType = string
const Value = ValueFactory<valueType>({
  type: String
})
const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldIsRequired } = getFormValidationLanguage(this)
    const valid = hasValue(this.value)
    return {
      valid,
      flags: {
        valueMissing: !valid
      },
      errors: [fieldIsRequired]
    }
  }
})

const BaseClass = FormAssociated(
  Validated(
    Errors(Label(Compact(Required(Value(Placeholder(Implicit(Disabled(Focusable(Weight(Styled(OneUxElement)))))))))))
  )
)

function hasValue(value: unknown) {
  return typeof value === 'string' ? !!value : value != null
}

type Format = 'text' | 'number' | 'password'

/**
 * A single line text input field
 */
@customElement('one-ux-input')
export class OneUxInputElement extends BaseClass {
  #oneUxFieldContext = new ContextConsumer(this, {
    context: oneUxFieldContext
  })

  $ref: Ref<Element> = createRef()
  /*
   * Visually present value as text, number or password
   */
  @property({ type: String, reflect: true })
  public format!: Format

  protected willUpdate(changedProperties: PropertyValues) {
    if (this.#oneUxFieldContext.value) {
      if (changedProperties.has('required')) {
        this.#oneUxFieldContext.value.setRequired(this.required)
      }
      if (changedProperties.has('disabled')) {
        this.#oneUxFieldContext.value.setDisabled(this.disabled)
      }
      if (changedProperties.has('value')) {
        const empty = !hasValue(this.value)
        this.#oneUxFieldContext.value.setEmpty(empty)
      }
    }
  }

  /**
   * **DEPRECATED** Use `compact` instead.
   */
  @property({ attribute: 'label-hidden', type: Boolean })
  public get labelHidden() {
    return this.compact
  }
  public set labelHidden(value: boolean) {
    log.deprecation({
      title: '"label-hidden" is deprecated in favour of the property "compact".',
      details: this
    })
    this.compact = value
  }

  protected render() {
    this.setAttribute('lang', getLangCode(this))

    const hasContext = !!this.#oneUxFieldContext.value
    const $content = html`
      <input
        class=${classMap({
          'one-ux-element--root': hasContext
        })}
        ${ref(this.$ref)}
        .value=${live(this.value || '')}
        .placeholder=${this.placeholder || ''}
        .disabled=${this.disabled}
        type=${ifDefined(this.format === 'password' ? 'password' : undefined)}
        inputmode=${ifDefined(this.format === 'number' ? 'numeric' : undefined)}
        aria-required=${!!this.required}
        aria-label=${hasContext ? this.#oneUxFieldContext.value!.label! : this.label}
        @beforeinput=${this.#handleBeforeInput}
        @input=${this.#handleInput}
        @change=${this.#handleChange}
      />
    `

    if (hasContext) {
      log.deprecation({
        title:
          'Using <one-ux-input> in a <one-ux-field> has been deprecated in favor of using <one-ux-input> stand alone.',
        message:
          'The API for <one-ux-field> and <one-ux-input> become unclear when used together. In order to prevent potential invalid states <one-ux-input> will not communicate with <one-ux-field> in the next breaking change.',
        details: this
      })
      return $content
    }

    return html`<one-ux-field
      class="one-ux-element--root"
      .label=${this.label}
      .compact=${this.compact}
      .required=${this.required}
      .disabled=${this.disabled}
      .implicit=${this.implicit}
      .weight=${this.weight}
      .empty=${!hasValue(this.value)}
      .errors=${this.errors}
    >
      ${$content}
    </one-ux-field>`
  }

  #handleBeforeInput(event: InputEvent) {
    event.stopPropagation()
    if (this.format !== 'number') {
      return
    }
    if (event.data && /[^0-9-.,]/.test(event.data)) {
      event.preventDefault()
    }
  }

  #handleInput(event: Event) {
    event.stopPropagation()
    const target = event.target as HTMLInputElement
    const beforeInputEvent = new InputEvent('beforeinput', {
      bubbles: true,
      composed: true,
      cancelable: true,
      data: target.value
    })
    if (this.dispatchEvent(beforeInputEvent)) {
      this._applyUserValue(target.value)
      const inputEvent = new InputEvent('input', {
        bubbles: true,
        composed: true,
        cancelable: false
      })
      this.dispatchEvent(inputEvent)
    } else {
      target.value = this.value ?? ''
    }
  }

  #handleChange() {
    this.dispatchEvent(new Event('change', { bubbles: true }))
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-input': OneUxInputElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-input': OneUxInputElement
    }
  }
}
