export const KEY_BACKSPACE = 8
export const KEY_TAB = 9
export const KEY_ENTER = 13
export const KEY_ESCAPE = 27
export const KEY_SPACE = 32
export const KEY_PAGEUP = 33
export const KEY_PAGEDOWN = 34
export const KEY_END = 35
export const KEY_HOME = 36
export const KEY_LEFT = 37
export const KEY_UP = 38
export const KEY_RIGHT = 39
export const KEY_DOWN = 40
export const KEY_DELETE = 46
export const KEY_A = 65
