import { PropertyValues, html, nothing } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import { OneUxElement } from '../common/OneUxElement'
import { FocusableFactory } from '../common/mixins/Focusable'
import { Implicit } from '../common/mixins/Implicit'
import { StyledFactory } from '../common/mixins/Styled'
import { keyCodes } from '../common/utils'
import type { OneUxIconToken, OneUxPaletteToken } from '../generated/design-tokens'
import { duration as oneUxDuration } from '../generated/design-tokens.json'
import { closeAnimation, openAnimation } from './animations'

import { style } from './style'
import { log } from '../common/utils/log'
import { flushAnimations } from '../common/utils/animation-utils'

type Icon = {
  set?: keyof OneUxIconToken
  name: string
  text: string
  color?: OneUxPaletteToken
}

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)

const BaseClass = Implicit(Focusable(Styled(OneUxElement)))

/**
 * A `<one-ux-collapsible-section>` is a collapsible section containing any type of content.
 */
@customElement('one-ux-collapsible-section')
export class OneUxCollapsibleSectionElement extends BaseClass {
  /**
   * The heading of the collapsible section.
   */
  @property({ type: String })
  public heading = ''

  /**
   * Sets whether the component is expanded or not.
   */
  @property({ type: Boolean, reflect: true })
  public expanded = false

  /**
   * Icon: Icons displayed right after heading. Supports up to three icons.
   * * set: A optional icon set. Is "default" if not provided.
   * * name: Name of the icon.
   * * text: Accessability label for the icon.
   * * color: A optional icon color.
   */
  @property({ type: Array })
  public icons!: Icon[]

  /**
   * Content will not be indented if set.
   */
  @property({ attribute: 'disable-indent', type: Boolean, reflect: true })
  public disableIndent = false

  @state()
  private _hasContent = false

  @state()
  private _active = false

  constructor() {
    super()
    this.width = 'max'
  }

  protected render() {
    if (!this.heading?.length) {
      log.error('Missing heading, not rendering.')
      return
    }

    return html`<div
      class=${classMap({
        'one-ux-element--root': true,
        'collapsible-section': true,
        active: this._active
      })}
      layout="rows"
    >
      <div class="collapsible-section--header" role="heading" aria-live="off">
        <button
          class="collapsible-section--button"
          one-ux-tooltip=${this.heading}
          aria-expanded=${this.expanded}
          @mousedown=${this.#onMouseDown}
          @mouseup=${this.#toggleWithClick}
          @keypress=${this.#toggleWithKeyboard}
        >
          <span class="collapsible-section--title">${this.heading}</span>
          ${this.#renderIcons()}
          <one-ux-icon
            class="collapsible-section--toggle-icon"
            icon=${this.expanded ? 'toggle-up' : 'toggle-down'}
            size="200"
            aria-hidden="true"
          ></one-ux-icon>
        </button>
      </div>
      <div
        class=${classMap({
          'collapsible-section--content-wrapper': true,
          expanded: this.expanded
        })}
      >
        <div
          class=${classMap({
            'collapsible-section--content': true,
            'has-content': this._hasContent
          })}
        >
          <slot @slotchange=${this.#onSlotchange}></slot>
        </div>
      </div>
    </div>`
  }

  protected async updated(dirty: PropertyValues) {
    if (!dirty.has('expanded') || dirty.get('expanded') == null) {
      return
    }
    const $wrapper = this.shadowRoot?.querySelector('.collapsible-section--content-wrapper') as HTMLDivElement
    const $content = this.shadowRoot?.querySelector('.collapsible-section--content') as HTMLDivElement
    if ($content && $wrapper) {
      flushAnimations($wrapper)

      const height = $content.clientHeight
      const animation = this.expanded ? openAnimation(height) : closeAnimation(height)

      $wrapper.animate(animation, {
        duration: oneUxDuration[200] * 2
      })
    }
  }

  #onSlotchange(e: any) {
    this._hasContent = (e.target?.assignedNodes() as HTMLSlotElement[]).length > 0
  }

  #renderIcons() {
    if (!this.icons?.length) {
      return nothing
    }

    return html`<span class="collapsible-section--icons">
      ${this.icons
        ?.slice(0, 3)
        .map(
          (icon) =>
            html`<one-ux-icon
              class="icon"
              .set=${icon.set || 'default'}
              .icon=${icon.name as OneUxIconToken[keyof OneUxIconToken]}
              .label=${icon.text}
              style=${styleMap(icon.color ? { color: `var(--one-ux-palette--${icon.color})` } : {})}
              size="300"
            ></one-ux-icon>`
        )}
    </span>`
  }

  #onMouseDown(event: MouseEvent) {
    if (event.button === 0) {
      this._active = true
    }
  }

  #toggleWithClick(event: MouseEvent) {
    if (event.button === 0) {
      event.preventDefault()
      this.#toggle()
      this.dispatchEvent(new Event('toggle'))
    } else if (this._active) {
      this._active = false
    }
  }

  #toggleWithKeyboard(event: KeyboardEvent) {
    event.preventDefault()
    if (event.code === keyCodes.SPACE || event.code === keyCodes.RETURN || event.code === keyCodes.NUMPADRETURN) {
      this.#toggle()
      this.dispatchEvent(new Event('toggle'))
    }
  }

  #toggle() {
    this.expanded = !this.expanded
    this._active = false
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-collapsible-section': OneUxCollapsibleSectionElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-collapsible-section': OneUxCollapsibleSectionElement
    }
  }
}
