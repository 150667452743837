import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: middle;
  }

  ::slotted(:not(one-ux-icon:first-of-type)) {
    position: fixed !important;
    top: -999px !important;
    left: -999px !important;
    visibility: hidden !important;
  }

  ::slotted(one-ux-icon:first-of-type) {
    font-size: 18px !important;
    width: 18px !important;
    height: 18px !important;
    overflow: hidden !important;
    flex-shrink: 0 !important;
  }

  #button {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-100);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-button-element--border-radius: var(--one-ux-radius--300);
    --one-ux-button-element--font: var(--one-ux-font--body-300);
    --one-ux-button-element--letter-spacing: var(--one-ux-letter-spacing--body-300);
    --one-ux-button-element--cursor: pointer;
    --one-ux-button-element--min-height: 32px;
    --one-ux-button-element--min-width: 74px;
    --one-ux-button-element--padding: 5px 14px;

    color: var(--one-ux-button-element--color);
    background: var(--one-ux-button-element--background);
    border-color: var(--one-ux-button-element--border-color);
    border-radius: var(--one-ux-button-element--border-radius);

    font: var(--one-ux-button-element--font);
    letter-spacing: var(--one-ux-button-element--letter-spacing);

    min-height: var(--one-ux-button-element--min-height);
    min-width: var(--one-ux-button-element--min-width);
    padding: var(--one-ux-button-element--padding);

    cursor: var(--one-ux-button-element--cursor);

    border-style: solid;
    border-width: var(--one-ux-border-width--500);
    line-height: normal;

    box-sizing: border-box;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--one-ux-spacing--small);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  one-ux-icon {
    font-size: var(--one-ux-size--icon-200);
  }

  .button-text {
    text-align: start;
  }

  :host([compact]) #button {
    height: 32px;
    min-width: 32px;
    --one-ux-button-element--padding: 0 !important;
    --one-ux-button-element--min-width: 0px;
  }

  :host([weight='low']) #button {
    --one-ux-button-element--min-height: 24px;
    --one-ux-button-element--padding: 1px 10px;
  }

  :host([weight='low'][compact]) #button {
    height: 24px;
    min-width: 24px;
  }

  :host([weight='high']) #button {
    --one-ux-button-element--border-radius: var(--one-ux-radius--700);
    --one-ux-button-element--font: var(--one-ux-font--body-variant-300);
    --one-ux-button-element--letter-spacing: var(--one-ux-letter-spacing--body-variant-300);
  }

  #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-200);
  }

  #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-300);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-neutral-400);
  }

  #button:focus {
    outline: none;
  }

  :host([state-keyboard-focus]) #button:focus,
  :host([state-keyboard-focus][purpose]) #button:focus {
    --one-ux-button-element--background: var(--one-ux-palette--keyboard-focus-200);
    --one-ux-button-element--border-color: var(--one-ux-palette--keyboard-focus-400);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([state-keyboard-focus]) #button:active,
  :host([state-keyboard-focus][purpose]) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--keyboard-focus-300);
  }

  :host([state-keyboard-focus][purpose='caution']) #button:is(:focus, :active) {
    --one-ux-button-element--color: var(--one-ux-palette--red-600);
  }

  :host([disabled]) #button {
    --one-ux-button-element--background: var(--one-ux-palette--gray-150) !important;
    --one-ux-button-element--color: var(--one-ux-palette--gray-400) !important;
    --one-ux-button-element--border-color: var(--one-ux-palette--gray-300) !important;
    --one-ux-button-element--cursor: default;
  }

  :host([purpose='main']) #button {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-400);
    --one-ux-button-element--color: var(--one-ux-palette--gray-100);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-core-400);
  }

  :host([purpose='main']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-500);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-core-500);
  }

  :host([purpose='main']) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-600);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-core-600);
  }

  :host([purpose='caution']) #button {
    --one-ux-button-element--background: var(--one-ux-palette--red-300);
    --one-ux-button-element--color: var(--one-ux-palette--gray-100);
    --one-ux-button-element--border-color: var(--one-ux-palette--red-300);
  }

  :host([purpose='caution']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--red-500);
    --one-ux-button-element--border-color: var(--one-ux-palette--red-500);
  }

  :host([purpose='caution']) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--red-600);
    --one-ux-button-element--border-color: var(--one-ux-palette--red-600);
  }

  :host([purpose='caution'][state-keyboard-focus]) #button {
    --one-ux-button-element--color: var(--one-ux-palette--red-600);
  }

  :host([purpose='notice']) #button {
    --one-ux-button-element--background: var(--one-ux-palette--azure-200);
    --one-ux-button-element--border-color: var(--one-ux-palette--azure-400);
  }

  :host([purpose='notice']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--azure-300);
  }

  :host([purpose='notice']) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--azure-400);
    --one-ux-button-element--border-color: var(--one-ux-palette--azure-500);
  }

  :host([implicit]) #button {
    --one-ux-button-element--background: transparent;
    --one-ux-button-element--border-color: transparent;
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-500);
  }

  :host([implicit]) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-150);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([implicit]) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-300);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([implicit][state-keyboard-focus]) #button:focus,
  :host([implicit][state-keyboard-focus][purpose]) #button:focus {
    --one-ux-button-element--background: var(--one-ux-palette--keyboard-focus-200);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-button-element--border-color: transparent;
  }

  :host([implicit][state-keyboard-focus]) #button:active,
  :host([implicit][state-keyboard-focus][purpose]) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--keyboard-focus-300);
    --one-ux-button-element--border-color: transparent;
  }

  :host([implicit][state-keyboard-focus][purpose='caution']) #button:is(:focus, :active) {
    --one-ux-button-element--color: var(--one-ux-palette--red-600);
  }

  :host([implicit][disabled]) #button {
    --one-ux-button-element--background: none !important;
    --one-ux-button-element--border-color: transparent !important;
  }

  :host([implicit][purpose='main']) #button {
    --one-ux-button-element--background: none;
    --one-ux-button-element--color: var(--one-ux-palette--brand-core-400);
    --one-ux-button-element--border-color: transparent;
  }

  :host([implicit][purpose='main']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-100);
  }

  :host([implicit][purpose='main']) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-150);
    --one-ux-button-element--color: var(--one-ux-palette--brand-core-600);
  }

  :host([implicit][purpose='caution']) #button {
    --one-ux-button-element--background: none;
    --one-ux-button-element--border-color: transparent;
    --one-ux-button-element--color: var(--one-ux-palette--red-500);
  }

  :host([implicit][purpose='caution']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--red-100);
  }

  :host([implicit][purpose='caution']) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--red-200);
    --one-ux-button-element--color: var(--one-ux-palette--red-600);
  }

  :host([implicit][purpose='notice']) #button {
    --one-ux-button-element--background: none;
    --one-ux-button-element--border-color: transparent;
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-500);
  }

  :host([implicit][purpose='notice']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--azure-200);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([implicit][purpose='notice']) #button:active {
    --one-ux-button-element--background: var(--one-ux-palette--azure-300);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }
`
