export async function animationFrameTimer(callback, duration) {
    return new Promise((resolve) => {
        const startTime = Date.now();
        const request = () => {
            const elapsed = Math.min(Date.now() - startTime, duration);
            requestAnimationFrame(() => {
                const isActive = callback(elapsed);
                if (isActive && elapsed < duration) {
                    request();
                }
                else {
                    resolve();
                }
            });
        };
        request();
    });
}
