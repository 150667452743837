import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: middle;
  }

  .one-ux-element--root {
    --one-ux-switch-element--height: 24px;
    --one-ux-switch-element--color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-switch-element--background-color: var(--one-ux-palette--gray-100);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--brand-neutral-550);
    --one-ux-switch-element--handle-size: 10px;
    --one-ux-switch-element--handle-left: var(--one-ux-spacing--smallest);
    cursor: pointer;
  }

  label {
    display: flex;
    align-items: center;
    gap: var(--one-ux-spacing--300);
    box-sizing: border-box;

    color: var(--one-ux-switch-element--color);
    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    user-select: none;
    height: var(--one-ux-switch-element--height);
    padding: var(--one-ux-spacing--200);
  }

  input {
    appearance: none;
    all: unset;
    display: inline-flex;
    box-sizing: border-box;
    width: 28px;
    height: 16px;
    background-color: var(--one-ux-switch-element--background-color);
    border: var(--one-ux-border-width--500) solid var(--one-ux-switch-element--border-color);
    border-radius: var(--one-ux-radius--700);
    align-items: center;
    justify-content: left;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  input::before {
    content: '';
    background-color: var(--one-ux-switch-element--handle-color);
    width: var(--one-ux-switch-element--handle-size);
    height: var(--one-ux-switch-element--handle-size);
    border-radius: var(--one-ux-radius--max);
    position: relative;
    left: var(--one-ux-switch-element--handle-left);
    transition-property: background, border-color, color, left;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  input:hover {
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-400);
  }

  input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-100);
  }

  input:checked::before {
    --one-ux-switch-element--handle-left: calc(
      100% - var(--one-ux-switch-element--handle-size) - var(--one-ux-spacing--smallest)
    );
  }

  input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-neutral-700);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-neutral-700);
  }

  /* PURPOSE MAIN */
  :host([purpose='main']) input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-core-400);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-core-400);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-100);
  }

  :host([purpose='main']) input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--brand-core-500);
    --one-ux-switch-element--border-color: var(--one-ux-palette--brand-core-500);
  }

  /* PURPOSE CAUTION */
  :host([purpose='caution']) input {
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-300);
  }

  :host([purpose='caution']) input:hover {
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-400);
  }

  :host([purpose='caution']) input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--red-400);
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-400);
  }

  :host([purpose='caution']) input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--red-500);
    --one-ux-switch-element--border-color: var(--one-ux-palette--red-500);
  }

  /* PURPOSE NOTICE */
  :host([purpose='notice']) input {
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-400);
  }

  :host([purpose='notice']) input:hover {
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-500);
  }

  :host([purpose='notice']) input:checked {
    --one-ux-switch-element--background-color: var(--one-ux-palette--azure-200);
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-400);
    --one-ux-switch-element--handle-color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([purpose='notice']) input:checked:hover {
    --one-ux-switch-element--background-color: var(--one-ux-palette--azure-300);
    --one-ux-switch-element--border-color: var(--one-ux-palette--azure-400);
  }

  /* IMPLICIT */
  :host([implicit]) input:not(:checked) {
    --one-ux-switch-element--background-color: transparent;
  }

  /* FOCUS */
  :host([state-keyboard-focus]) input:focus:not(:disabled) {
    --one-ux-switch-element--background-color: var(--one-ux-palette--keyboard-focus-200) !important;
    --one-ux-switch-element--border-color: var(--one-ux-palette--keyboard-focus-400) !important;
    --one-ux-switch-element--handle-color: var(--one-ux-palette--keyboard-focus-700) !important;
  }

  /* DISABLED */
  input:disabled {
    --one-ux-switch-element--background-color: var(--one-ux-palette--gray-150) !important;
    --one-ux-switch-element--border-color: var(--one-ux-palette--gray-300) !important;
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-400) !important;
  }

  input:checked:disabled {
    --one-ux-switch-element--background-color: var(--one-ux-palette--gray-350) !important;
    --one-ux-switch-element--border-color: var(--one-ux-palette--gray-350) !important;
    --one-ux-switch-element--handle-color: var(--one-ux-palette--gray-150) !important;
  }

  :host([disabled]) .one-ux-element--root {
    cursor: default;
  }

  :host([disabled]) label {
    --one-ux-switch-element--color: var(--one-ux-palette--gray-400);
  }
`
