import { KeyScale } from '../axis/key/KeyScale'
import { KeyValueSet } from '../axis/key/KeyValueSet'
import { ValueScale } from '../axis/value/ValueScale'
import { ColumnOverlappedRenderGroup, columnOverlappedRenderGroupData } from './types'

export const getX = (keyScale: KeyScale, dp: columnOverlappedRenderGroupData) => {
  const size = keyScale.size
  return keyScale.center(0) + dp.keyIndex * (size / 2)
}

export const getY = (valueScale: ValueScale, dp: columnOverlappedRenderGroupData) => {
  if (dp.value < 0) {
    return valueScale.clampPosition(dp.value) - getHeight(valueScale, dp) + 1
  }

  return valueScale.clampPosition(dp.value)
}

export const getHeight = (valueScale: ValueScale, dp: columnOverlappedRenderGroupData) =>
  Math.abs(Math.floor(valueScale.clampPosition(0)) - valueScale.clampPosition(dp.value))

export const createInnerKeyScale = (keyScale: KeyScale, group: ColumnOverlappedRenderGroup) => {
  const left = keyScale.start(group.key)
  const right = keyScale.end(group.key)
  const margin = keyScale.size * 0.2
  const bounds = keyScale.bounds
  bounds.left = left + margin
  bounds.right = right - margin
  const indices = [...Array(group.keyCount).keys()]

  return new KeyScale(bounds, new KeyValueSet(indices))
}
