import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: top;
  }

  :host([shape='bar']) {
    min-width: 160px;
  }

  .one-ux-element--root {
    --one-ux-progress-element--bar-plate: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);
    --one-ux-progress-element--bar-value: var(--one-ux-palette--mint-400);
    --one-ux-progress-element--circle-plate: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  svg {
    flex: 1 1 auto;
    cursor: default;
    vertical-align: top;
    aspect-ratio: inherit;
  }

  .bar--plate {
    fill: var(--one-ux-progress-element--bar-plate);
  }

  .circle--plate {
    stroke: var(--one-ux-progress-element--circle-plate);
  }

  .bar--value,
  .circle--value {
    fill: var(--one-ux-progress-element--bar-value);
  }

  ::slotted(:not(one-ux-icon, one-ux-link)) {
    position: fixed !important;
    visibility: hidden !important;
  }

  .barShape {
    display: flex;
    flex-direction: column;
  }

  .barShape .topRow {
    margin: 0;
    color: var(--one-ux-palette--brand-neutral-600);
  }

  .barShape .topRow .valueText {
    margin: 0;
    color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([weight='high']) .barShape .topRow {
    color: var(--one-ux-palette--brand-neutral-500);
  }

  :host([weight='high']) .barShape .middleRow {
    margin-top: var(--one-ux-spacing--400);
  }

  .barShape .middleRow {
    display: flex;
    align-items: center;
    gap: var(--one-ux-spacing--200);
    color: var(--one-ux-palette--brand-neutral-400);
    margin: 0;
  }

  .barShape .bottomRow {
    display: flex;
    align-items: flex-start;
    gap: var(--one-ux-spacing--200);
    color: var(--one-ux-palette--brand-neutral-400);
    margin: 0;
    line-height: 18px;
  }

  .barShape .bottomRow .slotArea {
    flex: 1 1 auto;
    min-width: 0;
  }

  .barShape.completed .middleRow :is(.valueText, one-ux-icon),
  .barShape.completed .bottomRow :is(.valueText, one-ux-icon) {
    color: var(--one-ux-palette--mint-400);
  }

  .circleShape {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  :host([weight='low']) .circleShape {
    flex-direction: row;
    gap: var(--one-ux-spacing--300);
    justify-content: center;
  }

  .circleShape .svgContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 0;
  }

  .circleShape .innerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow-wrap: break-word;
    text-align: center;
  }

  .circleShape .innerContent .valueText {
    margin: 0;
    color: var(--one-ux-palette--brand-neutral-600);
  }

  .circleShape .innerContent .slotArea {
    margin: 0;
    color: var(--one-ux-palette--brand-neutral-500);
  }

  .circleShape .outerContent {
    margin: 0;
    color: var(--one-ux-palette--brand-neutral-500);
  }

  .circleShape .outerContent .valueText {
    margin: 0;
    color: var(--one-ux-palette--brand-neutral-600);
  }

  .circleShape.completed .valueText {
    color: var(--one-ux-palette--mint-400);
  }
`
