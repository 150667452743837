import './pdr/runtime';
import './sem-2.0/sem-2.0';
import './elements/accessibility-debug';
import './elements/accessibility-hidden-focus-group';
import './elements/accessibility-roving-tabs';
import './elements/animation-height';
import './elements/autosize-textarea';
import './elements/input-color';
import './elements/input-date';
import './elements/input-select';
import './elements/layout-modal';
import './elements/layout-overlay';
import './elements/layout-popout/LayoutPopoutElement';
import './elements/layout-scroll';
import './elements/layout-sticky';
import './elements/pdr-chart';
import './elements/pdr-dashboard';
import './elements/pdr-field';
import './elements/pdr-icon/PdrIconElement';
import './elements/pdr-menu-button';
import './idiom/idiom';
import '../static/one-ux/index.css?manual';
import '../static/one-ux/index.js';
ensureManualStyling();
function ensureManualStyling() {
    const styleFile = PDR_ENTRY + '.css';
    const $links = document.head.getElementsByTagName('link');
    const hasRequiredStyling = Array.from($links).some(($link) => $link.href.toLowerCase().endsWith(styleFile));
    if (hasRequiredStyling) {
        return;
    }
    const href = __webpack_public_path__ + styleFile;
    PDR.log.warning(`[PDR] Did you FOUC up? The mandatory PDR styling was not present when the PDR package was loaded. This might cause Flash of Unstyled Content (FOUC) and other graphical issues. Consider linking the styling (${href}) in the head of the document instead.`);
    const $link = document.createElement('link');
    $link.rel = 'preload';
    $link.as = 'style';
    $link.href = href;
    $link.addEventListener('load', function load() {
        $link.rel = 'stylesheet';
        $link.removeEventListener('load', load);
    });
    document.head.appendChild($link);
}
