import { css } from 'lit'

export const style = css`
  .menu {
    --one-ux-menu--menu-item-content-background: none;

    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);
    color: var(--one-ux-palette--brand-neutral-600);
    white-space: nowrap;
  }

  .menu:focus {
    outline: none;
  }

  :host([disabled]) .menu,
  [aria-disabled='true'] {
    color: var(--one-ux-palette--gray-400);
  }

  :host(:not([disabled]):hover) #active-menu-item:not([aria-disabled='true']) > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--brand-neutral-150);
    --one-ux-menu--menu-item-cursor: pointer;
  }

  :host(:not([disabled]):active) #active-menu-item:not([aria-disabled='true']) > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--brand-neutral-300);
  }

  :host(:not([disabled])[state-keyboard-focus]:focus) #active-menu-item > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-200);
  }

  :host(:not([disabled])[state-keyboard-focus]:focus:active) #active-menu-item > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-300);
  }

  :host(:not([disabled])) [aria-expanded='true'] > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--brand-neutral-150);
  }

  :host(:not([disabled])[state-keyboard-focus]:focus) [aria-expanded='true'] > .menu-item-content {
    --one-ux-menu--menu-item-content-background: var(--one-ux-palette--keyboard-focus-200);
  }

  .menu-item-content {
    --one-ux-menu--menu-item-cursor: default;
    background: var(--one-ux-menu--menu-item-content-background);
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: var(--one-ux-menu--menu-item-cursor);
    border-radius: var(--one-ux-radius--300);
    min-width: 0;
    gap: 6px 8px;

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  .group-content {
    display: flex;
    align-items: center;
    gap: 6px 8px;
    flex-grow: 1;
  }

  hr {
    border: none;
    height: var(--one-ux-border-width--500);
    background: var(--one-ux-palette--brand-neutral-200);
    margin: 3.5px 0;
    height: 1px;
  }
`
