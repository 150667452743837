import chartType from '../../../constants/chartType'
import * as getters from '../getters'
import * as helpers from '../helpers'

export default (value) => ({
  transitionDuration: getters.getTransitionDuration(value),
  renderWhenInViewport: getters.getRenderWhenInViewport(value),
  disablePointLabelsOptimization: getters.getDisablePointLabelsOptimization(value),
  y1: getters.getY1(value),
  y2: getters.getY2(value),
  useSISuffix: getters.getUseSISuffix(value),
  locale: getters.getLocale(value),
  showGuidelines: getters.getShowGuidelines(value),
  prune: getters.getPrune(value),
  title: getters.getTitle(value),
  series: helpers.flattenSeries(value, (serie, datapoint) => {
    switch (getters.getChartType(value, serie)) {
      case chartType.area:
      case chartType.line:
        return getLineSeries(value, serie, datapoint)
      default:
        return getDefaultSeries(value, serie, datapoint)
    }
  })
})

const getDefaultSeries = (value, serie, datapoint) => ({
  type: getters.getChartType(value, serie),
  series: getters.getSeriesNumber(value, serie),
  key: getters.getKey(datapoint),
  value: getters.getValue(datapoint),
  name: getters.getName(serie),
  color: getters.getColor(serie, datapoint),
  pointLabels: getters.getPointLabels(serie),
  yTarget: getters.getYTarget(serie)
})

const getLineSeries = (value, serie, datapoint) => ({
  type: getters.getChartType(value, serie),
  series: getters.getSeriesNumber(value, serie),
  key: getters.getKey(datapoint),
  value: getters.getValue(datapoint),
  name: getters.getName(serie),
  color: getters.getColor(serie, datapoint),
  pointLabels: getters.getPointLabels(serie),
  yTarget: getters.getYTarget(serie),
  lineStyle: getters.getLineStyle(value, serie)
})
