import { html, nothing } from 'lit'
import { OneUxTypographyToken } from '@/one-ux/generated/design-tokens'
import { serieType } from '../types'

export const legendIconWidth = 20
export const legendIconHeight = 11
export const legendNameMarginLeft = 4
export const legendValueMarginLeft = 2

export const typographyName: OneUxTypographyToken = 'label-300'
const typographyValue: OneUxTypographyToken = 'mono-300'

export const Legend = (type: serieType, color: string, name: string, value = '') => {
  return html`
    <div style="display: flex; align-items: center; justify-content: space-between;">
      <div style="display: flex; align-items: center;">
        ${LegendIcon(type, color)}
        ${name?.length
          ? html`<span
              class="one-ux-typography--${typographyName}"
              style="margin: 0; margin-left: ${legendNameMarginLeft}px; color: var(--one-ux-palette--brand-neutral-600);"
              >${name}${value.length ? ':' : nothing}</span
            >`
          : nothing}
      </div>
      ${value.length
        ? html`<span
            class="one-ux-typography--${typographyValue}"
            style="margin: 0; margin-left: ${legendValueMarginLeft}px; margin-bottom: 2px; color: var(--one-ux-palette--brand-neutral-600);"
            >${value}</span
          >`
        : nothing}
    </div>
  `
}

const LegendIcon = (type: serieType, color: string) => {
  switch (type) {
    case 'column':
    case 'column-overlapped':
    case 'column-stacked':
      return html`<div
        style="width: ${legendIconWidth}px; height: ${legendIconHeight}px; background: ${color}; display: inline-flex;"
      ></div>`
    case 'line':
      return html`<div
        style="width: ${legendIconWidth}px; height: ${legendIconHeight}px; display: inline-flex; justify-content: center; align-items: center;"
      >
        <div
          style="width: 100%; height: 2px; background: ${color}; display: inline-flex; justify-content: center; align-items: center;"
        >
          <div style="width: 4px; height: 4px; background: ${color}; margin: 0 auto; border-radius: 100%;"></div>
        </div>
      </div>`
    case 'area':
      return html`<div
        style="width: ${legendIconWidth}px; height: ${legendIconHeight}px; position: relative; display: inline-flex;"
      >
        <div
          style="width: 100%; height: 2px; background: ${color};  display: inline-flex; justify-content: center; align-items: center;"
        ></div>
        <div style="width: 100%; height: 100%; background: ${color}; opacity: 0.3; position: absolute;"></div>
      </div>`
    default:
      return nothing
  }
}
