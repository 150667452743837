var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _SessionModule_manager;
import { PDRModule } from '../PDRModule';
import { CustomSettings, SessionSettings } from './SessionSettings';
export class SessionModule extends PDRModule {
    constructor() {
        super(SessionSettings.build);
        _SessionModule_manager.set(this, void 0);
        __classPrivateFieldSet(this, _SessionModule_manager, this._initialized.then(async ([{ utils }, config]) => {
            if (config instanceof CustomSettings) {
                const manager = await import(
                /* webpackChunkName: "custom-session-manager" */
                './custom/CustomManager');
                return new manager.CustomManager(config);
            }
            const manager = await import(
            /* webpackChunkName: "oidc-session-manager" */
            './oidc/OIDCManager');
            return new manager.OIDCManager(utils.lang, config);
        }), "f");
    }
    async getUser(forcedRefresh = false, scopes = []) {
        const manager = await __classPrivateFieldGet(this, _SessionModule_manager, "f");
        return manager.getUser(forcedRefresh, scopes);
    }
    async signOut() {
        const manager = await __classPrivateFieldGet(this, _SessionModule_manager, "f");
        manager.signOut();
    }
    async hasSession(scopes = []) {
        const manager = await __classPrivateFieldGet(this, _SessionModule_manager, "f");
        return manager.hasSession(scopes);
    }
}
_SessionModule_manager = new WeakMap();
