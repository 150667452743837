import { arc } from 'd3-shape'
import { DEG_TO_RAD } from '../common/constants'
import { OneUxGaugeChartElement } from './OneUxGaugeChartElement'
import { InternalData, InternalSegment } from './types'
import { getArcsInnerRadius, getArcsOuterRadius } from './utils'

export const createArcGenerator = (host: OneUxGaugeChartElement, data: InternalData) => {
  const outerRadius = getArcsOuterRadius(host, data)

  const { segmentPadAngleDegrees, segmentCornerRadius } = data.snapSizeConfig

  return arc<any, InternalSegment>()
    .innerRadius((d) => getArcsInnerRadius(host, data, d))
    .outerRadius(outerRadius)
    .startAngle((d) => d.startAngle)
    .endAngle((d) => d.endAngle)
    .padAngle(DEG_TO_RAD * segmentPadAngleDegrees)
    .cornerRadius(segmentCornerRadius)
}
