export const groupBy = <T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K,
  filter: (item: T) => boolean = () => true
) =>
  list.reduce((previous, currentItem) => {
    const isOK = filter(currentItem)
    if (!isOK) {
      return previous
    }

    const group = getKey(currentItem)
    if (!previous[group]) {
      previous[group] = []
    }
    previous[group].push(currentItem)

    return previous
  }, {} as Record<K, T[]>)
