import { html } from 'lit'
import { distributionMetadata, distributionValues } from '../types'
import { styleMap } from 'lit/directives/style-map.js'

export function Legend({
  metadata,
  values,
  direction
}: {
  metadata: distributionMetadata[]
  values?: distributionValues
  direction?: 'horizontal' | 'vertical'
}) {
  return html`
    <div
      class="legend"
      aria-hidden="true"
      style=${styleMap({
        display: 'flex',
        gap: direction == 'vertical' ? 'var(--one-ux-spacing--300) 0' : '0 var(--one-ux-spacing--200)',
        'flex-direction': direction == 'vertical' ? 'column' : null,
        'flex-wrap': 'wrap',
        'overflow-wrap': 'anywhere',
        color: 'var(--one-ux-palette--brand-neutral-600)'
      })}
    >
      ${metadata.map(
        (x) =>
          html`<div
            class="legend--item"
            style="
              display: flex;
              align-items: flex-start;
              white-space: nowrap;
              gap: var(--one-ux-spacing--100);
            "
          >
            <one-ux-icon
              size="100"
              icon=${x.icon.name}
              set=${x.icon.set}
              style=${styleMap({ color: x.color })}
            ></one-ux-icon>
            <div
              class="legend one-ux-typography--label-400"
              style="line-height: var(--one-ux-size--icon-100); margin: 0;"
            >
              ${values ? values[x.key] || 0 : x.name}
            </div>
          </div>`
      )}
    </div>
  `
}
