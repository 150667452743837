import { html, nothing } from 'lit'
import { styleMap } from 'lit/directives/style-map.js'
import { classMap } from 'lit/directives/class-map.js'
import { weight } from '@/one-ux/common/mixins/Weight'
import { FULL_CIRCLE_IN_RADIANS } from '../../common/constants'
import { ArcSegments } from './ArcSegments'
import { Legend } from '../../common/components/Legend'
import { distributionMetadata, distributionValues } from '../../common/types'

const weightStyles: Record<
  weight,
  {
    svgWidth: number
    innerSlotOffset: number
  }
> = {
  high: { svgWidth: 128, innerSlotOffset: 14 },
  normal: { svgWidth: 64, innerSlotOffset: 8 },
  low: { svgWidth: 32, innerSlotOffset: 4 }
}

export function ShapeArc({
  metadata,
  distribution,
  weight,
  compact,
  implicit,
  slots,
  shadowRoot
}: {
  metadata: distributionMetadata[]
  distribution: distributionValues
  weight: weight
  compact: boolean
  implicit: boolean
  slots: {
    hasSummary: boolean
    hasDescription: boolean
    summaryChanged: (event: Event) => void
    descriptionChanged: (event: Event) => void
  }
  shadowRoot: ShadowRoot
}) {
  const availableRadiansForSegments = 0.75 * FULL_CIRCLE_IN_RADIANS
  const startAngleForSegments = 0.25 * FULL_CIRCLE_IN_RADIANS + availableRadiansForSegments / 2
  const aspectRatio = 1 / (0.5 * (1 - Math.sin(startAngleForSegments)))
  const { innerSlotOffset } = weightStyles[weight]

  const minSvgWidth = weightStyles[weight].svgWidth
  const minSvgHeight = Math.ceil(minSvgWidth * (1 / aspectRatio))

  let svgWidth = minSvgWidth
  let svgHeight = minSvgHeight

  if (weight === 'high') {
    const $svg = shadowRoot.querySelector('svg')
    if ($svg) {
      svgWidth = $svg.clientWidth
      svgHeight = $svg.clientHeight
    }
  }

  const showLegend = weight === 'high' && !compact && !implicit

  return html`
    <div class="arcShape">
      <div
        class="svgAndSlots"
        style=${styleMap({
          'aspect-ratio': weight === 'high' ? aspectRatio : 'auto',
          width: weight === 'high' ? '100%' : 'auto'
        })}
      >
        <svg
          style=${styleMap({
            'min-width': minSvgWidth + 'px',
            'min-height': minSvgHeight + 'px',
            width: weight === 'high' ? 'auto' : svgWidth + 'px',
            height: weight === 'high' ? 'auto' : svgHeight + 'px'
          })}
          role="presentation"
        >
          ${ArcSegments({
            distribution,
            metadata,
            size: svgWidth,
            x: 0,
            y: 0,
            startAngle: startAngleForSegments,
            availableRadians: availableRadiansForSegments,
            weight
          })}
          <foreignObject
            x="${innerSlotOffset}"
            y="${innerSlotOffset}"
            width="${svgWidth - 2 * innerSlotOffset}"
            height=${svgWidth - 2 * innerSlotOffset}
          >
            <div class="innerSlots">
              ${weight === 'high' || weight === 'normal'
                ? html`
                    <slot
                      name="summary"
                      class=${classMap({
                        summary: true,
                        'one-ux-typography--heading-500': weight === 'high',
                        'one-ux-typography--heading-100': weight === 'normal'
                      })}
                      @slotchange=${slots.summaryChanged}
                    ></slot>
                  `
                : nothing}
              ${weight === 'high'
                ? html`
                    <slot
                      name="description"
                      class="description one-ux-typography--label-400"
                      @slotchange=${slots.descriptionChanged}
                    ></slot>
                  `
                : nothing}
            </div>
          </foreignObject>
        </svg>

        ${weight === 'normal' || weight == 'low'
          ? html`
              <div
                class="outerSlots"
                style=${styleMap({ display: !slots.hasSummary && !slots.hasDescription ? 'none' : null })}
              >
                ${weight === 'low'
                  ? html`
                      <slot
                        name="summary"
                        class="summary one-ux-typography--heading-100"
                        @slotchange=${slots.summaryChanged}
                      ></slot>
                    `
                  : nothing}

                <slot
                  name="description"
                  class=${classMap({
                    description: true,
                    'one-ux-typography--label-300': weight === 'normal',
                    'one-ux-typography--label-100': weight === 'low'
                  })}
                  @slotchange=${slots.descriptionChanged}
                ></slot>
              </div>
            `
          : nothing}
      </div>

      ${showLegend ? Legend({ metadata, values: distribution }) : nothing}
    </div>
  `
}
