import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: top;
  }

  :host([shape='bar']) {
    min-width: 160px;
  }

  .one-ux-element--root {
    display: flex;
    flex-direction: column;
    outline: none;
    justify-content: center;
    padding: var(--one-ux-spacing--200) var(--one-ux-spacing--300);
    border-radius: var(--one-ux-radius--300);
  }

  :host([compact]) .one-ux-element--root {
    padding: 0;
  }

  :host(:not([implicit]):hover) .one-ux-element--root {
    background: rgba(var(--one-ux-rgb--brand-neutral-500), 0.09);
  }

  :host([state-keyboard-focus]:focus) .one-ux-element--root {
    box-shadow: inset 0 0 0 2px var(--one-ux-palette--keyboard-focus-400);
    background: var(--one-ux-palette--keyboard-focus-100);
  }

  :is(.summary, .description)::slotted(:not(one-ux-text, one-ux-icon, one-ux-link)) {
    position: fixed !important;
    visibility: hidden !important;
  }

  .summary {
    color: var(--one-ux-palette--brand-neutral-600);
  }

  .description {
    color: var(--one-ux-palette--brand-neutral-500);
  }

  svg {
    flex: 1 1 auto;
    cursor: default;
    vertical-align: top;
    aspect-ratio: inherit;
  }

  .arcShape,
  .barShape {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  .arcShape {
    gap: var(--one-ux-spacing--100);
  }

  :host([weight='high']) .barShape {
    gap: var(--one-ux-spacing--400);
  }

  .arcShape .svgAndSlots {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 0;
  }

  :host([weight='low']) .arcShape .svgAndSlots {
    flex-direction: row;
    gap: var(--one-ux-spacing--300);
  }

  .arcShape .innerSlots {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
    overflow-wrap: break-word;
  }

  .arcShape .outerSlots {
    display: flex;
    flex-direction: column;
  }

  :host([weight='normal']) .arcShape .description {
    line-height: 1;
  }

  .barShape .slotsAndLegend {
    display: flex;
    align-items: center;
    gap: var(--one-ux-spacing--300);
    width: 100%;
    justify-content: space-between;
  }

  :host([weight='high']) .barShape .slotsAndLegend {
    align-items: baseline;
  }

  :host([weight='high']) .barShape .slots {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  :host(:is([weight='normal'], [weight='low'])) .barShape .description {
    color: var(--one-ux-palette--brand-neutral-600);
  }
`
