import { css } from 'lit'

export const style = css`
  :host {
    cursor: text;
  }

  .one-ux-element--root {
    --one-ux-textarea-element--line-height: var(--one-ux-line-height--body-300);

    font: var(--one-ux-font--body-300);
    letter-spacing: var(--one-ux-letter-spacing--body-300);

    box-sizing: border-box;

    min-width: 140px;
    max-width: 700px;
  }

  :host([weight='low']) .one-ux-element--root {
    --one-ux-textarea-element--line-height: var(--one-ux-line-height--body-200);

    font: var(--one-ux-font--body-200);
    letter-spacing: var(--one-ux-letter-spacing--body-200);
  }

  one-ux-scroll {
    margin: var(--one-ux-spacing--small) var(--one-ux-spacing--none) var(--one-ux-spacing--small)
      var(--one-ux-spacing--normal);
  }

  one-ux-editable-text {
    width: 100%;
    --one-ux-textarea-element--spacing-right: 0;
    padding-right: var(--one-ux-textarea-element--spacing-right);
  }

  one-ux-editable-text:not(.auto-resize) {
    --one-ux-textarea-element--spacing-right: var(--one-ux-spacing--normal);
  }

  one-ux-editable-text.auto-resize {
    margin: var(--one-ux-spacing--small) var(--one-ux-spacing--normal) var(--one-ux-spacing--small)
      var(--one-ux-spacing--normal);
  }

  one-ux-editable-text[disabled] {
    color: var(--one-ux-rgb--gray-400);
  }
`
