import { AnimatedCustomElement } from '../AnimatedCustomElement'
export class LayoutStickyElement extends AnimatedCustomElement {
  #placeholderElement = document.createElement('dynamic-layout-sticky-placeholder')
  #screenOffsetsElement = document.createElement('dynamic-layout-sticky-screen-offsets')

  onInitialized() {
    this.append(this.#placeholderElement)
    this.append(this.#screenOffsetsElement)
  }

  animate() {
    const elementRect = this.getBoundingClientRect()
    let occupiedTopOffset = 0

    Array.from(document.querySelectorAll('layout-sticky')).some(($sticky) => {
      if ($sticky === this) {
        return true
      }
      if ($sticky.hasAttribute('state-sticky')) {
        const occupyRect = $sticky.content.getBoundingClientRect()
        if (elementRect.right >= occupyRect.left && elementRect.left <= occupyRect.right) {
          occupiedTopOffset += occupyRect.bottom
        }
      }
    })

    const $content = this.content // TODO: Check if not null
    const parentRect = this.parentElement.getBoundingClientRect()
    const screenOffsetsRect = this.#screenOffsetsElement.getBoundingClientRect()

    const topScreenOffset = elementRect.top - screenOffsetsRect.top
    const bottomScreenOffset = screenOffsetsRect.bottom - elementRect.bottom
    const topLimit = Math.max(occupiedTopOffset, topScreenOffset)
    const maxScreenHeight =
      Math.min(parentRect.height + parentRect.top, window.innerHeight - bottomScreenOffset) -
      Math.max(parentRect.top, topLimit)

    if (elementRect.top <= topLimit) {
      const contentHeight = $content.clientHeight

      if (this.type === 'fill') {
        $content.style.height = maxScreenHeight + 'px'
      } else {
        this.#placeholderElement.style.height = contentHeight + 'px'
      }

      $content.style.top = Math.min(topLimit, parentRect.bottom - contentHeight) + 'px'
      $content.style.left = elementRect.left + 'px'
      $content.style.width = this.clientWidth + 'px'

      this.setAttribute('state-sticky', '')
    } else {
      if (this.type === 'fill') {
        $content.style.height = maxScreenHeight + 'px'
      }

      $content.style.top = ''
      $content.style.left = ''
      $content.style.width = ''

      this.removeAttribute('state-sticky')
    }
  }

  get content() {
    for (let i = 0; i < this.children.length; ++i) {
      const $child = this.children[i]
      if ($child.tagName === 'LAYOUT-STICKY-CONTENT') {
        return $child
      }
    }
    return null
  }

  get type() {
    const type = (this.getAttribute('type') || '').toLowerCase()
    switch (type) {
      case 'fill':
      case 'sticky':
        return type
      default:
        return 'sticky'
    }
  }

  set type(type) {
    if (typeof type !== 'undefined') {
      this.setAttribute('type', type)
    }
  }
}
