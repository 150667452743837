export class VerticalScrollingHandler {
  #gadgetElement
  #verticalScrollRequest

  constructor($gadget) {
    this.#gadgetElement = $gadget
  }

  startIfNeeded = (mouseY) => {
    const edgeScrollThreshold = 50
    const scrollSpeed = 10
    const $scrollbar = this.#gadgetElement.closest('[pdr-dashboard-scrollbar]')

    if (!$scrollbar) {
      return
    }

    cancelAnimationFrame(this.#verticalScrollRequest)
    const scrollbarRect = $scrollbar.getBoundingClientRect()
    if (mouseY < edgeScrollThreshold + scrollbarRect.top) {
      this.#scrollVertical($scrollbar, -scrollSpeed)
    } else if (scrollbarRect.bottom - mouseY < edgeScrollThreshold) {
      this.#scrollVertical($scrollbar, scrollSpeed)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.#verticalScrollRequest)
  }

  #scrollVertical = ($scrollbar, amount) => {
    this.#verticalScrollRequest = requestAnimationFrame(() => {
      $scrollbar.scrollTop += amount
      this.#scrollVertical($scrollbar, amount)
    })
  }
}
