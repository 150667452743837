import { html, nothing, svg } from 'lit'
import { styleMap } from 'lit/directives/style-map.js'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { getLineSegments } from '../../common/getLineSegments'
import { legendItem, barItem } from '../types'
import { transitionTiming } from '../animation'
import { repeat } from 'lit/directives/repeat.js'

export function Distribution({
  legend,
  bar,
  width,
  implicit
}: {
  legend: legendItem[]
  bar: barItem
  width: number
  implicit: boolean
}) {
  const segments = getLineSegments({
    segments: legend.map((x) => ({
      item: x,
      value: bar.data[x.key] || 0
    })),
    width,
    minSegmentWidth: 4,
    gap: 4
  })

  const barHeight = 12
  const labelHeight = 14
  const labelWidth = 50
  const totalHeight = implicit ? barHeight : barHeight + labelHeight

  return html`
    <svg
      style=${styleMap({
        height: `${totalHeight}px`,
        width: '100%',
        display: 'block',
        overflow: 'hidden'
      })}
    >
      ${repeat(
        segments,
        (segment) => segment.item.key,
        (segment) => {
          let labelX = segment.x + Math.round(segment.width / 2) - Math.round(labelWidth / 2)
          let textAlign = 'center'

          if (labelX < 0) {
            labelX = segment.x
            textAlign = 'left'
          }
          if (labelX + labelWidth > width) {
            labelX = segment.x + segment.width - labelWidth
            textAlign = 'right'
          }

          return svg`
          <g
            ${svgTransition({
              timing: transitionTiming,
              attributes: {
                opacity: interpolate.standard(0, 1, 0)
              }
            })}
          >
            <rect
              height=${barHeight}
              fill=${segment.item.color}
              rx="2"
              ${svgTransition({
                timing: transitionTiming,
                attributes: {
                  x: interpolate.standard(segment.x, segment.x, segment.x + segment.width / 2),
                  width: interpolate.standard(0, segment.width, 0)
                }
              })}
            ></rect>

            ${
              implicit
                ? nothing
                : svg`
                  <foreignObject
                    y=${barHeight}
                    width=${labelWidth}
                    height=${labelHeight}
                    class="one-ux-typography--mono-variant-400"
                    style=${styleMap({
                      color: 'var(--one-ux-palette--brand-neutral-600)',
                      'text-align': textAlign,
                      'word-spacing': '-1em'
                    })}
                    ${svgTransition({
                      timing: transitionTiming,
                      attributes: {
                        x: interpolate.constant(labelX)
                      }
                    })}
                  >
                    ${html`
                      <one-ux-icon
                        icon=${segment.item.icon.name}
                        set=${segment.item.icon.set}
                        style=${styleMap({
                          color: segment.item.color,
                          'font-size': '12px'
                        })}
                      ></one-ux-icon>
                      ${segment.value}
                    `}
                  </foreignObject>
                `
            }
          </g>
        `
        }
      )}
    </svg>
  `
}
