import { OneUxElement } from '../common/OneUxElement'
import { PropertyValueMap, html, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { live } from 'lit/directives/live.js'
import { ifDefined } from 'lit/directives/if-defined.js'

import { style } from './style'
import { StyledFactory } from '../common/mixins/Styled'
import { Purpose } from '../common/mixins/Purpose'
import { Implicit } from '../common/mixins/Implicit'
import { Focusable } from '../common/mixins/Focusable'
import { Disabled } from '../common/mixins/Disabled'
import { Compact } from '../common/mixins/Compact'
import { DelegateAria } from '../common/mixins/DelegateAria'
import { log } from '../common/utils/log'
import { FormAssociatedFactory } from '../common/mixins/FormAssociated'
import { InternalValueChangedEvent } from '../events/internal/InternalValueChangedEvent'
import { IValue, ValueFactory } from '../common/mixins/Value'
import { Checked, IChecked } from '../common/mixins/Checked'
import { HidableTooltip } from '../common/mixins/HidableTooltip'

const Styled = StyledFactory(style)

type valueType = unknown

const Value = ValueFactory<valueType, IChecked & IValue<unknown> & { indeterminate: boolean }>({
  getter: function () {
    if (this.checked && !this.indeterminate) {
      return this.internalValue
    }
    return null
  }
})

const FormAssociated = FormAssociatedFactory<
  valueType,
  IChecked & {
    _oldIndeterminate: boolean
    indeterminate: boolean
  }
>({
  reset: function () {
    this.indeterminate = this._oldIndeterminate
    this.checked = this.initialChecked
  }
})

const BaseClass = HidableTooltip(
  FormAssociated(Value(Checked(DelegateAria(Compact(Disabled(Focusable(Implicit(Purpose(Styled(OneUxElement))))))))))
)

/**
 * A checkbox component to be used for user input
 */
@customElement('one-ux-checkbox')
export class OneUxCheckboxElement extends BaseClass {
  /*
   * Visually displays an indeterminate state.
   * When user toggles a checkbox with indeterminate active,
   * the checked state will always be set to true and indeterminate to false.
   */
  @property({ type: Boolean })
  public indeterminate = false

  /*
   * The text associated with the checkbox.
   * This is a required property and the checkbox will not render if it is omitted.
   */
  @property({ type: String })
  public text!: string

  /*
   * Placement of the checkbox against the text
   */
  @property({ attribute: 'checkbox-position', type: String })
  public checkboxPosition = 'before-text' as 'before-text' | 'after-text'

  protected willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    const hasIndeterminate = _changedProperties.has('indeterminate')
    const hasChecked = _changedProperties.has('checked')
    const hasValue = _changedProperties.has('value')

    if (typeof this._oldIndeterminate === 'undefined' && hasIndeterminate) {
      this._oldIndeterminate = this.indeterminate
    }
    if (hasIndeterminate || hasChecked || hasValue) {
      this.dispatchEvent(new InternalValueChangedEvent())
    }
  }

  private _oldIndeterminate?: boolean

  protected render() {
    if (!this.text) {
      log.error('Missing text, not rendering.')
      return
    }

    const tooltip = this.compact && !this.hideTooltip ? this.text : undefined

    const checkbox = html`
      <input
        ${this._ariaTarget()}
        type="checkbox"
        .checked=${live(this.checked)}
        .indeterminate=${live(this.indeterminate)}
        .disabled=${this.disabled}
        one-ux-tooltip=${ifDefined(tooltip)}
        ?one-ux-tooltip-custom-aria=${!!tooltip}
        aria-label=${ifDefined(this.compact ? this.text : undefined)}
        @input=${this.#handleInput}
      />
    `
    const text = html`${!this.compact ? html`<span>${this.text}</span>` : nothing}`

    return html`<label class="one-ux-element--root">
      ${this.checkboxPosition == 'after-text' ? [text, checkbox] : [checkbox, text]}
    </label> `
  }

  #handleInput = (event: Event) => {
    event.stopPropagation()

    if (this.indeterminate) {
      this.checked = true
      this.indeterminate = false
    } else {
      this.checked = !this.checked
    }

    this.dispatchEvent(new Event('input'))
    this.dispatchEvent(new Event('change'))

    // Do not remove, ensure we always re-render after user input regardless if we change properties or not
    this.requestUpdate()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-checkbox': OneUxCheckboxElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-checkbox': OneUxCheckboxElement
    }
  }
}
