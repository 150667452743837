import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { Implicit } from '../common/mixins/Implicit'
import { Focusable } from '../common/mixins/Focusable'
import { StyledFactory } from '../common/mixins/Styled'
import { OneUxElement } from '../common/OneUxElement'
import { style } from './style'
import { log } from '../common/utils/log'

const Styled = StyledFactory(style)

const BaseClass = Focusable(Implicit(Styled(OneUxElement)))

/**
 * A styled alternative to native anchor tags.
 */
@customElement('one-ux-link')
export class OneUxLinkElement extends BaseClass {
  /**
   * The resource you want to link to. Required, no link will be rendered otherwise.
   * Possible to supply all values that a normal `href` of a `<a>`.
   * Note that navigation by ID only works in the light DOM and you can't link to things defined in `shadowDom`.
   * If the ID is defined on an element in the light dom and passed to a slot inside shadow DOM navigation will still work.
   */
  @property({ type: String })
  public url!: string

  /**
   * Optional text to display if you don't want to display the URL.
   */
  @property({ type: String })
  public text!: string

  /**
   * Allows you to control where the url opens.
   * Note that if 'current' is used in an `<iframe>` the frame will be navigated and not the current tab.
   */
  @property({ type: String })
  public open = 'current' as 'new' | 'current'

  /**
   * Allows you to download a resource. The value provided will be used as the filename.
   * Observe that request that are made cross origin will not be downloaded.
   */
  @property({ type: String })
  public download!: string

  render() {
    if (!this.url) {
      log.error({ title: 'No url provided, not rendering.', details: this })
      return
    }
    return html`<a
      class="one-ux-element--root link"
      download=${ifDefined(this.download)}
      target=${this.open === 'new' ? '_blank' : '_self'}
      href=${this.url}
      >${this.text || this.url}</a
    >`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-link': OneUxLinkElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-link': OneUxLinkElement
    }
  }
}
