import { path } from '../../common/path'
import { vector } from '../../common/types'
import { distance } from '../../common/vector'
import { bounds } from '../types'
import { calculateCatmullBezierCurves, fitBezierCurveToBoundary } from './catmull'
import { boundaryInterpolation, clampedYInterpolation } from './interpolation'

export const getStraightAreaPathData = (line: vector[], boundary: bounds, domainPosition: number) => {
  const currentPath = getStraightLinePathData(line, boundary, false)
  const firstPoint = line[0]
  const lastPoint = line[line.length - 1]
  currentPath.lineTo(
    lastPoint[0],
    Math.abs(lastPoint[1] - boundary.top) < Math.abs(lastPoint[1] - boundary.bottom) ? boundary.top : boundary.bottom
  )
  currentPath.lineTo(lastPoint[0], domainPosition)
  currentPath.lineTo(firstPoint[0], domainPosition)
  currentPath.lineTo(
    firstPoint[0],
    Math.abs(firstPoint[1] - boundary.top) < Math.abs(firstPoint[1] - boundary.bottom) ? boundary.top : boundary.bottom
  )

  return currentPath
}

export const getStraightLinePathData = (line: vector[], boundary: bounds, jump = true) => {
  const currentPath = path()

  if (line.length < 2) {
    return currentPath
  }

  for (let i = 0; i < line.length - 1; i++) {
    const [cx, cy] = line[i]
    const [nx, ny] = line[i + 1]
    const [x0, y0] = clampedYInterpolation(cx, cy, nx, ny, boundary)
    const [x1, y1] = clampedYInterpolation(nx, ny, cx, cy, boundary)

    const epsilon = 0.001
    if (distance(x0, y0, x1, y1) < epsilon) {
      continue
    }

    if (i === 0 || currentPath.isEmpty()) {
      currentPath.moveTo(x0, y0)
    } else if (cx !== x0) {
      if (jump) {
        currentPath.moveTo(x0, y0)
      } else {
        currentPath.lineTo(x0, y0)
      }
    }

    currentPath.lineTo(x1, y1)
  }

  return currentPath
}

export const getCurvedAreaPathData = (
  line: vector[],
  previousPoint: vector | null,
  nextPoint: vector | null,
  boundary: bounds,
  domainPosition: number
) => {
  const currentPath = getCurvedLinePathData(line, previousPoint, nextPoint, boundary, false)
  const firstPoint = line[0]
  const lastPoint = line[line.length - 1]
  currentPath.lineTo(
    lastPoint[0],
    Math.abs(lastPoint[1] - boundary.top) < Math.abs(lastPoint[1] - boundary.bottom) ? boundary.top : boundary.bottom
  )
  currentPath.lineTo(lastPoint[0], domainPosition)
  currentPath.lineTo(firstPoint[0], domainPosition)
  currentPath.lineTo(
    firstPoint[0],
    Math.abs(firstPoint[1] - boundary.top) < Math.abs(firstPoint[1] - boundary.bottom) ? boundary.top : boundary.bottom
  )

  return currentPath
}

export const getCurvedLinePathData = (
  line: vector[],
  previousPoint: vector | null,
  nextPoint: vector | null,
  boundary: bounds,
  jump = true
) => {
  const currentPath = path()

  if (line.length < 2) {
    return currentPath
  }

  const firstProjection =
    previousPoint ?? boundaryInterpolation(line[0][0], line[0][1], line[1][0], line[1][1], boundary)

  const lastProjection =
    nextPoint ??
    boundaryInterpolation(
      line[line.length - 2][0],
      line[line.length - 2][1],
      line[line.length - 1][0],
      line[line.length - 1][1],
      boundary
    )

  const vertices = [firstProjection, ...line, lastProjection]

  const curves = calculateCatmullBezierCurves(vertices, 0.5, 0)
  for (let i = 0; i < curves.length; i++) {
    const curve = fitBezierCurveToBoundary(curves[i], boundary)
    if (curve) {
      if (i === 0 || currentPath.isEmpty()) {
        currentPath.moveTo(curve[0][0], curve[0][1])
      } else if (curves[i - 1][3][0] !== curve[0][0]) {
        if (jump) {
          currentPath.moveTo(curve[0][0], curve[0][1])
        } else {
          currentPath.lineTo(curve[0][0], curve[0][1])
        }
      }

      currentPath.cubicBezierTo(curve[1][0], curve[1][1], curve[2][0], curve[2][1], curve[3][0], curve[3][1])
    }
  }

  return currentPath
}
