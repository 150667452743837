import { nothing, svg } from 'lit'
import { repeat } from 'lit/directives/repeat.js'
import { bounds } from '../../types'
import { path } from '../../../common/path'
import { ValueScale } from './ValueScale'
import { interpolate, svgTransition } from '@/one-ux/charts/common/directives/svgTransition'
import { timing } from '../../animation'

export function Supportlines(bounds: bounds, valueScale: ValueScale, animate: boolean) {
  const ticks = valueScale.ticks
  return svg`
    <g transform="translate(0, 0)" fill="none" class="axis-supportlines">
      ${repeat(
        ticks,
        (tick) => `supportline:${tick}`,
        (tick) => {
          const position = valueScale.position(tick)
          if (position === valueScale.domainPosition) {
            return nothing
          }
          const inset = 4
          const x = bounds.left + inset
          const y = Math.floor(valueScale.position(tick)) + 0.5
          const width = bounds.right - x - inset

          return svg`<path
           stroke="var(--one-ux-palette--brand-neutral-300)"
           stroke-linecap="square"
           stroke-width="0.5"
           stroke-dasharray="2"
           ${svgTransition({
             timing: timing(animate),
             attributes: {
               d: interpolate.constant([y, width], ([y, width]) => path().move(x, y).horizontalLine(width).toString()),
               opacity: interpolate.standard(0, 1, 0)
             }
           })}
         />`
        }
      )}
    </g>
  `
}
