import { NumberedVersion } from './numbered/NumberedVersion';
import { LatestVersion } from './special/LatestVersion';
import { StableVersion } from './special/StableVersion';
import { PrereleaseVersion } from './numbered/PrereleaseVersion';
import { PatchVersion } from './numbered/wildcard/PatchVersion';
import { MinorVersion } from './numbered/wildcard/MinorVersion';
import { MajorVersion } from './numbered/wildcard/MajorVersion';
import { Version } from './Version';
export class Factory {
    create(version) {
        if (version instanceof Version) {
            return version;
        }
        if (LatestVersion.matches(version)) {
            return new LatestVersion();
        }
        if (StableVersion.matches(version)) {
            return new StableVersion();
        }
        if (PrereleaseVersion.matches(version)) {
            return new PrereleaseVersion(version);
        }
        if (MajorVersion.matches(version)) {
            return new MajorVersion(version);
        }
        if (MinorVersion.matches(version)) {
            return new MinorVersion(version);
        }
        if (PatchVersion.matches(version)) {
            return new PatchVersion(version);
        }
        return new NumberedVersion(version);
    }
}
export default new Factory();
