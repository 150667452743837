var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _LogModule_log;
import { PDRModule } from '../PDRModule';
import { LogSettings } from './LogSettings';
import { Log, updateSettings, enableStackTraces, disableStackTraces } from './Log';
export class LogModule extends PDRModule {
    constructor() {
        super(LogSettings.build);
        _LogModule_log.set(this, void 0);
        __classPrivateFieldSet(this, _LogModule_log, this._configured.then((config) => {
            updateSettings({
                message: config.message,
                info: config.info,
                warning: config.warning,
                error: config.error,
                debug: config.debug,
                timer: config.timer,
                tracker: config.tracker
            });
            return new Log();
        }), "f");
    }
    async create(options) {
        await this._configured;
        return new Log(options.prefix);
    }
    async message(message) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        log.message(message);
    }
    async info(message) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        log.info(message);
    }
    async warning(message) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        log.warning(message);
    }
    async deprecation(message) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        log.deprecation(message);
    }
    async error(message) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        log.error(message);
    }
    async debug(message) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        log.debug(message);
    }
    async timer(message) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        return log.timer(message);
    }
    async tracker(trackName) {
        const log = await __classPrivateFieldGet(this, _LogModule_log, "f");
        return log.tracker(trackName);
    }
    enableStackTraces() {
        enableStackTraces();
    }
    async disableStackTraces() {
        disableStackTraces();
    }
}
_LogModule_log = new WeakMap();
