var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _PDRModule_instances, _PDRModule_settingsFactory, _PDRModule_resolveStarted, _PDRModule_resolveConfiguration, _PDRModule_onStarted, _PDRModule_onConfigured;
const events = {
    started: [],
    configured: []
};
export class PDRModule {
    constructor(settingsFactory = () => { }) {
        _PDRModule_instances.add(this);
        _PDRModule_settingsFactory.set(this, void 0);
        _PDRModule_resolveStarted.set(this, void 0);
        this._started = new Promise((resolve) => {
            __classPrivateFieldSet(this, _PDRModule_resolveStarted, resolve, "f");
        });
        _PDRModule_resolveConfiguration.set(this, void 0);
        this._configured = new Promise((resolve) => {
            __classPrivateFieldSet(this, _PDRModule_resolveConfiguration, resolve, "f");
        });
        this._initialized = Promise.all([this._started, this._configured]);
        events['started'].push(__classPrivateFieldGet(this, _PDRModule_instances, "m", _PDRModule_onStarted).bind(this));
        events['configured'].push(__classPrivateFieldGet(this, _PDRModule_instances, "m", _PDRModule_onConfigured).bind(this));
        __classPrivateFieldSet(this, _PDRModule_settingsFactory, settingsFactory, "f");
    }
    static notify(event, data) {
        if (!Object.prototype.hasOwnProperty.call(events, event)) {
            throw new Error(`Unknown PDR-event "${event}"`);
        }
        const callbacks = [...events[event]];
        for (let i = 0; i < callbacks.length; i++) {
            callbacks[i](data);
        }
    }
}
_PDRModule_settingsFactory = new WeakMap(), _PDRModule_resolveStarted = new WeakMap(), _PDRModule_resolveConfiguration = new WeakMap(), _PDRModule_instances = new WeakSet(), _PDRModule_onStarted = function _PDRModule_onStarted(library) {
    __classPrivateFieldGet(this, _PDRModule_resolveStarted, "f").call(this, library);
}, _PDRModule_onConfigured = function _PDRModule_onConfigured(config) {
    const settings = __classPrivateFieldGet(this, _PDRModule_settingsFactory, "f").call(this, config);
    if (settings) {
        __classPrivateFieldGet(this, _PDRModule_resolveConfiguration, "f").call(this, settings);
    }
};
