import { getLangCode } from '@/one-ux/common/utils/getLangCode';
const languages = {
    en: {
        ok: 'OK',
        copy: 'COPY',
        copied: 'COPIED'
    },
    sv: {
        ok: 'OK',
        copy: 'KOPIERA',
        copied: 'KOPIERAD'
    },
    nb: {
        ok: 'OK',
        copy: 'KOPIERE',
        copied: 'KOPIERT'
    },
    fi: {
        ok: 'OK',
        copy: 'KOPIO',
        copied: 'KOPIOITU'
    },
    da: {
        ok: 'OK',
        copy: 'KOPI',
        copied: 'KOPIERET'
    }
};
export const getLanguage = (reference) => {
    const element = reference || document.documentElement;
    const closestLang = getLangCode(element);
    return { translations: languages[closestLang], lang: closestLang };
};
