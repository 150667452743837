import type { OneUxPopoutElement } from '../../one-ux-popout/OneUxPopoutElement'
import { POPOUT_Z_INDEX } from '@/one-ux/one-ux-popout/style'
import { html, nothing } from 'lit'
import { styleMap } from 'lit/directives/style-map.js'

type errorPopoutOptions = {
  errors: string[]
  reference: OneUxPopoutElement['reference']
  hidden: boolean
}

export function ErrorsPopout({ errors, reference, hidden }: errorPopoutOptions) {
  if (errors.length === 0 || hidden) {
    return nothing
  }
  return html`<one-ux-popout
    class="errors-popout"
    indent-top="small"
    indent-right="large"
    indent-bottom="small"
    indent-left="normal"
    .reference=${reference}
    style=${styleMap({
      'z-index': (POPOUT_Z_INDEX - 1).toString()
    })}
  >
    <div
      style=${styleMap({
        display: 'flex',
        gap: 'var(--one-ux-spacing--small)',
        'align-items': 'flex-start'
      })}
    >
      <one-ux-icon style="color: var(--one-ux-palette--red-300)" size="300" icon="warning"></one-ux-icon>
      <ul
        style=${styleMap({
          margin: '0',
          padding: '0',
          'padding-top': '2px'
        })}
      >
        ${errors.map(
          (error) =>
            html` <li
              style=${styleMap({
                display: 'flex',
                font: 'var(--one-ux-font--body-300)',
                'letter-spacing': 'var(--one-ux-letter-spacing--body-300)',
                color: 'var(--one-ux-palette--brand-neutral-600)'
              })}
            >
              ${errors.length > 1 ? `• ${error}` : error}
            </li>`
        )}
      </ul>
    </div>
  </one-ux-popout>`
}
