import { html } from 'lit'
import { ifDefined } from 'lit/directives/if-defined.js'
import { ItemIcon } from '../../types'
import { log } from '../../../../../common/utils/log'

export function Icon(icon?: ItemIcon) {
  if (!icon) {
    return null
  }
  if (icon.text) {
    log.deprecation(
      'The `text` property of icons in <one-ux-menu> and <one-ux-menu-button> has been deprecated and is functionally a no-op.'
    )
  }
  return html`<one-ux-icon
    class="icon"
    set=${icon.set || 'default'}
    icon=${icon.name}
    size="300"
    style=${ifDefined(icon.color ? `color: var(--one-ux-palette--${icon.color});` : undefined)}
  ></one-ux-icon>`
}
