import { html, nothing, svg } from 'lit'
import { styleMap } from 'lit/directives/style-map.js'
import { interpolate, svgTransition } from '../../common/directives/svgTransition'
import { getLineSegments } from '../../common/getLineSegments'
import { legendItem, barItem } from '../types'
import { roundedRectangle } from '../../common/paths/roundedRectangle'
import { transitionTiming } from '../animation'
import { AxisScale } from '../../common/AxisScale'

export function Stacked({
  legend,
  bar,
  axisScale,
  width,
  implicit
}: {
  legend: legendItem[]
  bar: barItem
  axisScale: AxisScale
  width: number
  implicit: boolean
}) {
  const axisMin = axisScale.ticks[0]
  const axisMax = axisScale.ticks[axisScale.ticks.length - 1]

  const segments = legend
    .filter((x) => bar.data[x.key] > 0)
    .map((x) => ({
      item: x,
      value: bar.data[x.key]
    }))

  const scaleFactor = Math.abs(width / (axisMax - axisMin))
  const total = segments.reduce((total, segment) => total + segment.value, 0)

  const lineSegments = getLineSegments({
    segments,
    width: total * scaleFactor,
    minSegmentWidth: 0,
    gap: 0
  })

  const labelTextBaseline = 12
  const barHeight = 16
  const totalHeight = barHeight

  const reservedLabelWidth = 50
  const labelGap = 4
  let labelX = Math.max(total - axisMin, 0) * scaleFactor + labelGap
  const labelOverflow = labelX + reservedLabelWidth > width
  if (labelOverflow) {
    labelX -= 2 * labelGap
    labelX = Math.min(labelX, width - labelGap)
  }

  return html`
    <svg
      style=${styleMap({
        height: `${totalHeight}px`,
        width: '100%',
        display: 'block',
        overflow: 'hidden'
      })}
    >
      ${lineSegments.map((segment, index) => {
        const radiusLeftSide = index === 0 ? 2 : 0
        const radiusRightSide = index === lineSegments.length - 1 ? 2 : 0

        return svg`
          <g
            ${svgTransition({
              timing: transitionTiming,
              attributes: {
                transform: interpolate.constant(segment.x - axisMin * scaleFactor, (x) => `translate(${x},0)`),
                opacity: interpolate.standard(0, 1, 0)
              }
            })}
          >
            <path
              height=${barHeight}
              fill=${segment.item.color}
              ${svgTransition({
                timing: transitionTiming,
                attributes: {
                  d: interpolate.standard(0, segment.width, 0, (interpolatedWidth) =>
                    roundedRectangle(
                      interpolatedWidth,
                      barHeight,
                      radiusLeftSide,
                      radiusRightSide,
                      radiusRightSide,
                      radiusLeftSide
                    )
                  )
                }
              })}
            ></path>
          </g>
        `
      })}
      ${implicit
        ? nothing
        : svg`
          <text
            x=${labelX}
            y=${labelTextBaseline}
            text-anchor=${labelOverflow ? 'end' : 'start'}
            fill="var(--one-ux-palette--brand-neutral-600)"
            paint-order="stroke"
            stroke=${labelOverflow ? 'var(--one-ux-palette--gray-100)' : 'none'}
            stroke-width="2"
            class="one-ux-typography--mono-variant-400"
          >
            ${total}
          </text>
        `}
    </svg>
  `
}
