import { Microfrontend } from './Microfrontend';
function notAvailable() {
    const message = `Method only available for managed microfrontends. This microfrontend was installed using PDR but not loaded by PDR, to get att available functionality a microfrontend must be managed with PDR.`;
    throw new Error(message);
}
export class RogueMicrofrontend extends Microfrontend {
    constructor(tag, version) {
        super({ tag, version });
    }
    get loading() {
        return true;
    }
    get loaded() {
        return true;
    }
    get prefetched() {
        return true;
    }
    compatible() {
        return false;
    }
    getUrl() {
        return 'unknown';
    }
    async load() {
        notAvailable();
    }
    preload() {
        notAvailable();
    }
    prefetch() {
        notAvailable();
    }
}
